import React, { useRef, useMemo, useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  Target,
  Repeat,
  Globe,
  Sparkles,
  TrendingUp,
  Diamond,
  Zap,
  ArrowRight,
  Plus,
  X,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import NarrativeSection from "../NarrativeSection";
import MetricCard from "./MetricCard";

// Custom hex to hsl conversion for color manipulation
const hexToHSL = (hex) => {
  // Remove the # if present
  hex = hex.replace(/^#/, "");

  // Parse the values
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16) / 255;
    g = parseInt(hex[1] + hex[1], 16) / 255;
    b = parseInt(hex[2] + hex[2], 16) / 255;
  } else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16) / 255;
    g = parseInt(hex.substring(2, 4), 16) / 255;
    b = parseInt(hex.substring(4, 6), 16) / 255;
  }

  // Find greatest and smallest values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  // Calculate hue and saturation
  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
};

// Convert HSL to HSL string
const hslToString = (hsl, alpha = 1) => {
  return `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${alpha})`;
};

// Strategic Pill component for compact display of strategy items
const StrategyPill = ({ title, icon, onClick, isActive, color }) => {
  // Convert hex to HSL for better color manipulation
  const hsl = useMemo(() => {
    try {
      return hexToHSL(color || "#ffffff");
    } catch (e) {
      return { h: 0, s: 0, l: 100 };
    }
  }, [color]);

  // Create derived colors with different luminance
  const bgColor = isActive
    ? hslToString({ ...hsl, l: Math.min(30, hsl.l) }, 0.9)
    : hslToString({ ...hsl, l: 15 }, 0.2);

  const borderColor = isActive
    ? hslToString({ ...hsl, l: 60 }, 0.8)
    : hslToString({ ...hsl, l: 40 }, 0.3);

  const iconColor = isActive
    ? hslToString({ ...hsl, l: 80 }, 1)
    : hslToString({ ...hsl, s: hsl.s * 0.7, l: 70 }, 0.7);

  return (
    <motion.button
      onClick={onClick}
      className="flex items-center gap-3 rounded-full px-4 py-2 border transition-all"
      style={{
        background: bgColor,
        borderColor: borderColor,
      }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="text-xl" style={{ color: iconColor }}>
        {icon}
      </div>
      <span
        className={`font-medium ${isActive ? "text-white" : "text-white/70"}`}
      >
        {title}
      </span>
    </motion.button>
  );
};

// Detailed strategy card that shows when a pill is selected
const StrategyDetailCard = ({ item, onClose, color }) => {
  const hsl = useMemo(() => {
    try {
      return hexToHSL(color || "#ffffff");
    } catch (e) {
      return { h: 0, s: 0, l: 100 };
    }
  }, [color]);

  const bgGradient = `linear-gradient(135deg, 
    ${hslToString({ ...hsl, l: 20 }, 0.95)} 0%, 
    ${hslToString({ ...hsl, l: 10 }, 0.9)} 100%)`;

  const borderColor = hslToString({ ...hsl, l: 30 }, 0.6);
  const accentColor = hslToString({ ...hsl, l: 60 }, 1);

  // Split content into paragraphs for better readability
  const paragraphs = useMemo(() => {
    if (!item?.value) return ["No information available"];

    // For shorter text, keep as single paragraph
    if (item.value.length < 150) return [item.value];

    return item.value.split(/(?<=[.!?])\s+/).reduce((acc, sentence) => {
      if (acc.length === 0) return [sentence];

      const lastParagraph = acc[acc.length - 1];
      if (lastParagraph.length + sentence.length > 300) {
        return [...acc, sentence];
      } else {
        return [...acc.slice(0, -1), `${lastParagraph} ${sentence}`];
      }
    }, []);
  }, [item?.value]);

  return (
    <motion.div
      layout
      className="rounded-xl overflow-hidden backdrop-blur-md border relative z-20"
      style={{
        background: bgGradient,
        borderColor: borderColor,
        width: "100%",
        boxShadow: `0 10px 30px rgba(0, 0, 0, 0.25), 0 0 15px ${hslToString(
          { ...hsl, l: 40 },
          0.3
        )}`,
      }}
      initial={{ opacity: 0, scale: 0.9, y: 20 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.9, y: 20 }}
      transition={{ type: "spring", damping: 20 }}
    >
      <div className="p-6 h-full">
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center">
            <motion.div
              className="w-12 h-12 rounded-xl flex items-center justify-center mr-4"
              style={{ backgroundColor: hslToString({ ...hsl, l: 25 }, 0.8) }}
              whileHover={{ scale: 1.05, rotate: 5 }}
            >
              <div style={{ color: accentColor }}>{item.icon}</div>
            </motion.div>
            <h3 className="text-2xl font-bold text-white">{item.title}</h3>
          </div>

          <motion.button
            onClick={onClose}
            className="w-8 h-8 rounded-full flex items-center justify-center"
            style={{ backgroundColor: hslToString({ ...hsl, l: 20 }, 0.8) }}
            whileHover={{
              scale: 1.1,
              backgroundColor: hslToString({ ...hsl, l: 30 }, 0.8),
            }}
          >
            <X size={16} color="white" />
          </motion.button>
        </div>

        <div className="space-y-4 text-white/80">
          {paragraphs.map((paragraph, i) => (
            <motion.p
              key={i}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 + i * 0.1 }}
              className="leading-relaxed"
            >
              {paragraph}
            </motion.p>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

// Main component
const BrandStrategySection = ({
  strategy,
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [selectedItem, setSelectedItem] = useState(null);
  const [showNarrative, setShowNarrative] = useState(true);

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const translateY = useTransform(
    sectionProgress,
    [0, 0.2, 0.8, 1],
    [100, 0, 0, -100]
  );
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const rotate = useTransform(sectionProgress, [0, 0.5, 1], [-2, 0, 2]);

  // Generate colors for different strategy elements
  useEffect(() => {
    // Extract primary color and convert to RGB
    const primaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--primary-color")
      .trim();

    if (primaryColor) {
      const hexToRgb = (hex) => {
        hex = hex.replace("#", "");

        // Handle shorthand hex
        if (hex.length === 3) {
          hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return `${r}, ${g}, ${b}`;
      };

      try {
        document.documentElement.style.setProperty(
          "--primary-color-rgb",
          hexToRgb(primaryColor)
        );
      } catch (e) {
        console.error("Error setting RGB color values:", e);
      }
    }
  }, []);

  // If narrative visibility is explicitly set to true in strategy settings, always show it
  useEffect(() => {
    if (strategy?.visibility?.narrative === true) {
      setShowNarrative(true);
    }
  }, [strategy?.visibility?.narrative]);

  // Prepare data for the narrative generation
  const strategyData = useMemo(
    () => ({
      competitors: strategy?.competitors,
      uniqueSellingProposition: strategy?.uniqueSellingProposition,
      perceptionChange: strategy?.perceptionChange,
      mostOnBrand: strategy?.mostOnBrand,
      needsImprovement: strategy?.needsImprovement,
      globalRecognition: strategy?.globalRecognition,
      brandPosition: strategy?.brandPosition,
      brandPromise: strategy?.brandPromise,
    }),
    [
      strategy?.competitors,
      strategy?.uniqueSellingProposition,
      strategy?.perceptionChange,
      strategy?.mostOnBrand,
      strategy?.needsImprovement,
      strategy?.globalRecognition,
      strategy?.brandPosition,
      strategy?.brandPromise,
    ]
  );

  // Early return for null strategy - must be after all hooks
  if (!strategy) {
    return null;
  }

  // Create color variations based on primary color
  const primaryHsl = hexToHSL(palette?.primary || "#01b280");
  const colorVariations = [
    { ...primaryHsl, h: (primaryHsl.h + 0) % 360 }, // Primary
    { ...primaryHsl, h: (primaryHsl.h + 30) % 360 }, // Analogous +30
    { ...primaryHsl, h: (primaryHsl.h + 330) % 360 }, // Analogous -30
    { ...primaryHsl, h: (primaryHsl.h + 60) % 360 }, // Tertiary +60
    { ...primaryHsl, h: (primaryHsl.h + 300) % 360 }, // Tertiary -60
    { ...primaryHsl, h: (primaryHsl.h + 180) % 360 }, // Complementary
  ];

  // Map HSL objects to hex strings for each color
  const getHexFromHsl = (hsl) => {
    const h = hsl.h / 360;
    const s = hsl.s / 100;
    const l = hsl.l / 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h * 6) % 2) - 1));
    const m = l - c / 2;

    let r, g, b;
    if (h < 1 / 6) {
      [r, g, b] = [c, x, 0];
    } else if (h < 2 / 6) {
      [r, g, b] = [x, c, 0];
    } else if (h < 3 / 6) {
      [r, g, b] = [0, c, x];
    } else if (h < 4 / 6) {
      [r, g, b] = [0, x, c];
    } else if (h < 5 / 6) {
      [r, g, b] = [x, 0, c];
    } else {
      [r, g, b] = [c, 0, x];
    }

    const toHex = (v) => {
      const hex = Math.round((v + m) * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const strategyColors = colorVariations.map(getHexFromHsl);

  // Create the array with all possible strategy items
  const allItems = [
    {
      title: "Competitors",
      value: strategy.competitors,
      icon: <Target size={24} />,
      color: strategyColors[0],
      field: "competitors",
    },
    {
      title: "Perception Change",
      value: strategy.perceptionChange,
      icon: <Repeat size={24} />,
      color: strategyColors[1],
      field: "perceptionChange",
    },
    {
      title: "Global Recognition",
      value: strategy.globalRecognition,
      icon: <Globe size={24} />,
      color: strategyColors[2],
      field: "globalRecognition",
    },
    {
      title: "Most On Brand",
      value: strategy.mostOnBrand,
      icon: <Sparkles size={24} />,
      color: strategyColors[3],
      field: "mostOnBrand",
    },
    {
      title: "Needs Improvement",
      value: strategy.needsImprovement,
      icon: <TrendingUp size={24} />,
      color: strategyColors[4],
      field: "needsImprovement",
    },
    {
      title: "USP",
      value: strategy.uniqueSellingProposition,
      icon: <Diamond size={24} />,
      color: strategyColors[5],
      field: "uniqueSellingProposition",
    },
    {
      title: "Brand Promise",
      value: strategy.brandPromise,
      icon: <Zap size={24} />,
      color: strategyColors[0],
      field: "brandPromise",
    },
  ];

  // Filter items based on visibility settings
  const items = allItems.filter((item) => {
    // Check if there's a visibility setting for this field
    // If visibility is undefined or the specific field is undefined, default to showing the item
    const visibility = strategy.visibility;
    if (!visibility) return true;

    // Check if the field is specifically set to false
    return visibility[item.field] !== false;
  });

  // Debug log for items
  // console.log("Brand Strategy Section items:", { allItems: allItems.map((item) => item.field), visibleItems: items.map((item) => item.field), visibility: strategy.visibility });

  // Check if narrative should be shown
  const narrativeVisible = strategy.visibility?.narrative !== false;

  // Handle no items case
  if (items.length === 0) {
    return null;
  }

  const handleItemClick = (item) => {
    if (selectedItem?.title === item.title) {
      setSelectedItem(null);
      // Only show narrative if it's not explicitly hidden
      if (strategy.visibility?.narrative !== false) {
        setShowNarrative(true);
      }
    } else {
      setSelectedItem(item);
      // Only hide narrative if it's not explicitly set to be always visible
      if (strategy.visibility?.narrative !== true) {
        setShowNarrative(false);
      }
    }
  };

  const closeDetail = () => {
    setSelectedItem(null);
    // Only show narrative if it's not explicitly hidden
    if (strategy.visibility?.narrative !== false) {
      setShowNarrative(true);
    }
  };

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-20 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Dot pattern background */}
      <div className="absolute inset-0 -z-10">
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: 0.7,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 w-full px-6"
        style={{ translateY, rotate, margin: "0 auto" }}
      >
        {/* Wrap both the header and content in a consistent width container */}
        <div className="w-full mx-auto" style={{ maxWidth: "1200px" }}>
          <motion.h2
            className="text-5xl md:text-7xl mb-12 font-bold text-white text-left"
            style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Brand Strategy
          </motion.h2>

          {/* Interactive Strategy Dashboard */}
          <div className="grid grid-cols-1 gap-8 w-full">
            {/* Strategy Pills - Now Full Width */}
            <div className="w-full">
              {/* Strategy Pills Container */}
              <div className="flex flex-wrap gap-3 mb-8">
                {items.map((item, index) => (
                  <StrategyPill
                    key={index}
                    title={item.title}
                    icon={item.icon}
                    color={item.color}
                    isActive={selectedItem?.title === item.title}
                    onClick={() => handleItemClick(item)}
                  />
                ))}
              </div>

              {/* Detail Card Container with absolute width */}
              <div
                className="content-container"
                style={{
                  position: "relative",
                  width: "100%",
                  display: "block",
                  boxSizing: "border-box",
                }}
              >
                <AnimatePresence mode="wait">
                  {selectedItem && (
                    <StrategyDetailCard
                      item={selectedItem}
                      onClose={closeDetail}
                      color={selectedItem.color}
                    />
                  )}

                  {showNarrative && narrativeVisible && (
                    <motion.div
                      layout
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden"
                      style={{ width: "100%" }}
                    >
                      <div className="flex items-center px-6 py-4 border-b border-white/10 bg-black/20">
                        <div className="p-2 rounded-lg bg-[var(--primary-color-20)] mr-3">
                          <Diamond size={18} className="text-white" />
                        </div>
                        <h3 className="text-xl font-bold text-white">
                          Strategy Overview
                        </h3>
                      </div>
                      <div className="p-6 md:p-8 h-full">
                        <NarrativeSection
                          profileId={profileId}
                          section="brandStrategy"
                          sectionData={strategyData}
                          companyUrl={companyUrl}
                          fallbackText="Analyzing brand strategy..."
                          textColor="rgba(255, 255, 255, 0.9)"
                          accentColor="var(--primary-color)"
                          style={{
                            minHeight: "250px",
                            fontSize: "1.1rem",
                            lineHeight: "1.8",
                            fontWeight: "500",
                          }}
                        />
                      </div>
                    </motion.div>
                  )}

                  {/* Brand Promise Banner - Only show if visible */}
                  {strategy.brandPromise &&
                    showNarrative &&
                    strategy.visibility?.brandPromise !== false && (
                      <motion.div
                        layout
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                        className="mt-6 bg-gradient-to-r from-[var(--primary-color-20)] to-transparent backdrop-blur-sm rounded-xl border border-[var(--primary-color-30)] p-5"
                        style={{ width: "100%" }}
                      >
                        <div className="flex items-center gap-3 mb-2">
                          <div className="p-2 rounded-lg bg-[var(--primary-color-30)]">
                            <Zap size={22} className="text-white" />
                          </div>
                          <h3 className="text-xl font-bold text-white">
                            Brand Promise
                          </h3>
                        </div>
                        <p className="text-white/90 pl-11 leading-relaxed">
                          {strategy.brandPromise}
                        </p>
                      </motion.div>
                    )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default BrandStrategySection;
