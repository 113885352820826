import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { questionnaireSteps } from "../utils/questionnaireData";
import { submitForm, generateEmailExport } from "../services/apiService";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { CheckCircle2, ArrowRight } from "lucide-react";

/**
 * ReviewForm Component
 * Displays a comprehensive summary of all collected data from previous steps
 * Styled with a "floating in space" approach, with animated elements
 */
const ReviewForm = ({ formData, onComplete }) => {
  // Add state for expanded categories
  const [expandedCategories, setExpandedCategories] = React.useState(new Set());

  const [submissionStatus, setSubmissionStatus] = React.useState({
    isSubmitting: false,
    error: null,
    success: false,
  });

  const [clientEmail, setClientEmail] = React.useState('');

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return newSet;
    });
  };

  // Debug logging to understand data structure
  useEffect(() => {
    console.log("ReviewForm formData:", formData);
  }, [formData]);

  // Generate floating shapes for background
  const generateShapes = () => {
    return (
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-64 h-64 bg-primary-green/10 rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2 animate-float" />
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-primary-green/5 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2 animate-float-delayed" />
      </div>
    );
  };

  // Prepare characteristics data for radar chart
  const characteristicsData = React.useMemo(() => {
    console.log("Raw characteristics data:", formData.brandCharacteristics);
    if (!formData.brandCharacteristics) return [];

    // Find the brand characteristics step to get the field definitions
    const characteristicsStep = questionnaireSteps.find(
      (step) => step.id === "brand-characteristics"
    );
    if (!characteristicsStep) return [];

    // Group characteristics into categories
    const categories = {
      "Time & Age": ["timeOfDay", "ageFeel"],
      "Social & Energy": ["socialNature", "tempo", "volume", "community"],
      "Style & Form": ["formality", "density", "weight"],
      "Nature & Feel": ["digitalNature", "soundNature", "playfulness"],
      "Structure & Flow": ["predictability", "power", "intensity"],
      Atmosphere: ["brightness", "temperature", "tension"],
      "Innovation & Time": ["timelessness", "structure", "organization"],
      Complexity: ["complexity", "layers", "groundedness"],
      "Movement & Focus": ["gravity", "teamwork", "energy", "focus"],
      "Change & Tradition": ["innovation", "novelty"],
    };

    // Create a map of all characteristics
    const characteristicsMap = characteristicsStep.fields.reduce(
      (acc, field) => {
        acc[field.id] = {
          id: field.id,
          label: field.label,
          leftLabel: field.leftLabel,
          rightLabel: field.rightLabel,
          value: Number(formData.brandCharacteristics[field.id]) || 3,
          category:
            Object.entries(categories).find(([_, ids]) =>
              ids.includes(field.id)
            )?.[0] || "Other",
        };
        return acc;
      },
      {}
    );

    // Group characteristics by category
    const groupedData = Object.entries(categories).map(([category, ids]) => ({
      category,
      characteristics: ids.map((id) => characteristicsMap[id]).filter(Boolean),
    }));

    console.log("Processed characteristics data:", groupedData);
    return groupedData;
  }, [formData.brandCharacteristics]);

  // Prepare demographics data for visualization
  const processedDemographicsData = React.useMemo(() => {
    if (!formData.demographics) return null;

    const demographics = formData.demographics;

    // Process age range data
    const ageRangeData = {
      current: demographics.currentAgeRange || "",
      target: demographics.targetAgeRange || "",
      label: "Age Range",
    };

    // Process territory data
    const territoryData = {
      current: demographics.currentTerritory || "",
      target: demographics.targetTerritory || "",
      label: "Territory",
    };

    // Process gender data
    const genderData = {
      current: demographics.currentGender || "",
      target: demographics.targetGender || "",
      label: "Gender Distribution",
    };

    // Process interests data
    const interestsData = {
      current: demographics.currentInterests || "",
      target: demographics.targetInterests || "",
      label: "Interests/Hobbies",
    };

    // Process lifestyle data
    const lifestyleData = {
      current: demographics.currentLifestyle || "",
      target: demographics.targetLifestyle || "",
      label: "Lifestyle",
    };

    return {
      ageRange: ageRangeData,
      territory: territoryData,
      gender: genderData,
      interests: interestsData,
      lifestyle: lifestyleData,
      coreValues: demographics.coreValues || "",
      brandEssence: demographics.brandEssence || "",
    };
  }, [formData.demographics]);

  // Prepare color palette data for pie chart
  const colorPaletteData = React.useMemo(() => {
    if (!formData.brandStyle?.colors) return [];

    return Object.entries(formData.brandStyle.colors).map(([key, value]) => ({
      name: key,
      value: 1, // Equal segments
      color: value,
    }));
  }, [formData.brandStyle?.colors]);

  // Process brand sound data for visualization
  const processedBrandSoundData = React.useMemo(() => {
    if (!formData.brandSound) return null;

    const brandSound = formData.brandSound;
    return {
      currentSound: {
        title: "Current Brand Sound",
        value: brandSound.currentSound || "",
        icon: "🎵",
      },
      soundChallenge: {
        title: "Sound Challenges",
        value: brandSound.soundChallenge || "",
        icon: "⚠️",
      },
      soundCompetitor: {
        title: "Sound Inspiration",
        value: brandSound.soundCompetitor || "",
        icon: "🎯",
      },
      aspirationalBrand: {
        title: "Aspirational Brand",
        value: brandSound.aspirationalBrand || "",
        icon: "⭐",
      },
      currentSpending: {
        title: "Annual Music Budget",
        value: brandSound.currentSpending || "",
        icon: "💰",
      },
      currentChannels: {
        title: "Distribution Channels",
        value: brandSound.currentChannels || "",
        icon: "📱",
      },
      listeningEnvironment: {
        title: "Listening Environment",
        value: brandSound.listeningEnvironment || "",
        icon: "🎧",
      },
    };
  }, [formData.brandSound]);

  const handleSubmit = async () => {
    // Check if email is provided and valid
    if (!clientEmail.trim()) {
      setSubmissionStatus({
        isSubmitting: false,
        error: "Please enter your email address to receive your brand profile summary",
        success: false,
      });
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(clientEmail)) {
      setSubmissionStatus({
        isSubmitting: false,
        error: "Please enter a valid email address",
        success: false,
      });
      return;
    }

    setSubmissionStatus({ isSubmitting: true, error: null, success: false });

    try {
      // Submit form data to backend with client email
      const submissionResult = await submitForm({
        ...formData,
        clientEmail: clientEmail,
      });

      // Generate email export with visualizations
      const emailExport = await generateEmailExport({
        ...formData,
        clientEmail: clientEmail,
      }, true);

      // Update status and notify parent component
      setSubmissionStatus({
        isSubmitting: false,
        error: null,
        success: true,
      });

      // Call onComplete with submission results
      onComplete("final-review", {
        ...formData,
        clientEmail: clientEmail,
        submissionId: submissionResult.submissionId,
        emailExport: emailExport,
      });
    } catch (error) {
      console.error("Form submission failed:", error);
      setSubmissionStatus({
        isSubmitting: false,
        error: error.message || "Failed to submit form",
        success: false,
      });
    }
  };

  // Render summary sections for each questionnaire step
  const renderSection = (stepId, title) => {
    const step = questionnaireSteps.find((s) => s.id === stepId);
    if (!step || !step.fields) return null;

    // Convert stepId to the corresponding formData key
    const formDataKey = stepId.replace(/-./g, (x) => x[1].toUpperCase());
    const sectionData = formData[formDataKey] || {};

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="mb-12"
      >
        <h3 className="text-2xl text-white font-light mb-6 flex items-center">
          <motion.div
            className="w-8 h-8 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
            whileHover={{ scale: 1.1, rotate: 5 }}
          >
            <CheckCircle2 className="w-5 h-5 text-primary-green" />
          </motion.div>
          {title}
        </h3>
        <div className="bg-white/5 backdrop-blur-lg rounded-xl p-8 border border-white/10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {step.fields.map((field) => {
              // Try to get value from nested structure first, then fallback to direct access
              const value = sectionData[field.id] || formData[field.id];

              if (!value) return null;

              return (
                <motion.div
                  key={field.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className="space-y-2"
                >
                  <p className="text-white/60 text-sm">{field.label}</p>
                  <p className="text-white text-lg">
                    {Array.isArray(value)
                      ? value.join(", ")
                      : typeof value === "object"
                        ? JSON.stringify(value)
                        : value}
                  </p>
                </motion.div>
              );
            })}
          </div>
        </div>
      </motion.div>
    );
  };

  // Demographics section renderer
  const renderDemographicsSection = () => {
    if (!processedDemographicsData) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="mb-12"
      >
        <h3 className="text-2xl text-white font-light mb-6 flex items-center">
          <motion.div
            className="w-8 h-8 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
            whileHover={{ scale: 1.1, rotate: 5 }}
          >
            <CheckCircle2 className="w-5 h-5 text-primary-green" />
          </motion.div>
          Demographics
        </h3>
        <div className="space-y-8">
          {/* Current vs Target Comparisons */}
          {Object.entries(processedDemographicsData).map(([key, data]) => {
            if (key === "coreValues" || key === "brandEssence") return null;

            return (
              <motion.div
                key={key}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
              >
                <h4 className="text-lg text-white mb-4">{data.label}</h4>
                <div className="grid grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <p className="text-primary-green text-sm">Current</p>
                    <p className="text-white">{data.current}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-violet-400 text-sm">Target</p>
                    <p className="text-white">{data.target}</p>
                  </div>
                </div>
              </motion.div>
            );
          })}

          {/* Core Values and Brand Essence */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
          >
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-2">
                <h4 className="text-lg text-white mb-2">Core Values</h4>
                <p className="text-white">
                  {processedDemographicsData.coreValues}
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg text-white mb-2">Brand Essence</h4>
                <p className="text-white">
                  {processedDemographicsData.brandEssence}
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // Brand sound section renderer
  const renderBrandSoundSection = () => {
    if (!processedBrandSoundData) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="mb-12"
      >
        <h3 className="text-2xl text-white font-light mb-6 flex items-center">
          <motion.div
            className="w-8 h-8 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
            whileHover={{ scale: 1.1, rotate: 5 }}
          >
            <CheckCircle2 className="w-5 h-5 text-primary-green" />
          </motion.div>
          Brand Sound Strategy
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Current Sound and Challenges */}
          <div className="space-y-6">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
            >
              <div className="flex items-center mb-4">
                <span className="text-2xl mr-2">
                  {processedBrandSoundData.currentSound.icon}
                </span>
                <h4 className="text-lg text-white">
                  {processedBrandSoundData.currentSound.title}
                </h4>
              </div>
              <p className="text-white/80">
                {processedBrandSoundData.currentSound.value || "Not specified"}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
            >
              <div className="flex items-center mb-4">
                <span className="text-2xl mr-2">
                  {processedBrandSoundData.soundChallenge.icon}
                </span>
                <h4 className="text-lg text-white">
                  {processedBrandSoundData.soundChallenge.title}
                </h4>
              </div>
              <p className="text-white/80">
                {processedBrandSoundData.soundChallenge.value ||
                  "No challenges specified"}
              </p>
            </motion.div>
          </div>

          {/* Inspiration and Goals */}
          <div className="space-y-6">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
            >
              <div className="flex items-center mb-4">
                <span className="text-2xl mr-2">
                  {processedBrandSoundData.soundCompetitor.icon}
                </span>
                <h4 className="text-lg text-white">
                  {processedBrandSoundData.soundCompetitor.title}
                </h4>
              </div>
              <p className="text-white/80">
                {processedBrandSoundData.soundCompetitor.value ||
                  "No competitors specified"}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10"
            >
              <div className="flex items-center mb-4">
                <span className="text-2xl mr-2">
                  {processedBrandSoundData.aspirationalBrand.icon}
                </span>
                <h4 className="text-lg text-white">
                  {processedBrandSoundData.aspirationalBrand.title}
                </h4>
              </div>
              <p className="text-white/80">
                {processedBrandSoundData.aspirationalBrand.value ||
                  "No aspirational brand specified"}
              </p>
            </motion.div>
          </div>

          {/* Implementation Details */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10 md:col-span-2"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <div className="flex items-center mb-4">
                  <span className="text-2xl mr-2">
                    {processedBrandSoundData.currentSpending.icon}
                  </span>
                  <h4 className="text-lg text-white">
                    {processedBrandSoundData.currentSpending.title}
                  </h4>
                </div>
                <p className="text-white/80">
                  {processedBrandSoundData.currentSpending.value ||
                    "Not specified"}
                </p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <span className="text-2xl mr-2">
                    {processedBrandSoundData.currentChannels.icon}
                  </span>
                  <h4 className="text-lg text-white">
                    {processedBrandSoundData.currentChannels.title}
                  </h4>
                </div>
                <p className="text-white/80">
                  {processedBrandSoundData.currentChannels.value ||
                    "Not specified"}
                </p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <span className="text-2xl mr-2">
                    {processedBrandSoundData.listeningEnvironment.icon}
                  </span>
                  <h4 className="text-lg text-white">
                    {processedBrandSoundData.listeningEnvironment.title}
                  </h4>
                </div>
                <p className="text-white/80">
                  {processedBrandSoundData.listeningEnvironment.value ||
                    "Not specified"}
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // Add this new component at the top level of the file, before the ReviewForm component
  const CharacteristicCard = ({ label, value, leftLabel, rightLabel }) => {
    const percentage = value * 20;
    const radius = 40;
    const circumference = radius * Math.PI;
    const strokeDasharray = `${circumference} ${circumference}`;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    const getMeaning = () => {
      if (value === 1) return leftLabel;
      if (value === 3) return "Neutral";
      if (value === 5) return rightLabel;
      if (value < 3) return `Leans ${leftLabel}`;
      return `Leans ${rightLabel}`;
    };

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative group h-[400px] w-full flex flex-col bg-white/5 rounded-xl p-6 border border-white/10"
      >
        {/* Question display */}
        <div className="h-20 flex items-center justify-center">
          <p className="text-white/60 text-base text-center">{label}</p>
        </div>

        <div className="flex-grow flex flex-col justify-center items-center space-y-8">
          {/* Scale labels */}
          <div className="flex justify-between text-sm text-white/40 w-full">
            <span>{leftLabel}</span>
            <span>{rightLabel}</span>
          </div>

          {/* Gauge */}
          <div className="relative w-full h-40 flex items-center justify-center">
            <svg className="w-full h-full" viewBox="0 0 100 50">
              {/* Background track */}
              <path
                d="M 10,25 A 40,40 0 0,1 90,25"
                fill="none"
                stroke="rgba(41, 52, 80, 0.5)"
                strokeWidth="4"
                strokeLinecap="round"
              />

              {/* Progress arc with gradient */}
              <defs>
                <linearGradient
                  id={`gradient-${value}`}
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="rgb(1, 178, 128)" />
                  <stop offset="100%" stopColor="rgb(139, 92, 246)" />
                </linearGradient>
              </defs>

              <path
                d="M 10,25 A 40,40 0 0,1 90,25"
                fill="none"
                stroke={`url(#gradient-${value})`}
                strokeWidth="4"
                strokeLinecap="round"
                strokeDasharray={strokeDasharray}
                strokeDashoffset={strokeDashoffset}
                style={{
                  filter: "drop-shadow(0px 2px 4px rgba(1, 178, 128, 0.3))",
                }}
              />

              {/* Value text */}
              <text
                x="50"
                y="30"
                textAnchor="middle"
                className="text-4xl font-light"
                fill="white"
              >
                {value}
              </text>
            </svg>
          </div>

          {/* Value meaning */}
          <p className="text-white/80 text-base text-center">{getMeaning()}</p>
        </div>

        {/* Tooltip */}
        <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800/90 px-3 py-2 rounded-lg text-xs text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none whitespace-nowrap">
          {`${leftLabel} ← ${value}/5 → ${rightLabel}`}
        </div>
      </motion.div>
    );
  };

  return (
    <div className="w-full">
      {/* Floating geometric shapes background */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        {generateShapes()}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="relative z-10 max-w-6xl mx-auto"
      >
        <div className="text-center mb-12">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-4xl font-light text-white mb-4"
          >
            Review Your Brand Profile
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="text-xl text-white/60"
          >
            Please review the information you've provided before final
            submission
          </motion.p>
        </div>

        {/* Website Analysis Summary */}
        {formData.websiteUrl && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-12"
          >
            <h3 className="text-2xl text-white font-light mb-6 flex items-center">
              <motion.div
                className="w-8 h-8 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
                whileHover={{ scale: 1.1, rotate: 5 }}
              >
                <CheckCircle2 className="w-5 h-5 text-primary-green" />
              </motion.div>
              Website Analysis
            </h3>
            <div className="bg-white/5 backdrop-blur-lg rounded-xl p-8 border border-white/10">
              <p className="text-white/60 text-sm">Analyzed URL</p>
              <p className="text-white text-lg mb-8">{formData.websiteUrl}</p>

              {colorPaletteData.length > 0 && (
                <>
                  <p className="text-white/60 text-sm mb-4">Color Palette</p>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={colorPaletteData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          paddingAngle={5}
                          stroke="none"
                        >
                          {colorPaletteData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip
                          content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                              return (
                                <div className="bg-gray-800 p-3 rounded shadow-lg border border-gray-700">
                                  <p className="text-white/60">
                                    {payload[0].name}
                                  </p>
                                  <div
                                    className="w-6 h-6 mt-2 rounded"
                                    style={{
                                      backgroundColor: payload[0].payload.color,
                                    }}
                                  />
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        )}

        {/* Brand Info Summary */}
        {formData.brandInfo && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-12"
          >
            <h3 className="text-2xl text-white font-light mb-6 flex items-center">
              <motion.div
                className="w-8 h-8 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
                whileHover={{ scale: 1.1, rotate: 5 }}
              >
                <CheckCircle2 className="w-5 h-5 text-primary-green" />
              </motion.div>
              Company Information
            </h3>
            <div className="bg-white/5 backdrop-blur-lg rounded-xl p-8 border border-white/10">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className="space-y-2"
                >
                  <p className="text-white/60 text-sm">Company Name</p>
                  <p className="text-white text-lg">
                    {formData.brandInfo.companyName || "Not specified"}
                  </p>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className="space-y-2"
                >
                  <p className="text-white/60 text-sm">Industry</p>
                  <p className="text-white text-lg">
                    {formData.brandInfo.industry || "Not specified"}
                  </p>
                </motion.div>
                {formData.brandInfo.brandDescription && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className="space-y-2 md:col-span-2"
                  >
                    <p className="text-white/60 text-sm">Brand Description</p>
                    <p className="text-white text-lg">
                      {formData.brandInfo.brandDescription}
                    </p>
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
        )}

        {/* Brand sections */}
        {renderSection("brand-background", "Brand Background & Goals")}
        {renderSection("brand-personality", "Brand Personality")}
        {renderSection("brand-strategy", "Brand Strategy")}

        {/* Demographics */}
        {renderDemographicsSection()}

        {/* Brand Sound */}
        {renderBrandSoundSection()}

        {/* Characteristics Chart */}
        {characteristicsData.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-12"
          >
            <h3 className="text-2xl text-white font-light mb-6 flex items-center">
              <motion.div
                className="w-8 h-8 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
                whileHover={{ scale: 1.1, rotate: 5 }}
              >
                <CheckCircle2 className="w-5 h-5 text-primary-green" />
              </motion.div>
              Brand Characteristics
            </h3>
            <div className="space-y-8">
              {characteristicsData.map((group) => {
                const isExpanded = expandedCategories.has(group.category);
                return (
                  <motion.div
                    key={group.category}
                    initial={{ height: "auto" }}
                    animate={{ height: "auto" }}
                    className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 overflow-hidden"
                  >
                    <motion.button
                      onClick={() => toggleCategory(group.category)}
                      className="w-full px-6 py-4 flex items-center justify-between text-xl text-white/80 font-light hover:bg-white/5 transition-colors"
                    >
                      <span>{group.category}</span>
                      <motion.div
                        className="w-6 h-6 flex items-center justify-center"
                        initial={false}
                        animate={{ rotate: isExpanded ? 90 : 0 }}
                        transition={{ duration: 0.2 }}
                        whileHover={{ scale: 1.1 }}
                      >
                        <ArrowRight className="w-5 h-5" />
                      </motion.div>
                    </motion.button>

                    <motion.div
                      initial={false}
                      animate={{
                        height: isExpanded ? "auto" : 0,
                        opacity: isExpanded ? 1 : 0,
                      }}
                      transition={{
                        height: { duration: 0.3 },
                        opacity: { duration: 0.2 },
                      }}
                      className="overflow-hidden"
                    >
                      <div className="p-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                          {group.characteristics.map((characteristic) => (
                            <div key={characteristic.id}>
                              <CharacteristicCard
                                label={characteristic.label}
                                value={characteristic.value}
                                leftLabel={characteristic.leftLabel}
                                rightLabel={characteristic.rightLabel}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        )}

        {/* Project Goals */}
        {renderSection("project-goals", "Project Goals & Expectations")}

        {/* Email Address Input */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-col items-center mt-16 mb-8 w-full max-w-md mx-auto"
        >
          <div className="w-full bg-white/5 backdrop-blur-lg rounded-xl p-6 border border-white/10 mb-6">
            <h3 className="text-xl text-white font-light mb-4 flex items-center">
              <motion.div
                className="w-6 h-6 rounded-full bg-primary-green/20 flex items-center justify-center mr-3"
                whileHover={{ scale: 1.1, rotate: 5 }}
              >
                <CheckCircle2 className="w-4 h-4 text-primary-green" />
              </motion.div>
              Receive Your Brand Profile Summary
            </h3>
            <p className="text-white/60 text-sm mb-4">
              Enter your email address to receive a copy of your brand profile summary.
            </p>
            <div className="relative">
              <input
                type="email"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
                placeholder="your@email.com"
                className="w-full bg-black/30 border border-white/20 rounded-lg px-4 py-3 text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primary-green/50 focus:border-primary-green/50"
                required
              />
            </div>
          </div>
        </motion.div>

        {/* Submit Button */}
        <div className="flex flex-col items-center mt-4 mb-8 space-y-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleSubmit}
            disabled={submissionStatus.isSubmitting}
            className={`group px-8 py-4 rounded-full bg-primary-green text-black font-medium flex items-center space-x-2 hover:bg-primary-green/90 transition-all duration-300 ${submissionStatus.isSubmitting
              ? "opacity-50 cursor-not-allowed"
              : ""
              }`}
          >
            <span>
              {submissionStatus.isSubmitting
                ? "Submitting..."
                : "Submit Your Brand Profile"}
            </span>
            <ArrowRight
              className={`w-5 h-5 transform group-hover:translate-x-1 transition-transform ${submissionStatus.isSubmitting ? "animate-pulse" : ""
                }`}
            />
          </motion.button>

          {/* Error Message */}
          {submissionStatus.error && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-red-500 text-sm"
            >
              {submissionStatus.error}
            </motion.p>
          )}

          {/* Success Message */}
          {submissionStatus.success && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center"
            >
              <p className="text-primary-green text-sm mb-2">
                Your brand profile has been submitted successfully!
              </p>
              <p className="text-white/60 text-xs">
                You will receive a confirmation email shortly.
              </p>
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ReviewForm;
