/**
 * Types and interfaces for the Dynamic Questionnaire system
 */

/**
 * Question Types Enum
 */
export const QUESTION_TYPES = {
  TEXT: "text", // Single line text input
  TEXTAREA: "textarea", // Multi-line text input
  SELECT: "select", // Single selection from a list
  MULTISELECT: "multiselect", // Multiple selection from a list
  RADIO: "radio", // Radio button selection
  CHECKBOX: "checkbox", // Checkbox selection
  SLIDER: "slider", // Slider input for numerical ranges
  COLOR: "color", // Color picker
  URL: "url", // URL input
};

/**
 * Section Types - Maps to the different sections of the brand profile
 */
export const SECTION_TYPES = {
  BRAND_INFO: "brandInfo",
  BRAND_CHARACTERISTICS: "brandCharacteristics",
  BRAND_BACKGROUND: "brandBackground",
  BRAND_PERSONALITY: "brandPersonality",
  BRAND_STRATEGY: "brandStrategy",
  DEMOGRAPHICS: "demographics",
  BRAND_SOUND: "brandSound",
  PROJECT_GOALS: "projectGoals",
};

/**
 * Form completion status enum
 */
export const FORM_STATUS = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

/**
 * QuestionOption type
 * Used for questions with predefined options (select, multiselect, radio, checkbox)
 */
export const QuestionOptionType = {
  id: "", // Unique identifier for the option
  label: "", // Display label for the option
  value: "", // Value to be stored when selected
};

/**
 * Question type
 * Defines the structure of a question in the questionnaire
 */
export const QuestionType = {
  id: "", // Unique identifier for the question
  sectionId: "", // Section this question belongs to
  type: "", // Question type from QUESTION_TYPES
  title: "", // Question display title
  description: "", // Optional description/help text
  placeholder: "", // Placeholder for text inputs
  required: false, // Whether this question is required
  order: 0, // Order of the question in the section
  options: [], // List of options for select/radio/checkbox types
  validation: null, // Optional validation rules
  defaultValue: null, // Default value if any
  conditional: null, // Optional conditions to show/hide this question
};

/**
 * Section type
 * Defines a section of the questionnaire
 */
export const SectionType = {
  id: "", // Unique identifier for the section
  type: "", // Section type from SECTION_TYPES
  title: "", // Display title for the section
  description: "", // Description of the section
  order: 0, // Order of this section in the questionnaire
};

/**
 * Questionnaire type
 * Defines the overall questionnaire structure
 */
export const QuestionnaireType = {
  id: "", // Unique identifier for the questionnaire
  title: "", // Title of the questionnaire
  description: "", // Description of the questionnaire
  sections: [], // List of sections in the questionnaire
  createdAt: null, // Timestamp when created
  updatedAt: null, // Timestamp when last updated
  version: 1, // Version number
  status: "active", // Status of the questionnaire (active, draft, archived)
};

/**
 * QuestionResponse type
 * Represents a user's response to a question
 */
export const QuestionResponseType = {
  questionId: "", // ID of the question
  value: null, // Value of the response (can be any type)
  timestamp: null, // When the response was given/updated
};

/**
 * ResponseSubmission type
 * Represents an entire submission of the questionnaire
 */
export const ResponseSubmissionType = {
  id: "", // Unique identifier for the submission
  questionnaireId: "", // ID of the questionnaire
  userId: "", // ID of the user who submitted
  projectId: "", // ID of the associated project
  responses: {}, // Map of questionId to QuestionResponse
  startedAt: null, // When the user started the questionnaire
  submittedAt: null, // When the user submitted the questionnaire
  status: "", // Status from FORM_STATUS
};
