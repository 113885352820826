import React, { useRef, useMemo, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  Music,
  AlertTriangle,
  Target,
  Star,
  DollarSign,
  Smartphone,
  Headphones,
  PlayCircle,
  Volume2,
  Radio,
  ChevronRight,
  Zap,
  Plus,
  ExternalLink,
} from "lucide-react";
import NarrativeSection from "../NarrativeSection";
import MetricCard from "./MetricCard";

// Interactive Sound Feature Card
const SoundFeatureCard = ({ item, index, active, onClick }) => {
  const containerRef = useRef(null);
  const delayAnimation = index * 0.1;

  return (
    <motion.div
      ref={containerRef}
      className={`rounded-xl border overflow-hidden cursor-pointer transform transition-all duration-300 ${
        active ? "col-span-full row-span-2" : ""
      }`}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        borderColor: active
          ? "var(--primary-color)"
          : "rgba(255, 255, 255, 0.1)",
        minHeight: active ? "auto" : "220px", // Ensure minimum height for better visual consistency
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: delayAnimation }}
      onClick={onClick}
      whileHover={{
        scale: active ? 1 : 1.02,
        borderColor: "var(--primary-color)",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* Card Header */}
      <div className="px-5 pt-5 pb-3 border-b border-white/10">
        <div className="flex items-center gap-3 mb-2">
          <div
            className="p-2.5 rounded-lg"
            style={{ backgroundColor: "var(--primary-color-20)" }}
          >
            {item.icon}
          </div>
          <h3 className="text-lg font-semibold text-white">{item.title}</h3>

          {active && (
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: 90 }}
              className="text-white/60 ml-auto"
            >
              <ChevronRight size={20} />
            </motion.div>
          )}
        </div>

        {/* Tag for sound importance/priority */}
        {item.priority && (
          <div className="ml-12 mt-1.5 inline-flex items-center px-3 py-1.5 rounded-full bg-blue-400/20">
            <Star size={14} className="text-blue-400 mr-1.5" />
            <span className="text-xs text-blue-400 font-medium">
              {item.priority}
            </span>
          </div>
        )}
      </div>

      {/* Card Content */}
      <div className="p-5">
        {!active ? (
          // Collapsed View
          <div>
            <p className="text-white/80 line-clamp-4 text-sm leading-relaxed">
              {item.value || "Not specified"}
            </p>
            {item.examples && (
              <div className="mt-3 flex flex-wrap gap-2">
                <span className="text-xs text-white/50">Keywords: </span>
                {item.examples.slice(0, 2).map((example, i) => (
                  <span
                    key={i}
                    className="text-xs px-2 py-1 rounded-full bg-white/10 text-white/70"
                  >
                    {example}
                  </span>
                ))}
                {item.examples.length > 2 && (
                  <span className="text-xs px-2 py-1 rounded-full bg-white/10 text-white/70">
                    +{item.examples.length - 2} more
                  </span>
                )}
              </div>
            )}
          </div>
        ) : (
          // Expanded View
          <div className="space-y-6 pt-2">
            <motion.div
              className="bg-white/5 rounded-lg p-5 border border-white/10"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: delayAnimation + 0.2 }}
            >
              <p className="text-white/90 whitespace-pre-line leading-relaxed">
                {item.value || "Not specified"}
              </p>
            </motion.div>

            {item.examples && item.examples.length > 0 && (
              <motion.div
                className="space-y-3"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: delayAnimation + 0.3 }}
              >
                <h4 className="text-lg font-medium text-white flex items-center gap-2">
                  <Music size={16} className="text-[var(--primary-color)]" />
                  Sound Example Keywords
                </h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {item.examples.map((example, i) => (
                    <div
                      key={i}
                      className="p-3 rounded-lg border border-white/10 bg-white/5 flex items-center gap-3 group"
                    >
                      <div className="w-8 h-8 rounded-full bg-[var(--primary-color-30)] flex items-center justify-center group-hover:bg-[var(--primary-color)] transition-colors duration-200">
                        <PlayCircle size={18} className="text-white" />
                      </div>
                      <span className="text-sm text-white/80">{example}</span>
                    </div>
                  ))}
                </div>
              </motion.div>
            )}

            {item.recommendations && (
              <motion.div
                className="p-5 rounded-lg bg-gradient-to-r from-[var(--primary-color-20)] to-transparent border border-[var(--primary-color-30)] mt-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: delayAnimation + 0.4 }}
              >
                <div className="flex items-center gap-2 mb-3">
                  <Zap size={16} className="text-[var(--primary-color)]" />
                  <h4 className="text-white/90 font-medium">
                    Sound Recommendations
                  </h4>
                </div>
                <p className="text-white/80 text-sm leading-relaxed">
                  {item.recommendations}
                </p>
              </motion.div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

// Sound Frequency Visualization Component
const SoundFrequency = ({ title, icon, frequency }) => {
  // Generate normalized frequency data (either from a string or an array)
  const frequencyData = useMemo(() => {
    if (!frequency) return Array(20).fill(0.1); // Default minimal visualization

    if (typeof frequency === "string") {
      // Parse a descriptive string to generate a pattern
      const lowPattern =
        frequency.toLowerCase().includes("low") ||
        frequency.toLowerCase().includes("bass");
      const highPattern =
        frequency.toLowerCase().includes("high") ||
        frequency.toLowerCase().includes("treble");
      const midPattern =
        frequency.toLowerCase().includes("mid") ||
        frequency.toLowerCase().includes("middle");

      // Generate a pattern based on the description
      return Array(20)
        .fill(0)
        .map((_, i) => {
          const pos = i / 20;
          if (lowPattern && pos < 0.3) return 0.5 + Math.random() * 0.5;
          if (midPattern && pos >= 0.3 && pos < 0.7)
            return 0.5 + Math.random() * 0.5;
          if (highPattern && pos >= 0.7) return 0.5 + Math.random() * 0.5;
          return 0.1 + Math.random() * 0.2; // Background noise
        });
    }

    return Array(20)
      .fill(0)
      .map(() => 0.1 + Math.random() * 0.9); // Random pattern as fallback
  }, [frequency]);

  return (
    <motion.div
      className="p-6 rounded-xl border border-white/10 bg-white/5 backdrop-blur-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex items-center gap-3 mb-5">
        <div
          className="p-2.5 rounded-lg"
          style={{ backgroundColor: "var(--primary-color-20)" }}
        >
          {icon}
        </div>
        <h3 className="text-xl font-bold text-white">{title}</h3>
      </div>

      {/* Frequency Visualization */}
      <div className="h-20 flex items-end justify-between gap-1 mb-4">
        {frequencyData.map((value, index) => (
          <motion.div
            key={index}
            className="w-full bg-gradient-to-t from-[var(--primary-color)] to-[var(--accent-color)]"
            initial={{ height: 0 }}
            animate={{ height: `${value * 100}%` }}
            transition={{
              delay: index * 0.03,
              duration: 0.5,
              repeat: Infinity,
              repeatType: "reverse",
              repeatDelay: Math.random() * 2,
            }}
            style={{
              borderRadius: "2px",
              opacity: 0.7 + value * 0.3,
            }}
          />
        ))}
      </div>

      {/* Description */}
      <p className="text-white/70 text-sm">
        {frequency || "No frequency information available"}
      </p>
    </motion.div>
  );
};

const BrandSoundSection = ({
  brandSound,
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [showNarrative, setShowNarrative] = useState(true);

  // Effect to manage narrative visibility on mobile when a card is expanded
  React.useEffect(() => {
    if (activeCardIndex !== null && window.innerWidth < 768) {
      setShowNarrative(false);
    } else {
      setShowNarrative(true);
    }
  }, [activeCardIndex]);

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(sectionProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const bgOpacity = useTransform(sectionProgress, [0, 0.5, 1], [0.5, 0.7, 0.5]);

  // Prepare data for the narrative generation
  const brandSoundData = useMemo(
    () => ({
      currentSound: brandSound?.currentSound,
      soundChallenge: brandSound?.soundChallenge,
      soundCompetitor: brandSound?.soundCompetitor,
      aspirationalBrand: brandSound?.aspirationalBrand,
      soundGoals: brandSound?.soundGoals,
      soundPersonality: brandSound?.soundPersonality,
      soundMood: brandSound?.soundMood,
    }),
    [
      brandSound?.currentSound,
      brandSound?.soundChallenge,
      brandSound?.soundCompetitor,
      brandSound?.aspirationalBrand,
      brandSound?.soundGoals,
      brandSound?.soundPersonality,
      brandSound?.soundMood,
    ]
  );

  if (!brandSound) return null;

  // Get example keywords from user-provided data
  const getExampleKeywords = (category) => {
    if (!category) return [];

    // Only use custom examples provided by the user
    switch (category) {
      case "Current Brand Sound":
        return brandSound.currentSoundExamples || [];
      case "Sound Challenges":
        return brandSound.soundChallengeExamples || [];
      case "Sound Inspiration":
        return brandSound.soundInspirationExamples || [];
      case "Aspirational Brand":
        return brandSound.aspirationalBrandExamples || [];
      default:
        return [];
    }
  };

  // Determine which cards to show based on visibility settings
  const shouldShowCard = (cardType) => {
    // Default to showing if not explicitly set to false
    switch (cardType) {
      case "currentSound":
        return brandSound.showCurrentSound !== false;
      case "soundChallenge":
        return brandSound.showSoundChallenges !== false;
      case "soundCompetitor":
        return brandSound.showSoundInspiration !== false;
      case "aspirationalBrand":
        return brandSound.showAspirationalBrand !== false;
      case "currentChannels":
        return brandSound.showCurrentChannels !== false;
      case "currentSpending":
        return brandSound.showCurrentSpending !== false;
      case "listeningEnvironment":
        return brandSound.showListeningEnvironment !== false;
      case "voiceType":
        return brandSound.showVoiceType !== false;
      case "soundGoals":
        return brandSound.showSoundGoals !== false;
      case "soundRecommendations":
        return brandSound.showSoundRecommendations !== false;
      default:
        return true;
    }
  };

  // Filter sound items based on visibility settings
  const filteredSoundItems = [
    {
      icon: <Music size={20} className="text-white" />,
      title: "Current Brand Sound",
      value: brandSound.currentSound,
      examples: getExampleKeywords("Current Brand Sound"),
      priority: "Current State",
      recommendations:
        "Evolve the current sound signature to maintain brand recognition while modernizing the audio experience.",
      type: "currentSound",
    },
    {
      icon: <AlertTriangle size={20} className="text-white" />,
      title: "Sound Challenges",
      value: brandSound.soundChallenge,
      examples: getExampleKeywords("Sound Challenges"),
      priority: "To Address",
      type: "soundChallenge",
    },
    {
      icon: <Target size={20} className="text-white" />,
      title: "Sound Inspiration",
      value: brandSound.soundCompetitor,
      examples: getExampleKeywords("Sound Inspiration"),
      priority: "Reference",
      type: "soundCompetitor",
    },
    {
      icon: <Star size={20} className="text-white" />,
      title: "Aspirational Brand",
      value: brandSound.aspirationalBrand,
      examples: getExampleKeywords("Aspirational Brand"),
      priority: "Target State",
      recommendations:
        "Adopt elements from the aspirational sound while maintaining your unique brand identity.",
      type: "aspirationalBrand",
    },
    brandSound.soundGoals && {
      icon: <Target size={20} className="text-white" />,
      title: "Sound Goals",
      value: brandSound.soundGoals,
      priority: "Objectives",
      type: "soundGoals",
    },
    brandSound.soundRecommendations && {
      icon: <Zap size={20} className="text-white" />,
      title: "Sound Recommendations",
      value: brandSound.soundRecommendations,
      priority: "Strategy",
      type: "soundRecommendations",
    },
    brandSound.voiceType && {
      icon: <Volume2 size={20} className="text-white" />,
      title: "Voice Type",
      value: brandSound.voiceType,
      priority: "Voice Identity",
      type: "voiceType",
    },
  ].filter((item) => item && item.value && shouldShowCard(item.type));

  const implementationItems = [
    {
      icon: <DollarSign size={20} className="text-white" />,
      title: "Annual Music Budget",
      value: brandSound.currentSpending,
      metric: true,
      type: "currentSpending",
    },
    {
      icon: <Smartphone size={20} className="text-white" />,
      title: "Distribution Channels",
      value: brandSound.currentChannels,
      metric: true,
      type: "currentChannels",
    },
    {
      icon: <Headphones size={20} className="text-white" />,
      title: "Listening Environment",
      value: brandSound.listeningEnvironment,
      metric: true,
      type: "listeningEnvironment",
    },
  ].filter((item) => item.value && shouldShowCard(item.type));

  const handleCardClick = (index) => {
    setActiveCardIndex(activeCardIndex === index ? null : index);
  };

  // Should we show the narrative section
  const shouldShowNarrative = brandSound.showNarrative !== false;

  // Should we show the sound frequency visualization
  const shouldShowSoundFrequency = brandSound.showSoundFrequency !== false;

  // Should we show implementation details
  const shouldShowImplementationDetails =
    brandSound.showImplementationDetails !== false;

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-24 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Dot pattern background */}
      <div className="absolute inset-0 -z-10">
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </div>

      {/* Audio wave pattern overlay */}
      <div className="absolute inset-0 -z-10 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAwIDEwYzMzLjMzMyAwIDMzLjMzMyAxODAgMCAxODBTNjYuNjY3IDE5MCAxMDAgMTBjNTAgMCA1MCAxODAgMCAxODBzLTUwLTE4MCAwLTE4MG01MCAwYzI1IDAgMjUgMTgwIDAgMTgwcy0yNS0xODAgMC0xODAiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIi8+PC9zdmc+')",
            backgroundSize: "300px 300px",
          }}
        ></div>
      </div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: bgOpacity,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 max-w-7xl mx-auto px-6"
        style={{ scale }}
      >
        <motion.h2
          className="text-5xl md:text-7xl mb-16 font-bold text-white"
          style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Brand Sound
        </motion.h2>

        {/* Main Content Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          {/* Narrative Section - Left Side on Desktop */}
          <AnimatePresence>
            {showNarrative && shouldShowNarrative && (
              <motion.div
                className="lg:col-span-4 order-2 lg:order-1"
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -30 }}
                transition={{ duration: 0.4 }}
              >
                <motion.div
                  className="bg-white/5 backdrop-blur-sm rounded-xl p-7 border border-white/10 lg:sticky lg:top-24"
                  whileHover={{
                    boxShadow: "0 0 30px rgba(var(--primary-color-rgb), 0.1)",
                  }}
                >
                  <div className="mb-6 flex items-center justify-between">
                    <h3 className="text-2xl font-bold text-white flex items-center gap-3">
                      <Volume2
                        className="text-[var(--primary-color)]"
                        size={22}
                      />
                      Sound Strategy
                    </h3>
                  </div>

                  <NarrativeSection
                    profileId={profileId}
                    section="brandSound"
                    sectionData={brandSoundData}
                    companyUrl={companyUrl}
                    fallbackText="Analyzing brand sound strategy..."
                    textColor="rgba(255, 255, 255, 0.9)"
                    accentColor="var(--primary-color)"
                    style={{
                      minHeight: "300px",
                      fontSize: "1.05rem",
                      lineHeight: "1.8",
                      fontWeight: "400",
                    }}
                  />
                </motion.div>

                {/* Sound Frequency Visualization */}
                {shouldShowSoundFrequency && (
                  <SoundFrequency
                    title="Sound Personality"
                    icon={<Radio size={22} className="text-white" />}
                    frequency={
                      brandSound.soundPersonality || brandSound.soundMood
                    }
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>

          {/* Sound Feature Cards - Right Side */}
          <div
            className={`${
              shouldShowNarrative ? "lg:col-span-8" : "lg:col-span-12"
            } order-1 lg:order-2`}
          >
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-5 auto-rows-min"
              transition={{ staggerChildren: 0.1 }}
            >
              {filteredSoundItems.map((item, index) => (
                <SoundFeatureCard
                  key={index}
                  item={item}
                  index={index}
                  active={activeCardIndex === index}
                  onClick={() => handleCardClick(index)}
                />
              ))}
            </motion.div>

            {/* Implementation Details */}
            {shouldShowImplementationDetails &&
              implementationItems.length > 0 && (
                <motion.div
                  className="mt-10 rounded-xl border overflow-hidden backdrop-blur-sm"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.03)",
                    borderColor: "rgba(255, 255, 255, 0.1)",
                  }}
                  whileHover={{
                    borderColor: "var(--primary-color)",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  {/* Card Header - Matching SoundFeatureCard style */}
                  <div className="px-5 pt-5 pb-3 border-b border-white/10">
                    <div className="flex items-center gap-3 mb-2">
                      <div
                        className="p-2.5 rounded-lg"
                        style={{ backgroundColor: "var(--primary-color-20)" }}
                      >
                        <Headphones size={20} className="text-white" />
                      </div>
                      <h3 className="text-lg font-semibold text-white">
                        Implementation Details
                      </h3>
                    </div>

                    {/* Tag for category */}
                    <div className="ml-12 mt-1.5 inline-flex items-center px-3 py-1.5 rounded-full bg-purple-400/20">
                      <Zap size={14} className="text-purple-400 mr-1.5" />
                      <span className="text-xs text-purple-400 font-medium">
                        Resource Planning
                      </span>
                    </div>
                  </div>

                  {/* Card Content */}
                  <div className="p-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      {implementationItems.map((item, index) => (
                        <MetricCard
                          key={index}
                          title={item.title}
                          value={item.value}
                          icon={item.icon}
                          className="aspect-auto min-h-[180px] bg-white/5 border-white/10"
                          style={{ boxShadow: "none" }}
                        />
                      ))}
                    </div>
                  </div>
                </motion.div>
              )}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default BrandSoundSection;
