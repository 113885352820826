import React, { useRef, useState, useCallback, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  Plus,
  X,
  Music,
  Trash,
  Volume2,
  SkipBack,
  SkipForward,
  Upload,
  Check,
  AlertCircle,
} from "lucide-react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { AnimatePresence } from "framer-motion";

// Custom Play button with solid fill and rounded corners
const PlayButton = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 5.14v14.72a1 1 0 001.54.84l10.42-7.36a1 1 0 000-1.68L9.54 4.3A1 1 0 008 5.14z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      rx="2"
      ry="2"
    />
  </svg>
);

// Custom Pause button with solid fill and rounded corners
const PauseButton = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" y="4" width="4" height="16" rx="1.5" ry="1.5" />
    <rect x="14" y="4" width="4" height="16" rx="1.5" ry="1.5" />
  </svg>
);

const AudioUploader = ({ onAudioUploaded, currentUrl }) => {
  const [uploadStatus, setUploadStatus] = useState(
    currentUrl ? "success" : "idle"
  ); // idle, uploading, success, error
  const [uploadProgress, setUploadProgress] = useState(0);
  const [audioUrl, setAudioUrl] = useState(currentUrl || "");
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file is audio
    if (!file.type.startsWith("audio/")) {
      setUploadStatus("error");
      setErrorMessage("Please upload a valid audio file");
      return;
    }

    // Check file size (10MB max)
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      setUploadStatus("error");
      setErrorMessage(
        `File is too large. Maximum size is 10MB. Your file is ${(
          file.size /
          (1024 * 1024)
        ).toFixed(2)}MB`
      );
      return;
    }

    setUploadStatus("uploading");
    setErrorMessage("");

    try {
      // Create a reference to Firebase Storage
      const storageRef = ref(storage, `audio/${Date.now()}_${file.name}`);

      // Create upload task
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Listen for state changes
      uploadTask.on(
        "state_changed",
        // Progress observer
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        // Error observer
        (error) => {
          console.error("Upload error:", error);
          setUploadStatus("error");
          setErrorMessage("Upload failed: " + error.message);
        },
        // Completion observer
        async () => {
          // Get the download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setAudioUrl(downloadURL);
          setUploadStatus("success");
          onAudioUploaded(downloadURL, file.name);
        }
      );
    } catch (error) {
      console.error("Error handling file upload:", error);
      setUploadStatus("error");
      setErrorMessage("Upload failed: " + error.message);
    }
  };

  const handleUrlInput = (e) => {
    const url = e.target.value;
    setAudioUrl(url);
    if (url) {
      setUploadStatus("success");
      onAudioUploaded(url);
    } else {
      setUploadStatus("idle");
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="audio-uploader mt-2 mb-4">
      <div className="flex flex-col gap-4">
        {/* URL Input */}
        <div className="relative">
          <input
            type="text"
            placeholder="Enter audio URL or upload a file"
            className="w-full bg-white/5 border border-white/10 rounded-lg py-2 px-4 text-white placeholder-white/40 focus:outline-none focus:ring-1 focus:ring-white/30"
            value={audioUrl}
            onChange={handleUrlInput}
          />

          <button
            onClick={triggerFileInput}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 p-2 rounded-lg transition-colors"
            title="Upload audio file"
          >
            <Upload size={16} className="text-white/70" />
          </button>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
            accept="audio/*"
          />
        </div>

        {/* Upload Status Indicator */}
        {uploadStatus === "uploading" && (
          <div className="flex items-center gap-2">
            <div className="w-full bg-white/10 rounded-full h-1">
              <div
                className="bg-[var(--primary-color)] h-1 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <span className="text-xs text-white/60">
              {Math.round(uploadProgress)}%
            </span>
          </div>
        )}

        {uploadStatus === "success" && (
          <div className="flex items-center gap-2 text-green-400 text-sm">
            <Check size={14} />
            <span>Audio file ready</span>
          </div>
        )}

        {uploadStatus === "error" && (
          <div className="flex items-center gap-2 text-red-400 text-sm">
            <AlertCircle size={14} />
            <span>{errorMessage || "Please upload a valid audio file"}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const AudioPlayer = ({ audioUrl, title, onPlayingChange }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);

  useEffect(() => {
    // Reset player when audio URL changes
    setIsPlaying(false);
    setProgress(0);
    setCurrentTime(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [audioUrl]);

  // Notify parent component about playing state changes
  useEffect(() => {
    if (onPlayingChange) {
      onPlayingChange(isPlaying);
    }
  }, [isPlaying, onPlayingChange]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((err) => {
          console.error("Error playing audio:", err);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const current = audioRef.current.currentTime;
      const durationTime = audioRef.current.duration;
      setCurrentTime(current);
      setProgress((current / durationTime) * 100);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleProgressClick = (e) => {
    if (progressBarRef.current && audioRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      const newTime = pos * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
      setProgress(pos * 100);
    }
  };

  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setCurrentTime(0);
      setProgress(0);
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const formatTime = (time) => {
    if (!time || isNaN(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setProgress(0);
      setCurrentTime(0);
    }
  };

  return (
    <div className="custom-audio-player w-full max-w-[300px]">
      <audio
        ref={audioRef}
        src={audioUrl}
        onEnded={handleEnded}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        className="hidden"
      />

      <div className="flex flex-col items-center">
        {/* Play/Pause Button */}
        <motion.button
          onClick={togglePlay}
          className="relative flex items-center justify-center w-28 h-28 mb-5 rounded-full bg-gradient-to-br from-[var(--primary-color)] to-[var(--primary-color-dark,_var(--primary-color))] text-white shadow-lg z-10"
          whileHover={{
            scale: 1.05,
            boxShadow: "0 0 25px rgba(var(--primary-rgb), 0.5)",
          }}
          whileTap={{ scale: 0.95 }}
          disabled={!audioUrl}
          aria-label={isPlaying ? `Pause ${title}` : `Play ${title}`}
        >
          <motion.div
            className="absolute inset-0 rounded-full bg-white opacity-10"
            animate={{
              scale: isPlaying ? [1, 1.15, 1] : 1,
              opacity: isPlaying ? [0.1, 0.2, 0.1] : 0.1,
            }}
            transition={{
              duration: 2.5,
              repeat: isPlaying ? Infinity : 0,
              repeatType: "loop",
            }}
          />
          {isPlaying ? <PauseButton size={40} /> : <PlayButton size={40} />}
        </motion.button>

        {/* Custom Progress Bar */}
        <div className="w-full px-2">
          <div
            ref={progressBarRef}
            className="relative h-2.5 bg-white/10 rounded-full cursor-pointer overflow-hidden"
            onClick={handleProgressClick}
          >
            <motion.div
              className="absolute top-0 left-0 h-full bg-[var(--primary-color)]"
              style={{ width: `${progress}%` }}
              animate={{ width: `${progress}%` }}
              transition={{ type: "tween", duration: 0.1 }}
            />
          </div>

          {/* Time display with reset functionality */}
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center gap-2">
              <button
                onClick={resetAudio}
                className="py-1.5 px-2.5 rounded-md bg-white/5 hover:bg-white/15 text-sm font-medium text-white/70 hover:text-white transition-colors flex items-center gap-1.5 z-20 relative"
                title="Reset to beginning"
              >
                <SkipBack size={16} />
                <span>{formatTime(currentTime)}</span>
              </button>
            </div>
            <span className="text-xs font-medium text-white/70">
              {formatTime(duration)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AudioCard = ({ audio, index, isEditing, onUpdateAudio, headerStyle }) => {
  const [localAudio, setLocalAudio] = useState({ ...audio });
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  // Ensure descriptions is always an array
  useEffect(() => {
    if (!Array.isArray(localAudio.descriptions)) {
      setLocalAudio((prev) => ({
        ...prev,
        descriptions: prev.description ? [prev.description] : [],
      }));
    }
  }, [localAudio]);

  const handlePlayingChange = (playing) => {
    setIsAudioPlaying(playing);
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setLocalAudio((prev) => ({ ...prev, title: newTitle }));
    onUpdateAudio(index, { ...localAudio, title: newTitle });
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setLocalAudio((prev) => ({
      ...prev,
      descriptions: [newDescription], // Keep as single description in edit mode
    }));
    onUpdateAudio(index, {
      ...localAudio,
      descriptions: [newDescription],
    });
  };

  const handleAddDescription = () => {
    const newDescriptions = [...(localAudio.descriptions || []), ""];
    setLocalAudio((prev) => ({ ...prev, descriptions: newDescriptions }));
    onUpdateAudio(index, { ...localAudio, descriptions: newDescriptions });
  };

  const handleRemoveDescription = (descIndex) => {
    const newDescriptions = localAudio.descriptions.filter(
      (_, i) => i !== descIndex
    );
    setLocalAudio((prev) => ({ ...prev, descriptions: newDescriptions }));
    onUpdateAudio(index, { ...localAudio, descriptions: newDescriptions });
  };

  const handleDescriptionChangeAtIndex = (descIndex, value) => {
    const newDescriptions = [...localAudio.descriptions];
    newDescriptions[descIndex] = value;
    setLocalAudio((prev) => ({ ...prev, descriptions: newDescriptions }));
    onUpdateAudio(index, { ...localAudio, descriptions: newDescriptions });
  };

  const handleAudioUploaded = (url, filename) => {
    setIsLoading(true);

    // If a filename is provided and there's no title yet, use the filename as title
    const newTitle =
      !localAudio.title && filename
        ? filename.replace(/\.[^/.]+$/, "")
        : localAudio.title;

    const updatedAudio = { ...localAudio, url, title: newTitle };
    setLocalAudio(updatedAudio);
    onUpdateAudio(index, updatedAudio);

    // Reset loading after a short delay
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <motion.div
      key={`audio-file-${index}`}
      className={`bg-white/5 backdrop-blur-sm rounded-2xl p-8 border ${
        isAudioPlaying
          ? "border-[var(--primary-color)] shadow-[0_0_15px_rgba(var(--primary-rgb),0.3)]"
          : "border-white/10"
      } shadow-lg transition-all duration-300`}
      style={{
        backgroundImage:
          isHovered || isAudioPlaying
            ? `linear-gradient(135deg, rgba(1, 178, 128, 0.15), rgba(1, 178, 128, 0.05))`
            : "none",
        boxShadow:
          isHovered || isAudioPlaying
            ? "0 10px 30px -10px rgba(0, 0, 0, 0.6), 0 0 20px rgba(1, 178, 128, 0.2)"
            : "0 10px 30px -15px rgba(0, 0, 0, 0.5)",
      }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      whileHover={{
        scale: 1.02,
        borderColor: "rgba(1, 178, 128, 0.3)",
      }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
    >
      {/* Sound wave animation overlay when playing */}
      {isAudioPlaying && (
        <div className="absolute inset-0 overflow-hidden rounded-2xl pointer-events-none">
          <div className="absolute inset-x-0 bottom-0 h-16 opacity-10">
            <div className="flex items-end justify-around h-full px-2">
              {[...Array(16)].map((_, i) => (
                <motion.div
                  key={i}
                  className="bg-[var(--primary-color)] w-1.5 rounded-t-full"
                  animate={{
                    height: [
                      `${Math.random() * 30 + 10}%`,
                      `${Math.random() * 70 + 30}%`,
                      `${Math.random() * 30 + 10}%`,
                    ],
                  }}
                  transition={{
                    duration: 1 + Math.random(),
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                    delay: Math.random() * 0.5,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row items-center md:items-start gap-7">
        {/* Play Button Side */}
        <div className="flex-shrink-0 relative">
          <motion.div
            className="absolute -inset-4 rounded-full bg-[var(--primary-color)]"
            initial={{ opacity: 0 }}
            animate={{
              opacity: isHovered ? [0, 0.08, 0] : 0,
              scale: isHovered ? [0.8, 1.2, 0.8] : 0.8,
            }}
            transition={{
              duration: 2.5,
              repeat: isHovered ? Infinity : 0,
              repeatType: "loop",
            }}
          />

          {localAudio.url && (
            <AudioPlayer
              audioUrl={localAudio.url}
              title={localAudio.title || `Audio ${index + 1}`}
              onPlayingChange={handlePlayingChange}
            />
          )}
        </div>

        {/* Content Side */}
        <div className="flex-1 w-full">
          {isEditing ? (
            <>
              <input
                type="text"
                value={localAudio.title || ""}
                onChange={handleTitleChange}
                placeholder="Audio Title"
                className="w-full bg-white/10 border border-white/20 rounded-lg p-2 mb-4 text-white text-xl md:text-2xl font-medium focus:border-[var(--primary-color)] transition-colors"
                disabled={isLoading}
              />

              <AudioUploader
                onAudioUploaded={handleAudioUploaded}
                currentUrl={localAudio.url}
              />

              <div className="space-y-4">
                {localAudio.descriptions?.map((desc, descIndex) => (
                  <div key={descIndex} className="relative">
                    <textarea
                      value={desc}
                      onChange={(e) =>
                        handleDescriptionChangeAtIndex(
                          descIndex,
                          e.target.value
                        )
                      }
                      placeholder={`Description ${descIndex + 1}`}
                      rows={3}
                      className="w-full bg-white/10 border border-white/20 rounded-lg p-2 text-white/80 text-base md:text-lg focus:border-[var(--primary-color)] transition-colors"
                      disabled={isLoading}
                    />
                    {localAudio.descriptions.length > 1 && (
                      <button
                        onClick={() => handleRemoveDescription(descIndex)}
                        className="absolute top-2 right-2 p-1 text-red-400 hover:text-red-300 transition-colors"
                      >
                        <X size={16} />
                      </button>
                    )}
                  </div>
                ))}
                <button
                  onClick={handleAddDescription}
                  className="w-full py-2 px-4 bg-white/5 hover:bg-white/10 rounded-lg text-white/60 hover:text-white/80 transition-colors flex items-center justify-center gap-2"
                >
                  <Plus size={16} />
                  Add Another Description
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-between mb-5">
                <h4
                  className="text-xl md:text-2xl font-medium text-white"
                  style={{
                    ...headerStyle,
                    textShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {localAudio.title || `Audio ${index + 1}`}
                </h4>
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    isHovered ? "bg-[var(--primary-color)]" : "bg-white/10"
                  } transition-all duration-300`}
                >
                  <Music className="w-5 h-5 text-white" />
                </div>
              </div>

              <div className="prose prose-lg prose-invert relative">
                <AnimatePresence mode="wait">
                  <motion.p
                    key={currentDescriptionIndex}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className="text-white/80 text-base md:text-lg leading-relaxed whitespace-pre-line"
                  >
                    {localAudio.descriptions?.[currentDescriptionIndex] ||
                      "No description provided."}
                  </motion.p>
                </AnimatePresence>
                {localAudio.descriptions?.length > 1 && (
                  <div className="flex justify-center gap-2 mt-4">
                    {localAudio.descriptions.map((_, i) => (
                      <button
                        key={i}
                        onClick={() => setCurrentDescriptionIndex(i)}
                        className={`w-2 h-2 rounded-full transition-all duration-300 ${
                          i === currentDescriptionIndex
                            ? "bg-[var(--primary-color)] scale-125"
                            : "bg-white/20 hover:bg-white/40"
                        }`}
                        aria-label={`Go to description ${i + 1}`}
                      />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const NoteCard = ({ note, index, isEditing, onUpdateNote, headerStyle }) => {
  const [localNote, setLocalNote] = useState({ ...note });
  const [isHovered, setIsHovered] = useState(false);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setLocalNote((prev) => ({ ...prev, title: newTitle }));
    onUpdateNote(index, { ...localNote, title: newTitle });
  };

  const handleContentChange = (e) => {
    const newContent = e.target.value;
    setLocalNote((prev) => ({ ...prev, content: newContent }));
    onUpdateNote(index, { ...localNote, content: newContent });
  };

  const handleLabelChange = (e) => {
    const newLabel = e.target.value;
    setLocalNote((prev) => ({ ...prev, label: newLabel }));
    onUpdateNote(index, { ...localNote, label: newLabel });
  };

  return (
    <motion.div
      key={`note-${index}`}
      className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 shadow-lg transition-all duration-300"
      style={{
        backgroundImage: isHovered
          ? `linear-gradient(135deg, rgba(1, 178, 128, 0.15), rgba(1, 178, 128, 0.05))`
          : "none",
        boxShadow: isHovered
          ? "0 10px 30px -10px rgba(0, 0, 0, 0.6), 0 0 20px rgba(1, 178, 128, 0.2)"
          : "0 10px 30px -15px rgba(0, 0, 0, 0.5)",
      }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      whileHover={{
        scale: 1.02,
        borderColor: "rgba(1, 178, 128, 0.3)",
      }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
    >
      <div className="flex flex-col gap-4">
        {isEditing ? (
          <>
            <div className="flex items-center gap-4">
              <input
                type="text"
                value={localNote.title || ""}
                onChange={handleTitleChange}
                placeholder="Note Title"
                className="flex-1 bg-white/10 border border-white/20 rounded-lg p-2 text-white text-xl font-medium focus:border-[var(--primary-color)] transition-colors"
              />
              <input
                type="text"
                value={localNote.label || ""}
                onChange={handleLabelChange}
                placeholder="Label (optional)"
                className="w-32 bg-white/10 border border-white/20 rounded-lg p-2 text-white text-sm focus:border-[var(--primary-color)] transition-colors"
              />
            </div>
            <textarea
              value={localNote.content || ""}
              onChange={handleContentChange}
              placeholder="Note content"
              rows={4}
              className="w-full bg-white/10 border border-white/20 rounded-lg p-2 text-white/80 text-base focus:border-[var(--primary-color)] transition-colors"
            />
          </>
        ) : (
          <>
            <div className="flex items-center justify-between">
              <h4
                className="text-xl font-medium text-white"
                style={{
                  ...headerStyle,
                  textShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                {localNote.title || `Note ${index + 1}`}
              </h4>
              {localNote.label && (
                <span className="px-3 py-1 rounded-full text-sm font-medium bg-[var(--primary-color)]/20 text-[var(--primary-color)]">
                  {localNote.label}
                </span>
              )}
            </div>
            <div className="prose prose-lg prose-invert">
              <p className="text-white/80 text-base leading-relaxed">
                {localNote.content || "No content provided."}
              </p>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

const SoundboardSection = ({
  description,
  timeline,
  audioFiles,
  notes = [],
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
  isEditing = false,
  onUpdateAudioFiles = () => {},
  onUpdateNotes = () => {},
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start 0.8", "end start"],
  });

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(
    sectionProgress,
    [0, 0.2, 0.8, 1],
    [0.8, 1, 1, 0.8]
  );
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);

  const handleUpdateAudio = useCallback(
    (index, updatedAudio) => {
      const newAudioFiles = [...(audioFiles || [])];
      newAudioFiles[index] = updatedAudio;
      onUpdateAudioFiles(newAudioFiles);
    },
    [audioFiles, onUpdateAudioFiles]
  );

  const handleAddAudio = useCallback(() => {
    const newAudioFiles = [
      ...(audioFiles || []),
      { title: "", description: "", url: "" },
    ];
    onUpdateAudioFiles(newAudioFiles);
  }, [audioFiles, onUpdateAudioFiles]);

  const handleUpdateNote = useCallback(
    (index, updatedNote) => {
      const newNotes = [...(notes || [])];
      newNotes[index] = updatedNote;
      onUpdateNotes(newNotes);
    },
    [notes, onUpdateNotes]
  );

  const handleAddNote = useCallback(() => {
    const newNotes = [...(notes || []), { title: "", content: "", label: "" }];
    onUpdateNotes(newNotes);
  }, [notes, onUpdateNotes]);

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-20 overflow-hidden -mt-[20vh] z-10"
      style={{
        opacity,
        backgroundColor: "rgba(17, 17, 17, 0.5)",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Background elements (no change to negative margin for these) */}
      {/* Gradient overlay for smooth transition */}
      <div
        className="absolute inset-x-0 top-0 h-[30vh] pointer-events-none"
        style={{
          background:
            "linear-gradient(to bottom, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.3) 40%, rgba(17, 17, 17, 0.5) 100%)",
          zIndex: -5,
        }}
      />

      {/* Custom CSS for audio elements */}
      <style jsx>{`
        audio {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          background-color: rgba(255, 255, 255, 0.03);
          filter: contrast(1.1);
        }

        audio::-webkit-media-controls-panel {
          background-color: rgba(15, 15, 15, 0.6);
        }

        audio::-webkit-media-controls-time-remaining-display,
        audio::-webkit-media-controls-current-time-display {
          color: white;
        }

        audio::-webkit-media-controls-play-button,
        audio::-webkit-media-controls-timeline {
          filter: brightness(1.5);
        }
      `}</style>

      {/* Dot pattern background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-gradient-to-b from-black/20 via-black/15 to-black/20 opacity-50"></div>
      </div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: 0.5,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      {/* Content container - Adjust the top padding to position content properly */}
      <motion.div
        className="relative z-10 w-full mx-auto pt-[25vh]"
        style={{ scale }}
      >
        {/* Extra gradient overlay at the top that fades with scroll */}
        <motion.div
          className="absolute inset-x-0 top-0 h-[100vh] pointer-events-none"
          style={{
            background: `linear-gradient(to bottom, var(--primary-color-15, rgba(55, 55, 55, 0.15)) 0%, transparent 100%)`,
            opacity: useTransform(sectionProgress, [0, 0.3], [1, 0]),
            zIndex: -1,
          }}
        />

        {/* Invisible spacer to ensure content starts below hero */}
        <div id="soundboard-anchor" className="h-[10vh]"></div>

        {/* Main title - centered */}
        <div className="max-w-7xl mx-auto px-6 mb-20">
          <motion.h2
            className="text-5xl md:text-7xl mb-8 font-bold text-white"
            style={{
              textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)",
              opacity: useTransform(sectionProgress, [0.05, 0.25], [0, 1]),
              y: useTransform(sectionProgress, [0.05, 0.25], [30, 0]),
            }}
          >
            Brand Soundboard
          </motion.h2>
        </div>

        {/* Description Section - Left Aligned */}
        <motion.section
          className="mb-32 min-h-[40vh] relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <div className="max-w-7xl mx-auto px-6">
            <h3 className="text-4xl md:text-5xl font-bold mb-12 text-white">
              Overview
            </h3>
          </div>

          <div className="max-w-7xl mx-auto px-6">
            <div className="w-full md:w-4/5 lg:w-2/3 ml-0">
              <motion.div
                className="prose prose-xl prose-invert max-w-3xl"
                style={textStyle}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <p className="text-lg md:text-xl leading-relaxed">
                  {description || "No description provided."}
                </p>
              </motion.div>
            </div>
          </div>
        </motion.section>

        {/* Strategy Timeline Section - Right Aligned */}
        <motion.section
          className="mb-32 min-h-[40vh] relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <div className="max-w-7xl mx-auto px-6">
            <motion.h3
              className="text-4xl md:text-5xl font-bold mb-16 text-white text-right mr-0 md:mr-0 lg:mr-0"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Strategy Timeline
            </motion.h3>
          </div>

          <div className="max-w-7xl mx-auto px-6 flex justify-center">
            <div className="w-full md:w-4/5 lg:w-3/4">
              <div className="relative">
                {/* Timeline line with gradient */}
                <div
                  className="absolute left-[30px] md:left-1/2 top-0 bottom-0 w-[3px] rounded-full md:transform md:-translate-x-1/2"
                  style={{
                    background:
                      "linear-gradient(to bottom, var(--primary-color) 0%, rgba(255, 255, 255, 0.1) 100%)",
                  }}
                ></div>

                {/* Timeline steps */}
                {timeline && timeline.length > 0 ? (
                  <div className="space-y-16">
                    {timeline.map((step, index) => (
                      <motion.div
                        key={`timeline-step-${index}`}
                        className={`relative flex items-start ${
                          index % 2 === 0
                            ? "md:justify-start"
                            : "md:justify-end"
                        }`}
                        initial={{ opacity: 0, x: -30, filter: "blur(10px)" }}
                        whileInView={{ opacity: 1, x: 0, filter: "blur(0px)" }}
                        viewport={{ once: true }}
                        transition={{ delay: index * 0.15, duration: 0.7 }}
                      >
                        {/* Timeline marker with animated pulse */}
                        <div
                          className={`absolute top-3 w-[24px] h-[24px] rounded-full bg-white shadow-lg transform -translate-x-[10.5px] md:${
                            index % 2 === 0
                              ? "-translate-x-[10.5px]"
                              : "translate-x-[10.5px]"
                          } flex items-center justify-center z-10`}
                        >
                          <div className="w-[16px] h-[16px] rounded-full bg-[var(--primary-color)]"></div>
                          <motion.div
                            className="absolute w-[40px] h-[40px] rounded-full bg-[var(--primary-color)]"
                            initial={{ opacity: 0.1 }}
                            animate={{
                              opacity: [0.1, 0.3, 0.1],
                              scale: [1, 1.5, 1],
                            }}
                            transition={{
                              duration: 3,
                              repeat: Infinity,
                              repeatType: "loop",
                              ease: "easeInOut",
                              times: [0, 0.5, 1],
                            }}
                            style={{ opacity: 0.1 }}
                          />
                        </div>

                        {/* Step content container */}
                        <div
                          className={`w-full md:w-[calc(50%-30px)] pl-16 md:pl-0 md:${
                            index % 2 === 0
                              ? "pr-16 md:pr-8 text-right"
                              : "pl-16 md:pl-8 text-left"
                          }`}
                        >
                          {/* Step number */}
                          <div
                            className={`text-sm font-bold py-1.5 px-5 rounded-full inline-block ${
                              index % 2 === 0 ? "md:ml-auto" : "md:ml-0"
                            }`}
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "white",
                              boxShadow: "0 3px 10px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            STEP {index + 1}
                          </div>

                          <motion.h4
                            className={`text-2xl md:text-3xl font-semibold mb-5 text-white mt-8 ${
                              index % 2 === 0 ? "md:text-right" : "md:text-left"
                            }`}
                            style={{
                              ...headerStyle,
                              textShadow: "0 2px 6px rgba(0, 0, 0, 0.4)",
                            }}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{
                              delay: index * 0.15 + 0.2,
                              duration: 0.5,
                            }}
                          >
                            {step.title || `Step ${index + 1}`}
                          </motion.h4>
                          <motion.div
                            className={`prose prose-2xl prose-invert ${
                              index % 2 === 0 ? "md:text-right" : "md:text-left"
                            }`}
                            style={textStyle}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{
                              delay: index * 0.15 + 0.3,
                              duration: 0.5,
                            }}
                          >
                            <p className="text-xl md:text-2xl font-medium leading-relaxed text-white/90">
                              {step.textContent || "No content provided."}
                            </p>
                          </motion.div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                ) : (
                  <motion.div
                    className="relative pl-20 min-h-[200px] flex items-center"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                  >
                    {/* Empty timeline marker */}
                    <div className="absolute left-[30px] md:left-1/2 top-1/2 w-[20px] h-[20px] rounded-full bg-white/50 shadow-glow transform -translate-x-1/2 -translate-y-1/2"></div>

                    <p className="text-white/60 italic text-xl">
                      No timeline steps added yet.
                    </p>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </motion.section>

        {/* Audio Files Section - Left Aligned */}
        <motion.section
          className="min-h-[40vh] relative overflow-hidden py-20"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <div className="max-w-7xl mx-auto px-6">
            <motion.h3
              className="text-4xl md:text-5xl font-bold mb-12 text-white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Sounds
            </motion.h3>
          </div>

          {/* Audio wave decoration */}
          <motion.div
            className="absolute right-0 top-20 w-1/3 h-40 opacity-10 z-0 pointer-events-none"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 0.1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1.2 }}
          >
            <svg viewBox="0 0 800 200" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0,100 Q200,180 400,100 T800,100"
                fill="none"
                stroke="var(--primary-color)"
                strokeWidth="2"
                strokeDasharray="5,5"
              />
              <path
                d="M0,100 Q200,20 400,100 T800,100"
                fill="none"
                stroke="var(--primary-color)"
                strokeWidth="2"
              />
              <path
                d="M0,100 Q200,150 400,100 T800,100"
                fill="none"
                stroke="var(--primary-color)"
                strokeWidth="1"
              />
            </svg>
          </motion.div>

          <div className="max-w-7xl mx-auto px-6 relative z-10">
            <div className="w-full md:w-4/5 lg:w-3/4 ml-0">
              {audioFiles && audioFiles.length > 0 ? (
                <motion.div
                  className="grid grid-cols-1 md:grid-cols-2 gap-8"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ staggerChildren: 0.1 }}
                >
                  {audioFiles.map((audio, index) => (
                    <AudioCard
                      key={`audio-card-${index}`}
                      audio={audio}
                      index={index}
                      isEditing={isEditing}
                      onUpdateAudio={handleUpdateAudio}
                      headerStyle={headerStyle}
                    />
                  ))}

                  {isEditing && (
                    <motion.div
                      className="flex items-center justify-center h-[250px] border-2 border-dashed border-white/20 rounded-2xl hover:border-[var(--primary-color)] transition-colors cursor-pointer bg-white/5 backdrop-blur-sm"
                      onClick={handleAddAudio}
                      whileHover={{
                        scale: 1.02,
                        backgroundColor: "rgba(var(--primary-rgb), 0.05)",
                      }}
                      whileTap={{ scale: 0.98 }}
                      transition={{ duration: 0.2 }}
                    >
                      <div className="flex flex-col items-center">
                        <motion.div
                          className="w-16 h-16 mb-4 rounded-full bg-white/5 flex items-center justify-center"
                          whileHover={{
                            backgroundColor: "rgba(var(--primary-rgb), 0.2)",
                          }}
                        >
                          <Plus
                            size={30}
                            className="text-[var(--primary-color)]"
                          />
                        </motion.div>
                        <p className="text-white/60 text-lg">Add Audio File</p>
                      </div>
                    </motion.div>
                  )}
                </motion.div>
              ) : (
                <motion.div
                  className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                >
                  {isEditing ? (
                    <motion.div
                      className="flex flex-col items-center justify-center py-16 cursor-pointer"
                      onClick={handleAddAudio}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <motion.div
                        className="w-20 h-20 rounded-full mb-6 bg-white/5 flex items-center justify-center"
                        whileHover={{
                          backgroundColor: "rgba(var(--primary-rgb), 0.2)",
                          boxShadow: "0 0 30px rgba(var(--primary-rgb), 0.3)",
                        }}
                        transition={{ duration: 0.3 }}
                      >
                        <Plus
                          size={40}
                          className="text-[var(--primary-color)]"
                        />
                      </motion.div>
                      <p className="text-white/60 text-xl">
                        Add your first audio file
                      </p>
                      <p className="text-white/40 text-sm mt-2 max-w-md text-center">
                        Upload audio files that represent this brand's sound
                        identity. You can add multiple files to create a
                        complete soundboard.
                      </p>
                    </motion.div>
                  ) : (
                    <div className="flex flex-col items-center justify-center py-16">
                      <div className="w-24 h-24 rounded-full bg-white/5 flex items-center justify-center mb-6">
                        <Music className="w-16 h-16 text-white/20" />
                      </div>
                      <p className="text-white/60 italic text-xl">
                        No audio files added yet.
                      </p>
                      <p className="text-white/40 text-sm mt-2 max-w-md text-center">
                        You havent added any audio files to this soundboard.
                        Check back later for audio samples that represent this
                        brand's sound identity.
                      </p>
                    </div>
                  )}
                </motion.div>
              )}
            </div>
          </div>
        </motion.section>

        {/* Notes Section - Left Aligned */}
        {(notes?.length > 0 || isEditing) && (
          <motion.section
            className="min-h-[40vh] relative overflow-hidden py-20"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <div className="max-w-7xl mx-auto px-6">
              <motion.h3
                className="text-4xl md:text-5xl font-bold mb-12 text-white"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                Notes
              </motion.h3>
            </div>

            {/* Notes wave decoration */}
            <motion.div
              className="absolute right-0 top-20 w-1/3 h-40 opacity-10 z-0 pointer-events-none"
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 0.1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1.2 }}
            >
              <svg viewBox="0 0 800 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0,100 Q200,180 400,100 T800,100"
                  fill="none"
                  stroke="var(--primary-color)"
                  strokeWidth="2"
                  strokeDasharray="5,5"
                />
                <path
                  d="M0,100 Q200,20 400,100 T800,100"
                  fill="none"
                  stroke="var(--primary-color)"
                  strokeWidth="2"
                />
                <path
                  d="M0,100 Q200,150 400,100 T800,100"
                  fill="none"
                  stroke="var(--primary-color)"
                  strokeWidth="1"
                />
              </svg>
            </motion.div>

            <div className="max-w-7xl mx-auto px-6 relative z-10">
              <div className="w-full">
                {notes && notes.length > 0 ? (
                  <motion.div
                    className="grid grid-cols-1 gap-8"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ staggerChildren: 0.1 }}
                  >
                    {notes.map((note, index) => (
                      <NoteCard
                        key={`note-card-${index}`}
                        note={note}
                        index={index}
                        isEditing={isEditing}
                        onUpdateNote={handleUpdateNote}
                        headerStyle={headerStyle}
                      />
                    ))}

                    {isEditing && (
                      <motion.div
                        className="flex items-center justify-center h-[250px] border-2 border-dashed border-white/20 rounded-2xl hover:border-[var(--primary-color)] transition-colors cursor-pointer bg-white/5 backdrop-blur-sm"
                        onClick={handleAddNote}
                        whileHover={{
                          scale: 1.02,
                          backgroundColor: "rgba(var(--primary-rgb), 0.05)",
                        }}
                        whileTap={{ scale: 0.98 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="flex flex-col items-center">
                          <motion.div
                            className="w-16 h-16 mb-4 rounded-full bg-white/5 flex items-center justify-center"
                            whileHover={{
                              backgroundColor: "rgba(var(--primary-rgb), 0.2)",
                            }}
                          >
                            <Plus
                              size={30}
                              className="text-[var(--primary-color)]"
                            />
                          </motion.div>
                          <p className="text-white/60 text-lg">Add Note</p>
                        </div>
                      </motion.div>
                    )}
                  </motion.div>
                ) : (
                  isEditing && (
                    <motion.div
                      className="flex flex-col items-center justify-center py-16 cursor-pointer"
                      onClick={handleAddNote}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <motion.div
                        className="w-20 h-20 rounded-full mb-6 bg-white/5 flex items-center justify-center"
                        whileHover={{
                          backgroundColor: "rgba(var(--primary-rgb), 0.2)",
                          boxShadow: "0 0 30px rgba(var(--primary-rgb), 0.3)",
                        }}
                        transition={{ duration: 0.3 }}
                      >
                        <Plus
                          size={40}
                          className="text-[var(--primary-color)]"
                        />
                      </motion.div>
                      <p className="text-white/60 text-xl">
                        Add your first note
                      </p>
                      <p className="text-white/40 text-sm mt-2 max-w-md text-center">
                        Add notes to provide additional context or information
                        about your brand's sound identity.
                      </p>
                    </motion.div>
                  )
                )}
              </div>
            </div>
          </motion.section>
        )}
      </motion.div>
    </motion.section>
  );
};

export default SoundboardSection;
