import React, { useRef, useMemo, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { TrendingUp, ArrowRight } from "lucide-react";
import NarrativeSection from "../../Components/NarrativeSection";

const CharacteristicsSection = ({
  characteristics,
  expandedCategories,
  toggleCategory,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  // Auto-expand all categories on initial render
  useEffect(() => {
    if (!characteristics) return;

    // Immediately expand categories
    const expandCategories = () => {
      if (Array.isArray(characteristics)) {
        characteristics.forEach((group) => {
          toggleCategory(group.category);
        });
      } else {
        // For object format, expand all categories from our mapping
        const categoryMap = createCharacteristicCategoryMap();
        Object.keys(categoryMap).forEach((category) => {
          toggleCategory(category);
        });
      }
    };

    // Expand immediately and then again after a delay to ensure it works
    expandCategories();

    // Also set a timeout as a fallback to ensure expansion happens
    const timer = setTimeout(expandCategories, 300);

    return () => clearTimeout(timer);
  }, [characteristics]); // eslint-disable-line react-hooks/exhaustive-deps

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(sectionProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const bgOpacity = useTransform(sectionProgress, [0, 0.5, 1], [0.5, 0.7, 0.5]);
  const bgBlur = useTransform(sectionProgress, [0, 0.5, 1], [0, 3, 0]);

  // Define characteristic labels mapping
  const characteristicLabels = useMemo(
    () => ({
      timeOfDay: { leftLabel: "Morning", rightLabel: "Evening" },
      ageFeel: { leftLabel: "Youthful", rightLabel: "Mature" },
      socialNature: { leftLabel: "Introverted", rightLabel: "Outspoken" },
      tempo: { leftLabel: "Walk", rightLabel: "Run" },
      volume: { leftLabel: "Whisper", rightLabel: "Shout" },
      community: { leftLabel: "Individual", rightLabel: "Crowd" },
      formality: { leftLabel: "Casual", rightLabel: "Formal" },
      density: { leftLabel: "Spacious", rightLabel: "Dense" },
      weight: { leftLabel: "Light & Airy", rightLabel: "Thick and Heavy" },
      digitalNature: { leftLabel: "Natural", rightLabel: "Digital" },
      soundNature: { leftLabel: "Organic", rightLabel: "Electronic" },
      playfulness: { leftLabel: "Serious", rightLabel: "Playful" },
      predictability: {
        leftLabel: "Steady",
        rightLabel: "Constantly Changing",
      },
      power: { leftLabel: "Soft", rightLabel: "Powerful" },
      intensity: { leftLabel: "Innocent", rightLabel: "Intense" },
      brightness: { leftLabel: "Dark", rightLabel: "Bright" },
      temperature: { leftLabel: "Cool", rightLabel: "Warm" },
      tension: { leftLabel: "Relaxed", rightLabel: "On Edge" },
      timelessness: {
        leftLabel: "Modern & Trendy",
        rightLabel: "Classic & Timeless",
      },
      structure: {
        leftLabel: "Tight & Focussed",
        rightLabel: "Loose & Free-Flowing",
      },
      organization: {
        leftLabel: "Organised & Structured",
        rightLabel: "Wild & Unpredictable",
      },
      complexity: { leftLabel: "Simple", rightLabel: "Complex" },
      layers: { leftLabel: "Single", rightLabel: "Layered" },
      groundedness: { leftLabel: "Earthy", rightLabel: "Ethereal" },
      gravity: { leftLabel: "Grounded", rightLabel: "Floaty" },
      teamwork: { leftLabel: "Pack", rightLabel: "Solo Mission" },
      energy: { leftLabel: "Calm", rightLabel: "Uplifting" },
      focus: { leftLabel: "One Clear Idea", rightLabel: "Overlapping Parts" },
      innovation: { leftLabel: "Traditional", rightLabel: "Experimental" },
      novelty: { leftLabel: "Familiar", rightLabel: "New" },
    }),
    []
  );

  // Create a function to map characteristics to categories
  const createCharacteristicCategoryMap = () => {
    return {
      "Brand Personality": [
        "playfulness",
        "tension",
        "formality",
        "socialNature",
        "intensity",
        "community",
        "teamwork",
        "energy",
      ],
      "Visual & Aesthetic": [
        "brightness",
        "temperature",
        "density",
        "weight",
        "layers",
        "digitalNature",
        "groundedness",
        "gravity",
      ],
      "Temporal & Structural": [
        "timeOfDay",
        "ageFeel",
        "tempo",
        "timelessness",
        "structure",
        "organization",
        "complexity",
        "focus",
      ],
      "Innovation & Change": [
        "innovation",
        "novelty",
        "predictability",
        "power",
      ],
      "Sound & Voice": ["volume", "soundNature"],
    };
  };

  // Create a narrative summary based on the characteristics
  const { allCharacteristics, highValues, lowValues } = useMemo(() => {
    if (!characteristics) {
      return { allCharacteristics: [], highValues: [], lowValues: [] };
    }

    // Check if characteristics is an object (not an array)
    if (!Array.isArray(characteristics)) {
      // Convert object to array format
      const processedChars = Object.entries(characteristics).map(
        ([id, value]) => ({
          id,
          value: typeof value === "number" ? value : 3, // Default to middle value if not a number
          label: id.replace(/([A-Z])/g, " $1").trim(),
        })
      );

      // Filter extreme values
      const extremeValues = processedChars.filter(
        (char) =>
          char.value === 1 ||
          char.value === 2 ||
          char.value === 4 ||
          char.value === 5
      );

      // Group by high and low values
      const highVals = extremeValues.filter((char) => char.value >= 4);
      const lowVals = extremeValues.filter((char) => char.value <= 2);

      return {
        allCharacteristics: processedChars,
        highValues: highVals,
        lowValues: lowVals,
      };
    }

    // Original array processing logic
    const allChars = characteristics.flatMap((group) =>
      group.characteristics.map((char) => ({
        ...char,
        category: group.category,
      }))
    );

    // Find extreme values (1, 2, 4, 5) as they are more defining
    const extremeValues = allChars.filter(
      (char) =>
        char.value === 1 ||
        char.value === 2 ||
        char.value === 4 ||
        char.value === 5
    );

    // Group by high (4-5) and low (1-2) values
    const highVals = extremeValues.filter((char) => char.value >= 4);
    const lowVals = extremeValues.filter((char) => char.value <= 2);

    return {
      allCharacteristics: allChars,
      highValues: highVals,
      lowValues: lowVals,
    };
  }, [characteristics]);

  // Prepare data for the narrative generation
  const characteristicsData = useMemo(
    () => ({
      characteristics: allCharacteristics,
      highValues: highValues.map((char) => ({
        id: char.id,
        label: char.label,
        value: char.value,
        category: char.category,
        leftLabel: characteristicLabels[char.id]?.leftLabel,
        rightLabel: characteristicLabels[char.id]?.rightLabel,
      })),
      lowValues: lowValues.map((char) => ({
        id: char.id,
        label: char.label,
        value: char.value,
        category: char.category,
        leftLabel: characteristicLabels[char.id]?.leftLabel,
        rightLabel: characteristicLabels[char.id]?.rightLabel,
      })),
    }),
    [allCharacteristics, highValues, lowValues, characteristicLabels]
  );

  if (!characteristics) return null;

  // Group characteristics by type for more intuitive display
  const characteristicTypes = {
    "Personality & Feel": [
      "Time & Age",
      "Social & Energy",
      "Nature & Feel",
      "Atmosphere",
    ],
    "Structure & Style": ["Style & Form", "Structure & Flow", "Complexity"],
    "Innovation & Movement": [
      "Innovation & Time",
      "Movement & Focus",
      "Change & Tradition",
    ],
  };

  // Organize characteristics
  let organizedCharacteristics;

  if (Array.isArray(characteristics)) {
    // Original array processing for grouped characteristics
    organizedCharacteristics = Object.entries(characteristicTypes).map(
      ([type, categories]) => ({
        type,
        categories: characteristics.filter((group) =>
          categories.includes(group.category)
        ),
      })
    );
  } else {
    // Convert object format to organized structure with meaningful categories
    const categoryMap = createCharacteristicCategoryMap();

    organizedCharacteristics = [
      {
        type: "Brand Characteristics",
        categories: Object.entries(categoryMap)
          .map(([category, idList]) => ({
            category,
            characteristics: Object.entries(characteristics)
              .filter(([id]) => idList.includes(id))
              .map(([id, value]) => ({
                id,
                value: typeof value === "number" ? value : 3,
                label: id.replace(/([A-Z])/g, " $1").trim(),
              }))
              .filter((char) => char.characteristics?.length !== 0), // Filter out empty categories
          }))
          .filter((group) => group.characteristics.length > 0), // Only include categories with characteristics
      },
    ];
  }

  // Helper function to mark significant characteristics
  const getSignificanceClass = (value) => {
    if (value >= 4) return "text-green-400 font-bold";
    if (value <= 2) return "text-blue-400 font-bold";
    return "";
  };

  // Different background gradients for visual variety
  const bgGradients = [
    "linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.03) 100%)",
    "linear-gradient(135deg, rgba(var(--primary-color-rgb), 0.12) 0%, rgba(var(--primary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.12) 0%, rgba(var(--secondary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--accent-color-rgb), 0.12) 0%, rgba(var(--accent-color-rgb), 0.06) 100%)",
  ];

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-24 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Dot pattern background with dynamic blur */}
      <motion.div
        className="absolute inset-0 -z-10"
        style={{ filter: `blur(${bgBlur}px)` }}
      >
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </motion.div>

      {/* Goal pattern overlay - similar to ProjectGoals */}
      <div className="absolute inset-0 -z-10 opacity-5">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMSI+PGNpcmNsZSBjeD0iMTAwIiBjeT0iMTAwIiByPSI4MCIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjEwMCIgcj0iNjAiLz48Y2lyY2xlIGN4PSIxMDAiIGN5PSIxMDAiIHI9IjQwIi8+PGNpcmNsZSBjeD0iMTAwIiBjeT0iMTAwIiByPSIyMCIvPjxsaW5lIHgxPSIyMCIgeTE9IjEwMCIgeDI9IjE4MCIgeTI9IjEwMCIvPjxsaW5lIHgxPSIxMDAiIHkxPSIyMCIgeDI9IjEwMCIgeTI9IjE4MCIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjEwMCIgcj0iMTAiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+')",
            backgroundSize: "300px 300px",
          }}
        ></div>
      </div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: bgOpacity,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 max-w-7xl mx-auto px-6"
        style={{ scale }}
      >
        <motion.h2
          className="text-5xl md:text-7xl mb-16 font-bold text-white"
          style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Brand Characteristics
        </motion.h2>

        {/* Narrative Section - with updated styling similar to ProjectGoals */}
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={{ duration: 0.5 }}
          className="mb-16"
        >
          <motion.div
            className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 overflow-hidden"
            whileHover={{
              boxShadow: "0 0 30px rgba(var(--primary-color-rgb), 0.15)",
            }}
          >
            <div className="mb-6 flex items-center justify-between">
              <h3 className="text-2xl font-bold text-white flex items-center gap-3">
                <TrendingUp className="text-[var(--primary-color)]" size={22} />
                Characteristic Analysis
              </h3>
            </div>

            <NarrativeSection
              profileId={profileId}
              section="characteristics"
              sectionData={characteristicsData}
              companyUrl={companyUrl}
              fallbackText="Analyzing brand characteristics..."
              textColor="rgba(255, 255, 255, 0.9)"
              accentColor="var(--primary-color)"
              style={{
                minHeight: "300px",
                fontSize: "1.2rem",
                lineHeight: "1.8",
                fontWeight: "500",
              }}
            />
          </motion.div>
        </motion.div>

        {/* Characteristics by Type - Updated to card style matching ProjectGoals */}
        <div className="space-y-12">
          {organizedCharacteristics.map((typeGroup, typeIndex) => (
            <div key={typeGroup.type} className="space-y-6">
              <motion.h3
                className="text-3xl font-bold text-white mb-4"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: typeIndex * 0.1 }}
              >
                {typeGroup.type}
              </motion.h3>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {typeGroup.categories.map((group, groupIndex) => {
                  // Only render categories that have characteristics
                  if (
                    !group.characteristics ||
                    group.characteristics.length === 0
                  ) {
                    return null;
                  }

                  const isExpanded = expandedCategories.has(group.category);

                  // Find any notable characteristics in this category (extremes)
                  const notableCharacteristics = group.characteristics.filter(
                    (char) => char.value <= 2 || char.value >= 4
                  );

                  // Get a gradient for this card
                  const gradient = bgGradients[groupIndex % bgGradients.length];

                  return (
                    <motion.div
                      key={group.category}
                      className="rounded-xl overflow-hidden backdrop-blur-sm border border-white/10 cursor-pointer transition-all duration-300"
                      style={{
                        background: gradient,
                      }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: groupIndex * 0.1 }}
                      whileHover={{
                        scale: 1.02,
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <motion.div
                        className="p-6"
                        initial={false}
                        animate={{
                          padding: isExpanded ? "1.5rem" : "1.5rem",
                        }}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center">
                            <motion.div
                              className="w-10 h-10 rounded-full flex items-center justify-center mr-3 text-white"
                              style={{
                                backgroundColor: "var(--primary-color)",
                              }}
                              whileHover={{ scale: 1.1 }}
                            >
                              <TrendingUp size={20} />
                            </motion.div>
                            <h3 className="text-xl font-bold text-white">
                              {group.category}
                            </h3>
                          </div>
                          <motion.div
                            animate={{ rotate: isExpanded ? 90 : 0 }}
                            transition={{ duration: 0.3 }}
                            className="text-white/60"
                            onClick={() => toggleCategory(group.category)}
                          >
                            <ArrowRight size={20} />
                          </motion.div>
                        </div>

                        {/* Status badge showing number of traits */}
                        <div className="ml-0 mt-1.5 mb-3 inline-flex items-center px-3 py-1.5 rounded-full bg-green-400/20">
                          <span className="text-xs text-green-400 font-medium">
                            {group.characteristics.length} traits
                          </span>
                        </div>

                        {/* If not expanded, show a preview */}
                        {!isExpanded && (
                          <div className="text-white/70 line-clamp-3 text-sm">
                            {notableCharacteristics.length > 0
                              ? `${
                                  notableCharacteristics.length
                                } notable characteristics including ${notableCharacteristics
                                  .slice(0, 2)
                                  .map((c) => c.label)
                                  .join(", ")}${
                                  notableCharacteristics.length > 2 ? "..." : ""
                                }`
                              : "Click to view all characteristics in this category"}
                          </div>
                        )}

                        {/* Expanded content */}
                        {isExpanded && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            className="mt-4 space-y-4"
                          >
                            {group.characteristics.map((char, charIndex) => (
                              <motion.div
                                key={char.id}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: charIndex * 0.1 }}
                                className={`p-4 rounded-lg ${
                                  char.value <= 2 || char.value >= 4
                                    ? "bg-white/5 border border-white/10"
                                    : "bg-white/5 border border-white/5"
                                }`}
                              >
                                <div className="flex justify-between items-center mb-2">
                                  <span className="text-sm font-medium text-white/90">
                                    {char.label}
                                  </span>
                                  <span
                                    className={`text-lg font-bold ${getSignificanceClass(
                                      char.value
                                    )}`}
                                  >
                                    {char.value}/5
                                  </span>
                                </div>

                                {/* Progress bar with improved styling */}
                                <div className="h-3 rounded-full overflow-hidden bg-black/20">
                                  <motion.div
                                    className="h-full rounded-full"
                                    initial={{ width: 0 }}
                                    animate={{ width: `${char.value * 20}%` }}
                                    transition={{
                                      duration: 0.5,
                                      delay: charIndex * 0.05,
                                    }}
                                    style={{
                                      background:
                                        char.value >= 4
                                          ? "linear-gradient(90deg, var(--primary-color) 0%, #4ade80 100%)"
                                          : char.value <= 2
                                          ? "linear-gradient(90deg, #3b82f6 0%, var(--primary-color) 100%)"
                                          : "linear-gradient(90deg, var(--primary-color) 0%, var(--accent-color) 100%)",
                                    }}
                                  />
                                </div>

                                {/* Labels with improved styling */}
                                <div className="flex justify-between mt-2 text-xs">
                                  <div
                                    className={`px-2 py-1 rounded-md ${
                                      char.value <= 2
                                        ? "bg-blue-400/20 text-blue-400"
                                        : "bg-white/5 text-white/60"
                                    }`}
                                  >
                                    {characteristicLabels[char.id]?.leftLabel ||
                                      "Low " + char.label}
                                  </div>
                                  <div
                                    className={`px-2 py-1 rounded-md ${
                                      char.value >= 4
                                        ? "bg-green-400/20 text-green-400"
                                        : "bg-white/5 text-white/60"
                                    }`}
                                  >
                                    {characteristicLabels[char.id]
                                      ?.rightLabel || "High " + char.label}
                                  </div>
                                </div>
                              </motion.div>
                            ))}
                          </motion.div>
                        )}
                      </motion.div>
                    </motion.div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </motion.section>
  );
};

export default CharacteristicsSection;
