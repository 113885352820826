import { db } from "../../../firebase";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";

/**
 * Questionnaire Service
 * Handles all interactions with Firebase for questionnaires
 */
const questionnaireService = {
  /**
   * Create a new questionnaire template
   * @param {Object} questionnaireData - The questionnaire data
   * @returns {Promise<string>} - The ID of the created questionnaire
   */
  async createQuestionnaire(questionnaireData) {
    try {
      const questionnaire = {
        ...questionnaireData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        version: 1,
        status: questionnaireData.status || "draft",
      };

      const docRef = await addDoc(
        collection(db, "questionnaires"),
        questionnaire
      );
      return docRef.id;
    } catch (error) {
      console.error("Error creating questionnaire:", error);
      throw error;
    }
  },

  /**
   * Get a questionnaire by ID
   * @param {string} id - The questionnaire ID
   * @returns {Promise<Object>} - The questionnaire data
   */
  async getQuestionnaireById(id) {
    try {
      const docRef = doc(db, "questionnaires", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        throw new Error("Questionnaire not found");
      }
    } catch (error) {
      console.error("Error getting questionnaire:", error);
      throw error;
    }
  },

  /**
   * Get all questionnaires, optionally filtered by status
   * @param {string} status - Optional status filter
   * @returns {Promise<Array>} - Array of questionnaires
   */
  async getAllQuestionnaires(status = null) {
    try {
      let q;
      if (status) {
        q = query(
          collection(db, "questionnaires"),
          where("status", "==", status),
          orderBy("updatedAt", "desc")
        );
      } else {
        q = query(
          collection(db, "questionnaires"),
          orderBy("updatedAt", "desc")
        );
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error getting questionnaires:", error);
      throw error;
    }
  },

  /**
   * Get the active questionnaire
   * @returns {Promise<Object|null>} - The active questionnaire or null if none
   */
  async getActiveQuestionnaire() {
    try {
      const questionnaires = await this.getAllQuestionnaires("active");
      return questionnaires.length > 0 ? questionnaires[0] : null;
    } catch (error) {
      console.error("Error getting active questionnaire:", error);
      throw error;
    }
  },

  /**
   * Update a questionnaire
   * @param {string} id - The questionnaire ID
   * @param {Object} data - The updated data
   * @returns {Promise<void>}
   */
  async updateQuestionnaire(id, data) {
    try {
      const docRef = doc(db, "questionnaires", id);
      await updateDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error updating questionnaire:", error);
      throw error;
    }
  },

  /**
   * Delete a questionnaire
   * @param {string} id - The questionnaire ID
   * @returns {Promise<void>}
   */
  async deleteQuestionnaire(id) {
    try {
      const docRef = doc(db, "questionnaires", id);
      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error deleting questionnaire:", error);
      throw error;
    }
  },

  /**
   * Add a section to a questionnaire
   * @param {string} questionnaireId - The questionnaire ID
   * @param {Object} sectionData - The section data
   * @returns {Promise<void>}
   */
  async addSection(questionnaireId, sectionData) {
    try {
      const questionnaire = await this.getQuestionnaireById(questionnaireId);
      const sections = [...(questionnaire.sections || []), sectionData];

      await this.updateQuestionnaire(questionnaireId, { sections });
    } catch (error) {
      console.error("Error adding section:", error);
      throw error;
    }
  },

  /**
   * Update a section
   * @param {string} questionnaireId - The questionnaire ID
   * @param {string} sectionId - The section ID
   * @param {Object} sectionData - The updated section data
   * @returns {Promise<void>}
   */
  async updateSection(questionnaireId, sectionId, sectionData) {
    try {
      const questionnaire = await this.getQuestionnaireById(questionnaireId);
      const sections = questionnaire.sections.map((section) =>
        section.id === sectionId ? { ...section, ...sectionData } : section
      );

      await this.updateQuestionnaire(questionnaireId, { sections });
    } catch (error) {
      console.error("Error updating section:", error);
      throw error;
    }
  },

  /**
   * Delete a section
   * @param {string} questionnaireId - The questionnaire ID
   * @param {string} sectionId - The section ID to delete
   * @returns {Promise<void>}
   */
  async deleteSection(questionnaireId, sectionId) {
    try {
      const questionnaire = await this.getQuestionnaireById(questionnaireId);
      const sections = questionnaire.sections.filter(
        (section) => section.id !== sectionId
      );

      await this.updateQuestionnaire(questionnaireId, { sections });
    } catch (error) {
      console.error("Error deleting section:", error);
      throw error;
    }
  },

  /**
   * Add a question to a section
   * @param {string} questionnaireId - The questionnaire ID
   * @param {string} sectionId - The section ID
   * @param {Object} questionData - The question data
   * @returns {Promise<void>}
   */
  async addQuestion(questionnaireId, sectionId, questionData) {
    try {
      const questionnaire = await this.getQuestionnaireById(questionnaireId);
      const sections = questionnaire.sections.map((section) => {
        if (section.id === sectionId) {
          const questions = [...(section.questions || []), questionData];
          return { ...section, questions };
        }
        return section;
      });

      await this.updateQuestionnaire(questionnaireId, { sections });
    } catch (error) {
      console.error("Error adding question:", error);
      throw error;
    }
  },

  /**
   * Update a question
   * @param {string} questionnaireId - The questionnaire ID
   * @param {string} sectionId - The section ID
   * @param {string} questionId - The question ID
   * @param {Object} questionData - The updated question data
   * @returns {Promise<void>}
   */
  async updateQuestion(questionnaireId, sectionId, questionId, questionData) {
    try {
      const questionnaire = await this.getQuestionnaireById(questionnaireId);
      const sections = questionnaire.sections.map((section) => {
        if (section.id === sectionId) {
          const questions = section.questions.map((question) =>
            question.id === questionId
              ? { ...question, ...questionData }
              : question
          );
          return { ...section, questions };
        }
        return section;
      });

      await this.updateQuestionnaire(questionnaireId, { sections });
    } catch (error) {
      console.error("Error updating question:", error);
      throw error;
    }
  },

  /**
   * Delete a question
   * @param {string} questionnaireId - The questionnaire ID
   * @param {string} sectionId - The section ID
   * @param {string} questionId - The question ID to delete
   * @returns {Promise<void>}
   */
  async deleteQuestion(questionnaireId, sectionId, questionId) {
    try {
      const questionnaire = await this.getQuestionnaireById(questionnaireId);
      const sections = questionnaire.sections.map((section) => {
        if (section.id === sectionId) {
          const questions = section.questions.filter(
            (question) => question.id !== questionId
          );
          return { ...section, questions };
        }
        return section;
      });

      await this.updateQuestionnaire(questionnaireId, { sections });
    } catch (error) {
      console.error("Error deleting question:", error);
      throw error;
    }
  },
};

export default questionnaireService;
