import React from "react";
import { motion } from "framer-motion";
import { Mail, Linkedin, Instagram } from "lucide-react";

const About = () => {
  const teamMembers = [
    {
      name: "Luke Moseley",
      role: "Co-Founder",
      image: "/images/Luke_green.webp",
      email: "luke@hearmeout.com",
      linkedin: "https://www.linkedin.com/in/luke-moseley-b51aa647/",
      instagram: "https://www.instagram.com/moseleynoise",
    },
    {
      name: "George Holliday",
      role: "Co-Founder",
      image: "/images/George_green.webp",
      email: "george@hearmeout.com",
      linkedin: "https://www.linkedin.com/in/georgeholliday/",
      instagram: "https://www.instagram.com/georgejazzmanholliday/",
    },
  ];

  // Container animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  // Individual element animation variants
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.25, 0.25, 0, 0.95],
      },
    },
  };

  // Text fade in variants
  const textVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  return (
    <section className="relative py-24 bg-[#111111]">
      {/* Background Elements */}
      <div className="absolute inset-0 pointer-events-none">
        {/* Animated Background Logo */}
        <motion.div
          initial={{ opacity: 0, scale: 1.2 }}
          animate={{ opacity: 0.3 }}
          style={{
            rotate: [0, 3, -3, 0],
          }}
          transition={{
            opacity: { duration: 2 },
            rotate: {
              repeat: Infinity,
              duration: 20,
              ease: "easeInOut",
            },
          }}
          className="absolute inset-0"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src="/HEAR ME OUT - Logo - Purple.svg"
              alt=""
              className="w-[200%] md:w-[150%] max-w-none opacity-30 -mt-[25%]"
              style={{ filter: "blur(2px)" }}
            />
          </div>
        </motion.div>

        {/* Dot Pattern */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6">
        {/* Header Text */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="text-center max-w-4xl mx-auto mb-16"
        >
          <motion.div variants={textVariants}>
            <h1 className="text-4xl md:text-6xl font-light text-white/90 mb-8">
              Meet The Hear Me Out
              <br />
              <span className="font-bold">Sonic Branding Team</span>
            </h1>
          </motion.div>

          <motion.div variants={textVariants} className="space-y-6">
            <p className="text-lg md:text-xl text-white/70">
              At Hear Me Out, we feel that the agency world is full of
              justifying fees with lengthy presentations and waffle.
            </p>
            <p className="text-lg md:text-xl text-white/70">
              We're on a mission to change this. We've built a great process
              that keeps things fun, keeps things easy, without sacrificing a
              high quality outcome.
            </p>
            <p className="text-lg md:text-xl text-white/70">
              We wouldn't have the high level of client testimonials we have if
              we were BS'ing you. That's why we've made a page totally dedicated
              to Our Process, so you don't need to wait to start your Sonic
              Branding with us to see all the action.
            </p>
          </motion.div>
        </motion.div>

        {/* Team Members */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid md:grid-cols-2 gap-12"
        >
          {teamMembers.map((member) => (
            <motion.div
              key={member.name}
              variants={itemVariants}
              className="relative bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-2xl p-8 text-center"
              style={{
                background: `linear-gradient(135deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))`,
              }}
            >
              <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />

              <div className="relative z-10">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  className="mb-6"
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-b from-primary-purple/30 via-transparent to-primary-green/5 rounded-full" />
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-64 h-64 mx-auto rounded-full relative z-10"
                    />
                  </div>
                </motion.div>
                <h3 className="text-2xl font-bold text-white/90 mb-2">
                  {member.name}
                </h3>
                <p className="text-primary-green text-lg mb-6">{member.role}</p>

                {/* Contact Information */}
                <div className="space-y-4 text-center">
                  <a href={`mailto:${member.email}`} className="block group">
                    <p className="text-white/50 mb-1 text-sm">Email</p>
                    <div className="flex items-center gap-2 text-white group-hover:text-primary-green transition-colors justify-center">
                      <Mail className="w-5 h-5" />
                      <span>{member.email}</span>
                    </div>
                  </a>
                  <div className="flex gap-4 justify-center">
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                    >
                      <Linkedin className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                    </a>
                    <a
                      href={member.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                    >
                      <Instagram className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Background Gradient - Moved to bottom */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-transparent to-[#111111] opacity-60 pointer-events-none" />
    </section>
  );
};

export default About;
