import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HiOutlineCog, HiPlus, HiTrash, HiPencil } from "react-icons/hi";
import QuestionBuilder from "./QuestionBuilder";
import questionnaireService from "../services/questionnaireService";
import { SECTION_TYPES } from "../models/types";
import {
  createQuestion,
  reorderItems,
  formatQuestionForAdmin,
} from "../utils/helpers";

/**
 * SectionBuilder component
 * Allows editing a section and its questions
 */
const SectionBuilder = ({
  section,
  questionnaireId,
  onUpdateSection,
  onDeleteSection,
  dragHandleProps,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [title, setTitle] = useState(section.title);
  const [description, setDescription] = useState(section.description);
  const [type, setType] = useState(section.type);
  const [expanded, setExpanded] = useState(false);

  // Handle save section edits
  const handleSaveSection = () => {
    onUpdateSection({
      ...section,
      title,
      description,
      type,
    });
    setIsEditing(false);
  };

  // Cancel section edits
  const handleCancelEdit = () => {
    setTitle(section.title);
    setDescription(section.description);
    setType(section.type);
    setIsEditing(false);
  };

  // Add a new question
  const handleAddQuestion = () => {
    setIsAddingQuestion(true);
    setEditingQuestion(null);
  };

  // Edit an existing question
  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
    setIsAddingQuestion(true);
  };

  // Handle save question
  const handleSaveQuestion = async (questionData) => {
    try {
      if (editingQuestion) {
        // Update existing question
        await questionnaireService.updateQuestion(
          questionnaireId,
          section.id,
          editingQuestion.id,
          questionData
        );
        toast.success("Question updated successfully");
      } else {
        // Add new question
        const maxOrder = section.questions
          ? Math.max(...section.questions.map((q) => q.order), -1)
          : -1;

        const newQuestion = createQuestion(
          section.id,
          questionData.type,
          questionData.title,
          {
            ...questionData,
            order: maxOrder + 1,
          }
        );

        await questionnaireService.addQuestion(
          questionnaireId,
          section.id,
          newQuestion
        );
        toast.success("Question added successfully");
      }

      // Update the section in the parent component
      const updatedQuestionnaire =
        await questionnaireService.getQuestionnaireById(questionnaireId);
      const updatedSection = updatedQuestionnaire.sections.find(
        (s) => s.id === section.id
      );

      if (updatedSection) {
        onUpdateSection(updatedSection);
      }

      setIsAddingQuestion(false);
      setEditingQuestion(null);
    } catch (error) {
      console.error("Error saving question:", error);
      toast.error("Failed to save question");
    }
  };

  // Handle delete question
  const handleDeleteQuestion = async (questionId) => {
    if (!window.confirm("Are you sure you want to delete this question?")) {
      return;
    }

    try {
      await questionnaireService.deleteQuestion(
        questionnaireId,
        section.id,
        questionId
      );

      // Update the section in the parent component
      const updatedQuestionnaire =
        await questionnaireService.getQuestionnaireById(questionnaireId);
      const updatedSection = updatedQuestionnaire.sections.find(
        (s) => s.id === section.id
      );

      if (updatedSection) {
        onUpdateSection(updatedSection);
      }

      toast.success("Question deleted successfully");
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("Failed to delete question");
    }
  };

  // Handle drag and drop reordering of questions
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Reorder questions array
    const reorderedQuestions = reorderItems(
      section.questions,
      source.index,
      destination.index
    );

    // Update section with reordered questions
    try {
      await questionnaireService.updateSection(questionnaireId, section.id, {
        ...section,
        questions: reorderedQuestions,
      });

      // Update the section in the parent component
      onUpdateSection({
        ...section,
        questions: reorderedQuestions,
      });

      toast.success("Questions reordered successfully");
    } catch (error) {
      console.error("Error reordering questions:", error);
      toast.error("Failed to reorder questions");
    }
  };

  return (
    <div className="border border-neutral-700 rounded-lg overflow-hidden bg-neutral-800">
      {/* Section header */}
      <div className="flex items-center justify-between p-4 bg-neutral-800 border-b border-neutral-700">
        <div className="flex items-center gap-3">
          <div
            {...dragHandleProps}
            className="cursor-grab text-neutral-400 hover:text-white"
          >
            <HiOutlineCog size={20} />
          </div>

          <div>
            <h3 className="text-lg font-medium text-white">{section.title}</h3>
            <p className="text-sm text-neutral-400">{section.description}</p>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={() => setIsEditing(true)}
            className="p-2 text-blue-400 hover:bg-blue-500/20 rounded-full"
            title="Edit section"
          >
            <HiPencil size={18} />
          </button>

          <button
            onClick={onDeleteSection}
            className="p-2 text-red-400 hover:bg-red-500/20 rounded-full"
            title="Delete section"
          >
            <HiTrash size={18} />
          </button>

          <button
            onClick={() => setExpanded(!expanded)}
            className="p-2 text-neutral-400 hover:bg-neutral-700 rounded-full ml-2"
          >
            <span className="text-xs font-medium">
              {expanded ? "Collapse" : "Expand"}
            </span>
          </button>
        </div>
      </div>

      {/* Section edit form */}
      {isEditing && (
        <div className="p-4 bg-neutral-700/30 border-b border-neutral-700">
          <h4 className="text-base font-medium mb-3">Edit Section</h4>

          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-neutral-300 mb-1">
                Section Type
              </label>
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
              >
                {Object.entries(SECTION_TYPES).map(([key, value]) => (
                  <option key={value} value={value}>
                    {key.replace(/_/g, " ")}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-neutral-300 mb-1">
                Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-neutral-300 mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green h-20"
              />
            </div>

            <div className="flex justify-end space-x-3 pt-2">
              <button
                onClick={handleCancelEdit}
                className="px-3 py-1.5 border border-neutral-600 text-neutral-300 rounded-md hover:bg-neutral-700 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveSection}
                className="px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                disabled={!title}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Questions list and editor */}
      {expanded && (
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-base font-medium">Questions</h4>
            <button
              onClick={handleAddQuestion}
              className="flex items-center gap-1 px-3 py-1.5 bg-primary-green text-black rounded-md hover:bg-primary-green/90 transition text-sm"
            >
              <HiPlus size={16} />
              <span>Add Question</span>
            </button>
          </div>

          {isAddingQuestion ? (
            <QuestionBuilder
              question={editingQuestion}
              onSave={handleSaveQuestion}
              onCancel={() => {
                setIsAddingQuestion(false);
                setEditingQuestion(null);
              }}
            />
          ) : section.questions && section.questions.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={`section-${section.id}-questions`}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2"
                  >
                    {section.questions
                      .sort((a, b) => a.order - b.order)
                      .map((question, index) => (
                        <Draggable
                          key={question.id}
                          draggableId={question.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="flex items-center justify-between p-3 bg-neutral-700 rounded-md"
                            >
                              <div className="flex items-center gap-2">
                                <div
                                  {...provided.dragHandleProps}
                                  className="cursor-grab text-neutral-400 hover:text-white"
                                >
                                  <HiOutlineCog size={16} />
                                </div>
                                <div className="font-medium">
                                  {formatQuestionForAdmin(question)}
                                </div>
                              </div>

                              <div className="flex items-center gap-1">
                                <button
                                  onClick={() => handleEditQuestion(question)}
                                  className="p-1.5 text-blue-400 hover:bg-blue-500/20 rounded"
                                  title="Edit question"
                                >
                                  <HiPencil size={16} />
                                </button>
                                <button
                                  onClick={() =>
                                    handleDeleteQuestion(question.id)
                                  }
                                  className="p-1.5 text-red-400 hover:bg-red-500/20 rounded"
                                  title="Delete question"
                                >
                                  <HiTrash size={16} />
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="p-4 bg-neutral-700/30 rounded-md text-neutral-400 text-center">
              No questions yet. Click "Add Question" to create one.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionBuilder;
