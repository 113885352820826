import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Target,
  ListChecks,
  Calendar,
  Users,
  Star,
  AlertCircle,
  ArrowRight,
  CheckCircle2,
} from "lucide-react";

/**
 * ProjectGoalsForm component - Sonic branding project goals step
 * Styled with a "floating in space" approach, with animated elements
 */
const ProjectGoalsForm = ({ formData, updateNestedFormData, onComplete }) => {
  const [focusedField, setFocusedField] = useState(null);
  const [errors, setErrors] = useState({});
  const [cursorVisible, setCursorVisible] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);

  // Get project goals data from parent component or use default values
  const projectGoals = formData?.projectGoals || {
    mainGoal: "",
    processExpectations: "",
    deadlines: "",
    teamInvolvement: "",
    successMetrics: "",
  };

  // Define the questions
  const questions = [
    {
      id: "mainGoal",
      label:
        "What is the main goal you would be thrilled to achieve with utilizing Sonic Branding?",
      type: "textarea",
      icon: <Target className="w-6 h-6" />,
      required: true,
      placeholder: "Describe your primary goal for sonic branding",
      hint: "Think about the most important outcome you want to achieve through this project",
    },
    {
      id: "processExpectations",
      label: "Are there any specific expectations for the process?",
      type: "textarea",
      icon: <ListChecks className="w-6 h-6" />,
      required: false,
      placeholder: "Share your expectations about the project process",
      hint: "Include any specific requirements, preferences, or concerns about how the project should be conducted",
    },
    {
      id: "deadlines",
      label: "Are there any deadlines we need to be aware about?",
      type: "textarea",
      icon: <Calendar className="w-6 h-6" />,
      required: true,
      placeholder: "List any important dates or deadlines",
      hint: "Include both hard deadlines and preferred timelines for different project phases",
    },
    {
      id: "teamInvolvement",
      label:
        "How important is being physically involved in the creative process to you and your team?",
      type: "radio",
      icon: <Users className="w-6 h-6" />,
      required: true,
      options: [
        {
          value: "very-involved",
          label: "Very involved - We want to be part of every step",
        },
        {
          value: "somewhat-involved",
          label: "Somewhat involved - Key milestones only",
        },
        {
          value: "light-involvement",
          label: "Light involvement - Major decisions only",
        },
        { value: "minimal", label: "Minimal - Just show us the final results" },
      ],
      hint: "Select the level of involvement that best matches your team's preferences and availability",
    },
    {
      id: "successMetrics",
      label: "How will you measure the success of this sonic branding project?",
      type: "textarea",
      icon: <Star className="w-6 h-6" />,
      required: true,
      placeholder: "Describe your success metrics",
      hint: "Consider both quantitative metrics (e.g., brand recognition) and qualitative outcomes (e.g., emotional connection)",
    },
  ];

  // Refs for inputs
  const inputRefs = useRef({});

  // Cursor blink effect for focused fields
  useEffect(() => {
    let blinkInterval;
    if (focusedField) {
      blinkInterval = setInterval(() => {
        setCursorVisible((prev) => !prev);
      }, 530);
    } else {
      setCursorVisible(false);
    }

    return () => clearInterval(blinkInterval);
  }, [focusedField]);

  // Show hint highlight periodically
  useEffect(() => {
    const highlightInterval = setInterval(() => {
      setShowHighlight(true);
      setTimeout(() => setShowHighlight(false), 2000);
    }, 8000);

    return () => clearInterval(highlightInterval);
  }, []);

  // Handle input changes
  const handleInputChange = (field, value) => {
    updateNestedFormData("projectGoals", field, value);

    // Clear error for this field if it exists
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Validate form
    const newErrors = {};
    questions.forEach((question) => {
      if (question.required && !projectGoals[question.id]) {
        newErrors[question.id] = `${question.label} is required`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Find first question with error and jump to it
      const errorIndex = questions.findIndex((q) => newErrors[q.id]);
      if (errorIndex !== -1 && errorIndex !== questionIndex) {
        setQuestionIndex(errorIndex);
      }
      return;
    }

    // Submit form data to parent component
    onComplete("project-goals", { projectGoals });
  };

  // Navigate to next question
  const goToNextQuestion = () => {
    // Validate current question before proceeding
    const currentQuestion = questions[questionIndex];
    if (currentQuestion.required && !projectGoals[currentQuestion.id]) {
      setErrors({
        ...errors,
        [currentQuestion.id]: `${currentQuestion.label} is required`,
      });
      return;
    }

    if (questionIndex < questions.length - 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setQuestionIndex((prev) => prev + 1);
        setIsAnimating(false);
        // Focus on the next input
        const nextQuestion = questions[questionIndex + 1];
        if (inputRefs.current[nextQuestion.id]) {
          inputRefs.current[nextQuestion.id].focus();
        }
      }, 400);
    } else {
      handleSubmit();
    }
  };

  // Navigate to previous question
  const goToPrevQuestion = () => {
    if (questionIndex > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setQuestionIndex((prev) => prev - 1);
        setIsAnimating(false);
        // Focus on the previous input
        const prevQuestion = questions[questionIndex - 1];
        if (inputRefs.current[prevQuestion.id]) {
          inputRefs.current[prevQuestion.id].focus();
        }
      }, 400);
    }
  };

  // Handle key press for navigation
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      goToNextQuestion();
    }
  };

  const currentQuestion = questions[questionIndex];

  // Generate floating shapes for background
  const generateShapes = () => {
    return (
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-64 h-64 bg-primary-green/10 rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2 animate-float" />
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-primary-green/5 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2 animate-float-delayed" />
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center">
      {/* Floating geometric shapes background */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {generateShapes()}
      </div>

      {/* Progress indicator */}
      <div className="w-full flex justify-between mb-12 relative z-10">
        {questions.map((q, index) => (
          <motion.div
            key={q.id}
            className="relative"
            style={{ width: `${100 / questions.length - 1}%` }}
          >
            <motion.div
              className={`h-1 rounded-full ${
                index <= questionIndex ? "bg-primary-green" : "bg-white/20"
              } w-full`}
              initial={{ opacity: 0.5 }}
              animate={{
                opacity: index === questionIndex ? 1 : 0.7,
                scale: index === questionIndex ? [1, 1.2, 1] : 1,
              }}
              transition={{
                duration: 0.5,
                repeat: index === questionIndex ? Infinity : 0,
                repeatType: "reverse",
                repeatDelay: 1,
              }}
            />
            {index < questionIndex && (
              <motion.div
                className="absolute -top-2 -right-2 w-5 h-5 rounded-full bg-primary-green flex items-center justify-center"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <CheckCircle2 className="w-3 h-3 text-black" />
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>

      {/* Question number */}
      <motion.div
        className="absolute top-0 right-0 text-white/20 text-8xl font-bold -z-10 select-none"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 0.05, x: 0 }}
        key={questionIndex}
      >
        {questionIndex + 1}
      </motion.div>

      {/* Question carousel */}
      <div className="w-full max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentQuestion.id}
            initial={{ opacity: 0, y: 50, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.95 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 w-full"
          >
            {/* Question */}
            <div className="flex items-start space-x-4">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 5 }}
                whileTap={{ scale: 0.9, rotate: -5 }}
                className="text-primary-green opacity-90 mt-1 p-2 rounded-full bg-white/5"
                initial={{ rotate: -5 }}
                animate={{ rotate: 0 }}
                transition={{ duration: 0.5 }}
              >
                {currentQuestion.icon}
              </motion.div>

              <motion.h3
                className="text-2xl text-white font-light flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                {currentQuestion.label}
              </motion.h3>
            </div>

            {/* Question hint */}
            <motion.div
              className="ml-14 -mt-4 text-white/60 text-sm italic"
              initial={{ opacity: 0, y: -10 }}
              animate={{
                opacity: showHighlight ? 1 : 0.6,
                y: 0,
                scale: showHighlight ? 1.05 : 1,
              }}
              transition={{ duration: 0.5 }}
            >
              {currentQuestion.hint}
            </motion.div>

            {/* Input field */}
            <div className="relative">
              {currentQuestion.type === "textarea" ? (
                <textarea
                  ref={(el) => (inputRefs.current[currentQuestion.id] = el)}
                  id={currentQuestion.id}
                  value={projectGoals[currentQuestion.id] || ""}
                  onChange={(e) =>
                    handleInputChange(currentQuestion.id, e.target.value)
                  }
                  onFocus={() => setFocusedField(currentQuestion.id)}
                  onBlur={() => setFocusedField(null)}
                  onKeyDown={handleKeyPress}
                  placeholder={currentQuestion.placeholder}
                  rows={5}
                  className="w-full bg-transparent text-white text-lg border-none outline-none focus:outline-none focus:ring-0 py-2 px-3 rounded-lg bg-white/5"
                />
              ) : currentQuestion.type === "radio" ? (
                <div className="space-y-3">
                  {currentQuestion.options.map((option) => (
                    <div
                      key={option.value}
                      className="flex items-center space-x-3 group"
                      onClick={() =>
                        handleInputChange(currentQuestion.id, option.value)
                      }
                    >
                      <div className="relative w-6 h-6 rounded-full border-2 border-white/30 cursor-pointer group-hover:border-white/50">
                        {projectGoals[currentQuestion.id] === option.value && (
                          <motion.div
                            className="absolute inset-1 bg-primary-green rounded-full"
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.2 }}
                          />
                        )}
                      </div>
                      <span className="text-white text-lg cursor-pointer group-hover:text-white/90">
                        {option.label}
                      </span>
                    </div>
                  ))}
                </div>
              ) : null}

              {/* Animated focus border */}
              {currentQuestion.type === "textarea" && (
                <motion.div
                  className="absolute inset-0 border rounded-lg pointer-events-none"
                  initial={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
                  animate={{
                    borderColor:
                      focusedField === currentQuestion.id
                        ? "rgb(1, 178, 128)"
                        : errors[currentQuestion.id]
                        ? "rgb(239, 68, 68)"
                        : "rgba(255, 255, 255, 0.1)",
                    borderWidth:
                      focusedField === currentQuestion.id ? "2px" : "1px",
                    boxShadow:
                      focusedField === currentQuestion.id
                        ? "0 0 10px rgba(1, 178, 128, 0.2)"
                        : "none",
                  }}
                  transition={{ duration: 0.2 }}
                />
              )}

              {/* Character counter */}
              {currentQuestion.type === "textarea" && (
                <motion.div
                  className="absolute bottom-3 right-3 text-xs text-white/40"
                  animate={{
                    color:
                      (projectGoals[currentQuestion.id]?.length || 0) > 20
                        ? "rgba(1, 178, 128, 0.8)"
                        : "rgba(255, 255, 255, 0.4)",
                  }}
                >
                  {projectGoals[currentQuestion.id]?.length || 0} chars
                </motion.div>
              )}

              {errors[currentQuestion.id] && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 flex items-center mt-2 text-sm"
                >
                  <AlertCircle className="w-3 h-3 mr-1" />
                  {errors[currentQuestion.id]}
                </motion.p>
              )}
            </div>

            {/* Navigation buttons */}
            <div className="flex justify-between items-center mt-8">
              <motion.button
                type="button"
                onClick={goToPrevQuestion}
                className={`px-6 py-3 rounded-full border border-white/20 text-white ${
                  questionIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:border-white/40"
                }`}
                whileHover={questionIndex > 0 ? { x: -3 } : {}}
                whileTap={questionIndex > 0 ? { x: 0 } : {}}
                disabled={questionIndex === 0 || isAnimating}
              >
                Previous
              </motion.button>

              <motion.button
                type="button"
                onClick={goToNextQuestion}
                className="px-8 py-3 rounded-full bg-primary-green text-black font-medium flex items-center"
                whileHover={{ x: 3 }}
                whileTap={{ x: 0 }}
                disabled={isAnimating}
              >
                {questionIndex < questions.length - 1 ? (
                  <>
                    <span>Next</span>
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </>
                ) : (
                  "Submit"
                )}
              </motion.button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Question counter */}
      <motion.div
        className="text-white/50 text-sm mt-8 flex items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <span className="font-semibold text-primary-green">
          {questionIndex + 1}
        </span>
        <span className="mx-2">/</span>
        <span>{questions.length}</span>
        <span className="ml-4">•</span>
        <span className="ml-4 text-xs">Press Ctrl+Enter to advance</span>
      </motion.div>
    </div>
  );
};

export default ProjectGoalsForm;
