import { useState } from "react";
import { analyzeWebsite as analyzeWebsiteApi } from "../services/apiService";

/**
 * Custom hook for analyzing a website URL to extract brand colors, imagery, and design elements
 */
const useWebsiteAnalysis = () => {
  const [analysisResults, setAnalysisResults] = useState(null);
  const [analyzeError, setAnalyzeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Analyze a website URL to extract brand information
   * @param {string} url - The website URL to analyze
   * @returns {Promise} - Resolves when analysis is complete
   */
  const analyzeWebsite = async (url) => {
    setIsLoading(true);
    setAnalyzeError(null);
    console.log("Analyzing website:", url);

    try {
      // URL validation
      if (
        !url ||
        !url.match(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/)
      ) {
        throw new Error("Please enter a valid website URL");
      }

      // Call the API service to analyze the website
      console.log("Calling API service with URL:", url);
      const results = await analyzeWebsiteApi(url);
      console.log("Received analysis results:", results);

      setAnalysisResults(results);
      return results;
    } catch (error) {
      console.error("Error in useWebsiteAnalysis hook:", error);
      setAnalyzeError(error.message || "Failed to analyze website");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    analyzeWebsite,
    analysisResults,
    analyzeError,
    isLoading,
  };
};

export default useWebsiteAnalysis;
