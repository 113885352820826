// src/Pages/Services.js
import React, { useState } from "react";
import Navigation from "../Components/Navigation";
import About from "../Components/About";
import SEO from "../Components/SEO";

import BookCallCTA from "../Components/BookCallCTA";
import { audioManager } from "../utils/audioContext";

const Contact = () => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);

  return (
    <>
      <SEO
        title="Contact"
        description="Get in touch with Hear Me Out Music Co. for your audio production and sound design needs"
        type="website"
      />
      <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />

        <main className="relative">
          <section id="about">
            <About />
          </section>
          <section id="contact">
            <BookCallCTA />
          </section>
        </main>
      </div>
    </>
  );
};

export default Contact;
