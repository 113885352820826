import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Calendar,
  ArrowUpRight,
  Phone,
  Mail,
  Clock,
  X,
  Linkedin,
  Instagram,
} from "lucide-react";

const TikTokIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className} fill="currentColor">
    <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z" />
  </svg>
);

const BookCallCTA = () => {
  const [showWidget, setShowWidget] = useState(false);

  const handleBookClick = () => {
    setShowWidget(true);
  };

  const handleCloseWidget = () => {
    setShowWidget(false);
  };
  const handleHover = () => {
    // Add your hover logic here if needed
  };
  const handleClick = () => {
    // Add your click logic here if needed
  };

  return (
    <>
      <section className="relative pt-16 pb-16">
        {/* Background Elements */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-transparent to-[#111111] " />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
          <div className="grid lg:grid-cols-3 gap-8 items-start">
            {/* Left Column - Main CTA */}
            <div className="lg:col-span-1">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-2xl p-8 sm:p-12"
              >
                <h2 className="text-3xl sm:text-4xl font-light text-white mb-6">
                  Ready to Make Your Brand
                  <br />
                  <span className="font-bold">Sound Exceptional?</span>
                </h2>

                <p className="text-white/70 text-lg mb-8">
                  Schedule a free consultation to discuss how we can elevate
                  your brand's sonic identity.
                </p>

                <div className="space-y-4 mb-8">
                  {[
                    { icon: Clock, text: "30-minute discovery call" },
                    {
                      icon: Phone,
                      text: "Personalized sound strategy discussion",
                    },
                    { icon: Calendar, text: "Flexible scheduling options" },
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center gap-3 text-white/80"
                    >
                      <item.icon className="w-5 h-5 text-primary-green" />
                      <span>{item.text}</span>
                    </motion.div>
                  ))}
                </div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onHoverStart={handleHover}
                  onClick={handleBookClick}
                  className="w-full sm:w-auto px-8 py-4 bg-primary-green text-white rounded-full font-medium text-lg 
                           hover:bg-primary-green/90 transition-all inline-flex items-center justify-center gap-2"
                >
                  Book Your Free Call
                  <ArrowUpRight className="w-5 h-5" />
                </motion.button>
              </motion.div>
            </div>

            {/* Middle Column - Contact Info */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="lg:col-span-1"
            >
              <div className="bg-[#111111]/60 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                <h3 className="text-2xl font-bold text-white mb-6">
                  Other Ways to Connect
                </h3>

                <div className="space-y-6">
                  <a
                    href="mailto:contact@hearmeout.com"
                    onMouseEnter={handleHover}
                    onClick={handleClick}
                    className="block group"
                  >
                    <p className="text-white/50 mb-1 text-sm">Email Us</p>
                    <div className="flex items-center gap-2 text-white group-hover:text-primary-green transition-colors">
                      <Mail className="w-5 h-5" />
                      <span>contact@hearmeout.com</span>
                    </div>
                  </a>

                  <a
                    href="tel:+44 7851 487782"
                    onMouseEnter={handleHover}
                    onClick={handleClick}
                    className="block group"
                  >
                    <p className="text-white/50 mb-1 text-sm">Call Direct</p>
                    <div className="flex items-center gap-2 text-white group-hover:text-primary-green transition-colors">
                      <Phone className="w-5 h-5" />
                      <span>+44 07851 487782</span>
                    </div>
                  </a>

                  <div>
                    <p className="text-white/50 mb-1 text-sm">Business Hours</p>
                    <div className="flex items-center gap-2 text-white">
                      <Clock className="w-5 h-5" />
                      <span>Mon-Fri: 9:00 AM - 6:00 PM GMT</span>
                    </div>
                  </div>
                </div>

                {/* Quick Response Promise */}
                <div className="mt-8 p-4 bg-white/5 rounded-lg">
                  <p className="text-white/90 text-sm">
                    <span className="text-primary-green font-medium">
                      Quick Response Promise:
                    </span>{" "}
                    We typically respond to all inquiries within 24 hours during
                    business days.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Right Column - Social Links */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="lg:col-span-1"
            >
              <div className="bg-[#111111]/60 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                <h3 className="text-2xl font-bold text-white mb-6">
                  Follow Us
                </h3>

                <div className="space-y-8">
                  {/* Company Links */}
                  <div className="space-y-4">
                    <p className="text-white/50 mb-2 text-sm">Hear Me Out</p>
                    <div className="flex flex-wrap gap-4">
                      <a
                        href="https://www.linkedin.com/company/hear-me-out-music-co/?viewAsMember=true"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <Linkedin className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                      <a
                        href="https://www.instagram.com/hearmeoutmusic.co/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <Instagram className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                      <a
                        href="https://www.tiktok.com/@hearmeoutmusic.co"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <TikTokIcon className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                    </div>
                  </div>

                  {/* Luke's Links */}
                  <div className="space-y-4">
                    <p className="text-white/50 mb-2 text-sm">Luke Moseley</p>
                    <div className="flex flex-wrap gap-4">
                      <a
                        href="https://www.linkedin.com/in/luke-moseley-b51aa647/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <Linkedin className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                      <a
                        href="https://www.instagram.com/moseleynoise"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <Instagram className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                    </div>
                  </div>

                  {/* George's Links */}
                  <div className="space-y-4">
                    <p className="text-white/50 mb-2 text-sm">George Holiday</p>
                    <div className="flex flex-wrap gap-4">
                      <a
                        href="https://www.linkedin.com/in/georgeholliday/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <Linkedin className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                      <a
                        href="https://www.instagram.com/georgejazzmanholliday/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 bg-[#111111]/80 border border-white/10 rounded-xl hover:border-primary-green/50 transition-colors group"
                      >
                        <Instagram className="w-5 h-5 text-white group-hover:text-primary-green transition-colors" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Lightbox */}
      <AnimatePresence>
        {showWidget && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[999] flex items-center justify-center p-4 sm:p-6 md:p-8"
          >
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/90 backdrop-blur-md z-[998]"
              onClick={handleCloseWidget}
            />

            {/* Content */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="relative w-full max-w-4xl h-[80vh] bg-white rounded-2xl overflow-hidden shadow-2xl z-[999]"
            >
              {/* Close Button Container */}
              <div className="absolute top-4 right-4 z-[1000]">
                <button
                  onClick={handleCloseWidget}
                  className="p-2 rounded-full bg-black/10 hover:bg-black/20 transition-colors"
                >
                  <X className="w-6 h-6 text-black/70" />
                </button>
              </div>

              {/* Widget Container */}
              <div className="h-full w-full">
                <iframe
                  title="Hear Me Out Booking Calendar"
                  src="https://calendar.app.google/LYUDWGkMDXnyFo97A"
                  className="w-full h-full"
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "none",
                    borderRadius: "16px",
                  }}
                  frameBorder="0"
                  scrolling="yes"
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default BookCallCTA;
