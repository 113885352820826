import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Users,
  MapPin,
  UserRound,
  Heart,
  Calendar,
  Clock,
  Star,
  AlertCircle,
  ArrowRight,
  CheckCircle2,
} from "lucide-react";

/**
 * DemographicsForm component - Demographics information step
 * Styled with a "floating in space" approach, with animated elements
 */
const DemographicsForm = ({ formData, updateNestedFormData, onComplete }) => {
  const [focusedField, setFocusedField] = useState(null);
  const [errors, setErrors] = useState({});
  const [cursorVisible, setCursorVisible] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);

  // Get demographics data from parent component or use default values
  const demographics = formData?.demographics || {
    currentAgeRange: "",
    targetAgeRange: "",
    currentTerritory: "",
    targetTerritory: "",
    currentGender: "",
    targetGender: "",
    currentInterests: "",
    targetInterests: "",
    currentLifestyle: "",
    targetLifestyle: "",
    coreValues: "",
    brandEssence: "",
  };

  // Define the questions
  const questions = [
    {
      id: "ageRange",
      label: "What is your current and target age range?",
      type: "dual",
      icon: <Calendar className="w-6 h-6" />,
      required: true,
      fields: [
        {
          id: "currentAgeRange",
          label: "Current Age Range",
          placeholder: "e.g., 25-34",
          hint: "Describe your current primary age demographic",
        },
        {
          id: "targetAgeRange",
          label: "Target Age Range",
          placeholder: "e.g., 18-45",
          hint: "What age range would you like to reach?",
        },
      ],
    },
    {
      id: "territory",
      label: "Where are your current and target territories?",
      type: "dual",
      icon: <MapPin className="w-6 h-6" />,
      required: true,
      fields: [
        {
          id: "currentTerritory",
          label: "Current Territory",
          placeholder: "e.g., UK, Western Europe",
          hint: "List your current main geographic markets",
        },
        {
          id: "targetTerritory",
          label: "Target Territory",
          placeholder: "e.g., Global, North America",
          hint: "Where would you like to expand to?",
        },
      ],
    },
    {
      id: "gender",
      label: "What is your current and target gender distribution?",
      type: "dual",
      icon: <UserRound className="w-6 h-6" />,
      required: true,
      fields: [
        {
          id: "currentGender",
          label: "Current Gender Distribution",
          placeholder: "e.g., 60% female, 40% male",
          hint: "Describe your current gender demographics",
        },
        {
          id: "targetGender",
          label: "Target Gender Distribution",
          placeholder: "e.g., All genders equally",
          hint: "What gender distribution would you like to achieve?",
        },
      ],
    },
    {
      id: "interests",
      label:
        "What are the current and target interests/hobbies of your audience?",
      type: "dual",
      icon: <Heart className="w-6 h-6" />,
      required: true,
      fields: [
        {
          id: "currentInterests",
          label: "Current Interests/Hobbies",
          placeholder: "List main interests of current audience",
          hint: "What activities and interests define your current audience?",
        },
        {
          id: "targetInterests",
          label: "Target Interests/Hobbies",
          placeholder: "List desired interests to target",
          hint: "What new interests would you like to appeal to?",
        },
      ],
    },
    {
      id: "lifestyle",
      label: "Describe the current and target lifestyle of your audience",
      type: "dual",
      icon: <Clock className="w-6 h-6" />,
      required: true,
      fields: [
        {
          id: "currentLifestyle",
          label: "Current Lifestyle",
          placeholder: "Describe current audience daily routines",
          hint: "What specific characteristics, habits, or routines define your current audience?",
        },
        {
          id: "targetLifestyle",
          label: "Target Lifestyle",
          placeholder: "Describe ideal audience lifestyle",
          hint: "What lifestyle characteristics would you like to target?",
        },
      ],
    },
    {
      id: "coreValues",
      label: "What are the core values of your brand?",
      type: "textarea",
      icon: <Star className="w-6 h-6" />,
      required: true,
      placeholder: "List your brand's core values",
      hint: "What principles and beliefs guide your brand's decisions and actions?",
    },
    {
      id: "brandEssence",
      label: "What is your brand essence?",
      type: "textarea",
      icon: <Star className="w-6 h-6" />,
      required: true,
      placeholder: "Describe your brand's essence",
      hint: "What is the fundamental nature or core truth of your brand?",
    },
  ];

  // Refs for inputs
  const inputRefs = useRef({});

  // Cursor blink effect for focused fields
  useEffect(() => {
    let blinkInterval;
    if (focusedField) {
      blinkInterval = setInterval(() => {
        setCursorVisible((prev) => !prev);
      }, 530);
    } else {
      setCursorVisible(false);
    }

    return () => clearInterval(blinkInterval);
  }, [focusedField]);

  // Show hint highlight periodically
  useEffect(() => {
    const highlightInterval = setInterval(() => {
      setShowHighlight(true);
      setTimeout(() => setShowHighlight(false), 2000);
    }, 8000);

    return () => clearInterval(highlightInterval);
  }, []);

  // Handle input changes
  const handleInputChange = (field, value) => {
    updateNestedFormData("demographics", field, value);

    // Clear error for this field if it exists
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Validate form
    const newErrors = {};
    questions.forEach((question) => {
      if (question.type === "dual") {
        question.fields.forEach((field) => {
          if (question.required && !demographics[field.id]) {
            newErrors[field.id] = `${field.label} is required`;
          }
        });
      } else if (question.required && !demographics[question.id]) {
        newErrors[question.id] = `${question.label} is required`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Find first question with error and jump to it
      const errorIndex = questions.findIndex((q) => {
        if (q.type === "dual") {
          return q.fields.some((field) => newErrors[field.id]);
        }
        return newErrors[q.id];
      });
      if (errorIndex !== -1 && errorIndex !== questionIndex) {
        setQuestionIndex(errorIndex);
      }
      return;
    }

    // Submit form data to parent component
    onComplete("demographics", { demographics });
  };

  // Navigate to next question
  const goToNextQuestion = () => {
    // Validate current question before proceeding
    const currentQuestion = questions[questionIndex];
    const hasError = validateCurrentQuestion(currentQuestion);

    if (hasError) return;

    if (questionIndex < questions.length - 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setQuestionIndex((prev) => prev + 1);
        setIsAnimating(false);
        // Focus on the next input
        const nextQuestion = questions[questionIndex + 1];
        if (nextQuestion.type === "dual") {
          inputRefs.current[nextQuestion.fields[0].id]?.focus();
        } else {
          inputRefs.current[nextQuestion.id]?.focus();
        }
      }, 400);
    } else {
      handleSubmit();
    }
  };

  // Validate current question
  const validateCurrentQuestion = (question) => {
    if (!question.required) return false;

    const newErrors = {};
    if (question.type === "dual") {
      question.fields.forEach((field) => {
        if (!demographics[field.id]) {
          newErrors[field.id] = `${field.label} is required`;
        }
      });
    } else if (!demographics[question.id]) {
      newErrors[question.id] = `${question.label} is required`;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return true;
    }

    return false;
  };

  // Navigate to previous question
  const goToPrevQuestion = () => {
    if (questionIndex > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setQuestionIndex((prev) => prev - 1);
        setIsAnimating(false);
        // Focus on the previous input
        const prevQuestion = questions[questionIndex - 1];
        if (prevQuestion.type === "dual") {
          inputRefs.current[prevQuestion.fields[0].id]?.focus();
        } else {
          inputRefs.current[prevQuestion.id]?.focus();
        }
      }, 400);
    }
  };

  // Handle key press for navigation
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      goToNextQuestion();
    }
  };

  const currentQuestion = questions[questionIndex];

  // Generate floating shapes for background
  const generateShapes = () => {
    return (
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-64 h-64 bg-primary-green/10 rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2 animate-float" />
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-primary-green/5 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2 animate-float-delayed" />
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center">
      {/* Floating geometric shapes background */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {generateShapes()}
      </div>

      {/* Progress indicator */}
      <div className="w-full flex justify-between mb-12 relative z-10">
        {questions.map((q, index) => (
          <motion.div
            key={q.id}
            className="relative"
            style={{ width: `${100 / questions.length - 1}%` }}
          >
            <motion.div
              className={`h-1 rounded-full ${
                index <= questionIndex ? "bg-primary-green" : "bg-white/20"
              } w-full`}
              initial={{ opacity: 0.5 }}
              animate={{
                opacity: index === questionIndex ? 1 : 0.7,
                scale: index === questionIndex ? [1, 1.2, 1] : 1,
              }}
              transition={{
                duration: 0.5,
                repeat: index === questionIndex ? Infinity : 0,
                repeatType: "reverse",
                repeatDelay: 1,
              }}
            />
            {index < questionIndex && (
              <motion.div
                className="absolute -top-2 -right-2 w-5 h-5 rounded-full bg-primary-green flex items-center justify-center"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <CheckCircle2 className="w-3 h-3 text-black" />
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>

      {/* Question number */}
      <motion.div
        className="absolute top-0 right-0 text-white/20 text-8xl font-bold -z-10 select-none"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 0.05, x: 0 }}
        key={questionIndex}
      >
        {questionIndex + 1}
      </motion.div>

      {/* Question carousel */}
      <div className="w-full max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentQuestion.id}
            initial={{ opacity: 0, y: 50, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.95 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 w-full"
          >
            {/* Question */}
            <div className="flex items-start space-x-4">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 5 }}
                whileTap={{ scale: 0.9, rotate: -5 }}
                className="text-primary-green opacity-90 mt-1 p-2 rounded-full bg-white/5"
                initial={{ rotate: -5 }}
                animate={{ rotate: 0 }}
                transition={{ duration: 0.5 }}
              >
                {currentQuestion.icon}
              </motion.div>

              <motion.h3
                className="text-2xl text-white font-light flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                {currentQuestion.label}
              </motion.h3>
            </div>

            {/* Input fields */}
            <div className="space-y-6">
              {currentQuestion.type === "dual" ? (
                currentQuestion.fields.map((field) => (
                  <div key={field.id} className="space-y-2">
                    <label className="text-white/60 text-sm pl-14">
                      {field.label}
                    </label>
                    <div className="relative">
                      <textarea
                        ref={(el) => (inputRefs.current[field.id] = el)}
                        id={field.id}
                        value={demographics[field.id] || ""}
                        onChange={(e) =>
                          handleInputChange(field.id, e.target.value)
                        }
                        onFocus={() => setFocusedField(field.id)}
                        onBlur={() => setFocusedField(null)}
                        onKeyDown={handleKeyPress}
                        placeholder={field.placeholder}
                        rows={3}
                        className="w-full bg-transparent text-white text-lg border-none outline-none focus:outline-none focus:ring-0 py-2 px-3 rounded-lg bg-white/5"
                      />

                      {/* Animated focus border */}
                      <motion.div
                        className="absolute inset-0 border rounded-lg pointer-events-none"
                        initial={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
                        animate={{
                          borderColor:
                            focusedField === field.id
                              ? "rgb(1, 178, 128)"
                              : errors[field.id]
                              ? "rgb(239, 68, 68)"
                              : "rgba(255, 255, 255, 0.1)",
                          borderWidth:
                            focusedField === field.id ? "2px" : "1px",
                          boxShadow:
                            focusedField === field.id
                              ? "0 0 10px rgba(1, 178, 128, 0.2)"
                              : "none",
                        }}
                        transition={{ duration: 0.2 }}
                      />

                      {/* Character counter */}
                      <motion.div
                        className="absolute bottom-3 right-3 text-xs text-white/40"
                        animate={{
                          color:
                            (demographics[field.id]?.length || 0) > 20
                              ? "rgba(1, 178, 128, 0.8)"
                              : "rgba(255, 255, 255, 0.4)",
                        }}
                      >
                        {demographics[field.id]?.length || 0} chars
                      </motion.div>

                      {/* Field hint */}
                      <motion.div
                        className="ml-4 mt-1 text-white/60 text-sm italic"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{
                          opacity: showHighlight ? 1 : 0.6,
                          y: 0,
                          scale: showHighlight ? 1.05 : 1,
                        }}
                        transition={{ duration: 0.5 }}
                      >
                        {field.hint}
                      </motion.div>

                      {errors[field.id] && (
                        <motion.p
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="text-red-500 flex items-center mt-2 text-sm"
                        >
                          <AlertCircle className="w-3 h-3 mr-1" />
                          {errors[field.id]}
                        </motion.p>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="relative">
                  <textarea
                    ref={(el) => (inputRefs.current[currentQuestion.id] = el)}
                    id={currentQuestion.id}
                    value={demographics[currentQuestion.id] || ""}
                    onChange={(e) =>
                      handleInputChange(currentQuestion.id, e.target.value)
                    }
                    onFocus={() => setFocusedField(currentQuestion.id)}
                    onBlur={() => setFocusedField(null)}
                    onKeyDown={handleKeyPress}
                    placeholder={currentQuestion.placeholder}
                    rows={5}
                    className="w-full bg-transparent text-white text-lg border-none outline-none focus:outline-none focus:ring-0 py-2 px-3 rounded-lg bg-white/5"
                  />

                  {/* Animated focus border */}
                  <motion.div
                    className="absolute inset-0 border rounded-lg pointer-events-none"
                    initial={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
                    animate={{
                      borderColor:
                        focusedField === currentQuestion.id
                          ? "rgb(1, 178, 128)"
                          : errors[currentQuestion.id]
                          ? "rgb(239, 68, 68)"
                          : "rgba(255, 255, 255, 0.1)",
                      borderWidth:
                        focusedField === currentQuestion.id ? "2px" : "1px",
                      boxShadow:
                        focusedField === currentQuestion.id
                          ? "0 0 10px rgba(1, 178, 128, 0.2)"
                          : "none",
                    }}
                    transition={{ duration: 0.2 }}
                  />

                  {/* Character counter */}
                  <motion.div
                    className="absolute bottom-3 right-3 text-xs text-white/40"
                    animate={{
                      color:
                        (demographics[currentQuestion.id]?.length || 0) > 20
                          ? "rgba(1, 178, 128, 0.8)"
                          : "rgba(255, 255, 255, 0.4)",
                    }}
                  >
                    {demographics[currentQuestion.id]?.length || 0} chars
                  </motion.div>

                  {/* Question hint */}
                  <motion.div
                    className="ml-14 mt-2 text-white/60 text-sm italic"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{
                      opacity: showHighlight ? 1 : 0.6,
                      y: 0,
                      scale: showHighlight ? 1.05 : 1,
                    }}
                    transition={{ duration: 0.5 }}
                  >
                    {currentQuestion.hint}
                  </motion.div>

                  {errors[currentQuestion.id] && (
                    <motion.p
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="text-red-500 flex items-center mt-2 text-sm"
                    >
                      <AlertCircle className="w-3 h-3 mr-1" />
                      {errors[currentQuestion.id]}
                    </motion.p>
                  )}
                </div>
              )}
            </div>

            {/* Navigation buttons */}
            <div className="flex justify-between items-center mt-8">
              <motion.button
                type="button"
                onClick={goToPrevQuestion}
                className={`px-6 py-3 rounded-full border border-white/20 text-white ${
                  questionIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:border-white/40"
                }`}
                whileHover={questionIndex > 0 ? { x: -3 } : {}}
                whileTap={questionIndex > 0 ? { x: 0 } : {}}
                disabled={questionIndex === 0 || isAnimating}
              >
                Previous
              </motion.button>

              <motion.button
                type="button"
                onClick={goToNextQuestion}
                className="px-8 py-3 rounded-full bg-primary-green text-black font-medium flex items-center"
                whileHover={{ x: 3 }}
                whileTap={{ x: 0 }}
                disabled={isAnimating}
              >
                {questionIndex < questions.length - 1 ? (
                  <>
                    <span>Next</span>
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </>
                ) : (
                  "Submit"
                )}
              </motion.button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Question counter */}
      <motion.div
        className="text-white/50 text-sm mt-8 flex items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <span className="font-semibold text-primary-green">
          {questionIndex + 1}
        </span>
        <span className="mx-2">/</span>
        <span>{questions.length}</span>
        <span className="ml-4">•</span>
        <span className="ml-4 text-xs">Press Ctrl+Enter to advance</span>
      </motion.div>
    </div>
  );
};

export default DemographicsForm;
