import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  MessageCircle,
  User,
  History,
  TrendingUp,
  Zap,
  Heart,
  Users,
  AlertCircle,
  ArrowRight,
  CheckCircle2,
} from "lucide-react";

/**
 * BrandBackgroundForm component - Brand background and goals step
 * Styled with a "floating in space" approach, with animated elements
 */
const BrandBackgroundForm = ({
  formData,
  updateNestedFormData,
  onComplete,
}) => {
  const [focusedField, setFocusedField] = useState(null);
  const [errors, setErrors] = useState({});
  const [cursorVisible, setCursorVisible] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);

  // Get brand background data from parent component or use default values
  const brandBackground = formData?.brandBackground || {
    brandDescription: "",
    brandPersona: "",
    brandOrigin: "",
    recentShifts: "",
    upcomingShifts: "",
    recentExcitement: "",
    upcomingExcitement: "",
    biggestFans: "",
    isTargetMarket: "",
    targetMarketOther: "",
  };

  // Define the questions
  const questions = [
    {
      id: "brandDescription",
      label:
        "If you had to explain your brand to somebody that's never heard of it, how would you describe it?",
      type: "textarea",
      icon: <MessageCircle className="w-6 h-6" />,
      required: true,
      placeholder: "Describe your brand in a way that's easy to understand",
      hint: "Focus on what makes your brand unique and valuable to your audience",
    },
    {
      id: "brandPersona",
      label: "If your brand was a person, how would you describe them?",
      type: "textarea",
      icon: <User className="w-6 h-6" />,
      required: true,
      placeholder: "Describe your brand's personality traits",
      hint: "Think about characteristics, values, and behaviors that define your brand",
    },
    {
      id: "brandOrigin",
      label: "Where does the story of the brand begin?",
      type: "textarea",
      icon: <History className="w-6 h-6" />,
      required: false,
      placeholder: "Share your brand's origin story",
      hint: "Tell us about the key moments that shaped your brand's journey",
    },
    {
      id: "recentShifts",
      label:
        "Has the brand gone under any significant shifts in the past 12 months?",
      type: "textarea",
      icon: <TrendingUp className="w-6 h-6" />,
      required: false,
      placeholder: "Describe recent changes in your brand",
      hint: "Include any pivots, rebrands, or strategic shifts",
    },
    {
      id: "upcomingShifts",
      label:
        "Are there any big shifts coming for the brand that we should be aware of?",
      type: "textarea",
      icon: <TrendingUp className="w-6 h-6" />,
      required: false,
      placeholder: "Share upcoming changes or plans",
      hint: "Think about planned changes that might affect your brand's identity",
    },
    {
      id: "recentExcitement",
      label:
        "What is the most exciting thing to happen at the brand in the last 12 months?",
      type: "textarea",
      icon: <Zap className="w-6 h-6" />,
      required: false,
      placeholder: "Share your brand's recent wins",
      hint: "This could be achievements, milestones, or positive changes",
    },
    {
      id: "upcomingExcitement",
      label:
        "What one thing is the brand doing in the next 12 months that is exciting and we should know about?",
      type: "textarea",
      icon: <Zap className="w-6 h-6" />,
      required: false,
      placeholder: "Share upcoming exciting developments",
      hint: "Focus on the most impactful upcoming changes or initiatives",
    },
    {
      id: "biggestFans",
      label: "Who are your biggest fans, and what do they love most about you?",
      type: "textarea",
      icon: <Heart className="w-6 h-6" />,
      required: true,
      placeholder: "Describe your most loyal customers",
      hint: "Include demographics and what makes them connect with your brand",
    },
    {
      id: "isTargetMarket",
      label: "Are they your target market?",
      type: "radio",
      icon: <Users className="w-6 h-6" />,
      required: true,
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
        { value: "other", label: "Other" },
      ],
    },
    {
      id: "targetMarketOther",
      label: "Please specify your target market if different",
      type: "textarea",
      icon: <Users className="w-6 h-6" />,
      required: false,
      conditionalDisplay: {
        dependsOn: "isTargetMarket",
        showWhen: ["no", "other"],
      },
    },
  ];

  // Filter questions to hide conditional ones if needed
  const filteredQuestions = questions.filter((question) => {
    if (!question.conditionalDisplay) return true;

    const { dependsOn, showWhen } = question.conditionalDisplay;
    return showWhen.includes(brandBackground[dependsOn]);
  });

  // Refs for inputs
  const inputRefs = useRef({});

  // Show hint highlight periodically
  useEffect(() => {
    const highlightInterval = setInterval(() => {
      setShowHighlight(true);
      setTimeout(() => setShowHighlight(false), 2000);
    }, 8000);

    return () => clearInterval(highlightInterval);
  }, []);

  // Handle input changes
  const handleInputChange = (field, value) => {
    updateNestedFormData("brandBackground", field, value);

    // Clear error for this field if it exists
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Validate form
    const newErrors = {};
    filteredQuestions.forEach((question) => {
      if (question.required && !brandBackground[question.id]) {
        newErrors[question.id] = `${question.label} is required`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Find first question with error and jump to it
      const errorIndex = filteredQuestions.findIndex((q) => newErrors[q.id]);
      if (errorIndex !== -1 && errorIndex !== questionIndex) {
        setQuestionIndex(errorIndex);
      }
      return;
    }

    // Submit form data to parent component
    onComplete("brand-background", { brandBackground });
  };

  // Navigate to next question
  const goToNextQuestion = () => {
    // Validate current question before proceeding
    const currentQuestion = filteredQuestions[questionIndex];
    if (currentQuestion.required && !brandBackground[currentQuestion.id]) {
      setErrors({
        ...errors,
        [currentQuestion.id]: `${currentQuestion.label} is required`,
      });
      return;
    }

    if (questionIndex < filteredQuestions.length - 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setQuestionIndex((prev) => prev + 1);
        setIsAnimating(false);
        // Focus on the next input
        const nextQuestion = filteredQuestions[questionIndex + 1];
        if (inputRefs.current[nextQuestion.id]) {
          inputRefs.current[nextQuestion.id].focus();
        }
      }, 400);
    } else {
      handleSubmit();
    }
  };

  // Navigate to previous question
  const goToPrevQuestion = () => {
    if (questionIndex > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setQuestionIndex((prev) => prev - 1);
        setIsAnimating(false);
        // Focus on the previous input
        const prevQuestion = filteredQuestions[questionIndex - 1];
        if (inputRefs.current[prevQuestion.id]) {
          inputRefs.current[prevQuestion.id].focus();
        }
      }, 400);
    }
  };

  // Handle key press for navigation
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      goToNextQuestion();
    }
  };

  const currentQuestion = filteredQuestions[questionIndex];

  // Generate floating shapes for background
  const generateShapes = () => {
    return (
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-64 h-64 bg-primary-green/10 rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2 animate-float" />
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-primary-green/5 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2 animate-float-delayed" />
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center">
      {/* Floating geometric shapes background */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {generateShapes()}
      </div>

      {/* Progress indicator */}
      <div className="w-full flex justify-between mb-12 relative z-10">
        {filteredQuestions.map((q, index) => (
          <motion.div
            key={q.id}
            className="relative"
            style={{ width: `${100 / filteredQuestions.length - 1}%` }}
          >
            <motion.div
              className={`h-1 rounded-full ${
                index <= questionIndex ? "bg-primary-green" : "bg-white/20"
              } w-full`}
              initial={{ opacity: 0.5 }}
              animate={{
                opacity: index === questionIndex ? 1 : 0.7,
                scale: index === questionIndex ? [1, 1.2, 1] : 1,
              }}
              transition={{
                duration: 0.5,
                repeat: index === questionIndex ? Infinity : 0,
                repeatType: "reverse",
                repeatDelay: 1,
              }}
            />
            {index < questionIndex && (
              <motion.div
                className="absolute -top-2 -right-2 w-5 h-5 rounded-full bg-primary-green flex items-center justify-center"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <CheckCircle2 className="w-3 h-3 text-black" />
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>

      {/* Question number */}
      <motion.div
        className="absolute top-0 right-0 text-white/20 text-8xl font-bold -z-10 select-none"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 0.05, x: 0 }}
        key={questionIndex}
      >
        {questionIndex + 1}
      </motion.div>

      {/* Question carousel */}
      <div className="w-full max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentQuestion.id}
            initial={{ opacity: 0, y: 50, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.95 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 w-full"
          >
            {/* Question */}
            <div className="flex items-start space-x-4">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 5 }}
                whileTap={{ scale: 0.9, rotate: -5 }}
                className="text-primary-green opacity-90 mt-1 p-2 rounded-full bg-white/5"
                initial={{ rotate: -5 }}
                animate={{ rotate: 0 }}
                transition={{ duration: 0.5 }}
              >
                {currentQuestion.icon}
              </motion.div>

              <motion.h3
                className="text-2xl text-white font-light flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                {currentQuestion.label}
              </motion.h3>
            </div>

            {/* Question hint */}
            <motion.div
              className="ml-14 -mt-4 text-white/60 text-sm italic"
              initial={{ opacity: 0, y: -10 }}
              animate={{
                opacity: showHighlight ? 1 : 0.6,
                y: 0,
                scale: showHighlight ? 1.05 : 1,
              }}
              transition={{ duration: 0.5 }}
            >
              {currentQuestion.hint}
            </motion.div>

            {/* Input field */}
            <div className="relative">
              <textarea
                ref={(el) => (inputRefs.current[currentQuestion.id] = el)}
                id={currentQuestion.id}
                value={brandBackground[currentQuestion.id] || ""}
                onChange={(e) =>
                  handleInputChange(currentQuestion.id, e.target.value)
                }
                onFocus={() => setFocusedField(currentQuestion.id)}
                onBlur={() => setFocusedField(null)}
                onKeyDown={handleKeyPress}
                placeholder={currentQuestion.placeholder}
                rows={5}
                className="w-full bg-transparent text-white text-lg border-none outline-none focus:outline-none focus:ring-0 py-2 px-3 rounded-lg bg-white/5"
              />

              {/* Animated focus border */}
              <motion.div
                className="absolute inset-0 border rounded-lg pointer-events-none"
                initial={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
                animate={{
                  borderColor:
                    focusedField === currentQuestion.id
                      ? "rgb(1, 178, 128)"
                      : errors[currentQuestion.id]
                      ? "rgb(239, 68, 68)"
                      : "rgba(255, 255, 255, 0.1)",
                  borderWidth:
                    focusedField === currentQuestion.id ? "2px" : "1px",
                  boxShadow:
                    focusedField === currentQuestion.id
                      ? "0 0 10px rgba(1, 178, 128, 0.2)"
                      : "none",
                }}
                transition={{ duration: 0.2 }}
              />

              {/* Character counter */}
              <motion.div
                className="absolute bottom-3 right-3 text-xs text-white/40"
                animate={{
                  color:
                    (brandBackground[currentQuestion.id]?.length || 0) > 20
                      ? "rgba(1, 178, 128, 0.8)"
                      : "rgba(255, 255, 255, 0.4)",
                }}
              >
                {brandBackground[currentQuestion.id]?.length || 0} chars
              </motion.div>

              {errors[currentQuestion.id] && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 flex items-center mt-2 text-sm"
                >
                  <AlertCircle className="w-3 h-3 mr-1" />
                  {errors[currentQuestion.id]}
                </motion.p>
              )}
            </div>

            {/* Navigation buttons */}
            <div className="flex justify-between items-center mt-8">
              <motion.button
                type="button"
                onClick={goToPrevQuestion}
                className={`px-6 py-3 rounded-full border border-white/20 text-white ${
                  questionIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:border-white/40"
                }`}
                whileHover={questionIndex > 0 ? { x: -3 } : {}}
                whileTap={questionIndex > 0 ? { x: 0 } : {}}
                disabled={questionIndex === 0 || isAnimating}
              >
                Previous
              </motion.button>

              <motion.button
                type="button"
                onClick={goToNextQuestion}
                className="px-8 py-3 rounded-full bg-primary-green text-black font-medium flex items-center"
                whileHover={{ x: 3 }}
                whileTap={{ x: 0 }}
                disabled={isAnimating}
              >
                {questionIndex < filteredQuestions.length - 1 ? (
                  <>
                    <span>Next</span>
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </>
                ) : (
                  "Submit"
                )}
              </motion.button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Question counter */}
      <motion.div
        className="text-white/50 text-sm mt-8 flex items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <span className="font-semibold text-primary-green">
          {questionIndex + 1}
        </span>
        <span className="mx-2">/</span>
        <span>{filteredQuestions.length}</span>
        <span className="ml-4">•</span>
        <span className="ml-4 text-xs">Press Ctrl+Enter to advance</span>
      </motion.div>
    </div>
  );
};

export default BrandBackgroundForm;
