import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, geminiModel } from "../firebase";

/**
 * Service for generating and managing narrative content using Gemini
 */
class NarrativeService {
    constructor() {
        // Use the pre-initialized Gemini model from firebase.js
        this.model = geminiModel;
    }

    /**
     * Generate a narrative for a specific section of a brand profile
     * Always prioritizes using cached narratives from Firestore
     * Never regenerates manually edited narratives
     * 
     * @param {string} profileId - The ID of the brand profile
     * @param {string} section - The section name (e.g., "characteristics", "demographics")
     * @param {object} sectionData - The data for the section
     * @param {string} companyUrl - Optional URL of the company website for additional context
     * @returns {Promise<string>} - The generated narrative
     */
    async generateNarrative(profileId, section, sectionData, companyUrl = null) {
        try {
            // First check if we have a cached version in Firestore
            const profileRef = doc(db, "brandProfiles", profileId);
            const profileSnap = await getDoc(profileRef);

            // Get company information for context
            let companyName = "the brand";
            let companyInfo = {};

            if (profileSnap.exists()) {
                const profileData = profileSnap.data();

                // Extract company name and info for context
                if (profileData.brandInfo) {
                    companyName = profileData.brandInfo.companyName || "the brand";
                    companyInfo = {
                        industry: profileData.brandInfo.industry || "",
                        companySize: profileData.brandInfo.companySize || "",
                        location: profileData.brandInfo.location || "",
                        founded: profileData.brandInfo.founded || ""
                    };
                }

                // If narratives exist and this section has a narrative, return it immediately
                if (profileData.narratives && profileData.narratives[section] && profileData.narratives[section].text) {
                    const cachedNarrative = profileData.narratives[section];

                    // Always use the existing narrative if it's been manually edited
                    if (cachedNarrative.manuallyEdited) {
                        return cachedNarrative.text;
                    }

                    // Otherwise, use the cached AI-generated narrative
                    return cachedNarrative.text;
                } else {
                    // console.log(`No cached narrative found for section: ${section}, generating new one`);
                }
            }

            // If no narrative exists in Firestore, generate a new one
            const prompt = this.createPromptForSection(section, sectionData, companyName, companyInfo, companyUrl);

            // Generate content using Gemini
            const result = await this.model.generateContent(prompt);
            const narrative = result.response.text();

            // Store data hash for future reference
            const dataHash = this.generateDataHash(sectionData);

            // Update the profile with the new narrative
            await this.saveNarrativeToProfile(profileId, section, narrative, dataHash);

            // console.log(`Successfully generated and saved narrative for section: ${section}`);
            return narrative;
        } catch (error) {
            // console.error("Error generating narrative:", error);
            // Return a fallback message if generation fails
            return "Unable to generate narrative at this time. Please try again later.";
        }
    }

    /**
     * Generate a consistent hash for section data to compare for changes
     * 
     * @param {object} sectionData - The data for the section
     * @returns {string} - A hash of the data
     */
    generateDataHash(sectionData) {
        // Sort keys to ensure consistent hashing regardless of property order
        if (!sectionData) return '';

        const normalized = JSON.stringify(sectionData, Object.keys(sectionData).sort());
        return normalized;
    }

    /**
     * Save a generated narrative to the brand profile
     * 
     * @param {string} profileId - The ID of the brand profile
     * @param {string} section - The section name
     * @param {string} narrative - The generated narrative text
     * @param {string} dataHash - The hash of the data used to generate the narrative
     */
    async saveNarrativeToProfile(profileId, section, narrative, dataHash) {
        try {
            const profileRef = doc(db, "brandProfiles", profileId);
            const profileSnap = await getDoc(profileRef);

            if (profileSnap.exists()) {
                const profileData = profileSnap.data();
                const narratives = profileData.narratives || {};

                // Make sure we don't overwrite a manually edited narrative
                if (narratives[section] && narratives[section].manuallyEdited) {
                    // console.log(`Skipping save for section: ${section} (manually edited)`);
                    return;
                }

                // Update the narratives object with the new narrative
                narratives[section] = {
                    text: narrative,
                    dataHash: dataHash,
                    generatedAt: new Date(),
                    manuallyEdited: false
                };

                // Update the profile document
                await setDoc(profileRef, { narratives }, { merge: true });
                // console.log(`Narrative saved for section: ${section}`);
            } else {
                // console.warn(`Profile ${profileId} not found when saving narrative`);
            }
        } catch (error) {
            // console.error("Error saving narrative to profile:", error);
        }
    }

    /**
     * Create appropriate prompt for different sections
     * 
     * @param {string} section - The section name
     * @param {object} sectionData - The data for the section
     * @param {string} companyName - The name of the company
     * @param {object} companyInfo - Additional company information
     * @param {string} companyUrl - Optional URL of the company website
     * @returns {string} - The prompt for Gemini
     */
    createPromptForSection(section, sectionData, companyName, companyInfo, companyUrl) {
        const companyContext = `
        Company Name: ${companyName}
        Industry: ${companyInfo.industry || "Not specified"}
        Company Size: ${companyInfo.companySize || "Not specified"}
        Location: ${companyInfo.location || "Not specified"}
        Founded: ${companyInfo.founded || "Not specified"}
        Website: ${companyUrl || "Not provided"}
        `;

        const basePrompt = `You are a professional brand strategist writing a narrative for ${companyName}'s brand profile. 
        Write a concise, insightful paragraph (150-200 words) about ${companyName}'s ${section} based on the following data.
        
        IMPORTANT GUIDELINES:
        - Use a professional, business-focused tone
        - Avoid flowery language and excessive adjectives
        - Always refer to the brand by its actual name "${companyName}" (never use placeholders like "[Brand Name]")
        - Focus on concrete analysis rather than poetic descriptions
        - Be specific and data-driven in your insights
        - Keep the analysis practical and actionable for business purposes
        
        Company Context:
        ${companyContext}
        
        Data for ${section}:
        ${JSON.stringify(sectionData, null, 2)}
        `;

        // Add section-specific instructions
        switch (section) {
            case "characteristics":
                return basePrompt + `
                  Focus on the most distinctive characteristics (values at extremes of 1-2 or 4-5).
                  Explain what these characteristics reveal about ${companyName}'s brand personality.
                  Suggest how these traits might influence customer perception and brand experience.
                  Analyze how these characteristics could be expressed through sound (tempo, pitch, timbre, rhythm, etc.).
                  Consider how characteristics like energy, formality, and playfulness translate to sonic elements.
                  Provide practical implications for ${companyName}'s marketing, communication strategy, and sonic branding.
                `;

            case "demographics":
                return basePrompt + `
                  Compare ${companyName}'s current and target demographics.
                  Identify specific opportunities for growth or repositioning.
                  Consider how demographic factors might influence music and sound preferences.
                  Analyze how age, gender, territory, and lifestyle could inform sonic branding decisions.
                  Suggest practical ways ${companyName} might better connect with its target audience through sound.
                  Focus on actionable insights for creating audio experiences that resonate with the target demographic.
                `;

            case "brandStyle":
                return basePrompt + `
                  Analyze how ${companyName}'s visual elements could translate to a sonic identity.
                  Explain the psychological impact of the brand's color choices and how they might correspond to sonic elements (tempo, pitch, timbre).
                  Suggest how the visual identity could be complemented by sound to create a cohesive multi-sensory brand experience.
                  Consider how the brand's fonts, colors, and imagery might sound if translated to music or sound design.
                  Provide practical recommendations for creating sonic elements that align with the visual brand identity.
                  Discuss how the visual style could inform decisions about musical genres, instruments, and sound textures.
                `;

            case "brandStrategy":
                return basePrompt + `
                  Evaluate the coherence of ${companyName}'s strategic positioning.
                  Identify specific strengths and potential areas for improvement.
                  Suggest how the strategy aligns with current market trends and customer needs.
                  Consider how sonic branding could enhance ${companyName}'s competitive differentiation.
                  Analyze how sound strategy could complement the overall brand strategy.
                  Recommend how ${companyName} could use sound strategically to strengthen brand recognition and recall.
                  Provide actionable recommendations for integrating sonic elements into ${companyName}'s brand strategy.
                `;

            case "brandSound":
                return basePrompt + `
                  Describe how ${companyName}'s sound elements contribute to its brand identity.
                  Explain how sound reinforces ${companyName}'s personality traits and complements its visual identity.
                  Analyze how the current or proposed sonic elements align with the brand's visual style.
                  Suggest specific opportunities for developing a cohesive audio-visual brand experience.
                  Recommend how ${companyName} can create a distinctive sonic identity that stands out in its industry.
                  Provide practical recommendations for audio consistency across channels that complement the visual brand.
                `;

            case "projectGoals":
                return basePrompt + `
                  Analyze how ${companyName}'s project goals align with its overall strategy.
                  Identify key success metrics and potential challenges.
                  Consider how sonic branding goals could be integrated into the project objectives.
                  Suggest a practical roadmap for achieving these goals effectively, including sonic milestones.
                  Recommend how to measure the impact and effectiveness of sonic branding elements.
                  Provide specific recommendations for measuring progress and success of audio-visual brand integration.
                `;

            case "brandBackground":
                return basePrompt + `
                  Analyze ${companyName}'s history and evolution in a business context.
                  Identify key milestones and turning points in the brand's development.
                  Consider any historical sound associations or audio heritage the brand might have.
                  Suggest how ${companyName}'s background influences its current market positioning.
                  Analyze how the brand's history could inform authentic sonic branding decisions.
                  Provide insights on how the brand history can be leveraged for future growth, including sonic identity.
                `;

            default:
                return basePrompt + `
                  Provide a comprehensive analysis of this aspect of ${companyName}'s brand.
                  Identify key strengths and opportunities for development.
                  Consider how this element relates to ${companyName}'s sonic branding potential.
                  Suggest how this element connects to ${companyName}'s overall brand strategy.
                  Analyze how this aspect could be expressed or complemented through sound.
                  Provide practical, business-focused recommendations for multi-sensory brand integration.
                `;
        }
    }

    /**
     * Get all narratives for a brand profile
     * 
     * @param {string} profileId - The ID of the brand profile
     * @returns {Promise<object>} - Object containing all narratives
     */
    async getAllNarratives(profileId) {
        try {
            const profileRef = doc(db, "brandProfiles", profileId);
            const profileSnap = await getDoc(profileRef);

            if (profileSnap.exists()) {
                const profileData = profileSnap.data();
                return profileData.narratives || {};
            }

            return {};
        } catch (error) {
            console.error("Error getting narratives:", error);
            return {};
        }
    }
}

// Create an instance of the service
const narrativeServiceInstance = new NarrativeService();

// Export the instance
export default narrativeServiceInstance; 