import React, { useState, useRef } from "react";
import { motion } from "framer-motion";

/**
 * FloatingSlider Component
 * A stylized slider that appears to float in space
 * Used for the brand characteristics questions
 */
const FloatingSlider = ({
  id,
  label,
  leftLabel,
  rightLabel,
  min = 1,
  max = 5,
  value = 3,
  onChange,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const steps = max - min + 1;
  const labels = Array.from({ length: steps }, (_, i) => min + i);
  const containerRef = useRef(null);

  const handleChange = (newValue) => {
    if (onChange) {
      onChange(id, newValue);
    }
  };

  return (
    <div className="w-full mb-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="space-y-3"
      >
        <label className="text-white text-lg font-light">{label}</label>

        <div className="flex items-center justify-between my-2">
          <span className="text-white/60 text-sm">{leftLabel}</span>
          <span className="text-white/60 text-sm">{rightLabel}</span>
        </div>

        <div
          ref={containerRef}
          className="relative h-20 w-full cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* Track background */}
          <div className="absolute top-1/2 left-0 w-full h-[2px] bg-white/20 -translate-y-1/2" />

          {/* Filled track */}
          <motion.div
            className="absolute top-1/2 left-0 h-[2px] bg-gradient-to-r from-primary-green to-blue-500 -translate-y-1/2"
            style={{ width: `${((value - min) / (max - min)) * 100}%` }}
            animate={{
              width: `${((value - min) / (max - min)) * 100}%`,
              height: isHovered ? "3px" : "2px",
            }}
            transition={{ duration: 0.2 }}
          />

          {/* Step markers and labels */}
          <div className="absolute top-1/2 left-0 w-full -translate-y-1/2 flex justify-between">
            {labels.map((step, index) => {
              const isActive = value >= step;
              const isValuePoint = value === step;

              return (
                <div
                  key={step}
                  className="relative flex flex-col items-center"
                  onClick={() => handleChange(step)}
                >
                  <motion.div
                    className={`w-4 h-4 rounded-full ${
                      isActive ? "bg-primary-green" : "bg-white/30"
                    } cursor-pointer z-10`}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    animate={{
                      scale: isValuePoint ? 1.2 : 1,
                      boxShadow: isValuePoint
                        ? "0 0 10px rgba(0, 255, 170, 0.5)"
                        : "none",
                    }}
                    transition={{ duration: 0.2 }}
                  />

                  <motion.div
                    className="mt-2 text-sm font-medium"
                    animate={{
                      color: isActive
                        ? "rgb(1, 178, 128)"
                        : "rgba(255, 255, 255, 0.6)",
                    }}
                  >
                    {step}
                  </motion.div>
                </div>
              );
            })}
          </div>

          {/* Slider thumb */}
          <motion.div
            className="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 w-6 h-6 rounded-full bg-white shadow-lg cursor-grab active:cursor-grabbing"
            style={{
              left: `${((value - min) / (max - min)) * 100}%`,
            }}
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.95 }}
            animate={{
              boxShadow: isHovered
                ? "0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.1)"
                : "0 0 0 0px rgba(255, 255, 255, 0.1)",
            }}
            transition={{ duration: 0.2 }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0}
            dragMomentum={false}
            onDrag={(e, info) => {
              const container = containerRef.current;
              if (!container) return;

              const rect = container.getBoundingClientRect();
              const position = info.point.x - rect.left;
              const percentage = Math.max(
                0,
                Math.min(1, position / rect.width)
              );
              const newValue = Math.round(percentage * (max - min) + min);

              handleChange(newValue);
            }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default FloatingSlider;
