import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

const MetricCard = ({ title, value, icon, style, className = "" }) => {
  // Determine if the icon is a string (emoji) or a React component (Lucide icon)
  const isLucideIcon = typeof icon !== "string";
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  // Check if text is overflowing
  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [value]);

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <motion.div
      className={`backdrop-blur-sm rounded-2xl p-6 border ${className} ${
        isExpanded ? "expanded-card" : "aspect-square"
      }`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ scale: isExpanded ? 1 : 1.02 }}
      layout
      transition={{
        layout: { duration: 0.3, type: "spring" },
        scale: { duration: 0.2 },
      }}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        borderColor: "rgba(255, 255, 255, 0.1)",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
        zIndex: isExpanded ? 10 : 1,
        gridColumn: isExpanded ? "1 / -1" : "auto",
        position: isExpanded ? "relative" : "static",
        ...style,
      }}
    >
      <div className="flex items-center gap-2 mb-4">
        {isLucideIcon ? (
          <div className="w-6 h-6 flex items-center justify-center">
            {React.cloneElement(icon, {
              size: 22,
              color: "var(--primary-color)",
              strokeWidth: 1.5,
            })}
          </div>
        ) : (
          <span className="text-2xl">{icon}</span>
        )}
        <h3 className="text-lg font-bold text-white/90 truncate">{title}</h3>
      </div>
      <div
        ref={textRef}
        className={`text-xl font-medium text-white/80 ${
          !isExpanded && "line-clamp-5"
        }`}
      >
        {value || "Not specified"}
      </div>

      {/* Expand/Collapse Chevron */}
      {isOverflowing && (
        <button
          onClick={toggleExpand}
          className="absolute bottom-3 right-3 cursor-pointer"
          aria-label={isExpanded ? "Collapse" : "Expand"}
        >
          <div
            className={`w-3 h-3 border-t-[3px] border-r-[3px] border-white/80 transform transition-transform duration-300 rounded-tr-sm ${
              isExpanded ? "rotate-[315deg]" : "rotate-[135deg]"
            }`}
          ></div>
        </button>
      )}
    </motion.div>
  );
};

export default MetricCard;
