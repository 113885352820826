import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  ArrowLeft,
  Save,
  X,
  ChevronDown,
  RefreshCw,
  Image as ImageIcon,
  Trash,
  Plus,
  ExternalLink,
  Music,
  Upload,
  AlertCircle,
  Volume2,
} from "lucide-react";
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  query,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { toast } from "react-hot-toast";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

/**
 * BrandProfileEditor component
 * Allows editing of an existing brand profile
 * Directly aligned with the reviewForm data structure
 */
const BrandProfileEditor = () => {
  const { profileId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    brandInfo: true,
    brandSound: true,
    demographics: true,
    projectGoals: true,
    brandBackground: true,
    brandPersonality: true,
    brandStrategy: true,
    brandCharacteristics: true,
    brandStyle: true,
    brandImages: true,
    narratives: true,
    soundboard: true,
  });

  // Image management state
  const [newImage, setNewImage] = useState({
    url: "",
    type: "other", // Default to other
    dimensions: { width: 0, height: 0 },
  });
  const [testingImage, setTestingImage] = useState(false);
  const [imageTestResult, setImageTestResult] = useState(null);

  const handleBack = () => {
    navigate("/admin");
  };

  // Fetch the profile data when the component mounts
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const profileRef = doc(db, "brandProfiles", profileId);
        const profileSnap = await getDoc(profileRef);

        if (!profileSnap.exists()) {
          setError("Profile not found");
          setIsLoading(false);
          return;
        }

        const data = profileSnap.data();
        setFormData(data);
        setExpandedSections({
          brandInfo: true,
          demographics: false,
          brandPersonality: false,
          brandStrategy: false,
          brandBackground: false,
          brandSound: false,
          soundboard: false,
        });
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError("Failed to load profile data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [profileId]);

  // Update the form data when fields change
  const updateFormData = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Update nested form data (for fields like brandInfo.companyName)
  const updateNestedFormData = (parentField, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [parentField]: {
        ...prev[parentField],
        [field]: value,
      },
    }));
  };

  // Update nested narrative data
  const updateNarrativeData = (section, value) => {
    setFormData((prev) => {
      // Make sure narratives object exists
      const currentNarratives = prev.narratives || {};

      // Create or update the specific section
      const updatedSection = {
        ...(currentNarratives[section] || {}),
        text: value,
        // Preserve other properties like dataHash
        dataHash: currentNarratives[section]?.dataHash || "",
        // Add last manual edit timestamp
        lastEditedAt: new Date().toISOString(),
        manuallyEdited: true,
      };

      return {
        ...prev,
        narratives: {
          ...currentNarratives,
          [section]: updatedSection,
        },
      };
    });
  };

  // Update characteristics data (mirrors the brandCharacteristics structure)
  const updateCharacteristic = (name, value) => {
    setFormData((prev) => {
      // Update both characteristics and brandCharacteristics for compatibility
      return {
        ...prev,
        characteristics: {
          ...(prev.characteristics || {}),
          [name]: value,
        },
        brandCharacteristics: {
          ...(prev.brandCharacteristics || {}),
          [name]: value,
        },
      };
    });
  };

  // Toggle expanding a section
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      setError(null);

      // Check if a URL handle is provided and validate it's unique
      if (formData.urlHandle) {
        // Query documents with the same handle but different ID
        const urlHandleQuery = query(
          collection(db, "brandProfiles"),
          where("urlHandle", "==", formData.urlHandle)
        );
        const querySnapshot = await getDocs(urlHandleQuery);

        // Check if any document with this handle exists (that isn't this document)
        const conflictingDoc = querySnapshot.docs.find(
          (doc) => doc.id !== profileId
        );

        if (conflictingDoc) {
          toast.error(
            "This URL handle is already in use. Please choose a different one."
          );
          setIsSaving(false);
          return;
        }
      }

      // Update the profile in Firestore
      const profileRef = doc(db, "brandProfiles", profileId);

      // Ensure data structure matches what BrandProfile expects
      // This mirrors the structure in apiService.js submitForm
      const dataToUpdate = {
        ...formData,
        updatedAt: serverTimestamp(),
        // Ensure both characteristics and brandCharacteristics exist
        characteristics:
          formData.characteristics || formData.brandCharacteristics || {},
        brandCharacteristics:
          formData.brandCharacteristics || formData.characteristics || {},
        soundboard: {
          ...formData.soundboard,
          notes: formData.soundboard?.notes || [], // Add notes to the soundboard data
        },
      };

      await updateDoc(profileRef, dataToUpdate);

      // Show success message
      toast.success("Brand profile updated successfully!");

      // Navigate back to the brand profiles list
      navigate("/admin");
    } catch (error) {
      console.error("Error updating profile:", error);
      setError(error.message);
      toast.error("Failed to update profile. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  // Update image url
  const updateImageUrl = (index, newUrl) => {
    setFormData((prev) => {
      const updatedImages = [...(prev.brandStyle?.images || [])];
      updatedImages[index] = {
        ...updatedImages[index],
        url: newUrl,
      };

      return {
        ...prev,
        brandStyle: {
          ...(prev.brandStyle || {}),
          images: updatedImages,
        },
      };
    });
  };

  // Update image type
  const updateImageType = (index, newType) => {
    setFormData((prev) => {
      const updatedImages = [...(prev.brandStyle?.images || [])];
      updatedImages[index] = {
        ...updatedImages[index],
        type: newType,
      };

      return {
        ...prev,
        brandStyle: {
          ...(prev.brandStyle || {}),
          images: updatedImages,
        },
      };
    });
  };

  // Delete image
  const deleteImage = (index) => {
    setFormData((prev) => {
      const updatedImages = [...(prev.brandStyle?.images || [])];
      updatedImages.splice(index, 1);

      return {
        ...prev,
        brandStyle: {
          ...(prev.brandStyle || {}),
          images: updatedImages,
        },
      };
    });
  };

  // Add new image
  const addNewImage = () => {
    if (!newImage.url.trim()) {
      toast.error("Please enter an image URL");
      return;
    }

    setFormData((prev) => {
      const updatedImages = [...(prev.brandStyle?.images || [])];
      updatedImages.push({ ...newImage });

      return {
        ...prev,
        brandStyle: {
          ...(prev.brandStyle || {}),
          images: updatedImages,
        },
      };
    });

    // Reset new image form
    setNewImage({
      url: "",
      type: "other",
      dimensions: { width: 0, height: 0 },
    });

    toast.success("Image added successfully");
  };

  // Test if an image URL is accessible
  const testImageUrl = (url, updateFunction = null, index = null) => {
    if (!url.trim()) {
      toast.error("Please enter an image URL to test");
      return;
    }

    setTestingImage(true);
    setImageTestResult(null);

    // Create a native HTML Image element (not the Lucide React icon)
    const imgElement = new window.Image();

    imgElement.onload = () => {
      const dimensions = {
        width: imgElement.width,
        height: imgElement.height,
      };

      setImageTestResult({
        success: true,
        dimensions,
      });

      // If this is for updating an existing image's dimensions
      if (updateFunction && index !== null) {
        updateFunction(index, dimensions);
      } else {
        // For new image, update the dimensions in the state
        setNewImage((prev) => ({
          ...prev,
          dimensions,
        }));
      }

      setTestingImage(false);
    };

    imgElement.onerror = () => {
      setImageTestResult({
        success: false,
        error: "Image failed to load. It may be blocked or inaccessible.",
      });
      setTestingImage(false);
    };

    imgElement.src = url;
  };

  // Update image dimensions
  const updateImageDimensions = (index, dimensions) => {
    setFormData((prev) => {
      const updatedImages = [...(prev.brandStyle?.images || [])];
      updatedImages[index] = {
        ...updatedImages[index],
        dimensions,
      };

      return {
        ...prev,
        brandStyle: {
          ...(prev.brandStyle || {}),
          images: updatedImages,
        },
      };
    });
  };

  // Update soundboard description
  const updateSoundboardDescription = (value) => {
    setFormData((prev) => ({
      ...prev,
      soundboard: {
        ...prev.soundboard,
        description: value,
      },
    }));
  };

  // Add a new timeline step
  const addTimelineStep = () => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentTimeline = currentSoundboard.timeline || [];

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          timeline: [...currentTimeline, { title: "", textContent: "" }],
        },
      };
    });
  };

  // Update timeline step
  const updateTimelineStep = (index, field, value) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentTimeline = currentSoundboard.timeline || [];

      const updatedTimeline = [...currentTimeline];
      updatedTimeline[index] = {
        ...updatedTimeline[index],
        [field]: value,
      };

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          timeline: updatedTimeline,
        },
      };
    });
  };

  // Remove timeline step
  const removeTimelineStep = (index) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentTimeline = currentSoundboard.timeline || [];

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          timeline: currentTimeline.filter((_, i) => i !== index),
        },
      };
    });
  };

  // Add a new audio file
  const addAudioFile = () => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentAudioFiles = currentSoundboard.audioFiles || [];

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          audioFiles: [
            ...currentAudioFiles,
            { title: "", url: "", descriptions: [""] },
          ],
        },
      };
    });
  };

  // Update audio file
  const updateAudioFile = (index, field, value) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentAudioFiles = currentSoundboard.audioFiles || [];

      const updatedAudioFiles = [...currentAudioFiles];
      updatedAudioFiles[index] = {
        ...updatedAudioFiles[index],
        [field]: value,
      };

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          audioFiles: updatedAudioFiles,
        },
      };
    });
  };

  // Remove audio file
  const removeAudioFile = (index) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentAudioFiles = currentSoundboard.audioFiles || [];

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          audioFiles: currentAudioFiles.filter((_, i) => i !== index),
        },
      };
    });
  };

  // Add a new description to an audio file
  const addDescription = (audioIndex) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentAudioFiles = currentSoundboard.audioFiles || [];
      const currentDescriptions = currentAudioFiles[audioIndex]?.descriptions || [];

      const updatedAudioFiles = [...currentAudioFiles];
      updatedAudioFiles[audioIndex] = {
        ...updatedAudioFiles[audioIndex],
        descriptions: [...currentDescriptions, ""],
      };

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          audioFiles: updatedAudioFiles,
        },
      };
    });
  };

  // Update a specific description in an audio file
  const updateDescription = (audioIndex, descriptionIndex, value) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentAudioFiles = currentSoundboard.audioFiles || [];
      const currentDescriptions = [...(currentAudioFiles[audioIndex]?.descriptions || [])];

      currentDescriptions[descriptionIndex] = value;

      const updatedAudioFiles = [...currentAudioFiles];
      updatedAudioFiles[audioIndex] = {
        ...updatedAudioFiles[audioIndex],
        descriptions: currentDescriptions,
      };

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          audioFiles: updatedAudioFiles,
        },
      };
    });
  };

  // Remove a description from an audio file
  const removeDescription = (audioIndex, descriptionIndex) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentAudioFiles = currentSoundboard.audioFiles || [];
      const currentDescriptions = currentAudioFiles[audioIndex]?.descriptions || [];

      const updatedDescriptions = currentDescriptions.filter((_, i) => i !== descriptionIndex);

      const updatedAudioFiles = [...currentAudioFiles];
      updatedAudioFiles[audioIndex] = {
        ...updatedAudioFiles[audioIndex],
        descriptions: updatedDescriptions,
      };

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          audioFiles: updatedAudioFiles,
        },
      };
    });
  };

  // Handle audio file upload
  const handleAudioUpload = async (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "audio/*";

    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      // Validate it's an audio file
      if (!file.type.startsWith("audio/")) {
        toast.error("Please select a valid audio file");
        return;
      }

      // Check file size (10MB max)
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
      if (file.size > MAX_FILE_SIZE) {
        toast.error(
          `File is too large. Maximum size is 10MB. Your file is ${(
            file.size /
            (1024 * 1024)
          ).toFixed(2)}MB`
        );
        return;
      }

      // Create a unique toast ID to update progress
      const toastId = toast.loading("Preparing to upload audio file...");

      try {
        // Create a reference to Firebase Storage
        const storageRef = ref(storage, `audio/${Date.now()}_${file.name}`);

        // Create upload task
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes
        uploadTask.on(
          "state_changed",
          // Progress observer
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            toast.loading(`Uploading: ${Math.round(progress)}%`, {
              id: toastId,
            });
          },
          // Error observer
          (error) => {
            console.error("Upload error:", error);
            toast.error("Upload failed: " + error.message, { id: toastId });
          },
          // Completion observer
          async () => {
            // Get the download URL
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Update the audio file URL
            updateAudioFile(index, "url", downloadURL);

            // If no title is set, use the filename (without extension)
            const currentTitle =
              formData.soundboard?.audioFiles?.[index]?.title;
            if (!currentTitle) {
              const fileNameWithoutExt = file.name.replace(/\.[^/.]+$/, "");
              updateAudioFile(index, "title", fileNameWithoutExt);
            }

            toast.success("Audio file uploaded successfully", { id: toastId });
          }
        );
      } catch (error) {
        console.error("Error handling file upload:", error);
        toast.error("Upload failed: " + error.message, { id: toastId });
      }
    };

    fileInput.click();
  };

  // Add a function to test audio URLs
  const testAudioUrl = (url) => {
    if (!url.trim()) {
      toast.error("Please enter an audio URL to test");
      return;
    }

    toast.loading("Testing audio URL...");

    // Create an audio element to test the URL
    const audio = new Audio(url);

    // Listen for successful loading
    audio.oncanplaythrough = () => {
      toast.dismiss();
      toast.success("Audio file is valid and playable");
    };

    // Listen for errors
    audio.onerror = () => {
      toast.dismiss();
      toast.error("The audio URL is not accessible or valid");
    };

    // Start loading the audio
    audio.load();
  };

  // Update the notes functions to work with formData
  const addNote = () => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentNotes = currentSoundboard.notes || [];

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          notes: [...currentNotes, { title: "", content: "", label: "" }],
        },
      };
    });
  };

  const updateNote = (index, field, value) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentNotes = currentSoundboard.notes || [];

      const updatedNotes = [...currentNotes];
      updatedNotes[index] = {
        ...updatedNotes[index],
        [field]: value,
      };

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          notes: updatedNotes,
        },
      };
    });
  };

  const removeNote = (index) => {
    setFormData((prev) => {
      const currentSoundboard = prev.soundboard || {};
      const currentNotes = currentSoundboard.notes || [];

      return {
        ...prev,
        soundboard: {
          ...currentSoundboard,
          notes: currentNotes.filter((_, i) => i !== index),
        },
      };
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-white text-xl">Loading profile data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  // Extract the available narrative sections
  const narrativeSections = formData.narratives
    ? Object.keys(formData.narratives)
    : [];

  // Get characteristics from either characteristics or brandCharacteristics
  const characteristicsData =
    formData.brandCharacteristics || formData.characteristics || {};

  return (
    <div className="relative bg-[#111111] min-h-screen text-white">
      {/* Header with back button */}
      <div className="flex items-center justify-between p-6 border-b border-white/10">
        <div className="flex items-center gap-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleBack}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            <ArrowLeft className="w-5 h-5" />
          </motion.button>
          <h2 className="text-2xl font-bold text-white">
            Edit Brand Profile:{" "}
            {formData?.brandInfo?.companyName || "Unnamed Brand"}
          </h2>
        </div>
        <div className="flex items-center gap-3">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleBack}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
            title="Cancel"
          >
            <X className="w-5 h-5" />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSubmit}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-primary-green text-black"
          >
            {isSaving ? (
              <div className="w-4 h-4 border-2 border-black border-t-transparent rounded-full animate-spin"></div>
            ) : (
              <Save className="w-4 h-4" />
            )}
            <span>Save Changes</span>
          </motion.button>
        </div>
      </div>

      {/* Form container */}
      <div className="container mx-auto p-8">
        {formData && (
          <div className="space-y-8">
            {/* Brand Info Section */}
            <EditorSection
              title="Brand Information"
              expanded={expandedSections.brandInfo}
              onToggle={() => toggleSection("brandInfo")}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InputField
                  label="Company Name"
                  value={formData.brandInfo?.companyName || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandInfo", "companyName", value)
                  }
                />
                <InputField
                  label="Industry"
                  value={formData.brandInfo?.industry || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandInfo", "industry", value)
                  }
                />
                <InputField
                  label="Website URL"
                  value={formData.websiteUrl || ""}
                  onChange={(value) => updateFormData("websiteUrl", value)}
                />
                <InputField
                  label="Client Email"
                  value={formData.clientEmail || ""}
                  onChange={(value) => updateFormData("clientEmail", value)}
                  type="email"
                />
                <div className="md:col-span-2 bg-white/5 p-3 rounded-lg mb-2 border-l-4 border-primary-green/50">
                  <h4 className="text-sm font-medium text-white mb-1">
                    Custom URL for sharing
                  </h4>
                  <p className="text-xs text-white/70">
                    Creating a custom URL handle allows you to share a more
                    readable link with clients (e.g., /brand-profile/acme-brand
                    instead of /brand-profile/j8hd7f...). URLs are checked for
                    uniqueness when saving.
                  </p>
                </div>
                <InputField
                  label="URL Handle"
                  value={formData.urlHandle || ""}
                  onChange={(value) =>
                    updateFormData(
                      "urlHandle",
                      value.toLowerCase().replace(/[^a-z0-9-]/g, "-")
                    )
                  }
                  placeholder="custom-url-handle"
                  tooltip="Custom URL-friendly identifier for this brand profile, used in place of the document ID in URLs. Use only lowercase letters, numbers, and hyphens."
                />
                <SelectField
                  label="Status"
                  value={formData.status || "pending"}
                  options={[
                    { value: "pending", label: "Pending" },
                    { value: "active", label: "Active" },
                    { value: "archived", label: "Archived" },
                  ]}
                  onChange={(value) => updateFormData("status", value)}
                />
                <InputField
                  label="Established Year"
                  value={formData.brandInfo?.establishedYear || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandInfo", "establishedYear", value)
                  }
                />
                <InputField
                  label="Company Size"
                  value={formData.brandInfo?.companySize || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandInfo", "companySize", value)
                  }
                />
                <InputField
                  label="Market Position"
                  value={formData.brandInfo?.marketPosition || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandInfo", "marketPosition", value)
                  }
                />
                <TextAreaField
                  label="Brand Description"
                  value={formData.brandInfo?.brandDescription || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandInfo", "brandDescription", value)
                  }
                  className="md:col-span-2"
                />
              </div>
            </EditorSection>

            {/* Brand Style Section */}
            <EditorSection
              title="Brand Style"
              expanded={expandedSections.brandStyle}
              onToggle={() => toggleSection("brandStyle")}
            >
              <div className="space-y-6">
                <h3 className="text-lg font-semibold text-white">
                  Brand Colors
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {formData.brandStyle?.colors &&
                    Object.entries(formData.brandStyle.colors).map(
                      ([colorName, colorValue]) => (
                        <div key={colorName} className="space-y-2">
                          <label className="block text-sm font-medium text-white/70 capitalize">
                            {colorName}
                          </label>
                          <div className="flex items-center gap-3">
                            <div
                              className="w-10 h-10 rounded-md border border-white/10"
                              style={{ backgroundColor: colorValue }}
                            ></div>
                            <input
                              type="text"
                              value={colorValue}
                              onChange={(e) =>
                                updateNestedFormData("brandStyle", "colors", {
                                  ...formData.brandStyle?.colors,
                                  [colorName]: e.target.value,
                                })
                              }
                              className="flex-1 px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
                            />
                          </div>
                        </div>
                      )
                    )}
                </div>

                <h3 className="text-lg font-semibold text-white mt-8">
                  Brand Fonts
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <InputField
                    label="Primary Font"
                    value={formData.brandStyle?.fonts?.primary || ""}
                    onChange={(value) => {
                      const currentFonts = formData.brandStyle?.fonts || {};
                      updateNestedFormData("brandStyle", "fonts", {
                        ...currentFonts,
                        primary: value,
                      });
                    }}
                  />
                  <InputField
                    label="Secondary Font"
                    value={formData.brandStyle?.fonts?.secondary || ""}
                    onChange={(value) => {
                      const currentFonts = formData.brandStyle?.fonts || {};
                      updateNestedFormData("brandStyle", "fonts", {
                        ...currentFonts,
                        secondary: value,
                      });
                    }}
                  />
                </div>
              </div>
            </EditorSection>

            {/* Brand Sound Section */}
            <EditorSection
              title="Brand Sound"
              expanded={expandedSections.brandSound}
              onToggle={() => toggleSection("brandSound")}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="md:col-span-2">
                  <div className="bg-white/5 rounded-lg p-3 border-l-4 border-yellow-500/50 mb-6">
                    <h4 className="text-sm font-medium text-white mb-1">
                      Content Visibility Settings
                    </h4>
                    <p className="text-xs text-white/70">
                      Toggle which elements are visible in the Brand Sound
                      section. Disabled items will not appear on the public
                      profile page.
                    </p>
                  </div>

                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                    {[
                      {
                        id: "showSoundNarrative",
                        label: "Sound Narrative",
                        description:
                          "Controls the AI-generated narrative section",
                      },
                      { id: "showCurrentSound", label: "Current Sound" },
                      { id: "showSoundChallenges", label: "Sound Challenges" },
                      {
                        id: "showSoundInspiration",
                        label: "Sound Inspiration",
                      },
                      {
                        id: "showAspirationalBrand",
                        label: "Aspirational Brand",
                      },
                      { id: "showCurrentChannels", label: "Current Channels" },
                      {
                        id: "showCurrentSpending",
                        label: "Annual Music Budget",
                      },
                      {
                        id: "showListeningEnvironment",
                        label: "Listening Environment",
                      },
                      { id: "showVoiceType", label: "Voice Type" },
                      { id: "showSoundGoals", label: "Sound Goals" },
                      {
                        id: "showSoundRecommendations",
                        label: "Sound Recommendations",
                      },
                      { id: "showSoundFrequency", label: "Sound Frequency" },
                      {
                        id: "showImplementationDetails",
                        label: "Implementation Details",
                      },
                    ].map((item) => {
                      const isVisible =
                        formData.brandSound?.[item.id] !== false;

                      return (
                        <div
                          key={item.id}
                          className={`flex items-center space-x-3 p-2 rounded-lg hover:bg-white/5 ${item.id === "showSoundNarrative"
                            ? "md:col-span-3 bg-white/5"
                            : ""
                            }`}
                        >
                          <div className="relative inline-block w-10 h-5 mr-2">
                            <input
                              type="checkbox"
                              id={item.id}
                              className="opacity-0 w-0 h-0"
                              checked={isVisible}
                              onChange={(e) =>
                                updateNestedFormData(
                                  "brandSound",
                                  item.id,
                                  !isVisible
                                )
                              }
                            />
                            <label
                              htmlFor={item.id}
                              className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-colors duration-200 ${isVisible ? "bg-primary-green" : "bg-white/20"
                                }`}
                              style={{ width: "2.5rem", height: "1.25rem" }}
                            >
                              <span
                                className={`absolute h-4 w-4 rounded-full bg-white shadow transition-transform duration-200 ${isVisible ? "translate-x-5" : "translate-x-1"
                                  }`}
                                style={{ top: "0.125rem" }}
                              ></span>
                            </label>
                          </div>
                          <label
                            htmlFor={item.id}
                            className="text-sm text-white cursor-pointer"
                          >
                            {item.label}
                          </label>
                          {item.description && (
                            <span className="text-xs text-white/50 ml-2">
                              {item.description}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <TextAreaField
                  label="Current Sound"
                  value={formData.brandSound?.currentSound || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "currentSound", value)
                  }
                />
                <div className="space-y-4">
                  <label className="block text-sm font-medium text-white/70">
                    Current Sound Example Keywords
                  </label>
                  <div className="space-y-2">
                    {(formData.brandSound?.currentSoundExamples || []).map(
                      (example, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <input
                            type="text"
                            value={example}
                            onChange={(e) => {
                              const newExamples = [
                                ...(formData.brandSound?.currentSoundExamples ||
                                  []),
                              ];
                              newExamples[index] = e.target.value;
                              updateNestedFormData(
                                "brandSound",
                                "currentSoundExamples",
                                newExamples
                              );
                            }}
                            className="flex-1 px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
                            placeholder="e.g., Current Sonic Logo"
                          />
                          <button
                            onClick={() => {
                              const newExamples = [
                                ...(formData.brandSound?.currentSoundExamples ||
                                  []),
                              ].filter((_, i) => i !== index);
                              updateNestedFormData(
                                "brandSound",
                                "currentSoundExamples",
                                newExamples
                              );
                            }}
                            className="p-2 rounded-lg bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
                          >
                            <span>×</span>
                          </button>
                        </div>
                      )
                    )}
                    <button
                      onClick={() => {
                        const newExamples = [
                          ...(formData.brandSound?.currentSoundExamples || []),
                          "",
                        ];
                        updateNestedFormData(
                          "brandSound",
                          "currentSoundExamples",
                          newExamples
                        );
                      }}
                      className="px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/70 hover:bg-white/10 hover:text-white transition-colors w-full text-center"
                    >
                      + Add Keyword
                    </button>
                  </div>
                </div>

                <TextAreaField
                  label="Sound Competitor"
                  value={formData.brandSound?.soundCompetitor || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "soundCompetitor", value)
                  }
                />
                <div className="space-y-4">
                  <label className="block text-sm font-medium text-white/70">
                    Sound Inspiration Example Keywords
                  </label>
                  <div className="space-y-2">
                    {(formData.brandSound?.soundInspirationExamples || []).map(
                      (example, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <input
                            type="text"
                            value={example}
                            onChange={(e) => {
                              const newExamples = [
                                ...(formData.brandSound
                                  ?.soundInspirationExamples || []),
                              ];
                              newExamples[index] = e.target.value;
                              updateNestedFormData(
                                "brandSound",
                                "soundInspirationExamples",
                                newExamples
                              );
                            }}
                            className="flex-1 px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
                            placeholder="e.g., Competitor's Jingle"
                          />
                          <button
                            onClick={() => {
                              const newExamples = [
                                ...(formData.brandSound
                                  ?.soundInspirationExamples || []),
                              ].filter((_, i) => i !== index);
                              updateNestedFormData(
                                "brandSound",
                                "soundInspirationExamples",
                                newExamples
                              );
                            }}
                            className="p-2 rounded-lg bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
                          >
                            <span>×</span>
                          </button>
                        </div>
                      )
                    )}
                    <button
                      onClick={() => {
                        const newExamples = [
                          ...(formData.brandSound?.soundInspirationExamples ||
                            []),
                          "",
                        ];
                        updateNestedFormData(
                          "brandSound",
                          "soundInspirationExamples",
                          newExamples
                        );
                      }}
                      className="px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/70 hover:bg-white/10 hover:text-white transition-colors w-full text-center"
                    >
                      + Add Keyword
                    </button>
                  </div>
                </div>

                <TextAreaField
                  label="Sound Challenge"
                  value={formData.brandSound?.soundChallenge || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "soundChallenge", value)
                  }
                />
                <div className="space-y-4">
                  <label className="block text-sm font-medium text-white/70">
                    Sound Challenge Example Keywords
                  </label>
                  <div className="space-y-2">
                    {(formData.brandSound?.soundChallengeExamples || []).map(
                      (example, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <input
                            type="text"
                            value={example}
                            onChange={(e) => {
                              const newExamples = [
                                ...(formData.brandSound
                                  ?.soundChallengeExamples || []),
                              ];
                              newExamples[index] = e.target.value;
                              updateNestedFormData(
                                "brandSound",
                                "soundChallengeExamples",
                                newExamples
                              );
                            }}
                            className="flex-1 px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
                            placeholder="e.g., Background Noise"
                          />
                          <button
                            onClick={() => {
                              const newExamples = [
                                ...(formData.brandSound
                                  ?.soundChallengeExamples || []),
                              ].filter((_, i) => i !== index);
                              updateNestedFormData(
                                "brandSound",
                                "soundChallengeExamples",
                                newExamples
                              );
                            }}
                            className="p-2 rounded-lg bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
                          >
                            <span>×</span>
                          </button>
                        </div>
                      )
                    )}
                    <button
                      onClick={() => {
                        const newExamples = [
                          ...(formData.brandSound?.soundChallengeExamples ||
                            []),
                          "",
                        ];
                        updateNestedFormData(
                          "brandSound",
                          "soundChallengeExamples",
                          newExamples
                        );
                      }}
                      className="px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/70 hover:bg-white/10 hover:text-white transition-colors w-full text-center"
                    >
                      + Add Keyword
                    </button>
                  </div>
                </div>

                <TextAreaField
                  label="Aspirational Brand"
                  value={formData.brandSound?.aspirationalBrand || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandSound",
                      "aspirationalBrand",
                      value
                    )
                  }
                />
                <div className="space-y-4">
                  <label className="block text-sm font-medium text-white/70">
                    Aspirational Brand Example Keywords
                  </label>
                  <div className="space-y-2">
                    {(formData.brandSound?.aspirationalBrandExamples || []).map(
                      (example, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <input
                            type="text"
                            value={example}
                            onChange={(e) => {
                              const newExamples = [
                                ...(formData.brandSound
                                  ?.aspirationalBrandExamples || []),
                              ];
                              newExamples[index] = e.target.value;
                              updateNestedFormData(
                                "brandSound",
                                "aspirationalBrandExamples",
                                newExamples
                              );
                            }}
                            className="flex-1 px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
                            placeholder="e.g., Target Sound"
                          />
                          <button
                            onClick={() => {
                              const newExamples = [
                                ...(formData.brandSound
                                  ?.aspirationalBrandExamples || []),
                              ].filter((_, i) => i !== index);
                              updateNestedFormData(
                                "brandSound",
                                "aspirationalBrandExamples",
                                newExamples
                              );
                            }}
                            className="p-2 rounded-lg bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
                          >
                            <span>×</span>
                          </button>
                        </div>
                      )
                    )}
                    <button
                      onClick={() => {
                        const newExamples = [
                          ...(formData.brandSound?.aspirationalBrandExamples ||
                            []),
                          "",
                        ];
                        updateNestedFormData(
                          "brandSound",
                          "aspirationalBrandExamples",
                          newExamples
                        );
                      }}
                      className="px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/70 hover:bg-white/10 hover:text-white transition-colors w-full text-center"
                    >
                      + Add Keyword
                    </button>
                  </div>
                </div>

                <TextAreaField
                  label="Current Channels"
                  value={formData.brandSound?.currentChannels || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "currentChannels", value)
                  }
                />
                <TextAreaField
                  label="Current Spending"
                  value={formData.brandSound?.currentSpending || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "currentSpending", value)
                  }
                />
                <TextAreaField
                  label="Listening Environment"
                  value={formData.brandSound?.listeningEnvironment || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandSound",
                      "listeningEnvironment",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Voice Type"
                  value={formData.brandSound?.voiceType || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "voiceType", value)
                  }
                />
                <TextAreaField
                  label="Sound Goals"
                  value={formData.brandSound?.soundGoals || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandSound", "soundGoals", value)
                  }
                />
                <TextAreaField
                  label="Sound Recommendations"
                  value={formData.brandSound?.soundRecommendations || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandSound",
                      "soundRecommendations",
                      value
                    )
                  }
                />
              </div>
            </EditorSection>

            {/* Demographics Section */}
            <EditorSection
              title="Demographics"
              expanded={expandedSections.demographics}
              onToggle={() => toggleSection("demographics")}
            >
              <div className="bg-white/5 rounded-lg p-3 border-l-4 border-yellow-500/50 mb-6">
                <h4 className="text-sm font-medium text-white mb-1">
                  Content Visibility Settings
                </h4>
                <p className="text-xs text-white/70">
                  Toggle which elements are visible in the Demographics section.
                  Disabled items will not appear on the public profile page.
                </p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                {[
                  "currentAgeRange",
                  "targetAgeRange",
                  "currentTerritory",
                  "targetTerritory",
                  "currentGender",
                  "targetGender",
                  "currentInterests",
                  "targetInterests",
                  "currentLifestyle",
                  "targetLifestyle",
                  "coreValues",
                  "brandEssence",
                  "narrative", // Add narrative toggle
                ].map((field) => {
                  // Get display name by converting camelCase to Title Case With Spaces
                  const displayName = field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());

                  // Special case for narrative to make it clearer
                  const finalDisplayName =
                    field === "narrative" ? "Summary Narrative" : displayName;

                  // Get current visibility setting (default to true if not set)
                  const isVisible =
                    formData.demographics?.visibility?.[field] !== false;

                  return (
                    <div
                      key={field}
                      className={`flex items-center space-x-3 p-2 rounded-lg hover:bg-white/5 ${field === "narrative" ? "md:col-span-3 bg-white/5" : ""
                        }`}
                    >
                      <div className="relative inline-block w-10 h-5 mr-2">
                        <input
                          type="checkbox"
                          id={`demographics-visibility-${field}`}
                          className="opacity-0 w-0 h-0"
                          checked={isVisible}
                          onChange={() => {
                            // Initialize visibility object if it doesn't exist
                            const currentVisibility =
                              formData.demographics?.visibility || {};

                            updateNestedFormData("demographics", "visibility", {
                              ...currentVisibility,
                              [field]: !isVisible, // Toggle the current value
                            });
                          }}
                        />
                        <label
                          htmlFor={`demographics-visibility-${field}`}
                          className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-colors duration-200 ${isVisible ? "bg-primary-green" : "bg-white/20"
                            }`}
                          style={{ width: "2.5rem", height: "1.25rem" }}
                        >
                          <span
                            className={`absolute h-4 w-4 rounded-full bg-white shadow transition-transform duration-200 ${isVisible ? "translate-x-5" : "translate-x-1"
                              }`}
                            style={{ top: "0.125rem" }}
                          ></span>
                        </label>
                      </div>
                      <label
                        htmlFor={`demographics-visibility-${field}`}
                        className="text-sm text-white cursor-pointer"
                      >
                        {finalDisplayName}
                      </label>
                      {field === "narrative" && (
                        <span className="text-xs text-white/50 ml-2">
                          Controls the AI-generated summary section
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <TextAreaField
                  label="Current Age Range"
                  value={formData.demographics?.currentAgeRange || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "currentAgeRange",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Target Age Range"
                  value={formData.demographics?.targetAgeRange || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "targetAgeRange",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Current Territory"
                  value={formData.demographics?.currentTerritory || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "currentTerritory",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Target Territory"
                  value={formData.demographics?.targetTerritory || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "targetTerritory",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Current Gender"
                  value={formData.demographics?.currentGender || ""}
                  onChange={(value) =>
                    updateNestedFormData("demographics", "currentGender", value)
                  }
                />
                <TextAreaField
                  label="Target Gender"
                  value={formData.demographics?.targetGender || ""}
                  onChange={(value) =>
                    updateNestedFormData("demographics", "targetGender", value)
                  }
                />
                <TextAreaField
                  label="Current Interests"
                  value={formData.demographics?.currentInterests || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "currentInterests",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Target Interests"
                  value={formData.demographics?.targetInterests || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "targetInterests",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Current Lifestyle"
                  value={formData.demographics?.currentLifestyle || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "currentLifestyle",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Target Lifestyle"
                  value={formData.demographics?.targetLifestyle || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "demographics",
                      "targetLifestyle",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Core Values"
                  value={formData.demographics?.coreValues || ""}
                  onChange={(value) =>
                    updateNestedFormData("demographics", "coreValues", value)
                  }
                />
                <TextAreaField
                  label="Brand Essence"
                  value={formData.demographics?.brandEssence || ""}
                  onChange={(value) =>
                    updateNestedFormData("demographics", "brandEssence", value)
                  }
                />
              </div>
            </EditorSection>

            {/* Project Goals Section */}
            <EditorSection
              title="Project Goals"
              expanded={expandedSections.projectGoals}
              onToggle={() => toggleSection("projectGoals")}
            >
              <div className="bg-white/5 rounded-lg p-3 border-l-4 border-yellow-500/50 mb-6">
                <h4 className="text-sm font-medium text-white mb-1">
                  Content Visibility Settings
                </h4>
                <p className="text-xs text-white/70">
                  Toggle which elements are visible in the Project Goals
                  section. Disabled items will not appear on the public profile
                  page.
                </p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                {[
                  "mainGoal",
                  "processExpectations",
                  "deadlines",
                  "teamInvolvement",
                  "successMetrics",
                  "budget",
                  "additionalInfo",
                  "narrative", // Add narrative toggle
                ].map((field) => {
                  // Get display name by converting camelCase to Title Case With Spaces
                  const displayName = field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());

                  // Special case for narrative to make it clearer
                  const finalDisplayName =
                    field === "narrative" ? "Summary Narrative" : displayName;

                  // Get current visibility setting (default to true if not set)
                  const isVisible =
                    formData.projectGoals?.visibility?.[field] !== false;

                  return (
                    <div
                      key={field}
                      className={`flex items-center space-x-3 p-2 rounded-lg hover:bg-white/5 ${field === "narrative" ? "md:col-span-3 bg-white/5" : ""
                        }`}
                    >
                      <div className="relative inline-block w-10 h-5 mr-2">
                        <input
                          type="checkbox"
                          id={`projectgoals-visibility-${field}`}
                          className="opacity-0 w-0 h-0"
                          checked={isVisible}
                          onChange={() => {
                            // Initialize visibility object if it doesn't exist
                            const currentVisibility =
                              formData.projectGoals?.visibility || {};

                            updateNestedFormData("projectGoals", "visibility", {
                              ...currentVisibility,
                              [field]: !isVisible, // Toggle the current value
                            });
                          }}
                        />
                        <label
                          htmlFor={`projectgoals-visibility-${field}`}
                          className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-colors duration-200 ${isVisible ? "bg-primary-green" : "bg-white/20"
                            }`}
                          style={{ width: "2.5rem", height: "1.25rem" }}
                        >
                          <span
                            className={`absolute h-4 w-4 rounded-full bg-white shadow transition-transform duration-200 ${isVisible ? "translate-x-5" : "translate-x-1"
                              }`}
                            style={{ top: "0.125rem" }}
                          ></span>
                        </label>
                      </div>
                      <label
                        htmlFor={`projectgoals-visibility-${field}`}
                        className="text-sm text-white cursor-pointer"
                      >
                        {finalDisplayName}
                      </label>
                      {field === "narrative" && (
                        <span className="text-xs text-white/50 ml-2">
                          Controls the AI-generated project overview
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <TextAreaField
                  label="Main Goal"
                  value={formData.projectGoals?.mainGoal || ""}
                  onChange={(value) =>
                    updateNestedFormData("projectGoals", "mainGoal", value)
                  }
                />
                <TextAreaField
                  label="Primary Goal"
                  value={formData.projectGoals?.primaryGoal || ""}
                  onChange={(value) =>
                    updateNestedFormData("projectGoals", "primaryGoal", value)
                  }
                />
                <TextAreaField
                  label="Primary Objective"
                  value={formData.projectGoals?.primaryObjective || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "projectGoals",
                      "primaryObjective",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Timeline"
                  value={formData.projectGoals?.timeline || ""}
                  onChange={(value) =>
                    updateNestedFormData("projectGoals", "timeline", value)
                  }
                />
                <TextAreaField
                  label="Deadlines"
                  value={formData.projectGoals?.deadlines || ""}
                  onChange={(value) =>
                    updateNestedFormData("projectGoals", "deadlines", value)
                  }
                />
                <TextAreaField
                  label="Budget"
                  value={formData.projectGoals?.budget || ""}
                  onChange={(value) =>
                    updateNestedFormData("projectGoals", "budget", value)
                  }
                />
                <TextAreaField
                  label="Success Metrics"
                  value={formData.projectGoals?.successMetrics || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "projectGoals",
                      "successMetrics",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Process Expectations"
                  value={formData.projectGoals?.processExpectations || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "projectGoals",
                      "processExpectations",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Team Involvement"
                  value={formData.projectGoals?.teamInvolvement || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "projectGoals",
                      "teamInvolvement",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Additional Goals"
                  value={formData.projectGoals?.additionalGoals || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "projectGoals",
                      "additionalGoals",
                      value
                    )
                  }
                  className="md:col-span-2"
                />
              </div>
            </EditorSection>

            {/* Brand Background Section */}
            <EditorSection
              title="Brand Background"
              expanded={expandedSections.brandBackground}
              onToggle={() => toggleSection("brandBackground")}
            >
              <div className="bg-white/5 rounded-lg p-3 border-l-4 border-yellow-500/50 mb-6">
                <h4 className="text-sm font-medium text-white mb-1">
                  Content Visibility Settings
                </h4>
                <p className="text-xs text-white/70">
                  Toggle which elements are visible in the Brand Background
                  section. Disabled items will not appear on the public profile
                  page.
                </p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                {[
                  "brandDescription",
                  "brandPersona",
                  "brandOrigin",
                  "recentShifts",
                  "upcomingShifts",
                  "recentExcitement",
                  "upcomingExcitement",
                  "biggestFans",
                  "isTargetMarket",
                  "competitors",
                  "challenges",
                  "narrative", // Add narrative toggle
                ].map((field) => {
                  // Get display name by converting camelCase to Title Case With Spaces
                  const displayName = field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());

                  // Special case for narrative to make it clearer
                  const finalDisplayName =
                    field === "narrative" ? "Summary Narrative" : displayName;

                  // Get current visibility setting (default to true if not set)
                  const isVisible =
                    formData.brandBackground?.visibility?.[field] !== false;

                  return (
                    <div
                      key={field}
                      className={`flex items-center space-x-3 p-2 rounded-lg hover:bg-white/5 ${field === "narrative" ? "md:col-span-3 bg-white/5" : ""
                        }`}
                    >
                      <div className="relative inline-block w-10 h-5 mr-2">
                        <input
                          type="checkbox"
                          id={`visibility-${field}`}
                          className="opacity-0 w-0 h-0"
                          checked={isVisible}
                          onChange={() => {
                            // Initialize visibility object if it doesn't exist
                            const currentVisibility =
                              formData.brandBackground?.visibility || {};

                            updateNestedFormData(
                              "brandBackground",
                              "visibility",
                              {
                                ...currentVisibility,
                                [field]: !isVisible, // Toggle the current value
                              }
                            );
                          }}
                        />
                        <label
                          htmlFor={`visibility-${field}`}
                          className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-colors duration-200 ${isVisible ? "bg-primary-green" : "bg-white/20"
                            }`}
                          style={{ width: "2.5rem", height: "1.25rem" }}
                        >
                          <span
                            className={`absolute h-4 w-4 rounded-full bg-white shadow transition-transform duration-200 ${isVisible ? "translate-x-5" : "translate-x-1"
                              }`}
                            style={{ top: "0.125rem" }}
                          ></span>
                        </label>
                      </div>
                      <label
                        htmlFor={`visibility-${field}`}
                        className="text-sm text-white cursor-pointer"
                      >
                        {finalDisplayName}
                      </label>
                      {field === "narrative" && (
                        <span className="text-xs text-white/50 ml-2">
                          Controls the AI-generated summary section at the
                          bottom
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <TextAreaField
                  label="Brand Origin"
                  value={formData.brandBackground?.brandOrigin || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "brandOrigin",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Brand Persona"
                  value={formData.brandBackground?.brandPersona || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "brandPersona",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Brand Description"
                  value={formData.brandBackground?.brandDescription || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "brandDescription",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Recent Shifts"
                  value={formData.brandBackground?.recentShifts || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "recentShifts",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Upcoming Shifts"
                  value={formData.brandBackground?.upcomingShifts || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "upcomingShifts",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Recent Excitement"
                  value={formData.brandBackground?.recentExcitement || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "recentExcitement",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Upcoming Excitement"
                  value={formData.brandBackground?.upcomingExcitement || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "upcomingExcitement",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Biggest Fans"
                  value={formData.brandBackground?.biggestFans || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "biggestFans",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Is Target Market"
                  value={formData.brandBackground?.isTargetMarket || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "isTargetMarket",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Competitors"
                  value={formData.brandBackground?.competitors || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandBackground",
                      "competitors",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Challenges"
                  value={formData.brandBackground?.challenges || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandBackground", "challenges", value)
                  }
                />
              </div>
            </EditorSection>

            {/* Brand Personality Section */}
            <EditorSection
              title="Brand Personality"
              expanded={expandedSections.brandPersonality}
              onToggle={() => toggleSection("brandPersonality")}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <TextAreaField
                  label="Mission"
                  value={formData.brandPersonality?.mission || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandPersonality", "mission", value)
                  }
                />
                <TextAreaField
                  label="Vision"
                  value={formData.brandPersonality?.vision || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandPersonality", "vision", value)
                  }
                />
                <TextAreaField
                  label="Description"
                  value={formData.brandPersonality?.description || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "description",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Surprising Feedback"
                  value={formData.brandPersonality?.surprisingFeedback || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "surprisingFeedback",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Hidden Quality"
                  value={formData.brandPersonality?.hiddenQuality || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "hiddenQuality",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Magic Wand"
                  value={formData.brandPersonality?.magicWand || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandPersonality", "magicWand", value)
                  }
                />
                <TextAreaField
                  label="Biggest Strength"
                  value={formData.brandPersonality?.biggestStrength || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "biggestStrength",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Biggest Misconception"
                  value={formData.brandPersonality?.biggestMisconception || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "biggestMisconception",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Cultural Trends"
                  value={formData.brandPersonality?.culturalTrends || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "culturalTrends",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Celebrity Choice"
                  value={formData.brandPersonality?.celebrityChoice || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "celebrityChoice",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Core Values"
                  value={formData.brandPersonality?.coreValues || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "coreValues",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Brand Voice"
                  value={formData.brandPersonality?.brandVoice || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "brandVoice",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Brand Persona"
                  value={formData.brandPersonality?.brandPersona || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "brandPersona",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Personality Traits"
                  value={formData.brandPersonality?.personalityTraits || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandPersonality",
                      "personalityTraits",
                      value
                    )
                  }
                />
              </div>
            </EditorSection>

            {/* Brand Strategy Section */}
            <EditorSection
              title="Brand Strategy"
              expanded={expandedSections.brandStrategy}
              onToggle={() => toggleSection("brandStrategy")}
            >
              <div className="bg-white/5 rounded-lg p-3 border-l-4 border-yellow-500/50 mb-6">
                <h4 className="text-sm font-medium text-white mb-1">
                  Content Visibility Settings
                </h4>
                <p className="text-xs text-white/70">
                  Toggle which elements are visible in the Brand Strategy
                  section. Disabled items will not appear on the public profile
                  page.
                </p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                {[
                  "competitors",
                  "perceptionChange",
                  "globalRecognition",
                  "mostOnBrand",
                  "needsImprovement",
                  "uniqueSellingProposition",
                  "brandPromise",
                  "narrative",
                ].map((field) => {
                  // Get display name by converting camelCase to Title Case With Spaces
                  const displayName = field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());

                  // Special case for narrative and USP to make them clearer
                  let finalDisplayName = displayName;
                  if (field === "narrative") {
                    finalDisplayName = "Summary Narrative";
                  } else if (field === "uniqueSellingProposition") {
                    finalDisplayName = "Unique Selling Proposition (USP)";
                  }

                  // Get current visibility setting (default to true if not set)
                  const isVisible =
                    formData.brandStrategy?.visibility?.[field] !== false;

                  return (
                    <div
                      key={field}
                      className={`flex items-center space-x-3 p-2 rounded-lg hover:bg-white/5 ${field === "narrative" ? "md:col-span-3 bg-white/5" : ""
                        }`}
                    >
                      <div className="relative inline-block w-10 h-5 mr-2">
                        <input
                          type="checkbox"
                          id={`strategy-visibility-${field}`}
                          className="opacity-0 w-0 h-0"
                          checked={isVisible}
                          onChange={() => {
                            // Initialize visibility object if it doesn't exist
                            const currentVisibility =
                              formData.brandStrategy?.visibility || {};

                            updateNestedFormData(
                              "brandStrategy",
                              "visibility",
                              {
                                ...currentVisibility,
                                [field]: !isVisible, // Toggle the current value
                              }
                            );
                          }}
                        />
                        <label
                          htmlFor={`strategy-visibility-${field}`}
                          className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-colors duration-200 ${isVisible ? "bg-primary-green" : "bg-white/20"
                            }`}
                          style={{ width: "2.5rem", height: "1.25rem" }}
                        >
                          <span
                            className={`absolute h-4 w-4 rounded-full bg-white shadow transition-transform duration-200 ${isVisible ? "translate-x-5" : "translate-x-1"
                              }`}
                            style={{ top: "0.125rem" }}
                          ></span>
                        </label>
                      </div>
                      <label
                        htmlFor={`strategy-visibility-${field}`}
                        className="text-sm text-white cursor-pointer"
                      >
                        {finalDisplayName}
                      </label>
                      {field === "narrative" && (
                        <span className="text-xs text-white/50 ml-2">
                          Controls the AI-generated summary section at the
                          bottom
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <TextAreaField
                  label="Competitors"
                  value={formData.brandStrategy?.competitors || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandStrategy", "competitors", value)
                  }
                />
                <TextAreaField
                  label="Global Recognition"
                  value={formData.brandStrategy?.globalRecognition || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "globalRecognition",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Most On Brand"
                  value={formData.brandStrategy?.mostOnBrand || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandStrategy", "mostOnBrand", value)
                  }
                />
                <TextAreaField
                  label="Needs Improvement"
                  value={formData.brandStrategy?.needsImprovement || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "needsImprovement",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Unique Selling Proposition"
                  value={formData.brandStrategy?.uniqueSellingProposition || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "uniqueSellingProposition",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Perception Change"
                  value={formData.brandStrategy?.perceptionChange || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "perceptionChange",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Marketing Goals"
                  value={formData.brandStrategy?.marketingGoals || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "marketingGoals",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Target Audience"
                  value={formData.brandStrategy?.targetAudience || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "targetAudience",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Positioning"
                  value={formData.brandStrategy?.positioning || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandStrategy", "positioning", value)
                  }
                />
                <TextAreaField
                  label="Key Differentiators"
                  value={formData.brandStrategy?.differentiators || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "differentiators",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Value Proposition"
                  value={formData.brandStrategy?.valueProposition || ""}
                  onChange={(value) =>
                    updateNestedFormData(
                      "brandStrategy",
                      "valueProposition",
                      value
                    )
                  }
                />
                <TextAreaField
                  label="Brand Promise"
                  value={formData.brandStrategy?.brandPromise || ""}
                  onChange={(value) =>
                    updateNestedFormData("brandStrategy", "brandPromise", value)
                  }
                />
              </div>
            </EditorSection>

            {/* Brand Characteristics Section */}
            <EditorSection
              title="Brand Characteristics"
              expanded={expandedSections.brandCharacteristics}
              onToggle={() => toggleSection("brandCharacteristics")}
            >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {Object.entries(characteristicsData).map(([key, value]) => (
                  <InputField
                    key={key}
                    label={key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                    value={value}
                    onChange={(newValue) => updateCharacteristic(key, newValue)}
                  />
                ))}
              </div>
            </EditorSection>

            {/* Brand Images Section */}
            <EditorSection
              title="Brand Images"
              expanded={expandedSections.brandImages}
              onToggle={() => toggleSection("brandImages")}
            >
              <div className="space-y-8">
                <div className="bg-white/5 rounded-lg p-4 border border-white/10">
                  <h3 className="text-lg font-semibold text-white mb-4">
                    Images Overview
                  </h3>
                  <div className="grid grid-cols-4 gap-2 mb-4">
                    <div className="text-white/70 font-medium">
                      Logo Images:{" "}
                      {
                        (formData.brandStyle?.images || []).filter(
                          (img) => img.type === "logo"
                        ).length
                      }
                    </div>
                    <div className="text-white/70 font-medium">
                      Hero Images:{" "}
                      {
                        (formData.brandStyle?.images || []).filter(
                          (img) => img.type === "hero"
                        ).length
                      }
                    </div>
                    <div className="text-white/70 font-medium">
                      Soundboard Images:{" "}
                      {
                        (formData.brandStyle?.images || []).filter(
                          (img) => img.type === "soundboard"
                        ).length
                      }
                    </div>
                    <div className="text-white/70 font-medium">
                      Other Images:{" "}
                      {
                        (formData.brandStyle?.images || []).filter(
                          (img) => img.type === "other"
                        ).length
                      }
                    </div>
                  </div>
                  <p className="text-white/50 text-sm italic mb-2">
                    Images are used as backgrounds in various sections of the
                    brand profile. The parallax effect applies to these images.
                  </p>
                  <p className="text-white/50 text-sm italic">
                    If images appear blocked or don't load correctly, update
                    their URLs below.
                  </p>
                </div>

                {/* Add New Image */}
                <div className="bg-white/5 rounded-lg p-4 border border-white/10">
                  <h3 className="text-lg font-semibold text-white mb-4">
                    Add New Image
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4 items-end">
                    <div className="md:col-span-3">
                      <InputField
                        label="Image URL"
                        value={newImage.url}
                        onChange={(value) =>
                          setNewImage({ ...newImage, url: value })
                        }
                      />
                    </div>
                    <div className="md:col-span-1">
                      <SelectField
                        label="Image Type"
                        value={newImage.type}
                        options={[
                          { value: "logo", label: "Logo" },
                          { value: "hero", label: "Hero" },
                          { value: "soundboard", label: "Soundboard" },
                          { value: "other", label: "Other" },
                        ]}
                        onChange={(value) =>
                          setNewImage({ ...newImage, type: value })
                        }
                      />
                    </div>
                    <div className="md:col-span-1 flex gap-2">
                      <button
                        className="px-3 py-2 rounded-lg bg-white/10 hover:bg-white/20 text-white flex items-center justify-center gap-1"
                        onClick={() => testImageUrl(newImage.url)}
                        disabled={testingImage}
                      >
                        {testingImage ? (
                          <>
                            <RefreshCw size={16} className="animate-spin" />
                            Testing
                          </>
                        ) : (
                          <>
                            <ImageIcon size={16} />
                            Test
                          </>
                        )}
                      </button>
                      <button
                        className="px-3 py-2 rounded-lg bg-white/10 hover:bg-white/20 text-white flex items-center justify-center gap-1"
                        onClick={addNewImage}
                      >
                        <Plus size={16} />
                        Add
                      </button>
                    </div>
                  </div>

                  {/* Test result */}
                  {imageTestResult && (
                    <div
                      className={`mt-4 p-3 rounded-lg ${imageTestResult.success
                        ? "bg-green-900/30 border border-green-700/50 text-green-100"
                        : "bg-red-900/30 border border-red-700/50 text-red-100"
                        }`}
                    >
                      {imageTestResult.success ? (
                        <div className="flex items-center gap-3">
                          <div className="w-12 h-12 rounded-md bg-black/20 flex items-center justify-center overflow-hidden">
                            <img
                              src={newImage.url}
                              alt="Test preview"
                              className="max-w-full max-h-full object-contain"
                            />
                          </div>
                          <div>
                            <p className="font-medium">
                              Image loaded successfully!
                            </p>
                            <p className="text-sm opacity-80">
                              Dimensions: {imageTestResult.dimensions.width} ×{" "}
                              {imageTestResult.dimensions.height}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <p>{imageTestResult.error}</p>
                      )}
                    </div>
                  )}
                </div>

                {/* Existing Images */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-white">
                    Existing Images
                  </h3>

                  {formData.brandStyle?.images &&
                    formData.brandStyle.images.length > 0 ? (
                    <div className="space-y-4">
                      {formData.brandStyle.images.map((image, index) => (
                        <div
                          key={index}
                          className="bg-white/5 rounded-lg p-4 border border-white/10"
                        >
                          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-center">
                            {/* Image Preview */}
                            <div className="md:col-span-2 flex justify-center">
                              <div className="w-24 h-24 rounded-lg bg-black/20 flex items-center justify-center overflow-hidden">
                                <img
                                  src={image.url}
                                  alt={`${image.type} image`}
                                  className="max-w-full max-h-full object-contain"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCIgc3Ryb2tlPSIjOTk5IiBzdHJva2Utd2lkdGg9IjEuNSIvPjxwYXRoIGQ9Ik05IDl2MS41aDB2My43NWhNMTMuNSA5djYiIHN0cm9rZT0iIzk5OSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4=";
                                  }}
                                />
                              </div>
                            </div>

                            {/* Image URL */}
                            <div className="md:col-span-6">
                              <InputField
                                label="Image URL"
                                value={image.url}
                                onChange={(value) =>
                                  updateImageUrl(index, value)
                                }
                              />
                              <div className="flex items-center mt-1 gap-1">
                                <span className="text-xs text-white/50">
                                  Type:{" "}
                                </span>
                                <span
                                  className={`text-xs px-2 py-0.5 rounded-full ${image.type === "logo"
                                    ? "bg-blue-900/50 text-blue-100"
                                    : image.type === "hero"
                                      ? "bg-purple-900/50 text-purple-100"
                                      : image.type === "soundboard"
                                        ? "bg-green-900/50 text-green-100"
                                        : "bg-gray-800/70 text-gray-300"
                                    }`}
                                >
                                  {image.type}
                                </span>
                                <button
                                  onClick={() =>
                                    testImageUrl(
                                      image.url,
                                      updateImageDimensions,
                                      index
                                    )
                                  }
                                  disabled={testingImage}
                                  className="ml-2 text-xs text-white/50 hover:text-white flex items-center gap-1 px-1.5 py-0.5 rounded hover:bg-white/10"
                                >
                                  {testingImage ? (
                                    <RefreshCw
                                      size={12}
                                      className="animate-spin"
                                    />
                                  ) : (
                                    <ImageIcon size={12} />
                                  )}
                                  Test
                                </button>
                                <a
                                  href={image.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="ml-auto text-xs text-white/50 hover:text-white flex items-center gap-1"
                                >
                                  <ExternalLink size={12} />
                                  Open
                                </a>
                              </div>
                            </div>

                            {/* Image Type */}
                            <div className="md:col-span-2">
                              <SelectField
                                label="Change Type"
                                value={image.type}
                                options={[
                                  { value: "logo", label: "Logo" },
                                  { value: "hero", label: "Hero" },
                                  { value: "soundboard", label: "Soundboard" },
                                  { value: "other", label: "Other" },
                                ]}
                                onChange={(value) =>
                                  updateImageType(index, value)
                                }
                              />
                            </div>

                            {/* Delete Button */}
                            <div className="md:col-span-2 flex justify-center items-end h-full">
                              <button
                                className="px-3 py-2 rounded-lg bg-red-900/30 hover:bg-red-900/50 text-red-100 flex items-center justify-center gap-1"
                                onClick={() => deleteImage(index)}
                              >
                                <Trash size={16} />
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-white/50 p-4 text-center bg-white/5 rounded-lg">
                      No images found. Add images to enhance the brand profile.
                    </div>
                  )}
                </div>
              </div>
            </EditorSection>

            {/* Narratives Section */}
            <EditorSection
              title="AI-Generated Narratives"
              expanded={expandedSections.narratives}
              onToggle={() => toggleSection("narratives")}
            >
              {narrativeSections.length > 0 ? (
                <div className="space-y-6">
                  {narrativeSections.map((section) => (
                    <div
                      key={section}
                      className="bg-white/5 rounded-lg p-6 border border-white/10"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-semibold capitalize text-white">
                          {section
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}{" "}
                          Narrative
                        </h3>
                        <div className="text-xs text-white/50">
                          {formData.narratives[section].manuallyEdited
                            ? "Manually edited"
                            : "AI generated"}
                        </div>
                      </div>
                      <TextAreaField
                        value={formData.narratives[section].text || ""}
                        onChange={(value) =>
                          updateNarrativeData(section, value)
                        }
                        rows={6}
                      />
                      <div className="mt-2 text-xs text-white/50">
                        {formData.narratives[section].lastEditedAt
                          ? `Last edited: ${new Date(
                            formData.narratives[section].lastEditedAt
                          ).toLocaleString()}`
                          : ""}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-white/50 p-4 text-center">
                  No narrative sections found. Narratives will be generated when
                  the profile is viewed.
                </div>
              )}
            </EditorSection>

            {/* Soundboard Section */}
            <EditorSection
              title="Soundboard"
              expanded={expandedSections.soundboard}
              onToggle={() => toggleSection("soundboard")}
            >
              <div className="space-y-6">
                <TextAreaField
                  label="Soundboard Description"
                  value={formData.soundboard?.description || ""}
                  onChange={(value) => updateSoundboardDescription(value)}
                  rows={4}
                />

                {/* Timeline Steps */}
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-medium">Timeline Steps</h4>
                    <button
                      onClick={addTimelineStep}
                      className="px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg hover:bg-[var(--primary-color-dark)] transition-colors"
                    >
                      Add Step
                    </button>
                  </div>
                  {formData.soundboard?.timeline?.map((step, index) => (
                    <div key={index} className="space-y-4 p-4 bg-white/5 rounded-lg">
                      <div className="flex items-center justify-between">
                        <h5 className="text-md font-medium">Step {index + 1}</h5>
                        <button
                          onClick={() => removeTimelineStep(index)}
                          className="text-red-400 hover:text-red-300"
                        >
                          <Trash size={16} />
                        </button>
                      </div>
                      <InputField
                        label="Title"
                        value={step.title || ""}
                        onChange={(value) => updateTimelineStep(index, "title", value)}
                      />
                      <TextAreaField
                        label="Content"
                        value={step.textContent || ""}
                        onChange={(value) => updateTimelineStep(index, "textContent", value)}
                        rows={3}
                      />
                    </div>
                  ))}
                </div>

                {/* Audio Files */}
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-medium">Audio Files</h4>
                    <button
                      onClick={addAudioFile}
                      className="px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg hover:bg-[var(--primary-color-dark)] transition-colors"
                    >
                      Add Audio File
                    </button>
                  </div>
                  {formData.soundboard?.audioFiles?.map((audio, index) => (
                    <div key={index} className="space-y-4 p-4 bg-white/5 rounded-lg">
                      <div className="flex items-center justify-between">
                        <h5 className="text-md font-medium">Audio File {index + 1}</h5>
                        <button
                          onClick={() => removeAudioFile(index)}
                          className="text-red-400 hover:text-red-300"
                        >
                          <Trash size={16} />
                        </button>
                      </div>
                      <InputField
                        label="Title"
                        value={audio.title || ""}
                        onChange={(value) => updateAudioFile(index, "title", value)}
                      />
                      <div className="space-y-4">
                        <div className="flex items-center justify-between">
                          <h6 className="text-sm font-medium">Descriptions</h6>
                          <button
                            onClick={() => addDescription(index)}
                            className="px-3 py-1 bg-white/5 text-white/70 rounded-lg hover:bg-white/10 hover:text-white transition-colors text-sm"
                          >
                            Add Description
                          </button>
                        </div>
                        {(audio.descriptions || [""]).map((description, descIndex) => (
                          <div key={descIndex} className="relative">
                            <TextAreaField
                              label={`Description ${descIndex + 1}`}
                              value={description}
                              onChange={(value) => updateDescription(index, descIndex, value)}
                              rows={3}
                            />
                            {(audio.descriptions?.length > 1) && (
                              <button
                                onClick={() => removeDescription(index, descIndex)}
                                className="absolute top-2 right-2 p-1 text-red-400 hover:text-red-300"
                              >
                                <X size={16} />
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center gap-4">
                        <InputField
                          label="Audio URL"
                          value={audio.url || ""}
                          onChange={(value) => updateAudioFile(index, "url", value)}
                          className="flex-1"
                        />
                        <button
                          onClick={() => handleAudioUpload(index)}
                          className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Notes */}
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-medium">Notes</h4>
                    <button
                      onClick={addNote}
                      className="px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg hover:bg-[var(--primary-color-dark)] transition-colors"
                    >
                      Add Note
                    </button>
                  </div>
                  {formData.soundboard?.notes?.map((note, index) => (
                    <div key={index} className="space-y-4 p-4 bg-white/5 rounded-lg">
                      <div className="flex items-center justify-between">
                        <h5 className="text-md font-medium">Note {index + 1}</h5>
                        <button
                          onClick={() => removeNote(index)}
                          className="text-red-400 hover:text-red-300"
                        >
                          <Trash size={16} />
                        </button>
                      </div>
                      <InputField
                        label="Title"
                        value={note.title || ""}
                        onChange={(value) => updateNote(index, "title", value)}
                      />
                      <InputField
                        label="Label"
                        value={note.label || ""}
                        onChange={(value) => updateNote(index, "label", value)}
                      />
                      <TextAreaField
                        label="Content"
                        value={note.content || ""}
                        onChange={(value) => updateNote(index, "content", value)}
                        rows={3}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </EditorSection>
          </div>
        )}
      </div>
    </div>
  );
};

// Helper components for the editor
const EditorSection = ({ title, children, expanded = false, onToggle }) => {
  return (
    <div className="bg-white/5 rounded-lg border border-white/10 overflow-hidden">
      <div
        className="flex items-center justify-between p-4 cursor-pointer"
        onClick={onToggle}
      >
        <h3 className="text-xl font-semibold text-white">{title}</h3>
        <motion.div
          animate={{ rotate: expanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-5 h-5 text-white/70" />
        </motion.div>
      </div>
      {expanded && (
        <div className="p-6 border-t border-white/10">{children}</div>
      )}
    </div>
  );
};

const InputField = ({
  label,
  value,
  onChange,
  className = "",
  type = "text",
  placeholder = "",
  tooltip = "",
}) => {
  return (
    <div className={`space-y-2 ${className}`}>
      <label className="block text-sm font-medium text-white/70 flex items-center">
        {label}
        {tooltip && (
          <div className="group relative ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-white/40"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64 p-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200 z-10">
              {tooltip}
            </div>
          </div>
        )}
      </label>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50 placeholder-white/30"
      />
    </div>
  );
};

const TextAreaField = ({
  label,
  value,
  onChange,
  className = "",
  rows = 4,
}) => {
  return (
    <div className={`space-y-2 ${className}`}>
      {label && (
        <label className="block text-sm font-medium text-white/70">
          {label}
        </label>
      )}
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={rows}
        className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
      />
    </div>
  );
};

const SelectField = ({ label, value, options, onChange, className = "" }) => {
  return (
    <div className={`space-y-2 ${className}`}>
      <label className="block text-sm font-medium text-white/70">{label}</label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-primary-green/50"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BrandProfileEditor;
