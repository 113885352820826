import React, { useRef, useMemo, useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  User,
  MessagesSquare,
  Eye,
  Heart,
  Star,
  Lightbulb,
  Award,
  Zap,
  Feather,
  Music,
  Users,
  Sparkles,
  ChevronRight,
  ChevronLeft,
  BarChart,
  CheckCircle,
} from "lucide-react";
import NarrativeSection from "../NarrativeSection";

// Interactive Card Component
const PersonalityCard = ({ item, index, active, onClick }) => {
  // Different background colors for visual variety
  const bgGradients = [
    "linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.03) 100%)",
    "linear-gradient(135deg, rgba(var(--primary-color-rgb), 0.12) 0%, rgba(var(--primary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.12) 0%, rgba(var(--secondary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--accent-color-rgb), 0.12) 0%, rgba(var(--accent-color-rgb), 0.06) 100%)",
  ];

  const gradient = bgGradients[index % bgGradients.length];
  const delayAnimation = index * 0.1;

  // Split long text into chunks for better readability when expanded
  const formatExpandedText = (text) => {
    if (!text) return ["No information available"];

    // If text is shorter than 200 chars, don't split it
    if (text.length < 200) return [text];

    // Split by sentences for better paragraph breaks
    const sentences = text.split(/(?<=[.!?])\s+/);
    const paragraphs = [];
    let currentParagraph = "";

    sentences.forEach((sentence) => {
      if (currentParagraph.length + sentence.length > 300) {
        paragraphs.push(currentParagraph);
        currentParagraph = sentence;
      } else {
        currentParagraph += (currentParagraph ? " " : "") + sentence;
      }
    });

    if (currentParagraph) {
      paragraphs.push(currentParagraph);
    }

    return paragraphs;
  };

  const paragraphs = formatExpandedText(item.value);

  return (
    <motion.div
      className={`rounded-xl overflow-hidden backdrop-blur-sm border border-white/10 cursor-pointer transition-all duration-300 ${
        active ? "col-span-full" : ""
      }`}
      style={{
        background: gradient,
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: delayAnimation }}
      onClick={onClick}
      whileHover={{
        scale: active ? 1 : 1.02,
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <motion.div
        className="p-6"
        initial={false}
        animate={{
          padding: active ? "1.5rem" : "1.5rem",
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <motion.div
              className="w-10 h-10 rounded-full flex items-center justify-center mr-3 text-white"
              style={{ backgroundColor: "var(--primary-color)" }}
              whileHover={{ scale: 1.1 }}
            >
              {item.icon}
            </motion.div>
            <h3 className="text-xl font-bold text-white">{item.title}</h3>
          </div>
          <motion.div
            animate={{ rotate: active ? 90 : 0 }}
            transition={{ duration: 0.3 }}
            className="text-white/60"
          >
            <ChevronRight size={20} />
          </motion.div>
        </div>

        <AnimatePresence>
          {!active && (
            <motion.div
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="mt-3"
            >
              <p className="text-white/70 line-clamp-3">
                {item.value || "Not specified"}
              </p>
            </motion.div>
          )}

          {active && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-4 space-y-4"
            >
              {paragraphs.map((paragraph, i) => (
                <p key={i} className="text-white/90 leading-relaxed">
                  {paragraph}
                </p>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

const BrandPersonalitySection = ({
  personality,
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [showNarrative, setShowNarrative] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  // Effect to manage narrative visibility on mobile when a card is expanded
  useEffect(() => {
    if (activeCardIndex !== null && window.innerWidth < 768) {
      setShowNarrative(false);
    } else {
      setShowNarrative(true);
    }
  }, [activeCardIndex]);

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(sectionProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const bgOpacity = useTransform(sectionProgress, [0, 0.5, 1], [0.5, 0.7, 0.5]);
  const bgBlur = useTransform(sectionProgress, [0, 0.5, 1], [0, 3, 0]);

  // Mobile pagination settings
  const CARDS_PER_SLIDE = 3;

  // Prepare data for the narrative generation
  const personalityData = useMemo(
    () => ({
      mission: personality?.mission,
      vision: personality?.vision,
      description: personality?.description,
      surprisingFeedback: personality?.surprisingFeedback,
      hiddenQuality: personality?.hiddenQuality,
      magicWand: personality?.magicWand,
      biggestStrength: personality?.biggestStrength,
      biggestMisconception: personality?.biggestMisconception,
      culturalTrends: personality?.culturalTrends,
      celebrityChoice: personality?.celebrityChoice,
      coreValues: personality?.coreValues,
      brandVoice: personality?.brandVoice,
      brandPersona: personality?.brandPersona,
      personalityTraits: personality?.personalityTraits,
    }),
    [
      personality?.mission,
      personality?.vision,
      personality?.description,
      personality?.surprisingFeedback,
      personality?.hiddenQuality,
      personality?.magicWand,
      personality?.biggestStrength,
      personality?.biggestMisconception,
      personality?.culturalTrends,
      personality?.celebrityChoice,
      personality?.coreValues,
      personality?.brandVoice,
      personality?.brandPersona,
      personality?.personalityTraits,
    ]
  );

  if (!personality) return null;

  // Define personality items with icons
  const personalityItems = [
    {
      title: "Mission",
      value: personality.mission,
      icon: <Star size={20} className="text-white" />,
    },
    {
      title: "Vision",
      value: personality.vision,
      icon: <Eye size={20} className="text-white" />,
    },
    {
      title: "Description",
      value: personality.description,
      icon: <MessagesSquare size={20} className="text-white" />,
    },
    {
      title: "Surprising Feedback",
      value: personality.surprisingFeedback,
      icon: <Sparkles size={20} className="text-white" />,
    },
    {
      title: "Hidden Quality",
      value: personality.hiddenQuality,
      icon: <Eye size={20} className="text-white" />,
    },
    {
      title: "Magic Wand",
      value: personality.magicWand,
      icon: <Sparkles size={20} className="text-white" />,
    },
    {
      title: "Biggest Strength",
      value: personality.biggestStrength,
      icon: <Award size={20} className="text-white" />,
    },
    {
      title: "Biggest Misconception",
      value: personality.biggestMisconception,
      icon: <Zap size={20} className="text-white" />,
    },
    {
      title: "Cultural Trends",
      value: personality.culturalTrends,
      icon: <BarChart size={20} className="text-white" />,
    },
    {
      title: "Celebrity Choice",
      value: personality.celebrityChoice,
      icon: <User size={20} className="text-white" />,
    },
    {
      title: "Core Values",
      value: personality.coreValues,
      icon: <Heart size={20} className="text-white" />,
    },
    {
      title: "Brand Voice",
      value: personality.brandVoice,
      icon: <Feather size={20} className="text-white" />,
    },
    {
      title: "Brand Persona",
      value: personality.brandPersona,
      icon: <Users size={20} className="text-white" />,
    },
    {
      title: "Personality Traits",
      value: personality.personalityTraits,
      icon: <Lightbulb size={20} className="text-white" />,
    },
  ];

  // Filter out empty items
  const filteredItems = personalityItems.filter((item) => item.value);

  // Group items into slides for mobile navigation
  const itemSlides = [];
  for (let i = 0; i < filteredItems.length; i += CARDS_PER_SLIDE) {
    itemSlides.push(filteredItems.slice(i, i + CARDS_PER_SLIDE));
  }

  const handleCardClick = (index) => {
    setActiveCardIndex(activeCardIndex === index ? null : index);
  };

  const nextSlide = () => {
    setActiveSlide((prev) => (prev < itemSlides.length - 1 ? prev + 1 : 0));
  };

  const prevSlide = () => {
    setActiveSlide((prev) => (prev > 0 ? prev - 1 : itemSlides.length - 1));
  };

  // Reset active slide if the current slide index is now out of bounds
  if (itemSlides.length === 0) {
    setActiveSlide(0);
  } else if (activeSlide >= itemSlides.length) {
    setActiveSlide(itemSlides.length - 1);
  }

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-20 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Dot pattern background with dynamic blur */}
      <motion.div
        className="absolute inset-0 -z-10"
        style={{ filter: `blur(${bgBlur}px)` }}
      >
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </motion.div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: 0.7,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 max-w-7xl mx-auto px-6"
        style={{ scale }}
      >
        <motion.h2
          className="text-5xl md:text-7xl mb-10 font-bold text-white"
          style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Brand Personality
        </motion.h2>

        {/* Main Content */}
        <div className="space-y-10">
          {/* Desktop View: Interactive Cards Grid */}
          <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 auto-rows-min">
            {filteredItems.map((item, index) => (
              <PersonalityCard
                key={index}
                item={item}
                index={index}
                active={activeCardIndex === index}
                onClick={() => handleCardClick(index)}
              />
            ))}
          </div>

          {/* Mobile View: Interactive Cards Carousel */}
          <div className="md:hidden relative">
            <div className="flex justify-between items-center mb-4">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={prevSlide}
                className="bg-white/10 rounded-full p-2"
              >
                <ChevronLeft className="text-white" size={20} />
              </motion.button>

              <div className="text-white/60 text-sm">
                {activeSlide + 1} / {itemSlides.length}
              </div>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={nextSlide}
                className="bg-white/10 rounded-full p-2"
              >
                <ChevronRight className="text-white" size={20} />
              </motion.button>
            </div>

            <div className="space-y-4">
              {itemSlides[activeSlide]?.map((item, index) => (
                <PersonalityCard
                  key={index}
                  item={item}
                  index={index}
                  active={
                    activeCardIndex === index + activeSlide * CARDS_PER_SLIDE
                  }
                  onClick={() =>
                    handleCardClick(index + activeSlide * CARDS_PER_SLIDE)
                  }
                />
              ))}
            </div>

            {itemSlides.length > 1 && (
              <div className="flex justify-center mt-6 space-x-2">
                {itemSlides.map((_, index) => (
                  <motion.button
                    key={index}
                    className="w-2 h-2 rounded-full"
                    style={{
                      backgroundColor:
                        activeSlide === index
                          ? "var(--primary-color)"
                          : "rgba(255, 255, 255, 0.3)",
                    }}
                    onClick={() => setActiveSlide(index)}
                    whileHover={{ scale: 1.3 }}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Narrative Section */}
          <AnimatePresence>
            {showNarrative && (
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 40 }}
                transition={{ duration: 0.5 }}
                className="mt-16"
              >
                <motion.div
                  className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 overflow-hidden"
                  whileHover={{
                    boxShadow: "0 0 30px rgba(var(--primary-color-rgb), 0.15)",
                  }}
                >
                  <div className="mb-6 flex items-center justify-between">
                    <h3 className="text-2xl font-bold text-white flex items-center gap-3">
                      <User className="text-[var(--primary-color)]" size={22} />
                      Brand Personality Overview
                    </h3>
                  </div>

                  <NarrativeSection
                    profileId={profileId}
                    section="brandPersonality"
                    sectionData={personalityData}
                    companyUrl={companyUrl}
                    fallbackText="Analyzing brand personality..."
                    textColor="rgba(255, 255, 255, 0.9)"
                    accentColor="var(--primary-color)"
                    style={{
                      minHeight: "300px",
                      fontSize: "1.2rem",
                      lineHeight: "1.8",
                      fontWeight: "500",
                    }}
                  />
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default BrandPersonalitySection;
