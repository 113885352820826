import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Plus,
  Edit,
  Trash2,
  Loader,
  LogOut,
  FileText,
  Briefcase,
  GripVertical,
  ClipboardList,
  Users,
  FileQuestion,
} from "lucide-react";
import { motion } from "framer-motion";
import ProjectEditor from "./ProjectEditor";
import BlogEditor from "./BlogEditor";
import { projectOperations } from "../../utils/projectFirestore";
import { blogOperations } from "../../utils/blogFirestore";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./alert-dialog";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Import the OnboardingModule
import OnboardingModule from "./OnboardingModule";
import BrandProfilesModule from "./BrandProfilesModule";

// Import the QuestionnaireModule
import QuestionnaireModule from "../DynamicQuestionnaire/admin/QuestionnaireModule";

// Define the SortableItem component first, before the main component
const SortableItem = ({ id, item, activeTab, onEdit, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`bg-white/5 rounded-lg border ${
        isDragging ? "border-primary-green/20" : "border-white/10"
      } p-6`}
    >
      <div className="flex justify-between items-start">
        <div className="flex items-start gap-4">
          <div {...attributes} {...listeners} className="cursor-move pt-1">
            <GripVertical className="w-4 h-4 text-white/40" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-2">
              {item.title}
            </h3>
            <div className="flex items-center gap-4 text-sm text-white/70">
              <span className="px-2 py-1 rounded-full bg-white/10">
                {activeTab === "projects" ? item.type : item.category}
              </span>
              <span>
                Last modified:{" "}
                {item.updatedAt
                  ? new Date(item.updatedAt).toLocaleDateString()
                  : "N/A"}
              </span>
              <span
                className={`px-2 py-1 rounded-full ${
                  item.status === "published"
                    ? "bg-primary-green/20 text-primary-green"
                    : "bg-yellow-500/20 text-yellow-500"
                }`}
              >
                {item.status || "draft"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onEdit}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            <Edit className="w-4 h-4" />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onDelete}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            <Trash2 className="w-4 h-4" />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

const AdminDashboard = ({ onLogout }) => {
  const [activeTab, setActiveTab] = useState("projects"); // "projects", "blogs", "onboarding", "brand-profiles", or "questionnaires"
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [error, setError] = useState(null);
  const [fetchStatus, setFetchStatus] = useState("loading");
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const repairOrdering = async () => {
    try {
      setFetchStatus("loading");
      await projectOperations.repairProjectOrders();
      await fetchItems(); // Refresh the list
      setFetchStatus("success");
    } catch (error) {
      console.error("Error repairing order:", error);
      setError(error);
      setFetchStatus("error");
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.findIndex((item) => item.id === active.id);
        const newIndex = prevItems.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(prevItems, oldIndex, newIndex);

        // Update order in Firebase
        projectOperations.updateProjectOrder(
          newItems.map((item, index) => ({
            id: item.id,
            order: index,
          }))
        );

        return newItems;
      });
    }
  };

  // Get the correct operations based on active tab
  const operations =
    activeTab === "projects" ? projectOperations : blogOperations;

  const fetchItems = useCallback(async () => {
    try {
      setFetchStatus("loading");
      const fetchedItems =
        activeTab === "projects"
          ? await projectOperations.getProjects()
          : await blogOperations.getPosts();
      setItems(fetchedItems);
      setFetchStatus("success");
    } catch (error) {
      // console.error(`Error fetching ${activeTab}:`, error);
      setError(error.message);
      setFetchStatus("error");
    }
  }, [activeTab]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, activeTab]);

  const handleLogout = async () => {
    try {
      await onLogout();
      navigate("/");
    } catch (error) {
      // console.error("Logout error:", error);
    }
  };

  const handleCreateNew = () => {
    const timestamp = new Date().toISOString();

    if (activeTab === "projects") {
      setSelectedItem({
        title: "New Project",
        type: "tv-commercial",
        status: "draft",
        createdAt: timestamp,
        updatedAt: timestamp,
        sections: [
          { type: "brand", data: { logo: "" } },
          { type: "video", data: { videoUrl: "", posterImage: "" } },
          { type: "overview", data: { brief: "", overview: "" } },
          {
            type: "feedback",
            data: { whatTheyLoved: "", whatWeLoved: "", result: "" },
          },
          { type: "imageGrid", data: { images: [] } },
        ],
      });
    } else {
      setSelectedItem({
        title: "New Post",
        content: "",
        excerpt: "",
        category: "",
        status: "draft",
        author: "",
        featuredImage: "",
        createdAt: timestamp,
        updatedAt: timestamp,
      });
    }
    setIsEditorOpen(true);
  };

  const handleSave = async (updatedItem) => {
    try {
      setFetchStatus("loading");
      console.log("Starting save operation:", {
        activeTab,
        selectedItem,
        updatedItem,
      });

      if (activeTab === "projects") {
        if (selectedItem?.id) {
          // Ensure we're passing the ID and data correctly
          console.log("Updating project:", selectedItem.id, updatedItem);
          await projectOperations.updateProject(selectedItem.id, {
            ...updatedItem,
            status: updatedItem.status || "draft",
            updatedAt: new Date().toISOString(),
          });
        } else {
          console.log("Creating new project");
          await projectOperations.createProject({
            ...updatedItem,
            status: updatedItem.status || "draft",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      } else {
        if (selectedItem?.id) {
          console.log("Updating blog post:", selectedItem.id, updatedItem);
          await blogOperations.updatePost(selectedItem.id, {
            ...updatedItem,
            status: updatedItem.status || "draft",
            updatedAt: new Date().toISOString(),
          });
        } else {
          console.log("Creating new blog post");
          await blogOperations.createPost({
            ...updatedItem,
            status: updatedItem.status || "draft",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      }

      // Only clear the editor and refresh after successful save
      setIsEditorOpen(false);
      setSelectedItem(null);
      await fetchItems();
      setFetchStatus("success");
    } catch (error) {
      console.error("Error in handleSave:", error);
      setError(error.message);
      setFetchStatus("error");
    }
  };

  // Updated handleDelete function
  const handleDelete = async () => {
    if (!deleteItem) return;

    try {
      setFetchStatus("loading");
      if (activeTab === "projects") {
        await projectOperations.deleteProject(deleteItem.id);
      } else {
        await blogOperations.deletePost(deleteItem.id);
      }
      await fetchItems();
      setDeleteItem(null);
    } catch (error) {
      console.error(`Error deleting ${activeTab.slice(0, -1)}:`, error);
      setError(error.message);
      setFetchStatus("error");
    }
  };

  return (
    <div className="min-h-screen bg-[#111111] text-white p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-12">
        <h1 className="text-4xl font-light">Admin Dashboard</h1>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleLogout}
          className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white/5 hover:bg-white/10"
        >
          <LogOut className="w-4 h-4" />
          <span>Logout</span>
        </motion.button>
      </div>

      {/* Navigation Tabs */}
      <div className="flex gap-4 mb-8 border-b border-white/10">
        <button
          onClick={() => setActiveTab("projects")}
          className={`px-4 py-2 ${
            activeTab === "projects"
              ? "text-primary-green border-b-2 border-primary-green"
              : "text-white/60 hover:text-white"
          }`}
        >
          <div className="flex items-center gap-2">
            <Briefcase className="w-4 h-4" />
            <span>Projects</span>
          </div>
        </button>
        <button
          onClick={() => setActiveTab("blogs")}
          className={`px-4 py-2 ${
            activeTab === "blogs"
              ? "text-primary-green border-b-2 border-primary-green"
              : "text-white/60 hover:text-white"
          }`}
        >
          <div className="flex items-center gap-2">
            <FileText className="w-4 h-4" />
            <span>Blog Posts</span>
          </div>
        </button>
        <button
          onClick={() => setActiveTab("onboarding")}
          className={`px-4 py-2 ${
            activeTab === "onboarding"
              ? "text-primary-green border-b-2 border-primary-green"
              : "text-white/60 hover:text-white"
          }`}
        >
          <div className="flex items-center gap-2">
            <ClipboardList className="w-4 h-4" />
            <span>Onboarding</span>
          </div>
        </button>
        <button
          onClick={() => setActiveTab("brand-profiles")}
          className={`px-4 py-2 ${
            activeTab === "brand-profiles"
              ? "text-primary-green border-b-2 border-primary-green"
              : "text-white/60 hover:text-white"
          }`}
        >
          <div className="flex items-center gap-2">
            <Users className="w-4 h-4" />
            <span>Brand Profiles</span>
          </div>
        </button>
        <button
          onClick={() => setActiveTab("questionnaires")}
          className={`px-4 py-2 ${
            activeTab === "questionnaires"
              ? "text-primary-green border-b-2 border-primary-green"
              : "text-white/60 hover:text-white"
          }`}
        >
          <div className="flex items-center gap-2">
            <FileQuestion className="w-4 h-4" />
            <span>Questionnaires</span>
          </div>
        </button>
      </div>

      {/* Content Area */}
      <div className="space-y-8">
        {/* Action Bar */}
        {activeTab !== "brand-profiles" && activeTab !== "questionnaires" && (
          <div className="flex justify-between items-center">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleCreateNew}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-primary-green text-black"
            >
              <Plus className="w-4 h-4" />
              <span>
                Create New {activeTab === "projects" ? "Project" : "Post"}
              </span>
            </motion.button>
            {activeTab === "projects" && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={repairOrdering}
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white/5 hover:bg-white/10"
              >
                <span>Repair Order</span>
              </motion.button>
            )}
          </div>
        )}

        {/* Content */}
        {activeTab === "projects" && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <div className="space-y-4">
                {items.map((item) => (
                  <SortableItem
                    key={item.id}
                    id={item.id}
                    item={item}
                    activeTab={activeTab}
                    onEdit={() => {
                      setSelectedItem({
                        ...item,
                        updatedAt: item.updatedAt || new Date().toISOString(),
                      });
                      setIsEditorOpen(true);
                    }}
                    onDelete={() => setDeleteItem(item)}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        )}

        {activeTab === "blogs" && (
          <div className="space-y-4">
            {items.map((item) => (
              <SortableItem
                key={item.id}
                id={item.id}
                item={item}
                activeTab={activeTab}
                onEdit={() => {
                  setSelectedItem({
                    ...item,
                    updatedAt: item.updatedAt || new Date().toISOString(),
                  });
                  setIsEditorOpen(true);
                }}
                onDelete={() => setDeleteItem(item)}
              />
            ))}
          </div>
        )}

        {activeTab === "onboarding" && <OnboardingModule />}

        {activeTab === "brand-profiles" && <BrandProfilesModule />}

        {activeTab === "questionnaires" && <QuestionnaireModule />}
      </div>

      {/* Editor Modal */}
      {isEditorOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-[#111111] rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            {activeTab === "projects" ? (
              <ProjectEditor
                project={selectedItem}
                onSave={handleSave}
                onClose={() => setIsEditorOpen(false)}
              />
            ) : (
              <BlogEditor
                post={selectedItem}
                onSave={handleSave}
                onClose={() => setIsEditorOpen(false)}
              />
            )}
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={!!deleteItem} onOpenChange={() => setDeleteItem(null)}>
        <AlertDialogContent className="relative p-6 bg-[#111111] border border-primary-purple/20 rounded-lg backdrop-blur-xl shadow-xl shadow-primary-green/5">
          <AlertDialogHeader className="space-y-4">
            <AlertDialogTitle className="text-2xl font-light text-white">
              Delete{" "}
              <span className="font-bold text-primary-purple">
                {deleteItem?.title}
              </span>
              ?
            </AlertDialogTitle>
            <AlertDialogDescription className="text-base text-white/70">
              <div className="p-4 bg-white/5 border border-white/10 rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <div className="w-2 h-2 rounded-full bg-primary-green animate-pulse"></div>
                  <span className="text-primary-green font-medium">
                    Warning:
                  </span>
                </div>
                This action cannot be undone. This will permanently delete this{" "}
                {activeTab.slice(0, -1)} and all associated data.
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex items-center gap-3 pt-6">
            <button
              onClick={() => setDeleteItem(null)}
              className="flex-1 px-4 py-3 rounded-full bg-white/5 text-white 
                   hover:bg-white/10 transition-colors duration-200
                   border border-white/10 font-medium"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="flex-1 px-4 py-3 rounded-full bg-primary-purple 
                   text-white hover:bg-primary-purple/90 transition-colors duration-200
                   border border-primary-purple/20 font-medium"
            >
              Delete {activeTab.slice(0, -1)}
            </button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default AdminDashboard;
