import React, { useState, useEffect, useRef } from "react";
import {
  motion,
  AnimatePresence,
  useScroll,
  useTransform,
} from "framer-motion";
import UrlAnalyzer from "./UrlAnalyzer";
import BrandInfoForm from "./BrandInfoForm";
import BrandCharacteristicsForm from "./BrandCharacteristicsForm";
import BrandBackgroundForm from "./BrandBackgroundForm";
import BrandPersonalityForm from "./BrandPersonalityForm";
import BrandStrategyForm from "./BrandStrategyForm";
import DemographicsForm from "./DemographicsForm";
import BrandSoundForm from "./BrandSoundForm";
import ProjectGoalsForm from "./ProjectGoalsForm";
import ReviewForm from "./ReviewForm";
import {
  questionnaireSteps,
  getStepById,
  getNextStepId,
  getPreviousStepId,
} from "../utils/questionnaireData";

/**
 * TimelineQuestionnaire Component
 * Implements a timeline-style questionnaire using the Timeline pattern
 * Each step floats in space similar to the UrlAnalyzer component
 */
const TimelineQuestionnaire = ({
  formData,
  updateFormData,
  updateNestedFormData,
  analysisResults,
  onUrlAnalyze,
  isAnalyzing,
  analyzeError,
  brandStyle,
  onSubmit,
  isSubmitting,
}) => {
  const [currentStepId, setCurrentStepId] = useState(questionnaireSteps[0].id);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [height, setHeight] = useState(0);

  const containerRef = useRef(null);
  const timelineRef = useRef(null);

  // Set up the scroll animation
  useEffect(() => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [timelineRef]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 10%", "end 50%"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  // Handle completion of a step
  const handleStepComplete = (stepId, stepData) => {
    // Update form data with the step's data
    if (stepData) {
      Object.entries(stepData).forEach(([key, value]) => {
        updateFormData(key, value);
      });
    }

    // Mark this step as completed
    if (!completedSteps.includes(stepId)) {
      setCompletedSteps([...completedSteps, stepId]);
    }

    // Move to the next step if available
    const nextStepId = getNextStepId(stepId);
    if (nextStepId) {
      setCurrentStepId(nextStepId);
      // Scroll to the next step
      const element = document.getElementById(`step-${nextStepId}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  // Render step component based on step type
  const renderStepComponent = (step) => {
    const isCurrentStep = step.id === currentStepId;
    const isCompleted = completedSteps.includes(step.id);

    switch (step.component) {
      case "UrlAnalyzer":
        return (
          <UrlAnalyzer
            onAnalyze={onUrlAnalyze}
            isAnalyzing={isAnalyzing}
            analysisResults={analysisResults}
            analyzeError={analyzeError}
            inTimelineMode={true}
          />
        );
      case "BrandInfoForm":
        return (
          <BrandInfoForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "BrandCharacteristicsForm":
        return (
          <BrandCharacteristicsForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "BrandBackgroundForm":
        return (
          <BrandBackgroundForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "BrandPersonalityForm":
        return (
          <BrandPersonalityForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "BrandStrategyForm":
        return (
          <BrandStrategyForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "DemographicsForm":
        return (
          <DemographicsForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "BrandSoundForm":
        return (
          <BrandSoundForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "ProjectGoalsForm":
        return (
          <ProjectGoalsForm
            formData={formData}
            updateNestedFormData={updateNestedFormData}
            onComplete={handleStepComplete}
          />
        );
      case "ReviewForm":
        return (
          <ReviewForm formData={formData} onComplete={handleStepComplete} />
        );
      // For other form components, we'll need to create them
      // Will use similar floating styling as UrlAnalyzer
      default:
        return (
          <div className="text-white opacity-70">
            Step component "{step.component}" not implemented yet
          </div>
        );
    }
  };

  // Prepare the data for the timeline
  const timelineData = questionnaireSteps.map((step) => ({
    title: step.title,
    content: (
      <div id={`step-${step.id}`} className="mb-10 pb-10">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl"
        >
          <h3 className="text-xl md:text-2xl text-white mb-4">
            {step.description}
          </h3>

          {/* Step component */}
          <div className="mt-8">{renderStepComponent(step)}</div>

          {/* Navigation buttons */}
          <div className="flex justify-between mt-12">
            {getPreviousStepId(step.id) && (
              <motion.button
                whileHover={{ scale: 1.05, x: -5 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setCurrentStepId(getPreviousStepId(step.id))}
                className="py-3 px-6 rounded-full bg-transparent text-white border border-white/20 hover:border-white/40 transition-all duration-300"
                disabled={isSubmitting}
              >
                Previous
              </motion.button>
            )}

            {step.id === "final-review" ? (
              <motion.button
                whileHover={{ scale: 1.05, x: 5 }}
                whileTap={{ scale: 0.98 }}
                onClick={onSubmit}
                disabled={isSubmitting}
                className={`py-3 px-8 rounded-full bg-primary-green text-black transition-all duration-300 font-medium flex items-center gap-2
                  ${
                    isSubmitting
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-primary-green/90"
                  }`}
              >
                <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
                {isSubmitting && (
                  <svg
                    className="animate-spin h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </motion.button>
            ) : (
              getNextStepId(step.id) && (
                <motion.button
                  whileHover={{ scale: 1.05, x: 5 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleStepComplete(step.id)}
                  disabled={isSubmitting}
                  className="py-3 px-8 rounded-full bg-primary-green text-black hover:bg-primary-green/90 transition-all duration-300 font-medium"
                >
                  Next
                </motion.button>
              )
            )}
          </div>
        </motion.div>
      </div>
    ),
  }));

  return (
    <div
      className="w-full bg-[#111111] dark:bg-[#111111] font-sans"
      ref={containerRef}
    >
      <div ref={timelineRef} className="relative max-w-7xl mx-auto pb-20">
        {timelineData.map((item, index) => (
          <div
            key={index}
            className="flex justify-start pt-10 md:pt-40 md:gap-10"
          >
            <div className="sticky flex flex-col md:flex-row z-40 items-center top-40 self-start max-w-xs lg:max-w-sm md:w-full">
              <div className="h-10 absolute left-3 md:left-3 w-10 rounded-full bg-[#111111] flex items-center justify-center">
                <div
                  className={`h-4 w-4 rounded-full ${
                    completedSteps.includes(questionnaireSteps[index].id)
                      ? "bg-primary-green"
                      : currentStepId === questionnaireSteps[index].id
                      ? "bg-blue-500"
                      : "bg-neutral-700"
                  } border border-neutral-600 p-2`}
                />
              </div>
              <h3 className="hidden md:block text-xl md:pl-20 md:text-3xl font-bold text-white">
                {item.title}
              </h3>
            </div>

            <div className="relative pl-20 pr-4 md:pl-4 w-full">
              <h3 className="md:hidden block text-2xl mb-4 text-left font-bold text-white">
                {item.title}
              </h3>
              {item.content}
            </div>
          </div>
        ))}

        {/* Timeline bar */}
        <div
          style={{
            height: height + "px",
          }}
          className="absolute md:left-8 left-8 top-0 overflow-hidden w-[2px] bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-700 to-transparent to-[99%] [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)]"
        >
          <motion.div
            style={{
              height: heightTransform,
              opacity: opacityTransform,
            }}
            className="absolute inset-x-0 top-0 w-[2px] bg-gradient-to-t from-primary-green via-blue-500 to-transparent from-[0%] via-[10%] rounded-full"
          />
        </div>
      </div>
    </div>
  );
};

export default TimelineQuestionnaire;
