import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useWebsiteAnalysis from "./hooks/useWebsiteAnalysis";
import useFormState from "./hooks/useFormState";
import TimelineQuestionnaire from "./components/TimelineQuestionnaire";
import { submitForm } from "./services/apiService";
import { toast } from "react-hot-toast";

/**
 * OnboardingForm component
 * Main component for the client onboarding process
 * Now uses a timeline-based approach instead of step-by-step
 */
const OnboardingForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();

  // Custom hooks
  const {
    analyzeWebsite,
    analysisResults,
    analyzeError,
    isLoading: isAnalyzing,
  } = useWebsiteAnalysis();
  const {
    formData,
    updateFormData,
    updateNestedFormData,
    resetForm,
    exportFormData,
  } = useFormState();

  // Derive brand styling from analysis results for dynamic styling
  const brandStyle = analysisResults
    ? {
        colors: analysisResults.colors,
        fonts: analysisResults.fonts,
      }
    : null;

  // If analysis is complete, apply the colors to the form data
  useEffect(() => {
    if (analysisResults) {
      updateFormData("brandStyle", {
        colors: analysisResults.colors,
        fonts: analysisResults.fonts,
        images: analysisResults.images,
      });

      // If company details are available, pre-fill brand info
      if (analysisResults.companyDetails) {
        updateNestedFormData(
          "brandInfo",
          "companyName",
          analysisResults.companyDetails.name
        );
        updateNestedFormData(
          "brandInfo",
          "industry",
          analysisResults.companyDetails.industry
        );
      }
    }
  }, [analysisResults]);

  // Handle website analysis
  const handleUrlAnalysis = async (url) => {
    try {
      await analyzeWebsite(url);
      updateFormData("websiteUrl", url);
    } catch (error) {
      console.error("Website analysis failed:", error);
    }
  };

  // Handle final submission
  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      await submitForm(formData);
      toast.success("Form submitted successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitError(
        error.message || "Failed to submit form. Please try again."
      );
      toast.error("Failed to submit form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[#111111] min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {submitError && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 mb-8"
            >
              <p className="text-red-500">{submitError}</p>
            </motion.div>
          )}

          <TimelineQuestionnaire
            formData={formData}
            updateFormData={updateFormData}
            updateNestedFormData={updateNestedFormData}
            analysisResults={analysisResults}
            onUrlAnalyze={handleUrlAnalysis}
            isAnalyzing={isAnalyzing}
            analyzeError={analyzeError}
            brandStyle={brandStyle}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default OnboardingForm;
