import React, { useState } from "react";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Homepage from "./Pages/Homepage";
import Services from "./Pages/Services";
import ClientWork from "./Pages/ClientWork";
import ProjectPage from "./Pages/[slug]"; // Change this import
import AdminRoute from "./Components/admin/AdminRoute";
import "./firebase";
import ProcessSection from "./Components/ProcessSection";
import ROI from "./Pages/ROI";
import useInitAudio from "./Components/useInitAudio";
import AudioInitialiser from "./Components/AudioInitialiser";
import SoundBoard from "./Pages/Soundboard";
import BlogPage from "./Pages/BlogPage";
import BlogPost from "./Pages/BlogPost";
import OnboardingForm from "./Components/OnboardingForm/OnboardingForm";
import Contact from "./Pages/Contact";
import BrandProfile from "./Pages/BrandProfile/BrandProfile";
import RouteDebugger from "./Components/RouteDebugger";
import BrandProfileEditor from "./Components/admin/BrandProfileEditor";

function App() {
  const [debug, setDebug] = useState(false);
  useInitAudio();
  return (
    <HelmetProvider>
      <Router>
        {debug && <RouteDebugger />}
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 4000,
            style: {
              background: "#333",
              color: "#fff",
            },
          }}
        />
        {/* <AudioInitialiser /> */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/client-work" element={<ClientWork />} />
          <Route path="/work/:slug" element={<ProjectPage />} />{" "}
          <Route path="/process" element={<ProcessSection />} /> //
          <Route path="/soundboard" element={<SoundBoard />} />
          <Route path="/about" element={<Homepage />} />
          <Route path="/roi" element={<ROI />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:postId" element={<BlogPost />} />
          <Route path="/admin/*" element={<AdminRoute />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/onboarding" element={<OnboardingForm />} />
          <Route path="/brand-profile/:profileId" element={<BrandProfile />} />
          <Route path="/admin/brand-profiles/:profileId/edit" element={<BrandProfileEditor />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
