import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Briefcase, Check, AlertCircle } from "lucide-react";

/**
 * BrandInfoForm component - Brand information step
 * Styled with a "floating in space" approach, similar to UrlAnalyzer
 */
const BrandInfoForm = ({ formData, updateNestedFormData, onComplete }) => {
  const [focusedField, setFocusedField] = useState(null);
  const [errors, setErrors] = useState({});

  const companyNameRef = useRef(null);
  const industryRef = useRef(null);
  const descriptionRef = useRef(null);

  // Get form data from parent component or use default values
  const brandInfo = formData?.brandInfo || {
    companyName: "",
    industry: "",
    brandDescription: "",
  };

  // Handle input changes
  const handleInputChange = (field, value) => {
    updateNestedFormData("brandInfo", field, value);

    // Clear error for this field if it exists
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form
    const newErrors = {};
    if (!brandInfo.companyName) {
      newErrors.companyName = "Company name is required";
    }
    if (!brandInfo.industry) {
      newErrors.industry = "Industry is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Submit form data to parent component
    onComplete("brand-info", { brandInfo });
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="space-y-10">
        {/* Company Name Input */}
        <div className="space-y-4">
          <label className="text-white opacity-60 text-sm pl-2 font-light">
            Company Name
          </label>
          <div className="relative">
            <motion.div
              className="flex items-center space-x-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="text-white opacity-70"
              >
                <Briefcase
                  className={`w-6 h-6 ${
                    errors.companyName
                      ? "text-red-500"
                      : brandInfo.companyName
                      ? "text-primary-green"
                      : "text-white"
                  }`}
                />
              </motion.div>

              <div className="relative flex-1">
                <input
                  ref={companyNameRef}
                  id="company-name"
                  type="text"
                  value={brandInfo.companyName}
                  onChange={(e) =>
                    handleInputChange("companyName", e.target.value)
                  }
                  onFocus={() => setFocusedField("companyName")}
                  onBlur={() => setFocusedField(null)}
                  placeholder="Enter your company name"
                  className="w-full bg-transparent text-white text-xl md:text-2xl border-none outline-none focus:outline-none focus:ring-0 py-2"
                />

                {/* Animated underline */}
                <motion.div
                  className="absolute bottom-0 left-0 h-[1px] bg-white"
                  initial={{ width: "0%" }}
                  animate={{
                    width:
                      focusedField === "companyName"
                        ? "100%"
                        : brandInfo.companyName
                        ? "100%"
                        : "0%",
                    backgroundColor: errors.companyName
                      ? "#ef4444"
                      : brandInfo.companyName
                      ? "rgb(1, 178, 128)"
                      : "#ffffff",
                  }}
                  transition={{ duration: 0.3 }}
                />
              </div>
            </motion.div>
          </div>

          <AnimatePresence>
            {errors.companyName && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-red-500 flex items-center mt-1 text-sm ml-10"
              >
                <AlertCircle className="w-3 h-3 mr-1" />
                {errors.companyName}
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        {/* Industry Select */}
        <div className="space-y-4">
          <label className="text-white opacity-60 text-sm pl-2 font-light">
            Industry
          </label>
          <div className="relative">
            <motion.div
              className="flex items-center space-x-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="text-white opacity-70"
              >
                <svg
                  className={`w-6 h-6 ${
                    errors.industry
                      ? "text-red-500"
                      : brandInfo.industry
                      ? "text-primary-green"
                      : "text-white"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                  />
                </svg>
              </motion.div>

              <div className="relative flex-1">
                <select
                  ref={industryRef}
                  id="industry"
                  value={brandInfo.industry}
                  onChange={(e) =>
                    handleInputChange("industry", e.target.value)
                  }
                  onFocus={() => setFocusedField("industry")}
                  onBlur={() => setFocusedField(null)}
                  className="w-full bg-transparent text-white text-xl md:text-2xl border-none outline-none focus:outline-none focus:ring-0 py-2 appearance-none"
                >
                  <option value="" disabled className="bg-[#111111] text-white">
                    Select your industry
                  </option>
                  <option
                    value="technology"
                    className="bg-[#111111] text-white"
                  >
                    Technology
                  </option>
                  <option
                    value="healthcare"
                    className="bg-[#111111] text-white"
                  >
                    Healthcare
                  </option>
                  <option value="education" className="bg-[#111111] text-white">
                    Education
                  </option>
                  <option value="finance" className="bg-[#111111] text-white">
                    Finance
                  </option>
                  <option value="retail" className="bg-[#111111] text-white">
                    Retail
                  </option>
                  <option value="other" className="bg-[#111111] text-white">
                    Other
                  </option>
                </select>

                {/* Animated underline */}
                <motion.div
                  className="absolute bottom-0 left-0 h-[1px] bg-white"
                  initial={{ width: "0%" }}
                  animate={{
                    width:
                      focusedField === "industry"
                        ? "100%"
                        : brandInfo.industry
                        ? "100%"
                        : "0%",
                    backgroundColor: errors.industry
                      ? "#ef4444"
                      : brandInfo.industry
                      ? "rgb(1, 178, 128)"
                      : "#ffffff",
                  }}
                  transition={{ duration: 0.3 }}
                />

                {/* Dropdown arrow */}
                <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </motion.div>
          </div>

          <AnimatePresence>
            {errors.industry && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-red-500 flex items-center mt-1 text-sm ml-10"
              >
                <AlertCircle className="w-3 h-3 mr-1" />
                {errors.industry}
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        {/* Brand Description Textarea */}
        <div className="space-y-4">
          <label className="text-white opacity-60 text-sm pl-2 font-light">
            Brand Description (Optional)
          </label>
          <div className="relative">
            <motion.div
              className="flex items-start space-x-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="text-white opacity-70 mt-2"
              >
                <svg
                  className={`w-6 h-6 ${
                    brandInfo.brandDescription
                      ? "text-primary-green"
                      : "text-white"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </motion.div>

              <div className="relative flex-1">
                <textarea
                  ref={descriptionRef}
                  id="brand-description"
                  value={brandInfo.brandDescription || ""}
                  onChange={(e) =>
                    handleInputChange("brandDescription", e.target.value)
                  }
                  onFocus={() => setFocusedField("brandDescription")}
                  onBlur={() => setFocusedField(null)}
                  placeholder="Describe your brand in a few sentences"
                  rows={3}
                  className="w-full bg-transparent text-white text-lg md:text-xl border-none outline-none focus:outline-none focus:ring-0 py-2 resize-none"
                />

                {/* Animated underline */}
                <motion.div
                  className="absolute bottom-0 left-0 h-[1px] bg-white"
                  initial={{ width: "0%" }}
                  animate={{
                    width:
                      focusedField === "brandDescription"
                        ? "100%"
                        : brandInfo.brandDescription
                        ? "100%"
                        : "0%",
                    backgroundColor: brandInfo.brandDescription
                      ? "rgb(1, 178, 128)"
                      : "#ffffff",
                  }}
                  transition={{ duration: 0.3 }}
                />
              </div>
            </motion.div>
          </div>
        </div>

        <div className="flex justify-center pt-10">
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            whileHover={{ scale: 1.05, y: -5 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            className="py-4 rounded-full font-medium flex items-center justify-center bg-primary-green text-black hover:bg-primary-green/90 transition-all duration-300 w-64 mx-auto"
          >
            Continue
          </motion.button>
        </div>
      </form>
    </div>
  );
};

export default BrandInfoForm;
