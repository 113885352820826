import { v4 as uuidv4 } from "uuid";
import { SECTION_TYPES } from "../models/types";

/**
 * Helper functions for the dynamic questionnaire system
 */

/**
 * Generate a unique ID
 * @returns {string} - Unique ID
 */
export const generateId = () => uuidv4();

/**
 * Create a new section with defaults
 * @param {string} type - Section type from SECTION_TYPES
 * @param {string} title - Section title
 * @param {string} description - Section description
 * @param {number} order - Order of the section
 * @returns {Object} - New section object
 */
export const createSection = (type, title, description, order = 0) => ({
  id: generateId(),
  type,
  title,
  description,
  order,
  questions: [],
});

/**
 * Create a new question with defaults
 * @param {string} sectionId - ID of the section this question belongs to
 * @param {string} type - Question type
 * @param {string} title - Question title
 * @param {Object} additionalProps - Additional properties for the question
 * @returns {Object} - New question object
 */
export const createQuestion = (
  sectionId,
  type,
  title,
  additionalProps = {}
) => ({
  id: generateId(),
  sectionId,
  type,
  title,
  description: "",
  placeholder: "",
  required: false,
  order: 0,
  options: [],
  validation: null,
  defaultValue: null,
  conditional: null,
  ...additionalProps,
});

/**
 * Create a question option
 * @param {string} label - Option label
 * @param {string} value - Option value
 * @returns {Object} - New option object
 */
export const createOption = (label, value = null) => ({
  id: generateId(),
  label,
  value: value || label,
});

/**
 * Create default sections for a new questionnaire
 * @returns {Array} - Array of default sections
 */
export const createDefaultSections = () => [
  createSection(
    SECTION_TYPES.BRAND_INFO,
    "Brand Information",
    "Basic information about your brand",
    0
  ),
  createSection(
    SECTION_TYPES.BRAND_CHARACTERISTICS,
    "Brand Characteristics",
    "Define the core characteristics of your brand",
    1
  ),
  createSection(
    SECTION_TYPES.BRAND_BACKGROUND,
    "Brand Background",
    "Tell us about your brand's history and background",
    2
  ),
  createSection(
    SECTION_TYPES.BRAND_PERSONALITY,
    "Brand Personality",
    "Describe your brand's personality and values",
    3
  ),
  createSection(
    SECTION_TYPES.BRAND_STRATEGY,
    "Brand Strategy",
    "Strategic focus and direction for your brand",
    4
  ),
  createSection(
    SECTION_TYPES.DEMOGRAPHICS,
    "Target Demographics",
    "Who is your target audience?",
    5
  ),
  createSection(
    SECTION_TYPES.BRAND_SOUND,
    "Brand Sound",
    "Define your brand's sonic identity",
    6
  ),
  createSection(
    SECTION_TYPES.PROJECT_GOALS,
    "Project Goals",
    "What do you hope to achieve with this project?",
    7
  ),
];

/**
 * Create a new questionnaire with default sections
 * @param {string} title - Questionnaire title
 * @param {string} description - Questionnaire description
 * @returns {Object} - New questionnaire object
 */
export const createDefaultQuestionnaire = (title, description) => ({
  id: generateId(),
  title,
  description,
  sections: createDefaultSections(),
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  version: 1,
  status: "draft",
});

/**
 * Reorder items in an array
 * @param {Array} list - The array to reorder
 * @param {number} startIndex - The start index
 * @param {number} endIndex - The end index
 * @returns {Array} - The reordered array
 */
export const reorderItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // Update order property
  return result.map((item, index) => ({
    ...item,
    order: index,
  }));
};

/**
 * Check if a condition is met
 * @param {Object} condition - The condition to check
 * @param {Object} responses - The current responses
 * @returns {boolean} - Whether the condition is met
 */
export const checkCondition = (condition, responses) => {
  if (!condition || !condition.questionId) return true;

  const response = responses[condition.questionId];
  if (!response) return false;

  const { value } = response;

  switch (condition.operator) {
    case "equals":
      return value === condition.value;
    case "notEquals":
      return value !== condition.value;
    case "contains":
      return Array.isArray(value)
        ? value.includes(condition.value)
        : String(value).includes(condition.value);
    case "notContains":
      return Array.isArray(value)
        ? !value.includes(condition.value)
        : !String(value).includes(condition.value);
    case "greaterThan":
      return Number(value) > Number(condition.value);
    case "lessThan":
      return Number(value) < Number(condition.value);
    default:
      return true;
  }
};

/**
 * Check if a question should be shown based on its conditions
 * @param {Object} question - The question to check
 * @param {Object} responses - The current responses
 * @returns {boolean} - Whether the question should be shown
 */
export const shouldShowQuestion = (question, responses) => {
  if (!question.conditional) return true;

  const { conditional } = question;

  if (Array.isArray(conditional)) {
    // If there are multiple conditions, check based on the logic type
    const { logicType = "AND" } = question;
    if (logicType === "AND") {
      return conditional.every((condition) =>
        checkCondition(condition, responses)
      );
    } else {
      return conditional.some((condition) =>
        checkCondition(condition, responses)
      );
    }
  } else {
    // Single condition
    return checkCondition(conditional, responses);
  }
};

/**
 * Validate a single question response
 * @param {Object} question - The question
 * @param {any} value - The response value
 * @returns {string|null} - Error message or null if valid
 */
export const validateQuestionResponse = (question, value) => {
  // Required check
  if (
    question.required &&
    (value === null || value === undefined || value === "")
  ) {
    return `${question.title} is required`;
  }

  // Custom validation
  if (question.validation && typeof question.validation === "function") {
    return question.validation(value);
  }

  return null;
};

/**
 * Validate all responses in a section
 * @param {Object} section - The section
 * @param {Object} responses - The responses
 * @returns {Object} - Object mapping questionId to error message
 */
export const validateSectionResponses = (section, responses) => {
  const errors = {};

  section.questions.forEach((question) => {
    // Only validate questions that should be shown
    if (shouldShowQuestion(question, responses)) {
      const response = responses[question.id];
      const value = response ? response.value : null;
      const error = validateQuestionResponse(question, value);
      if (error) {
        errors[question.id] = error;
      }
    }
  });

  return errors;
};

/**
 * Format question for display in admin UI
 * @param {Object} question - The question
 * @returns {string} - Formatted question
 */
export const formatQuestionForAdmin = (question) => {
  const typeDisplay =
    question.type.charAt(0).toUpperCase() + question.type.slice(1);
  return `[${typeDisplay}] ${question.title}${question.required ? " *" : ""}`;
};

/**
 * Generate sample questions for a section
 * @param {string} sectionId - ID of the section
 * @param {string} sectionType - Type of the section
 * @returns {Array} - Array of sample questions
 */
export const generateSampleQuestions = (sectionId, sectionType) => {
  switch (sectionType) {
    case SECTION_TYPES.BRAND_INFO:
      return [
        createQuestion(sectionId, "text", "What is your company name?", {
          required: true,
          order: 0,
        }),
        createQuestion(sectionId, "text", "What industry are you in?", {
          required: true,
          order: 1,
        }),
        createQuestion(
          sectionId,
          "textarea",
          "Briefly describe what your company does",
          { required: true, order: 2 }
        ),
      ];
    case SECTION_TYPES.BRAND_CHARACTERISTICS:
      return [
        createQuestion(
          sectionId,
          "textarea",
          "What are your brand's core values?",
          { required: true, order: 0 }
        ),
        createQuestion(
          sectionId,
          "textarea",
          "What sets your brand apart from competitors?",
          { required: true, order: 1 }
        ),
      ];
    case SECTION_TYPES.BRAND_BACKGROUND:
      return [
        createQuestion(sectionId, "textarea", "What is your brand's story?", {
          required: true,
          order: 0,
        }),
        createQuestion(sectionId, "textarea", "How was your brand founded?", {
          required: false,
          order: 1,
        }),
      ];
    case SECTION_TYPES.BRAND_PERSONALITY:
      return [
        createQuestion(
          sectionId,
          "multiselect",
          "Select personality traits that describe your brand",
          {
            required: true,
            order: 0,
            options: [
              createOption("Professional"),
              createOption("Friendly"),
              createOption("Innovative"),
              createOption("Trustworthy"),
              createOption("Luxurious"),
              createOption("Playful"),
              createOption("Bold"),
              createOption("Conservative"),
            ],
          }
        ),
      ];
    case SECTION_TYPES.BRAND_STRATEGY:
      return [
        createQuestion(
          sectionId,
          "textarea",
          "What is your brand's mission statement?",
          { required: true, order: 0 }
        ),
        createQuestion(
          sectionId,
          "textarea",
          "What are your short-term brand goals?",
          { required: true, order: 1 }
        ),
        createQuestion(
          sectionId,
          "textarea",
          "What are your long-term brand goals?",
          { required: true, order: 2 }
        ),
      ];
    case SECTION_TYPES.DEMOGRAPHICS:
      return [
        createQuestion(
          sectionId,
          "radio",
          "Primary age range of your target audience",
          {
            required: true,
            order: 0,
            options: [
              createOption("18-24"),
              createOption("25-34"),
              createOption("35-44"),
              createOption("45-54"),
              createOption("55+"),
              createOption("All ages"),
            ],
          }
        ),
        createQuestion(sectionId, "checkbox", "Markets you operate in", {
          required: true,
          order: 1,
          options: [
            createOption("North America"),
            createOption("Europe"),
            createOption("Asia"),
            createOption("Africa"),
            createOption("South America"),
            createOption("Australia/Oceania"),
          ],
        }),
      ];
    case SECTION_TYPES.BRAND_SOUND:
      return [
        createQuestion(
          sectionId,
          "radio",
          "How important is sound to your brand?",
          {
            required: true,
            order: 0,
            options: [
              createOption("Very important"),
              createOption("Important"),
              createOption("Somewhat important"),
              createOption("Not very important"),
              createOption("Unsure"),
            ],
          }
        ),
        createQuestion(
          sectionId,
          "textarea",
          "Describe the ideal sound for your brand",
          { required: false, order: 1 }
        ),
      ];
    case SECTION_TYPES.PROJECT_GOALS:
      return [
        createQuestion(
          sectionId,
          "textarea",
          "What are your primary goals for this project?",
          { required: true, order: 0 }
        ),
        createQuestion(
          sectionId,
          "textarea",
          "What metrics will you use to measure success?",
          { required: false, order: 1 }
        ),
      ];
    default:
      return [];
  }
};
