import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import narrativeService from "../services/narrativeService";

/**
 * A reusable component for displaying AI-generated narratives
 *
 * @param {Object} props
 * @param {string} props.profileId - The ID of the brand profile
 * @param {string} props.section - The section name (e.g., "characteristics", "demographics")
 * @param {object} props.sectionData - The data for the section
 * @param {string} props.companyUrl - Optional URL of the company website
 * @param {string} props.fallbackText - Text to display while loading or if generation fails
 * @param {object} props.style - Additional styles for the narrative container
 * @param {string} props.textColor - Text color for the narrative
 * @param {string} props.accentColor - Accent color for the loading indicator and icons
 */
const NarrativeSection = ({
  profileId,
  section,
  sectionData,
  companyUrl,
  fallbackText = "Generating insights...",
  style = {},
  textColor = "#ffffff",
  accentColor = "#ffffff",
}) => {
  const [narrative, setNarrative] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use ref to track if we've already fetched the narrative in this session
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    // Skip if we don't have the required data
    if (!profileId || !section || !sectionData) {
      setLoading(false);
      return;
    }

    // Only fetch the narrative once per session
    // The narrativeService will handle checking Firestore for existing narratives
    if (!hasFetchedRef.current) {
      const fetchNarrative = async () => {
        if (!profileId || !section) return;

        // Skip if we've already fetched this section in this session
        if (hasFetchedRef.current) {
          // console.log(`Skipping fetch for section: ${section} (already fetched in this session)`);
          return;
        }

        try {
          setLoading(true);
          const text = await narrativeService.generateNarrative(
            profileId,
            section,
            sectionData,
            companyUrl
          );
          setNarrative(text);
          setError(null);

          // Mark as fetched for this session
          hasFetchedRef.current = true;
        } catch (err) {
          // console.error("Error fetching narrative:", err);
          setError("Failed to generate insights. Please try again later.");
        } finally {
          setLoading(false);
        }
      };

      fetchNarrative();
    } else {
      // Skip fetch if we've already fetched once in this session
      // console.log(
      //   `Skipping fetch for section: ${section} (already fetched in this session)`
      // );
      setLoading(false);
    }

    // Cleanup function for when component unmounts
    return () => {
      // No cleanup needed
    };
  }, [profileId, section, sectionData, companyUrl]);

  return (
    <motion.div
      className="backdrop-blur-sm rounded-xl p-6 h-full"
      style={{
        color: textColor,
        border:
          style.border !== undefined
            ? style.border
            : `1px solid ${accentColor}20`,
        background:
          style.background !== undefined
            ? style.background
            : "rgba(0, 0, 0, 0.3)",
        padding: style.padding !== undefined ? style.padding : undefined,
        ...style,
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* NO AI ELEMENTS VERSION */}
      {loading ? (
        <div className="flex flex-col items-center justify-center h-32">
          <div
            className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 mb-4"
            style={{ borderColor: accentColor }}
          ></div>
          <p className="text-sm opacity-70">{fallbackText}</p>
        </div>
      ) : error ? (
        <div className="text-red-400 p-4 rounded-md bg-red-900/20">
          <p>{error}</p>
        </div>
      ) : (
        <div className="prose prose-invert max-w-none">
          <div
            className="text-lg sm:text-xl font-medium leading-relaxed whitespace-pre-line"
            style={{ textShadow: "0 1px 3px rgba(0, 0, 0, 0.3)" }}
          >
            {narrative}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default NarrativeSection;
