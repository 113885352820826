import React, { useRef, useMemo, useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  Users,
  Globe,
  Zap,
  Target,
  Star,
  Heart,
  Sparkles,
  ChevronRight,
  ChevronLeft,
  ArrowUpRight,
  Check,
  Clock,
} from "lucide-react";
import NarrativeSection from "../NarrativeSection";
import MetricCard from "./MetricCard";

// Interactive Demographics Comparison Card
const DemographicComparisonCard = ({ item, index, active, onClick }) => {
  const containerRef = useRef(null);

  // Calculate if there's a significant difference between current and target
  const hasDifference = useMemo(() => {
    // Simple comparison - if the text content is different
    if (!item.current || !item.target) return false;
    if (typeof item.current !== "string" || typeof item.target !== "string")
      return false;

    // Normalize and compare
    const normalizedCurrent = item.current.toLowerCase().trim();
    const normalizedTarget = item.target.toLowerCase().trim();

    return normalizedCurrent !== normalizedTarget;
  }, [item.current, item.target]);

  const delayAnimation = index * 0.1;

  return (
    <motion.div
      ref={containerRef}
      className={`rounded-xl border overflow-hidden cursor-pointer transform transition-all duration-300 ${active ? "col-span-full row-span-2" : ""
        }`}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        borderColor: active
          ? "var(--primary-color)"
          : "rgba(255, 255, 255, 0.1)",
        minHeight: active ? "auto" : "220px", // Ensure minimum height for better visual consistency
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: delayAnimation }}
      onClick={onClick}
      whileHover={{
        scale: active ? 1 : 1.02,
        borderColor: "var(--primary-color)",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* Card Header - MODIFIED */}
      <div className="px-5 pt-5 pb-3 border-b border-white/10">
        <div className="flex items-center gap-3 mb-2">
          <div
            className="p-2.5 rounded-lg"
            style={{ backgroundColor: "var(--primary-color-20)" }}
          >
            {item.icon}
          </div>
          <h3 className="text-lg font-semibold text-white">{item.title}</h3>

          {active && (
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: 90 }}
              className="text-white/60 ml-auto"
            >
              <ChevronRight size={20} />
            </motion.div>
          )}
        </div>

        {/* Status label now below the title */}
        {hasDifference && !active && (
          <div className="ml-12 mt-1.5 inline-flex items-center px-3 py-1.5 rounded-full bg-yellow-400/20">
            <Clock size={14} className="text-yellow-400 mr-1.5" />
            <span className="text-xs text-yellow-400 font-medium">
              Transitioning
            </span>
          </div>
        )}
        {!hasDifference && !active && (
          <div className="ml-12 mt-1.5 inline-flex items-center px-3 py-1.5 rounded-full bg-green-400/20">
            <Check size={14} className="text-green-400 mr-1.5" />
            <span className="text-xs text-green-400 font-medium">Aligned</span>
          </div>
        )}
      </div>

      {/* Card Content */}
      <div className="p-5">
        {!active ? (
          // Collapsed View
          <div className="grid grid-cols-1 gap-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-xs font-medium text-white/60">
                  Current
                </span>
                {hasDifference && (
                  <ArrowUpRight size={14} className="text-yellow-400" />
                )}
              </div>
              <p className="text-white/80 line-clamp-2 text-sm leading-relaxed">
                {item.current || "Not specified"}
              </p>
            </div>

            <div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-xs font-medium text-white/60">
                  Target
                </span>
                {hasDifference && (
                  <Target size={14} className="text-blue-400" />
                )}
              </div>
              <p className="text-white/80 line-clamp-2 text-sm leading-relaxed">
                {item.target || "Not specified"}
              </p>
            </div>
          </div>
        ) : (
          // Expanded View
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-2">
            <div className="space-y-4">
              <div className="flex items-center gap-2 mb-4">
                <Clock size={18} className="text-white/70" />
                <h4 className="text-xl font-semibold text-white">Current</h4>
              </div>
              <motion.div
                className="bg-white/5 rounded-lg p-5 border border-white/10"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: delayAnimation + 0.2 }}
              >
                <p className="text-white/90 whitespace-pre-line leading-relaxed">
                  {item.current || "Not specified"}
                </p>
              </motion.div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-2 mb-4">
                <Target size={18} className="text-white/70" />
                <h4 className="text-xl font-semibold text-white">Target</h4>
              </div>
              <motion.div
                className="bg-[var(--primary-color-20)] rounded-lg p-5 border border-[var(--primary-color-50)]"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: delayAnimation + 0.3 }}
              >
                <p className="text-white/90 whitespace-pre-line leading-relaxed">
                  {item.target || "Not specified"}
                </p>
              </motion.div>
            </div>

            {hasDifference && (
              <motion.div
                className="col-span-full p-5 rounded-lg bg-gradient-to-r from-yellow-400/10 to-orange-400/10 border border-yellow-400/20 mt-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: delayAnimation + 0.4 }}
              >
                <div className="flex items-center gap-2 mb-3">
                  <Zap size={16} className="text-yellow-400" />
                  <h4 className="text-white/90 font-medium">
                    Transition Strategy
                  </h4>
                </div>
                <p className="text-white/80 text-sm leading-relaxed">
                  The brand is transitioning from its current{" "}
                  {item.title.toLowerCase()} demographic to its target. This
                  shift requires strategic messaging and positioning adjustments
                  to attract the new audience while retaining valuable segments
                  of the current one.
                </p>
              </motion.div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

// Donut chart visualizing brand essence or core values
const BrandValueDonut = ({ values, title, icon, color }) => {
  const [hoveredSegment, setHoveredSegment] = useState(null);

  // Parse and normalize values
  const normalizedValues = useMemo(() => {
    if (!values) return [];

    // If values is a string, try to parse comma-separated values
    if (typeof values === "string") {
      return values
        .split(/,|;/)
        .map((item) => item.trim())
        .filter((item) => item.length > 0)
        .slice(0, 6); // Limit to 6 values for donut
    }

    return [];
  }, [values]);

  // Generate colors for segments
  const segmentColors = useMemo(() => {
    if (!color) return normalizedValues.map(() => `var(--primary-color)`);

    // Create variations based on the base color
    // We'll use HSL to adjust luminance and saturation
    try {
      const baseHue = parseInt(color.replace("#", ""), 16);

      return normalizedValues.map((_, index) => {
        const hue = (baseHue + index * 30) % 360;
        return `hsl(${hue}, 70%, 50%)`;
      });
    } catch (e) {
      return normalizedValues.map(() => `var(--primary-color)`);
    }
  }, [normalizedValues, color]);

  // If we don't have values, don't render
  if (normalizedValues.length === 0) return null;

  // Create the donut chart segments
  const totalValues = normalizedValues.length;
  const segmentSize = 360 / totalValues;

  return (
    <motion.div
      className="p-7 rounded-xl border border-white/10 bg-white/5 backdrop-blur-md mt-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
    >
      <div className="flex items-center gap-4 mb-7">
        <div
          className="w-12 h-12 rounded-lg flex items-center justify-center"
          style={{ backgroundColor: `var(--${color || "primary"}-color-20)` }}
        >
          {icon}
        </div>
        <h3 className="text-2xl font-bold text-white">{title}</h3>
      </div>

      <div className="relative flex items-center justify-center mb-8 pt-4 mx-auto max-w-xs">
        <div className="relative w-64 h-64">
          {/* Donut segments */}
          {normalizedValues.map((value, index) => {
            const startAngle = index * segmentSize;
            const endAngle = (index + 1) * segmentSize;
            const isHovered = hoveredSegment === index;

            return (
              <motion.div
                key={index}
                className="absolute inset-0 flex items-center justify-center"
                initial={{ opacity: 0, rotate: startAngle }}
                animate={{
                  opacity: 1,
                  rotate: startAngle,
                  scale: isHovered ? 1.05 : 1,
                }}
                transition={{
                  delay: 0.6 + index * 0.1,
                  duration: 0.3,
                }}
                onMouseEnter={() => setHoveredSegment(index)}
                onMouseLeave={() => setHoveredSegment(null)}
              >
                <svg
                  viewBox="0 0 100 100"
                  width="100%"
                  height="100%"
                  className="transform -rotate-90"
                >
                  <path
                    d={`
                      M 50 50
                      L ${50 + 42 * Math.cos((startAngle * Math.PI) / 180)} ${50 + 42 * Math.sin((startAngle * Math.PI) / 180)
                      }
                      A 42 42 0 ${endAngle - startAngle > 180 ? 1 : 0} 1 ${50 + 42 * Math.cos((endAngle * Math.PI) / 180)
                      } ${50 + 42 * Math.sin((endAngle * Math.PI) / 180)}
                      Z
                    `}
                    fill={segmentColors[index]}
                    opacity={isHovered ? 1 : 0.7}
                    stroke="#111"
                    strokeWidth="1"
                    className="transition-all duration-300 cursor-pointer"
                  />
                </svg>

                {/* Value label that appears on hover */}
                {isHovered && (
                  <motion.div
                    className="absolute text-center pointer-events-none"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div
                      className="bg-black/80 backdrop-blur-md text-white px-4 py-2 rounded-lg text-sm font-medium border"
                      style={{ borderColor: segmentColors[index] }}
                    >
                      {value}
                    </div>
                  </motion.div>
                )}
              </motion.div>
            );
          })}

          {/* Center hole */}
          <div
            className="absolute rounded-full"
            style={{
              top: "25%",
              left: "25%",
              width: "50%",
              height: "50%",
              background: "rgba(17, 17, 17, 0.8)",
              backdropFilter: "blur(4px)",
              border: "2px solid rgba(255, 255, 255, 0.1)",
            }}
          />
        </div>
      </div>

      {/* Legend */}
      <div className="grid grid-cols-2 gap-4">
        {normalizedValues.map((value, index) => (
          <div
            key={index}
            className="flex items-center gap-3 text-sm p-2 rounded-lg hover:bg-white/5 transition-colors"
            onMouseEnter={() => setHoveredSegment(index)}
            onMouseLeave={() => setHoveredSegment(null)}
          >
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: segmentColors[index] }}
            />
            <span className="text-white/90 truncate font-medium">{value}</span>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

const DemographicsSection = ({
  demographics,
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [showNarrative, setShowNarrative] = useState(true);

  useEffect(() => {
    // When a card is activated, hide narrative on mobile
    // but keep it visible on desktop
    if (activeCardIndex !== null && window.innerWidth < 768) {
      setShowNarrative(false);
    } else {
      setShowNarrative(true);
    }
  }, [activeCardIndex]);

  // If narrative visibility is explicitly set to true in demographics settings, always show it
  useEffect(() => {
    if (demographics?.visibility?.narrative === true) {
      setShowNarrative(true);
    }
  }, [demographics?.visibility?.narrative]);

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(sectionProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const bgOpacity = useTransform(sectionProgress, [0, 0.5, 1], [0.5, 0.7, 0.5]);

  // Prepare data for the narrative generation
  const demographicsData = useMemo(
    () => ({
      current: {
        ageRange: demographics?.currentAgeRange,
        gender: demographics?.currentGender,
        territory: demographics?.currentTerritory,
        interests: demographics?.currentInterests,
        lifestyle: demographics?.currentLifestyle,
      },
      target: {
        ageRange: demographics?.targetAgeRange,
        gender: demographics?.targetGender,
        territory: demographics?.targetTerritory,
        interests: demographics?.targetInterests,
        lifestyle: demographics?.targetLifestyle,
      },
      brandEssence: demographics?.brandEssence,
      coreValues: demographics?.coreValues,
    }),
    [
      demographics?.currentAgeRange,
      demographics?.currentGender,
      demographics?.currentTerritory,
      demographics?.currentInterests,
      demographics?.currentLifestyle,
      demographics?.targetAgeRange,
      demographics?.targetGender,
      demographics?.targetTerritory,
      demographics?.targetInterests,
      demographics?.targetLifestyle,
      demographics?.brandEssence,
      demographics?.coreValues,
    ]
  );

  if (!demographics) return null;

  const allDemographicItems = [
    {
      title: "Age Range",
      current: demographics.currentAgeRange,
      target: demographics.targetAgeRange,
      icon: <Users size={20} className="text-white" />,
      currentField: "currentAgeRange",
      targetField: "targetAgeRange",
    },
    {
      title: "Territory",
      current: demographics.currentTerritory,
      target: demographics.targetTerritory,
      icon: <Globe size={20} className="text-white" />,
      currentField: "currentTerritory",
      targetField: "targetTerritory",
    },
    {
      title: "Gender Distribution",
      current: demographics.currentGender,
      target: demographics.targetGender,
      icon: <Zap size={20} className="text-white" />,
      currentField: "currentGender",
      targetField: "targetGender",
    },
    {
      title: "Interests & Hobbies",
      current: demographics.currentInterests,
      target: demographics.targetInterests,
      icon: <Target size={20} className="text-white" />,
      currentField: "currentInterests",
      targetField: "targetInterests",
    },
    {
      title: "Lifestyle",
      current: demographics.currentLifestyle,
      target: demographics.targetLifestyle,
      icon: <Star size={20} className="text-white" />,
      currentField: "currentLifestyle",
      targetField: "targetLifestyle",
    },
  ];

  // Filter items based on visibility settings
  const demographicItems = allDemographicItems.filter((item) => {
    // Check if there's a visibility setting for this field
    // If visibility is undefined or the specific field is undefined, default to showing the item
    const visibility = demographics.visibility;
    if (!visibility) return true;

    // Check if either current or target field is visible
    return (
      visibility[item.currentField] !== false ||
      visibility[item.targetField] !== false
    );
  });

  // Check if narrative visibility is specifically turned off
  const showNarrativeSection = demographics.visibility?.narrative !== false;

  // Check if core values visibility is specifically turned off
  const showCoreValues = demographics.visibility?.coreValues !== false;

  // Check if brand essence visibility is specifically turned off
  const showBrandEssence = demographics.visibility?.brandEssence !== false;

  const handleCardClick = (index) => {
    setActiveCardIndex(activeCardIndex === index ? null : index);
    // Only hide narrative when a card is expanded if the narrative is not explicitly set to be visible
    if (demographics.visibility?.narrative !== true) {
      setShowNarrative(index === activeCardIndex);
    }
  };

  // If no items are visible, don't render the section
  if (
    demographicItems.length === 0 &&
    !showNarrativeSection &&
    !showCoreValues &&
    !showBrandEssence
  ) {
    return null;
  }

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-24 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* CSS for expanded cards */}
      <style>
        {`
          .expanded-card {
            grid-column: 1 / -1 !important;
            z-index: 10;
            position: relative;
          }
        `}
      </style>

      {/* Dot pattern background */}
      <div className="absolute inset-0 -z-10">
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: bgOpacity,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 max-w-7xl mx-auto px-6"
        style={{ scale }}
      >
        <motion.h2
          className="text-5xl md:text-7xl mb-16 font-bold text-white"
          style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Demographics
        </motion.h2>

        {/* Main Content Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          {/* Narrative Section - Left Side on Desktop */}
          <AnimatePresence>
            {showNarrative && showNarrativeSection && (
              <motion.div
                className="lg:col-span-4 order-2 lg:order-1"
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -30 }}
                transition={{ duration: 0.4 }}
              >
                <motion.div
                  className="bg-white/5 backdrop-blur-sm rounded-xl p-7 border border-white/10 lg:sticky lg:top-24"
                  whileHover={{
                    boxShadow: "0 0 30px rgba(var(--primary-color-rgb), 0.1)",
                  }}
                >
                  <div className="mb-6 flex items-center justify-between">
                    <h3 className="text-2xl font-bold text-white flex items-center gap-3">
                      <Users
                        className="text-[var(--primary-color)]"
                        size={22}
                      />
                      Demographic Analysis
                    </h3>
                  </div>

                  <NarrativeSection
                    profileId={profileId}
                    section="demographics"
                    sectionData={demographicsData}
                    companyUrl={companyUrl}
                    fallbackText="Analyzing demographics..."
                    textColor="rgba(255, 255, 255, 0.9)"
                    accentColor="var(--primary-color)"
                    style={{
                      minHeight: "300px",
                      fontSize: "1.05rem",
                      lineHeight: "1.8",
                      fontWeight: "400",
                    }}
                  />
                </motion.div>

                {/* Brand Values Visualization */}
                {showCoreValues && demographics.coreValues && (
                  <BrandValueDonut
                    values={demographics.coreValues}
                    title="Core Values"
                    icon={<Heart size={22} className="text-white" />}
                    color="primary"
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>

          {/* Demographics Comparison Cards - Right Side */}
          <div className="lg:col-span-8 order-1 lg:order-2">
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 auto-rows-min"
              transition={{ staggerChildren: 0.1 }}
            >
              {demographicItems.map((item, index) => (
                <DemographicComparisonCard
                  key={index}
                  item={item}
                  index={index}
                  active={activeCardIndex === index}
                  onClick={() => handleCardClick(index)}
                />
              ))}
            </motion.div>

            {/* Brand Essence Box */}
            {showBrandEssence && demographics.brandEssence && (
              <motion.div
                className="mt-10 p-8 rounded-xl border border-white/10 bg-gradient-to-br from-[rgba(var(--primary-color-rgb),0.2)] to-transparent backdrop-blur-sm"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <div className="flex items-center gap-4 mb-5">
                  <div className="p-3 rounded-full bg-[var(--primary-color-30)]">
                    <Sparkles size={22} className="text-white" />
                  </div>
                  <h3 className="text-2xl font-bold text-white">
                    Brand Essence
                  </h3>
                </div>
                <p className="text-white/90 text-lg leading-relaxed">
                  {demographics.brandEssence}
                </p>
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default DemographicsSection;
