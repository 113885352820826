// src/serviceWorkerRegistration.js
export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;

      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          // console.log("ServiceWorker registration successful:", registration);

          registration.addEventListener("updatefound", () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) return;

            installingWorker.addEventListener("statechange", () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  // console.log("New content is available; please refresh.");
                } else {
                  // console.log("Content is cached for offline use.");
                }
              }
            });
          });
        })
        .catch((error) => {
          // console.error("Error during service worker registration:", error);
        });
    });
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
