import React, { useState } from "react";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import OnboardingForm from "../OnboardingForm/OnboardingForm";

/**
 * Integration component for the OnboardingForm in the admin panel
 */
const OnboardingModule = ({ onBack }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div
      className={`relative bg-[#111111] rounded-lg ${
        isFullScreen ? "fixed inset-0 z-50" : ""
      }`}
    >
      {/* Header with back button */}
      <div className="flex items-center justify-between p-6 border-b border-white/10">
        <div className="flex items-center gap-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onBack}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            <ArrowLeft className="w-5 h-5" />
          </motion.button>
          <h2 className="text-2xl font-bold text-white">Client Onboarding</h2>
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={toggleFullScreen}
            className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white text-sm"
          >
            {isFullScreen ? "Exit Full Screen" : "Full Screen"}
          </button>
        </div>
      </div>

      {/* Onboarding form container */}
      <div className="p-4">
        <OnboardingForm />
      </div>
    </div>
  );
};

export default OnboardingModule;
