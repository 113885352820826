import React, { useState } from "react";
import { motion } from "framer-motion";
import FloatingSlider from "./FloatingSlider";
import { ChevronDown, ChevronUp } from "lucide-react";

/**
 * BrandCharacteristicsForm component - Quick-fire brand characteristic questions
 * Uses sliders to capture brand personality traits
 */
const BrandCharacteristicsForm = ({
  formData,
  updateNestedFormData,
  onComplete,
}) => {
  const [expandedSections, setExpandedSections] = useState({
    timeOfDay: true,
    expression: true,
    soundFeel: false,
    structure: false,
    mood: false,
  });

  // Group sliders by category for better organization
  const sliderGroups = {
    timeOfDay: [
      {
        id: "timeOfDay",
        label: "Is your brand a morning, midday or evening brand?",
        leftLabel: "Morning",
        rightLabel: "Evening",
      },
      {
        id: "ageFeel",
        label: "Would your brand be youthful or mature?",
        leftLabel: "Youthful",
        rightLabel: "Mature",
      },
    ],
    expression: [
      {
        id: "socialNature",
        label: "Would your brand be outspoken or introverted?",
        leftLabel: "Introverted",
        rightLabel: "Outspoken",
      },
      {
        id: "tempo",
        label: "Does it feel like a run or a walk?",
        leftLabel: "Walk",
        rightLabel: "Run",
      },
      {
        id: "volume",
        label: "Is it a whisper or a shout?",
        leftLabel: "Whisper",
        rightLabel: "Shout",
      },
      {
        id: "community",
        label: "Is it an individual or a crowd?",
        leftLabel: "Individual",
        rightLabel: "Crowd",
      },
      {
        id: "formality",
        label: "Does it feel casual or formal?",
        leftLabel: "Casual",
        rightLabel: "Formal",
      },
    ],
    soundFeel: [
      {
        id: "density",
        label: "Does it feel spacious or dense?",
        leftLabel: "Spacious",
        rightLabel: "Dense",
      },
      {
        id: "weight",
        label: "Does it feel light and airy or thick and heavy?",
        leftLabel: "Light & Airy",
        rightLabel: "Thick and Heavy",
      },
      {
        id: "digitalNature",
        label: "Does it feel natural or digital?",
        leftLabel: "Natural",
        rightLabel: "Digital",
      },
      {
        id: "soundNature",
        label: "Does it feel organic or electronic?",
        leftLabel: "Organic",
        rightLabel: "Electronic",
      },
    ],
    structure: [
      {
        id: "playfulness",
        label: "Does it feel serious or playful?",
        leftLabel: "Serious",
        rightLabel: "Playful",
      },
      {
        id: "predictability",
        label: "Does it feel steady and predictable or constantly changing?",
        leftLabel: "Steady",
        rightLabel: "Constantly Changing",
      },
      {
        id: "power",
        label: "Does it feel powerful or soft?",
        leftLabel: "Soft",
        rightLabel: "Powerful",
      },
      {
        id: "intensity",
        label: "Does it feel intense or innocent?",
        leftLabel: "Innocent",
        rightLabel: "Intense",
      },
      {
        id: "brightness",
        label: "Is it sunny or dark?",
        leftLabel: "Dark",
        rightLabel: "Bright",
      },
      {
        id: "temperature",
        label: "Is it warm or cool?",
        leftLabel: "Cool",
        rightLabel: "Warm",
      },
      {
        id: "tension",
        label: "Is it relaxed or on edge?",
        leftLabel: "Relaxed",
        rightLabel: "On Edge",
      },
    ],
    mood: [
      {
        id: "timelessness",
        label: "Is it modern and trendy or classic and timeless?",
        leftLabel: "Modern & Trendy",
        rightLabel: "Classic & Timeless",
      },
      {
        id: "structure",
        label: "Does it feel tight and focussed or loose and free-flowing?",
        leftLabel: "Tight & Focussed",
        rightLabel: "Loose & Free-Flowing",
      },
      {
        id: "organization",
        label:
          "Does it feel organised and structured or wild and unpredictable?",
        leftLabel: "Organised & Structured",
        rightLabel: "Wild & Unpredictable",
      },
      {
        id: "complexity",
        label: "Is it simple or complex?",
        leftLabel: "Simple",
        rightLabel: "Complex",
      },
      {
        id: "layers",
        label: "Is it a single layer or multiple layers?",
        leftLabel: "Single",
        rightLabel: "Layered",
      },
      {
        id: "groundedness",
        label: "Is it earthy or ethereal?",
        leftLabel: "Earthy",
        rightLabel: "Ethereal",
      },
      {
        id: "gravity",
        label: "Is it grounded or floaty?",
        leftLabel: "Grounded",
        rightLabel: "Floaty",
      },
      {
        id: "teamwork",
        label: "Is it a pack or is it a solo mission?",
        leftLabel: "Pack",
        rightLabel: "Solo Mission",
      },
      {
        id: "energy",
        label: "Is it uplifting like a cheer or calm like a sigh?",
        leftLabel: "Calm",
        rightLabel: "Uplifting",
      },
      {
        id: "focus",
        label:
          "Is it focussed on one clear idea or a blend of overlapping parts?",
        leftLabel: "One Clear Idea",
        rightLabel: "Overlapping Parts",
      },
      {
        id: "innovation",
        label: "Does it feel traditional or experimental?",
        leftLabel: "Traditional",
        rightLabel: "Experimental",
      },
      {
        id: "novelty",
        label: "Does it feel familiar or new?",
        leftLabel: "Familiar",
        rightLabel: "New",
      },
    ],
  };

  // Get brand characteristics from form data or initialize defaults
  const brandCharacteristics = formData?.brandCharacteristics || {};

  // Handle slider value changes
  const handleSliderChange = (id, value) => {
    updateNestedFormData("brandCharacteristics", id, value);
  };

  // Toggle section expansion
  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onComplete("brand-characteristics", { brandCharacteristics });
  };

  // Section component with toggle functionality
  const SectionHeader = ({ title, section, count }) => (
    <motion.div
      className="flex items-center justify-between py-4 px-3 mb-6 border-b border-white/10 cursor-pointer"
      onClick={() => toggleSection(section)}
      whileHover={{ backgroundColor: "rgba(255, 255, 255, 0.05)" }}
    >
      <h3 className="text-xl text-white font-medium flex items-center">
        {title}
        <span className="ml-2 text-sm text-white/50">({count} questions)</span>
      </h3>
      {expandedSections[section] ? (
        <ChevronUp className="w-5 h-5 text-white/70" />
      ) : (
        <ChevronDown className="w-5 h-5 text-white/70" />
      )}
    </motion.div>
  );

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          {/* Time of Day Section */}
          <div>
            <SectionHeader
              title="Time & Age"
              section="timeOfDay"
              count={sliderGroups.timeOfDay.length}
            />

            {expandedSections.timeOfDay && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6 pl-4"
              >
                {sliderGroups.timeOfDay.map((slider) => (
                  <FloatingSlider
                    key={slider.id}
                    id={slider.id}
                    label={slider.label}
                    leftLabel={slider.leftLabel}
                    rightLabel={slider.rightLabel}
                    value={brandCharacteristics[slider.id] || 3}
                    onChange={handleSliderChange}
                  />
                ))}
              </motion.div>
            )}
          </div>

          {/* Expression Section */}
          <div>
            <SectionHeader
              title="Expression & Communication"
              section="expression"
              count={sliderGroups.expression.length}
            />

            {expandedSections.expression && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6 pl-4"
              >
                {sliderGroups.expression.map((slider) => (
                  <FloatingSlider
                    key={slider.id}
                    id={slider.id}
                    label={slider.label}
                    leftLabel={slider.leftLabel}
                    rightLabel={slider.rightLabel}
                    value={brandCharacteristics[slider.id] || 3}
                    onChange={handleSliderChange}
                  />
                ))}
              </motion.div>
            )}
          </div>

          {/* Sound Feel Section */}
          <div>
            <SectionHeader
              title="Texture & Feeling"
              section="soundFeel"
              count={sliderGroups.soundFeel.length}
            />

            {expandedSections.soundFeel && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6 pl-4"
              >
                {sliderGroups.soundFeel.map((slider) => (
                  <FloatingSlider
                    key={slider.id}
                    id={slider.id}
                    label={slider.label}
                    leftLabel={slider.leftLabel}
                    rightLabel={slider.rightLabel}
                    value={brandCharacteristics[slider.id] || 3}
                    onChange={handleSliderChange}
                  />
                ))}
              </motion.div>
            )}
          </div>

          {/* Structure Section */}
          <div>
            <SectionHeader
              title="Tone & Emotion"
              section="structure"
              count={sliderGroups.structure.length}
            />

            {expandedSections.structure && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6 pl-4"
              >
                {sliderGroups.structure.map((slider) => (
                  <FloatingSlider
                    key={slider.id}
                    id={slider.id}
                    label={slider.label}
                    leftLabel={slider.leftLabel}
                    rightLabel={slider.rightLabel}
                    value={brandCharacteristics[slider.id] || 3}
                    onChange={handleSliderChange}
                  />
                ))}
              </motion.div>
            )}
          </div>

          {/* Mood Section */}
          <div>
            <SectionHeader
              title="Structure & Style"
              section="mood"
              count={sliderGroups.mood.length}
            />

            {expandedSections.mood && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6 pl-4"
              >
                {sliderGroups.mood.map((slider) => (
                  <FloatingSlider
                    key={slider.id}
                    id={slider.id}
                    label={slider.label}
                    leftLabel={slider.leftLabel}
                    rightLabel={slider.rightLabel}
                    value={brandCharacteristics[slider.id] || 3}
                    onChange={handleSliderChange}
                  />
                ))}
              </motion.div>
            )}
          </div>
        </div>

        <div className="flex justify-center pt-10 mt-10">
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            whileHover={{ scale: 1.05, y: -5 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            className="py-4 rounded-full font-medium flex items-center justify-center bg-primary-green text-black hover:bg-primary-green/90 transition-all duration-300 w-64 mx-auto"
          >
            Continue
          </motion.button>
        </div>
      </form>
    </div>
  );
};

export default BrandCharacteristicsForm;
