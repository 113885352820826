import React, { useState, useEffect } from "react";
import { HiPlus, HiTrash } from "react-icons/hi";
import { QUESTION_TYPES } from "../models/types";
import { createOption } from "../utils/helpers";

/**
 * QuestionBuilder component
 * Allows creating and editing questions
 */
const QuestionBuilder = ({ question, onSave, onCancel }) => {
  // Initialize form state
  const [formData, setFormData] = useState({
    type: "text",
    title: "",
    description: "",
    placeholder: "",
    required: false,
    validation: null,
    defaultValue: "",
    options: [],
  });

  // Populate form with question data if editing
  useEffect(() => {
    if (question) {
      setFormData({
        type: question.type || "text",
        title: question.title || "",
        description: question.description || "",
        placeholder: question.placeholder || "",
        required: question.required || false,
        validation: question.validation || null,
        defaultValue: question.defaultValue || "",
        options: question.options || [],
      });
    }
  }, [question]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle option changes
  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...formData.options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [field]: value,
    };

    setFormData({
      ...formData,
      options: updatedOptions,
    });
  };

  // Add a new option
  const handleAddOption = () => {
    setFormData({
      ...formData,
      options: [...formData.options, createOption("New Option")],
    });
  };

  // Remove an option
  const handleRemoveOption = (index) => {
    const updatedOptions = [...formData.options];
    updatedOptions.splice(index, 1);

    setFormData({
      ...formData,
      options: updatedOptions,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  // Determine if the question type requires options
  const hasOptions = ["select", "multiselect", "radio", "checkbox"].includes(
    formData.type
  );

  return (
    <div className="border border-neutral-700 rounded-lg p-4 bg-neutral-700/30 mb-6">
      <h4 className="text-base font-medium mb-4">
        {question ? "Edit Question" : "Add New Question"}
      </h4>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Question Type */}
        <div>
          <label className="block text-sm font-medium text-neutral-300 mb-1">
            Question Type
          </label>
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
          >
            {Object.entries(QUESTION_TYPES).map(([key, value]) => (
              <option key={value} value={value}>
                {key.replace(/_/g, " ")}
              </option>
            ))}
          </select>
        </div>

        {/* Question Title */}
        <div>
          <label className="block text-sm font-medium text-neutral-300 mb-1">
            Question Text
          </label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
            placeholder="Enter the question text"
            required
          />
        </div>

        {/* Question Description */}
        <div>
          <label className="block text-sm font-medium text-neutral-300 mb-1">
            Description / Help Text
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green h-20"
            placeholder="Optional description or help text"
          />
        </div>

        {/* Placeholder (for text inputs) */}
        {["text", "textarea", "url"].includes(formData.type) && (
          <div>
            <label className="block text-sm font-medium text-neutral-300 mb-1">
              Placeholder Text
            </label>
            <input
              type="text"
              name="placeholder"
              value={formData.placeholder}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
              placeholder="Optional placeholder text"
            />
          </div>
        )}

        {/* Default Value */}
        {!hasOptions && formData.type !== "color" && (
          <div>
            <label className="block text-sm font-medium text-neutral-300 mb-1">
              Default Value
            </label>
            <input
              type="text"
              name="defaultValue"
              value={formData.defaultValue}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
              placeholder="Optional default value"
            />
          </div>
        )}

        {/* Required checkbox */}
        <div className="flex items-center">
          <input
            type="checkbox"
            name="required"
            id="required"
            checked={formData.required}
            onChange={handleChange}
            className="h-4 w-4 text-primary-green focus:ring-primary-green border-neutral-500 rounded"
          />
          <label
            htmlFor="required"
            className="ml-2 block text-sm text-neutral-300"
          >
            Required
          </label>
        </div>

        {/* Options (for select, multiselect, radio, checkbox) */}
        {hasOptions && (
          <div className="border-t border-neutral-600 pt-4 mt-4">
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-neutral-300">
                Options
              </label>
              <button
                type="button"
                onClick={handleAddOption}
                className="flex items-center text-sm text-blue-400 hover:text-blue-300 transition"
              >
                <HiPlus size={16} className="mr-1" />
                Add Option
              </button>
            </div>

            {formData.options.length === 0 ? (
              <div className="text-neutral-400 text-sm p-3 bg-neutral-800 rounded-md">
                No options defined. Click "Add Option" to create options.
              </div>
            ) : (
              <div className="space-y-2">
                {formData.options.map((option, index) => (
                  <div key={option.id} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={option.label}
                      onChange={(e) =>
                        handleOptionChange(index, "label", e.target.value)
                      }
                      className="flex-1 px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
                      placeholder="Option text"
                    />
                    <input
                      type="text"
                      value={option.value}
                      onChange={(e) =>
                        handleOptionChange(index, "value", e.target.value)
                      }
                      className="w-1/3 px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
                      placeholder="Value (optional)"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveOption(index)}
                      className="p-2 text-red-400 hover:bg-red-500/20 rounded"
                      title="Remove option"
                    >
                      <HiTrash size={16} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Form actions */}
        <div className="flex justify-end space-x-3 pt-4 border-t border-neutral-600">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 border border-neutral-600 text-neutral-300 rounded-md hover:bg-neutral-700 transition"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-primary-green text-black rounded-md hover:bg-primary-green/90 transition"
            disabled={
              !formData.title || (hasOptions && formData.options.length === 0)
            }
          >
            {question ? "Update Question" : "Add Question"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuestionBuilder;
