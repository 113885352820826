import React from "react";
import { useLocation, useParams } from "react-router-dom";

const RouteDebugger = () => {
  const location = useLocation();
  const params = useParams();

  return process.env.NODE_ENV === "development" ? (
    <div className="fixed bottom-4 right-4 z-50 p-4 bg-black/80 rounded-lg text-xs text-white/70">
      <div>Path: {location.pathname}</div>
      <div>Params: {JSON.stringify(params)}</div>
      <div>Search: {location.search}</div>
    </div>
  ) : null;
};

export default RouteDebugger;
