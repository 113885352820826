import { useState, useEffect } from "react";

/**
 * Initial form data structure matching all form steps
 */
const initialFormData = {
  // Website analysis data
  websiteUrl: "",
  brandStyle: {
    colors: {
      primary: "",
      secondary: "",
      background: "",
      text: "",
    },
    fonts: {
      primary: "",
      secondary: "",
    },
    images: [],
  },

  // Brand information
  brandInfo: {
    companyName: "",
    industry: "",
    establishedYear: "",
    companySize: "",
    marketPosition: "",
    targetCountries: [],
  },

  // Brand personality
  brandPersonality: {
    description: "",
    values: [],
    mission: "",
    vision: "",
  },

  // Brand strategy
  brandStrategy: {
    uniqueSellingProposition: "",
    competitors: [],
    differentiators: [],
    marketingGoals: [],
  },

  // Demographics
  demographics: {
    current: {
      ageRange: [],
      territories: [],
      gender: [],
      interests: [],
    },
    target: {
      ageRange: [],
      territories: [],
      gender: [],
      interests: [],
    },
  },

  // Brand sound
  brandSound: {
    preferredMusicGenres: [],
    soundAdjectives: [],
    voiceType: "",
    soundExamples: [],
  },

  // Brand characteristics (slider questions 1-5 scale)
  characteristics: {
    morning_evening: 3,
    youthful_mature: 3,
    introverted_outspoken: 3,
    corporate_casual: 3,
    serious_playful: 3,
    traditional_innovative: 3,
    value_premium: 3,
    safe_bold: 3,
    complex_simple: 3,
    exclusive_accessible: 3,
  },

  // Project goals
  projectGoals: {
    primaryObjective: "",
    secondaryObjectives: [],
    timeline: "",
    budget: "",
    successMeasures: [],
  },

  // Visual assets
  visuals: {
    selectedAssets: [],
    uploadedAssets: [],
    logoFiles: [],
  },
};

/**
 * Custom hook for managing form state across multiple steps
 */
const useFormState = () => {
  // Use localStorage to persist form data if available
  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("onboardingFormData");
    return savedFormData ? JSON.parse(savedFormData) : initialFormData;
  });

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("onboardingFormData", JSON.stringify(formData));
  }, [formData]);

  /**
   * Update a top-level field in the form data
   * @param {string} field - The field to update
   * @param {any} value - The new value
   */
  const updateFormData = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  /**
   * Update a nested field in the form data
   * @param {string} parentField - The parent field
   * @param {string} childField - The child field
   * @param {any} value - The new value
   */
  const updateNestedFormData = (parentField, childField, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [parentField]: {
        ...prevData[parentField],
        [childField]: value,
      },
    }));
  };

  /**
   * Reset the form to its initial state
   */
  const resetForm = () => {
    setFormData(initialFormData);
    localStorage.removeItem("onboardingFormData");
  };

  /**
   * Import form data (from a previous session or template)
   * @param {object} data - The form data to import
   */
  const importFormData = (data) => {
    setFormData(data);
  };

  /**
   * Export the current form data
   * @returns {object} The current form data
   */
  const exportFormData = () => {
    return formData;
  };

  return {
    formData,
    updateFormData,
    updateNestedFormData,
    resetForm,
    importFormData,
    exportFormData,
  };
};

export default useFormState;
