import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import questionnaireService from "../services/questionnaireService";
import SectionBuilder from "./SectionBuilder";
import {
  createDefaultQuestionnaire,
  reorderItems,
  createSection,
  generateSampleQuestions,
} from "../utils/helpers";
import { SECTION_TYPES } from "../models/types";

/**
 * QuestionnaireBuilder component
 * Allows admins to create and manage questionnaire templates
 */
const QuestionnaireBuilder = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [saving, setSaving] = useState(false);

  // Load questionnaires on component mount
  useEffect(() => {
    loadQuestionnaires();
  }, []);

  // Load all questionnaires from the database
  const loadQuestionnaires = async () => {
    try {
      setLoading(true);
      const data = await questionnaireService.getAllQuestionnaires();
      setQuestionnaires(data);
      setLoading(false);
    } catch (error) {
      console.error("Error loading questionnaires:", error);
      toast.error("Failed to load questionnaires");
      setLoading(false);
    }
  };

  // Create a new questionnaire
  const handleCreateQuestionnaire = () => {
    setCurrentQuestionnaire(null);
    setTitle("New Brand Questionnaire");
    setDescription("Collect essential information about your client's brand");
    setIsEditing(true);
  };

  // Edit an existing questionnaire
  const handleEditQuestionnaire = (questionnaire) => {
    setCurrentQuestionnaire(questionnaire);
    setTitle(questionnaire.title);
    setDescription(questionnaire.description);
    setIsEditing(true);
  };

  // Cancel editing
  const handleCancelEdit = () => {
    setIsEditing(false);
    setCurrentQuestionnaire(null);
    setTitle("");
    setDescription("");
  };

  // Save the questionnaire
  const handleSaveQuestionnaire = async () => {
    try {
      setSaving(true);

      let questionnaireId;
      if (currentQuestionnaire) {
        // Update existing questionnaire
        await questionnaireService.updateQuestionnaire(
          currentQuestionnaire.id,
          {
            title,
            description,
            updatedAt: new Date().toISOString(),
          }
        );
        questionnaireId = currentQuestionnaire.id;
        toast.success("Questionnaire updated successfully");
      } else {
        // Create a new questionnaire
        const newQuestionnaire = createDefaultQuestionnaire(title, description);
        questionnaireId = await questionnaireService.createQuestionnaire(
          newQuestionnaire
        );
        toast.success("Questionnaire created successfully");

        // Create sample questions for each section
        const questionnaire = await questionnaireService.getQuestionnaireById(
          questionnaireId
        );

        // Add sample questions to each section
        for (const section of questionnaire.sections) {
          const sampleQuestions = generateSampleQuestions(
            section.id,
            section.type
          );

          if (sampleQuestions.length > 0) {
            // Update section with sample questions
            await questionnaireService.updateSection(
              questionnaireId,
              section.id,
              { ...section, questions: sampleQuestions }
            );
          }
        }
      }

      // Reload questionnaires and get the updated one
      await loadQuestionnaires();
      const updatedQuestionnaire =
        await questionnaireService.getQuestionnaireById(questionnaireId);
      setCurrentQuestionnaire(updatedQuestionnaire);
      setSaving(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving questionnaire:", error);
      toast.error("Failed to save questionnaire");
      setSaving(false);
    }
  };

  // Handle drag and drop reordering of sections
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const questionnaire = { ...currentQuestionnaire };

    // Reorder sections array
    const reorderedSections = reorderItems(
      questionnaire.sections,
      source.index,
      destination.index
    );

    // Update questionnaire with reordered sections
    try {
      await questionnaireService.updateQuestionnaire(questionnaire.id, {
        sections: reorderedSections,
      });

      // Update local state
      setCurrentQuestionnaire({
        ...questionnaire,
        sections: reorderedSections,
      });

      toast.success("Sections reordered successfully");
    } catch (error) {
      console.error("Error reordering sections:", error);
      toast.error("Failed to reorder sections");
    }
  };

  // Add a new section
  const handleAddSection = async () => {
    if (!currentQuestionnaire) return;

    try {
      // Find the highest order
      const maxOrder = currentQuestionnaire.sections.reduce(
        (max, section) => Math.max(max, section.order),
        -1
      );

      // Create a new section
      const newSection = createSection(
        SECTION_TYPES.BRAND_INFO, // Default type, will be edited by user
        "New Section",
        "Description of the new section",
        maxOrder + 1
      );

      // Add to the questionnaire
      await questionnaireService.addSection(
        currentQuestionnaire.id,
        newSection
      );

      // Reload the current questionnaire
      const updatedQuestionnaire =
        await questionnaireService.getQuestionnaireById(
          currentQuestionnaire.id
        );
      setCurrentQuestionnaire(updatedQuestionnaire);

      toast.success("Section added successfully");
    } catch (error) {
      console.error("Error adding section:", error);
      toast.error("Failed to add section");
    }
  };

  // Delete a section
  const handleDeleteSection = async (sectionId) => {
    if (!currentQuestionnaire) return;

    if (
      !window.confirm(
        "Are you sure you want to delete this section? This will also delete all questions in this section."
      )
    ) {
      return;
    }

    try {
      await questionnaireService.deleteSection(
        currentQuestionnaire.id,
        sectionId
      );

      // Reload the current questionnaire
      const updatedQuestionnaire =
        await questionnaireService.getQuestionnaireById(
          currentQuestionnaire.id
        );
      setCurrentQuestionnaire(updatedQuestionnaire);

      toast.success("Section deleted successfully");
    } catch (error) {
      console.error("Error deleting section:", error);
      toast.error("Failed to delete section");
    }
  };

  // Update a section
  const handleUpdateSection = async (sectionId, sectionData) => {
    if (!currentQuestionnaire) return;

    try {
      await questionnaireService.updateSection(
        currentQuestionnaire.id,
        sectionId,
        sectionData
      );

      // Reload the current questionnaire
      const updatedQuestionnaire =
        await questionnaireService.getQuestionnaireById(
          currentQuestionnaire.id
        );
      setCurrentQuestionnaire(updatedQuestionnaire);

      toast.success("Section updated successfully");
    } catch (error) {
      console.error("Error updating section:", error);
      toast.error("Failed to update section");
    }
  };

  // Activate a questionnaire
  const handleActivateQuestionnaire = async (questionnaireId) => {
    try {
      // First, set all questionnaires to draft
      const allQuestionnaires =
        await questionnaireService.getAllQuestionnaires();
      for (const questionnaire of allQuestionnaires) {
        if (questionnaire.status === "active") {
          await questionnaireService.updateQuestionnaire(questionnaire.id, {
            status: "draft",
          });
        }
      }

      // Then set the selected one to active
      await questionnaireService.updateQuestionnaire(questionnaireId, {
        status: "active",
      });

      // Reload questionnaires
      await loadQuestionnaires();

      toast.success("Questionnaire activated successfully");
    } catch (error) {
      console.error("Error activating questionnaire:", error);
      toast.error("Failed to activate questionnaire");
    }
  };

  // Delete a questionnaire
  const handleDeleteQuestionnaire = async (questionnaireId) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this questionnaire? This action cannot be undone."
      )
    ) {
      return;
    }

    try {
      await questionnaireService.deleteQuestionnaire(questionnaireId);

      // Reload questionnaires
      await loadQuestionnaires();

      if (currentQuestionnaire && currentQuestionnaire.id === questionnaireId) {
        setCurrentQuestionnaire(null);
      }

      toast.success("Questionnaire deleted successfully");
    } catch (error) {
      console.error("Error deleting questionnaire:", error);
      toast.error("Failed to delete questionnaire");
    }
  };

  return (
    <div className="px-4 py-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-8">Questionnaire Builder</h1>

      {/* List of questionnaires */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Available Questionnaires</h2>
          <button
            onClick={handleCreateQuestionnaire}
            className="px-4 py-2 bg-primary-green text-black rounded-md hover:bg-primary-green/90 transition"
          >
            Create New
          </button>
        </div>

        {loading ? (
          <div className="py-4">Loading questionnaires...</div>
        ) : questionnaires.length === 0 ? (
          <div className="py-4 text-neutral-500">
            No questionnaires found. Create your first questionnaire to get
            started.
          </div>
        ) : (
          <div className="grid gap-4">
            {questionnaires.map((questionnaire) => (
              <div
                key={questionnaire.id}
                className="border border-neutral-700 rounded-lg p-4 bg-neutral-800"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-medium text-white">
                      {questionnaire.title}
                      {questionnaire.status === "active" && (
                        <span className="ml-2 px-2 py-0.5 text-xs bg-green-500/20 text-green-500 rounded-full">
                          Active
                        </span>
                      )}
                    </h3>
                    <p className="text-neutral-400 mt-1">
                      {questionnaire.description}
                    </p>
                    <div className="text-sm text-neutral-500 mt-2">
                      Sections: {questionnaire.sections?.length || 0} | Last
                      updated:{" "}
                      {new Date(questionnaire.updatedAt).toLocaleDateString()}
                    </div>
                  </div>

                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditQuestionnaire(questionnaire)}
                      className="px-3 py-1 text-sm bg-blue-500/20 text-blue-400 rounded hover:bg-blue-500/30 transition"
                    >
                      Edit
                    </button>

                    {questionnaire.status !== "active" && (
                      <button
                        onClick={() =>
                          handleActivateQuestionnaire(questionnaire.id)
                        }
                        className="px-3 py-1 text-sm bg-green-500/20 text-green-400 rounded hover:bg-green-500/30 transition"
                      >
                        Activate
                      </button>
                    )}

                    <button
                      onClick={() =>
                        handleDeleteQuestionnaire(questionnaire.id)
                      }
                      className="px-3 py-1 text-sm bg-red-500/20 text-red-400 rounded hover:bg-red-500/30 transition"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Questionnaire editor */}
      {isEditing && (
        <div className="mt-8 border border-neutral-700 rounded-lg p-6 bg-neutral-800">
          <h2 className="text-xl font-semibold mb-4">
            {currentQuestionnaire
              ? "Edit Questionnaire"
              : "Create New Questionnaire"}
          </h2>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-neutral-300 mb-1">
                Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-neutral-300 mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-green h-24"
              />
            </div>

            <div className="flex justify-end space-x-3 pt-2">
              <button
                onClick={handleCancelEdit}
                className="px-4 py-2 border border-neutral-600 text-neutral-300 rounded-md hover:bg-neutral-700 transition"
                disabled={saving}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveQuestionnaire}
                className="px-4 py-2 bg-primary-green text-black rounded-md hover:bg-primary-green/90 transition disabled:opacity-50"
                disabled={saving || !title}
              >
                {saving ? "Saving..." : "Save Questionnaire"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Section editor (when a questionnaire is selected) */}
      {currentQuestionnaire && !isEditing && (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">
              Editing: {currentQuestionnaire.title}
            </h2>
            <button
              onClick={handleAddSection}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
            >
              Add Section
            </button>
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-6"
                >
                  {currentQuestionnaire.sections
                    .sort((a, b) => a.order - b.order)
                    .map((section, index) => (
                      <Draggable
                        key={section.id}
                        draggableId={section.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <SectionBuilder
                              section={section}
                              questionnaireId={currentQuestionnaire.id}
                              onUpdateSection={(sectionData) =>
                                handleUpdateSection(section.id, sectionData)
                              }
                              onDeleteSection={() =>
                                handleDeleteSection(section.id)
                              }
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default QuestionnaireBuilder;
