import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion, useScroll } from "framer-motion";
import { db, storage } from "../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import SoundboardSection from "../../Components/Soundboard/Soundboard";

// Import modularized components
import HeroSection from "../../Components/BrandProfile/HeroSection";
import BrandBackgroundSection from "../../Components/BrandProfile/BrandBackgroundSection";
import BrandStrategySection from "../../Components/BrandProfile/BrandStrategySection";
import DemographicsSection from "../../Components/BrandProfile/DemographicsSection";
import BrandSoundSection from "../../Components/BrandProfile/BrandSoundSection";
import CharacteristicsSection from "../../Components/BrandProfile/CharacteristicsSection";
import ProjectGoalsSection from "../../Components/BrandProfile/ProjectGoalsSection";
import BrandPersonalitySection from "../../Components/BrandProfile/BrandPersonalitySection";

const BrandProfile = () => {
  const { profileId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [documentId, setDocumentId] = useState(null); // Store the actual document ID
  const [activeTab, setActiveTab] = useState("brandDiscovery"); // Track active tab: brandDiscovery or soundboard
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: containerRef });

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);

        // First try to find by URL handle
        const urlHandleQuery = query(
          collection(db, "brandProfiles"),
          where("urlHandle", "==", profileId)
        );
        const urlHandleSnap = await getDocs(urlHandleQuery);

        if (!urlHandleSnap.empty) {
          // Found by URL handle
          const docSnapshot = urlHandleSnap.docs[0];
          const data = docSnapshot.data();
          setProfileData(data);
          setDocumentId(docSnapshot.id);
        } else {
          // Try direct document ID lookup as fallback
          const profileRef = doc(db, "brandProfiles", profileId);
          const profileSnap = await getDoc(profileRef);

          if (profileSnap.exists()) {
            setProfileData(profileSnap.data());
            setDocumentId(profileId);
          } else {
            throw new Error("Profile not found");
          }
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProfile();
  }, [profileId]);

  // Initialize all categories as expanded when profileData loads
  useEffect(() => {
    if (!profileData) return;

    // Initialize categories for characteristics
    const initializeCategories = () => {
      const charsToUse =
        profileData.characteristics || profileData.brandCharacteristics;
      if (!charsToUse) return;

      if (Array.isArray(charsToUse)) {
        const newCategories = new Set(expandedCategories);
        charsToUse.forEach((group) => {
          newCategories.add(group.category);
        });
        setExpandedCategories(newCategories);
      } else {
        // For object format
        const categoryMap = {
          "Brand Personality": true,
          "Visual & Aesthetic": true,
          "Temporal & Structural": true,
          "Innovation & Change": true,
          "Sound & Voice": true,
        };

        setExpandedCategories(new Set(Object.keys(categoryMap)));
      }
    };

    initializeCategories();
  }, [profileData]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatDate = (timestamp) => {
    if (!timestamp) return "Not specified";
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  // Helper function to get images by type
  const getImagesByType = (type) => {
    if (
      !profileData?.brandStyle?.images ||
      profileData.brandStyle.images.length === 0
    ) {
      return [];
    }
    return profileData.brandStyle.images.filter((img) => img.type === type);
  };

  // Get different image types
  const logoImages = getImagesByType("logo");
  const heroImages = getImagesByType("hero");
  const otherImages = getImagesByType("other");
  const soundboardImages = getImagesByType("soundboard");

  // Replace with this enhanced version
  const getImageUrl = (imageArray, section, fallbackColor) => {
    // Return if no images
    if (!imageArray || imageArray.length === 0) {
      return null;
    }

    // Use localStorage to store the last used image index for this profile
    const cacheKey = `brand_profile_${profileId}_last_image_index`;
    let lastUsedIndex = parseInt(localStorage.getItem(cacheKey) || '0', 10);

    // For sections that use 'other' images, cycle through available images
    if (section === 'strategy' || section === 'demographics' || section === 'personality' || section === 'sound' || section === 'characteristics' || section === 'goals') {
      // Get all other images
      const otherImages = profileData?.brandStyle?.images?.filter(img => img.type === 'other') || [];

      if (otherImages.length > 0) {
        // Cycle through available images
        const nextIndex = (lastUsedIndex + 1) % otherImages.length;
        localStorage.setItem(cacheKey, nextIndex.toString());
        return otherImages[nextIndex].url;
      }
    }

    // For non-'other' sections, use the original logic
    const cacheKeySection = `brand_profile_${profileId}_${section}_image`;
    let imageIndex = localStorage.getItem(cacheKeySection);

    if (imageIndex === null) {
      const imagesWithDimensions = imageArray.filter(
        (img) =>
          img.dimensions &&
          (img.dimensions.width > 0 || img.dimensions.height > 0)
      );

      if (imagesWithDimensions.length > 0) {
        const sortedImages = [...imagesWithDimensions].sort((a, b) => {
          const aSize = (a.dimensions.width || 0) * (a.dimensions.height || 0);
          const bSize = (b.dimensions.width || 0) * (b.dimensions.height || 0);
          return bSize - aSize;
        });

        imageIndex = imageArray.findIndex(
          (img) => img.url === sortedImages[0].url
        );
      } else {
        imageIndex = 0;
      }

      localStorage.setItem(cacheKeySection, imageIndex);
    } else {
      imageIndex = parseInt(imageIndex, 10);
      if (imageIndex >= imageArray.length) {
        imageIndex = 0;
        localStorage.setItem(cacheKeySection, imageIndex);
      }
    }

    return imageArray[imageIndex].url;
  };

  // Color utility functions
  const colorUtils = {
    // Convert hex to RGB
    hexToRgb: (hex) => {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
    },

    // Convert RGB to hex
    rgbToHex: (r, g, b) => {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    },

    // Calculate color brightness (0-255)
    getBrightness: (hex) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return 128; // Default mid-brightness
      return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    },

    // Calculate color saturation (0-1)
    getSaturation: (hex) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return 0;
      const r = rgb.r / 255;
      const g = rgb.g / 255;
      const b = rgb.b / 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      const l = (max + min) / 2;
      if (max === min) return 0;
      const d = max - min;
      return l > 0.5 ? d / (2 - max - min) : d / (max + min);
    },

    // Determine if a color is grayscale
    isGrayscale: (hex) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return false;
      const tolerance = 10; // Allow slight variations in RGB values
      return (
        Math.abs(rgb.r - rgb.g) <= tolerance &&
        Math.abs(rgb.g - rgb.b) <= tolerance &&
        Math.abs(rgb.r - rgb.b) <= tolerance
      );
    },

    // Get contrasting text color (black or white)
    getContrastText: (hex) => {
      const brightness = colorUtils.getBrightness(hex);
      return brightness > 128 ? "#000000" : "#ffffff";
    },

    // Adjust color brightness
    adjustBrightness: (hex, factor) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return hex;

      const newR = Math.min(255, Math.max(0, Math.round(rgb.r * factor)));
      const newG = Math.min(255, Math.max(0, Math.round(rgb.g * factor)));
      const newB = Math.min(255, Math.max(0, Math.round(rgb.b * factor)));

      return `#${newR.toString(16).padStart(2, "0")}${newG
        .toString(16)
        .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
    },

    // Adjust color opacity
    withOpacity: (hex, opacity) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return hex;
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    },

    // Create a complementary color
    getComplementary: (hex) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return "#000000";

      // Simple complementary: invert each channel
      return colorUtils.rgbToHex(255 - rgb.r, 255 - rgb.g, 255 - rgb.b);
    },

    // Create an analogous color (slightly shifted hue)
    getAnalogous: (hex, degree = 30) => {
      const rgb = colorUtils.hexToRgb(hex);
      if (!rgb) return hex;

      // Convert RGB to HSL
      const r = rgb.r / 255;
      const g = rgb.g / 255;
      const b = rgb.b / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h,
        s,
        l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
          default:
            h = 0;
        }

        h /= 6;
      }

      // Shift hue by degree/360
      h = (h + degree / 360) % 1;

      // Convert back to RGB
      let r1, g1, b1;

      if (s === 0) {
        r1 = g1 = b1 = l; // achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r1 = hue2rgb(p, q, h + 1 / 3);
        g1 = hue2rgb(p, q, h);
        b1 = hue2rgb(p, q, h - 1 / 3);
      }

      return colorUtils.rgbToHex(
        Math.round(r1 * 255),
        Math.round(g1 * 255),
        Math.round(b1 * 255)
      );
    },

    // Create a visually appealing color palette from available colors
    createPalette: (colors) => {
      if (!colors || Object.keys(colors).length === 0) {
        return {
          primary: "#01b280",
          secondary: "#6f57a5",
          accent: "#ff8936",
          background: "#ffffff",
          text: "#333333",
          darkAccent: "#01805c",
          lightAccent: "#e6f7f2",
          cardBg: "#ffffff",
          cardBgTranslucent: "rgba(255, 255, 255, 0.8)",
          darkCardBg: "#1a1a1a",
          darkCardBgTranslucent: "rgba(26, 26, 26, 0.8)",
          success: "#28a745",
          warning: "#ffc107",
          error: "#dc3545",
          info: "#17a2b8",
          getContrastText: (color) => colorUtils.getContrastText(color),
        };
      }

      // Check if we have a cached palette for this profile
      const cacheKey = `brand_profile_${profileId}_palette`;
      const cachedPalette = localStorage.getItem(cacheKey);

      if (cachedPalette) {
        try {
          const parsed = JSON.parse(cachedPalette);
          // Add the getContrastText function
          parsed.getContrastText = (color) => colorUtils.getContrastText(color);
          return parsed;
        } catch (e) {
          console.error("Error parsing cached palette:", e);
          // Fall through to regenerate
        }
      }

      // Extract all colors into an array
      const colorArray = Object.values(colors).filter(
        (c) => c && c !== "#ffffff" && c !== "#000000"
      );

      // If no valid colors, return default palette
      if (colorArray.length === 0) {
        return {
          primary: "#01b280",
          secondary: "#6f57a5",
          accent: "#ff8936",
          background: "#ffffff",
          text: "#333333",
          darkAccent: "#01805c",
          lightAccent: "#e6f7f2",
          cardBg: "#ffffff",
          cardBgTranslucent: "rgba(255, 255, 255, 0.8)",
          darkCardBg: "#1a1a1a",
          darkCardBgTranslucent: "rgba(26, 26, 26, 0.8)",
          success: "#28a745",
          warning: "#ffc107",
          error: "#dc3545",
          info: "#17a2b8",
          getContrastText: (color) => colorUtils.getContrastText(color),
        };
      }

      // Sort colors by saturation (most saturated first)
      const sortedBySaturation = [...colorArray].sort(
        (a, b) => colorUtils.getSaturation(b) - colorUtils.getSaturation(a)
      );

      // Sort colors by brightness (darkest first)
      const sortedByBrightness = [...colorArray].sort(
        (a, b) => colorUtils.getBrightness(a) - colorUtils.getBrightness(b)
      );

      // Find grayscale colors
      const grayscaleColors = colorArray.filter((c) =>
        colorUtils.isGrayscale(c)
      );

      // Select primary color (prefer saturated colors)
      const primary = sortedBySaturation[0] || colorArray[0];

      // Select secondary color (second most saturated, or complementary)
      const secondary =
        sortedBySaturation.length > 1
          ? sortedBySaturation[1]
          : colorUtils.getAnalogous(primary, 180);

      // Select accent (brightest saturated color or complementary to primary)
      const brightSaturated = sortedBySaturation.filter(
        (c) => colorUtils.getBrightness(c) > 128
      );
      const accent =
        brightSaturated.length > 0
          ? brightSaturated[0]
          : colorUtils.getAnalogous(primary, 120);

      // Select background (prefer white or very light colors)
      const veryLightColors = colorArray.filter(
        (c) => colorUtils.getBrightness(c) > 240
      );
      const background =
        veryLightColors.length > 0 ? veryLightColors[0] : "#ffffff";

      // Select text color (prefer dark colors or black)
      const veryDarkColors = colorArray.filter(
        (c) => colorUtils.getBrightness(c) < 60
      );
      const text = veryDarkColors.length > 0 ? veryDarkColors[0] : "#333333";

      // Create darker version of primary for accents
      const darkAccent = colorUtils.adjustBrightness(primary, 0.7);

      // Create lighter version of primary for subtle backgrounds
      const lightAccent = colorUtils.adjustBrightness(primary, 1.8);

      // Create section background colors
      const sectionBg1 = colorUtils.adjustBrightness(background, 0.98);
      const sectionBg2 = colorUtils.adjustBrightness(lightAccent, 1.1);

      // Create card backgrounds
      const cardBg = background;
      const cardBgTranslucent = colorUtils.withOpacity(background, 0.8);

      // Create dark card backgrounds for contrast
      const darkCardBg = colorUtils.adjustBrightness(text, 1.2);
      const darkCardBgTranslucent = colorUtils.withOpacity(darkCardBg, 0.8);

      // Create status colors based on primary and secondary
      const success = colorUtils.adjustBrightness(primary, 0.8);
      const warning = colorUtils.getAnalogous(primary, 30); // Use an analogous color instead of yellow
      const error = colorUtils.adjustBrightness(secondary, 0.8);
      const info = colorUtils.getAnalogous(primary, -60);

      // Create the palette object
      const generatedPalette = {
        primary,
        secondary,
        accent,
        background,
        text,
        darkAccent,
        lightAccent,
        sectionBg1,
        sectionBg2,
        cardBg,
        cardBgTranslucent,
        darkCardBg,
        darkCardBgTranslucent,
        success,
        warning,
        error,
        info,
      };

      // Cache the palette
      try {
        localStorage.setItem(cacheKey, JSON.stringify(generatedPalette));
      } catch (e) {
        console.error("Error caching palette:", e);
      }

      // Add the function after serialization
      generatedPalette.getContrastText = (color) =>
        colorUtils.getContrastText(color);

      return generatedPalette;
    },
  };

  // Create optimized color palette
  const brandColors = profileData?.brandStyle?.colors || {};
  const optimizedPalette = colorUtils.createPalette(brandColors);

  // Apply optimized colors
  const primaryColor = optimizedPalette.primary;
  const secondaryColor = optimizedPalette.secondary;
  const accentColor = optimizedPalette.accent;
  const backgroundColor = "#111111"; // Use dark background
  const textColor = "rgba(255, 255, 255, 0.7)"; // Semi-transparent white
  const darkAccentColor = optimizedPalette.darkAccent;
  const lightAccentColor = optimizedPalette.lightAccent;
  const cardBg = "rgba(255, 255, 255, 0.05)"; // Translucent white
  const cardBgTranslucent = "rgba(255, 255, 255, 0.05)";
  const darkCardBg = "rgba(0, 0, 0, 0.3)";
  const darkCardBgTranslucent = "rgba(0, 0, 0, 0.3)";

  // Ensure text colors have proper contrast with their backgrounds
  const headerTextColor = "#ffffff"; // White for headers
  const bodyTextColor = "rgba(255, 255, 255, 0.7)"; // Semi-transparent white for body
  const cardText = "rgba(255, 255, 255, 0.7)";
  const darkCardText = "rgba(255, 255, 255, 0.7)";

  const brandFonts = profileData?.brandStyle?.fonts || {};
  const primaryFont = brandFonts.primary || "inherit";
  const secondaryFont = brandFonts.secondary || "inherit";

  const headerStyle = {
    fontFamily: primaryFont,
    color: primaryColor,
  };

  const textStyle = {
    fontFamily: secondaryFont,
    color: textColor,
  };

  // Render content based on active tab
  const renderContent = () => {
    if (activeTab === "brandDiscovery") {
      return (
        <>
          {/* Brand Background Section */}
          {profileData && profileData.brandBackground && (
            <BrandBackgroundSection
              background={profileData.brandBackground}
              scrollProgress={scrollYProgress}
              headerStyle={headerStyle}
              textStyle={textStyle}
              profileId={documentId}
              companyUrl={profileData.brandInfo?.companyUrl}
              backgroundImage={getImageUrl(
                heroImages,
                "background",
                "var(--background-color)"
              )}
              palette={optimizedPalette}
            />
          )}

          {/* Brand Strategy Section */}
          {profileData && profileData.brandStrategy && (
            <BrandStrategySection
              strategy={profileData.brandStrategy}
              scrollProgress={scrollYProgress}
              headerStyle={headerStyle}
              textStyle={textStyle}
              profileId={documentId}
              companyUrl={profileData.brandInfo?.companyUrl}
              backgroundImage={getImageUrl(
                otherImages,
                "strategy",
                "var(--background-color)"
              )}
              palette={optimizedPalette}
            />
          )}

          {/* Demographics Section */}
          {profileData && profileData.demographics && (
            <DemographicsSection
              demographics={profileData.demographics}
              scrollProgress={scrollYProgress}
              headerStyle={headerStyle}
              textStyle={textStyle}
              profileId={documentId}
              companyUrl={profileData.brandInfo?.companyUrl}
              backgroundImage={getImageUrl(
                otherImages,
                "demographics",
                "var(--background-color)"
              )}
              palette={optimizedPalette}
            />
          )}

          {/* Brand Personality Section */}
          {profileData && profileData.brandPersonality && (
            <BrandPersonalitySection
              personality={profileData.brandPersonality}
              scrollProgress={scrollYProgress}
              headerStyle={headerStyle}
              textStyle={textStyle}
              profileId={documentId}
              companyUrl={profileData.brandInfo?.companyUrl}
              backgroundImage={getImageUrl(
                otherImages,
                "personality",
                "var(--background-color)"
              )}
              palette={optimizedPalette}
            />
          )}

          {/* Brand Sound Section */}
          {profileData && profileData.brandSound && (
            <BrandSoundSection
              brandSound={profileData.brandSound}
              scrollProgress={scrollYProgress}
              headerStyle={headerStyle}
              textStyle={textStyle}
              profileId={documentId}
              companyUrl={profileData.brandInfo?.companyUrl}
              backgroundImage={getImageUrl(
                otherImages,
                "sound",
                "var(--accent-color)"
              )}
              palette={optimizedPalette}
            />
          )}

          {/* Characteristics Section */}
          {profileData &&
            (profileData.characteristics ||
              profileData.brandCharacteristics) && (
              <CharacteristicsSection
                characteristics={
                  profileData.characteristics ||
                  profileData.brandCharacteristics
                }
                expandedCategories={expandedCategories}
                toggleCategory={toggleCategory}
                profileId={documentId}
                companyUrl={profileData.brandInfo?.companyUrl}
                backgroundImage={getImageUrl(
                  otherImages,
                  "characteristics",
                  "var(--background-color)"
                )}
                palette={optimizedPalette}
              />
            )}

          {/* Project Goals Section */}
          {profileData && profileData.projectGoals && (
            <ProjectGoalsSection
              projectGoals={profileData.projectGoals}
              scrollProgress={scrollYProgress}
              headerStyle={headerStyle}
              textStyle={textStyle}
              profileId={documentId}
              companyUrl={profileData.brandInfo?.companyUrl}
              backgroundImage={getImageUrl(
                otherImages,
                "goals",
                "var(--background-color)"
              )}
              palette={optimizedPalette}
            />
          )}
        </>
      );
    } else if (activeTab === "soundboard") {
      return (
        <SoundboardSection
          description={profileData?.soundboard?.description}
          timeline={profileData?.soundboard?.timeline}
          audioFiles={profileData?.soundboard?.audioFiles}
          notes={profileData?.soundboard?.notes}
          scrollProgress={scrollYProgress}
          headerStyle={headerStyle}
          textStyle={textStyle}
          profileId={documentId}
          companyUrl={profileData?.brandInfo?.companyUrl}
          backgroundImage={
            // First try to use a soundboard image
            (soundboardImages.length > 0 &&
              getImageUrl(
                soundboardImages,
                "soundboard",
                "var(--accent-color)"
              )) ||
            // Then try fallbacks to other image types
            getImageUrl(otherImages, "sound", "var(--accent-color)") ||
            getImageUrl(heroImages, "hero", "var(--accent-color)")
          }
          palette={optimizedPalette}
        />
      );
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-white text-xl">Loading brand profile...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className="relative min-h-screen bg-[#111111] text-white overflow-hidden"
      style={{
        "--primary-color": primaryColor,
        "--secondary-color": secondaryColor,
        "--accent-color": accentColor,
        "--background-color": backgroundColor,
        "--header-font": headerStyle.fontFamily || "sans-serif",
        "--body-font": textStyle.fontFamily || "sans-serif",
      }}
    >
      {/* Global styles */}
      <style>
        {`
          body {
            background-color: #111111;
            color: white;
          }
          .shadow-glow {
            box-shadow: 0 0 10px 0 var(--primary-color);
          }
          .shadow-sm-glow {
            box-shadow: 0 0 5px 0 var(--primary-color);
          }
        `}
      </style>

      {/* Hero Section */}
      {profileData && profileData.brandInfo && (
        <HeroSection
          brandName={profileData.brandInfo.companyName}
          industry={profileData.brandInfo.industry}
          scrollProgress={scrollYProgress}
          headerStyle={headerStyle}
          textStyle={textStyle}
          heroImage={getImageUrl(heroImages, "hero")}
          logoImage={logoImages.length > 0 ? logoImages[0].url : null}
          palette={optimizedPalette}
          profileId={documentId}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      {/* Render content based on active tab */}
      {renderContent()}
    </div>
  );
};

export default BrandProfile;
