import React, { useRef, useMemo, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  Target,
  ClipboardList,
  Calendar,
  Users,
  BarChart4,
  Flag,
  Zap,
  CheckCircle,
  ChevronRight,
  ChevronLeft,
  Clock,
  DollarSign,
  TrendingUp,
  Sparkles,
  ArrowRight,
} from "lucide-react";
import NarrativeSection from "../NarrativeSection";
import MetricCard from "./MetricCard";

// Interactive Project Goal Card
const ProjectGoalCard = ({ item, index, active, onClick }) => {
  // Different background colors for visual variety
  const bgGradients = [
    "linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.03) 100%)",
    "linear-gradient(135deg, rgba(var(--primary-color-rgb), 0.12) 0%, rgba(var(--primary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.12) 0%, rgba(var(--secondary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--accent-color-rgb), 0.12) 0%, rgba(var(--accent-color-rgb), 0.06) 100%)",
  ];

  const gradient = bgGradients[index % bgGradients.length];
  const delayAnimation = index * 0.1;

  // Split long text into chunks for better readability when expanded
  const formatExpandedText = (text) => {
    if (!text) return ["No information available"];

    // If text is shorter than 200 chars, don't split it
    if (text.length < 200) return [text];

    // Split by sentences for better paragraph breaks
    const sentences = text.split(/(?<=[.!?])\s+/);
    const paragraphs = [];
    let currentParagraph = "";

    sentences.forEach((sentence) => {
      if (currentParagraph.length + sentence.length > 300) {
        paragraphs.push(currentParagraph);
        currentParagraph = sentence;
      } else {
        currentParagraph += (currentParagraph ? " " : "") + sentence;
      }
    });

    if (currentParagraph) {
      paragraphs.push(currentParagraph);
    }

    return paragraphs;
  };

  const paragraphs = formatExpandedText(item.value);

  return (
    <motion.div
      className={`rounded-xl overflow-hidden backdrop-blur-sm border border-white/10 cursor-pointer transition-all duration-300 ${
        active ? "col-span-full" : ""
      }`}
      style={{
        background: gradient,
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: delayAnimation }}
      onClick={onClick}
      whileHover={{
        scale: active ? 1 : 1.02,
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <motion.div
        className="p-6"
        initial={false}
        animate={{
          padding: active ? "1.5rem" : "1.5rem",
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <motion.div
              className="w-10 h-10 rounded-full flex items-center justify-center mr-3 text-white"
              style={{ backgroundColor: "var(--primary-color)" }}
              whileHover={{ scale: 1.1 }}
            >
              {item.icon}
            </motion.div>
            <h3 className="text-xl font-bold text-white">{item.title}</h3>
          </div>
          <motion.div
            animate={{ rotate: active ? 90 : 0 }}
            transition={{ duration: 0.3 }}
            className="text-white/60"
          >
            <ChevronRight size={20} />
          </motion.div>
        </div>

        {/* Status label or priority */}
        {item.status && (
          <div className="ml-0 mt-1.5 mb-3 inline-flex items-center px-3 py-1.5 rounded-full bg-green-400/20">
            <CheckCircle size={14} className="text-green-400 mr-1.5" />
            <span className="text-xs text-green-400 font-medium">
              {item.status}
            </span>
          </div>
        )}
        {item.priority && (
          <div className="ml-0 mt-1.5 mb-3 inline-flex items-center px-3 py-1.5 rounded-full bg-yellow-400/20">
            <Flag size={14} className="text-yellow-400 mr-1.5" />
            <span className="text-xs text-yellow-400 font-medium">
              {item.priority}
            </span>
          </div>
        )}

        <AnimatePresence>
          {!active && (
            <motion.div
              className="text-white/70 line-clamp-3 text-sm"
              exit={{ opacity: 0, height: 0 }}
            >
              {item.value || "No information available"}
            </motion.div>
          )}

          {active && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="mt-4 space-y-4"
            >
              {paragraphs.map((paragraph, i) => (
                <motion.p
                  key={i}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: i * 0.1 }}
                  className="text-white/80 leading-relaxed"
                >
                  {paragraph}
                </motion.p>
              ))}

              {/* Timeline if available */}
              {item.timeline && (
                <motion.div
                  className="flex items-center gap-3 mt-4 px-4 py-3 rounded-lg bg-white/5 border border-white/10"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <Calendar size={18} className="text-[var(--primary-color)]" />
                  <div>
                    <h4 className="text-sm font-medium text-white/80">
                      Timeline
                    </h4>
                    <p className="text-white/90">{item.timeline}</p>
                  </div>
                </motion.div>
              )}

              {/* Success Metrics if available */}
              {item.metrics && item.metrics.length > 0 && (
                <motion.div
                  className="space-y-3 mt-4"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <h4 className="text-md font-medium text-white flex items-center gap-2">
                    <TrendingUp
                      size={16}
                      className="text-[var(--primary-color)]"
                    />
                    Success Metrics
                  </h4>
                  <div className="grid grid-cols-1 gap-2">
                    {item.metrics.map((metric, i) => (
                      <div
                        key={i}
                        className="p-3 rounded-lg border border-white/10 bg-white/5 flex items-center gap-3"
                      >
                        <div className="w-6 h-6 rounded-full bg-[var(--primary-color-30)] flex items-center justify-center">
                          <CheckCircle size={14} className="text-white" />
                        </div>
                        <span className="text-sm text-white/80">{metric}</span>
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}

              {/* Additional details if available */}
              {item.details && (
                <motion.div
                  className="p-4 rounded-lg bg-gradient-to-r from-[var(--primary-color-20)] to-transparent border border-[var(--primary-color-30)] mt-4"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Zap size={16} className="text-[var(--primary-color)]" />
                    <h4 className="text-white/90 font-medium">
                      Additional Details
                    </h4>
                  </div>
                  <p className="text-white/80 text-sm leading-relaxed">
                    {item.details}
                  </p>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

// Project Milestones Timeline Component
const ProjectTimeline = ({ title, deadlines }) => {
  // Parse deadlines to extract milestones
  const milestones = useMemo(() => {
    if (!deadlines) return [];

    // Try to extract dates and milestones from text
    const dateRegex =
      /(\d{1,2}[\/\-\.]\d{1,2}[\/\-\.]\d{2,4}|\w+ \d{1,2}(?:st|nd|rd|th)?,? \d{4}?|\w+ \d{4})/gi;

    // Try to find dates in the text
    const dates = deadlines.match(dateRegex) || [];

    // If we found dates, create milestone objects
    if (dates.length > 0) {
      const milestoneItems = [];

      // Extract text segments around dates to use as descriptions
      let lastIndex = 0;
      let text = deadlines;

      dates.forEach((date, index) => {
        const dateIndex = text.indexOf(date, lastIndex);
        if (dateIndex !== -1) {
          // Get text before this date (but after previous date) as description
          let description = text.substring(lastIndex, dateIndex).trim();

          // Clean up description
          description = description.replace(/^[:\-–—,;.]+/, "").trim();

          if (description) {
            milestoneItems.push({
              date,
              description,
            });
          } else if (index > 0) {
            // If no description, associate with previous milestone
            milestoneItems.push({
              date,
              description: "Next milestone",
            });
          }

          lastIndex = dateIndex + date.length;
        }
      });

      // Get any remaining text after the last date
      const remainingText = text.substring(lastIndex).trim();
      if (remainingText && remainingText.length > 3) {
        milestoneItems.push({
          date: "Final Completion",
          description: remainingText.replace(/^[:\-–—,;.]+/, "").trim(),
        });
      }

      return milestoneItems;
    }

    // If no dates found, create a single milestone with the full text
    return [
      {
        date: "Project Timeline",
        description: deadlines,
      },
    ];
  }, [deadlines]);

  // If no valid deadlines, don't render
  if (!deadlines) return null;

  return (
    <motion.div
      className="p-6 rounded-xl border border-white/10 bg-white/5 backdrop-blur-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex items-center gap-3 mb-5">
        <div
          className="p-2.5 rounded-lg"
          style={{ backgroundColor: "var(--primary-color-20)" }}
        >
          <Calendar size={20} className="text-white" />
        </div>
        <h3 className="text-xl font-bold text-white">{title}</h3>
      </div>

      {/* Timeline Visualization */}
      <div className="relative pl-6 mt-8 space-y-8 before:absolute before:top-0 before:bottom-0 before:left-[11px] before:w-[2px] before:bg-gradient-to-b before:from-[var(--primary-color)] before:to-[var(--accent-color)]">
        {milestones.map((milestone, index) => (
          <motion.div
            key={index}
            className="relative"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5 + index * 0.1 }}
          >
            {/* Milestone dot */}
            <div className="absolute top-1 left-[-24px] w-6 h-6 rounded-full bg-[var(--primary-color)] shadow-lg shadow-[var(--primary-color-30)] flex items-center justify-center">
              <div className="w-2 h-2 rounded-full bg-white" />
            </div>

            {/* Milestone content */}
            <div>
              <h4 className="text-white font-semibold mb-1">
                {milestone.date}
              </h4>
              <p className="text-white/70 text-sm">{milestone.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

const ProjectGoalsSection = ({
  projectGoals,
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [showNarrative, setShowNarrative] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  // Effect to manage narrative visibility on mobile when a card is expanded
  React.useEffect(() => {
    if (activeCardIndex !== null && window.innerWidth < 768) {
      setShowNarrative(false);
    } else {
      setShowNarrative(true);
    }
  }, [activeCardIndex]);

  // If narrative visibility is explicitly set to true in projectGoals settings, always show it
  React.useEffect(() => {
    if (projectGoals?.visibility?.narrative === true) {
      setShowNarrative(true);
    }
  }, [projectGoals?.visibility?.narrative]);

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(sectionProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const bgOpacity = useTransform(sectionProgress, [0, 0.5, 1], [0.5, 0.7, 0.5]);
  const bgBlur = useTransform(sectionProgress, [0, 0.5, 1], [0, 3, 0]);

  // Mobile pagination settings
  const CARDS_PER_SLIDE = 3;

  // Prepare data for the narrative generation
  const projectGoalsData = useMemo(
    () => ({
      mainGoal: projectGoals?.mainGoal,
      processExpectations: projectGoals?.processExpectations,
      deadlines: projectGoals?.deadlines,
      teamInvolvement: projectGoals?.teamInvolvement,
      successMetrics: projectGoals?.successMetrics,
      budget: projectGoals?.budget,
      additionalInfo: projectGoals?.additionalInfo,
    }),
    [
      projectGoals?.mainGoal,
      projectGoals?.processExpectations,
      projectGoals?.deadlines,
      projectGoals?.teamInvolvement,
      projectGoals?.successMetrics,
      projectGoals?.budget,
      projectGoals?.additionalInfo,
    ]
  );

  if (!projectGoals) return null;

  // Helper function to parse success metrics into array
  const parseMetrics = (metricsText) => {
    if (!metricsText) return [];
    // Split by common delimiters and filter empty items
    return metricsText
      .split(/[,;•\n]/)
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
  };

  // Define formatted project goal items for display
  const allGoalItems = [
    {
      icon: <Target size={20} className="text-white" />,
      title: "Primary Goal",
      value: projectGoals.mainGoal,
      priority: "High Priority",
      details:
        "This is the core objective that will guide all project decisions and evaluations.",
      field: "mainGoal",
    },
    {
      icon: <ClipboardList size={20} className="text-white" />,
      title: "Process Expectations",
      value: projectGoals.processExpectations,
      status: "In Progress",
      timeline: projectGoals.deadlines ? "See Timeline" : undefined,
      field: "processExpectations",
    },
    {
      icon: <Calendar size={20} className="text-white" />,
      title: "Timeline",
      value: projectGoals.deadlines,
      timeline: projectGoals.deadlines,
      field: "deadlines",
    },
    {
      icon: <Users size={20} className="text-white" />,
      title: "Team Involvement",
      value:
        projectGoals.teamInvolvement === "very-involved"
          ? "Very involved - The team needs to be involved in every step of the process"
          : projectGoals.teamInvolvement === "somewhat-involved"
          ? "Somewhat involved - The team should be involved at key milestones"
          : projectGoals.teamInvolvement === "light-involvement"
          ? "Light involvement - The team only needs to be involved in major decisions"
          : "Minimal - The team only needs to see final results",
      field: "teamInvolvement",
    },
    {
      icon: <BarChart4 size={20} className="text-white" />,
      title: "Success Metrics",
      value: projectGoals.successMetrics,
      metrics: parseMetrics(projectGoals.successMetrics),
      status: "Target",
      field: "successMetrics",
    },
    {
      icon: <DollarSign size={20} className="text-white" />,
      title: "Budget",
      value: projectGoals.budget,
      priority: projectGoals.budget ? "Allocated" : "To Be Determined",
      field: "budget",
    },
  ];

  // Filter items based on visibility settings
  const goalItems = allGoalItems.filter((item) => {
    // Check if there's a visibility setting for this field
    // If visibility is undefined or the specific field is undefined, default to showing the item
    const visibility = projectGoals.visibility;
    if (!visibility) return true;

    // Check if the field is specifically set to false
    return visibility[item.field] !== false;
  });

  // Check if narrative visibility is specifically turned off
  const showNarrativeSection = projectGoals.visibility?.narrative !== false;

  // Check if timeline visibility is specifically turned off
  const showTimeline = projectGoals.visibility?.deadlines !== false;

  // Check if additional info visibility is specifically turned off
  const showAdditionalInfo =
    projectGoals.visibility?.additionalInfo !== false &&
    projectGoals.additionalInfo;

  // Group items into slides for mobile navigation
  const goalItemsFiltered = goalItems.filter(
    (item) => item.title !== "Timeline"
  );
  const itemSlides = [];
  for (let i = 0; i < goalItemsFiltered.length; i += CARDS_PER_SLIDE) {
    itemSlides.push(goalItemsFiltered.slice(i, i + CARDS_PER_SLIDE));
  }

  const handleCardClick = (index) => {
    setActiveCardIndex(activeCardIndex === index ? null : index);
    // Only hide narrative when a card is expanded if the narrative is not explicitly set to be visible
    if (projectGoals.visibility?.narrative !== true) {
      setShowNarrative(index === activeCardIndex);
    }
  };

  const nextSlide = () => {
    setActiveSlide((prev) => (prev < itemSlides.length - 1 ? prev + 1 : 0));
  };

  const prevSlide = () => {
    setActiveSlide((prev) => (prev > 0 ? prev - 1 : itemSlides.length - 1));
  };

  // If no items are visible, don't render the section
  if (
    goalItems.length === 0 &&
    !showNarrativeSection &&
    !showTimeline &&
    !showAdditionalInfo
  ) {
    return null;
  }

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-24 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Dot pattern background with dynamic blur */}
      <motion.div
        className="absolute inset-0 -z-10"
        style={{ filter: `blur(${bgBlur}px)` }}
      >
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </motion.div>

      {/* Goal pattern overlay */}
      <div className="absolute inset-0 -z-10 opacity-5">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMSI+PGNpcmNsZSBjeD0iMTAwIiBjeT0iMTAwIiByPSI4MCIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjEwMCIgcj0iNjAiLz48Y2lyY2xlIGN4PSIxMDAiIGN5PSIxMDAiIHI9IjQwIi8+PGNpcmNsZSBjeD0iMTAwIiBjeT0iMTAwIiByPSIyMCIvPjxsaW5lIHgxPSIyMCIgeTE9IjEwMCIgeDI9IjE4MCIgeTI9IjEwMCIvPjxsaW5lIHgxPSIxMDAiIHkxPSIyMCIgeDI9IjEwMCIgeTI9IjE4MCIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjEwMCIgcj0iMTAiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+')",
            backgroundSize: "300px 300px",
          }}
        ></div>
      </div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: bgOpacity,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 max-w-7xl mx-auto px-6"
        style={{ scale }}
      >
        <motion.h2
          className="text-5xl md:text-7xl mb-16 font-bold text-white"
          style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Project Goals
        </motion.h2>

        {/* Main Content */}
        <div className="space-y-10">
          {/* Desktop View: Interactive Cards Grid */}
          <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 auto-rows-min">
            {goalItemsFiltered.map((item, index) => (
              <ProjectGoalCard
                key={index}
                item={item}
                index={index}
                active={activeCardIndex === index}
                onClick={() => handleCardClick(index)}
              />
            ))}
          </div>

          {/* Mobile View: Interactive Cards Carousel */}
          <div className="md:hidden relative">
            <div className="flex justify-between items-center mb-4">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={prevSlide}
                className="bg-white/10 rounded-full p-2"
              >
                <ChevronLeft className="text-white" size={20} />
              </motion.button>

              <div className="text-white/60 text-sm">
                {activeSlide + 1} / {itemSlides.length}
              </div>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={nextSlide}
                className="bg-white/10 rounded-full p-2"
              >
                <ChevronRight className="text-white" size={20} />
              </motion.button>
            </div>

            <AnimatePresence mode="wait">
              <motion.div
                key={activeSlide}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.3 }}
                className="space-y-4"
              >
                {itemSlides[activeSlide]?.map((item, index) => (
                  <ProjectGoalCard
                    key={index}
                    item={item}
                    index={index + activeSlide * CARDS_PER_SLIDE}
                    active={
                      activeCardIndex === index + activeSlide * CARDS_PER_SLIDE
                    }
                    onClick={() =>
                      handleCardClick(index + activeSlide * CARDS_PER_SLIDE)
                    }
                  />
                ))}
              </motion.div>
            </AnimatePresence>

            {/* Pagination dots */}
            <div className="flex justify-center mt-6 space-x-2">
              {itemSlides.map((_, index) => (
                <motion.button
                  key={index}
                  className="w-2 h-2 rounded-full"
                  style={{
                    backgroundColor:
                      activeSlide === index
                        ? "var(--primary-color)"
                        : "rgba(255, 255, 255, 0.3)",
                  }}
                  onClick={() => setActiveSlide(index)}
                  whileHover={{ scale: 1.3 }}
                />
              ))}
            </div>
          </div>

          {/* Narrative Section */}
          <AnimatePresence>
            {showNarrative && showNarrativeSection && (
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 40 }}
                transition={{ duration: 0.5 }}
                className="mt-16"
              >
                <motion.div
                  className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 overflow-hidden"
                  whileHover={{
                    boxShadow: "0 0 30px rgba(var(--primary-color-rgb), 0.15)",
                  }}
                >
                  <div className="mb-6 flex items-center justify-between">
                    <h3 className="text-2xl font-bold text-white flex items-center gap-3">
                      <Flag className="text-[var(--primary-color)]" size={22} />
                      Project Overview
                    </h3>
                  </div>

                  <NarrativeSection
                    profileId={profileId}
                    section="projectGoals"
                    sectionData={projectGoalsData}
                    companyUrl={companyUrl}
                    fallbackText="Analyzing project goals..."
                    textColor="rgba(255, 255, 255, 0.9)"
                    accentColor="var(--primary-color)"
                    style={{
                      minHeight: "300px",
                      fontSize: "1.2rem",
                      lineHeight: "1.8",
                      fontWeight: "500",
                    }}
                  />
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Project Timeline Visualization */}
          {showTimeline && projectGoals.deadlines && (
            <ProjectTimeline
              title="Project Timeline"
              deadlines={projectGoals.deadlines}
            />
          )}

          {/* Additional Info Section */}
          {showAdditionalInfo && (
            <motion.div
              className="mt-10 p-8 rounded-xl border border-white/10 bg-gradient-to-br from-[rgba(var(--primary-color-rgb),0.2)] to-transparent backdrop-blur-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="p-3 rounded-full bg-[var(--primary-color-30)]">
                  <Sparkles size={22} className="text-white" />
                </div>
                <h3 className="text-2xl font-bold text-white">
                  Additional Information
                </h3>
              </div>

              <p className="text-white/90 text-lg leading-relaxed whitespace-pre-line">
                {projectGoals.additionalInfo}
              </p>
            </motion.div>
          )}
        </div>
      </motion.div>
    </motion.section>
  );
};

export default ProjectGoalsSection;
