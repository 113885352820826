import React, { useRef, useMemo, useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  ClipboardList,
  Users,
  Sunrise,
  Repeat,
  Target,
  Sparkles,
  ChevronRight,
  ChevronLeft,
  ArrowRight,
  AlertCircle,
  Award,
  Flag,
  TrendingUp,
  Frown,
  Heart,
  Trophy,
} from "lucide-react";
import NarrativeSection from "../NarrativeSection";

const InteractiveMetricCard = ({
  item,
  index,
  headerStyle,
  isExpanded,
  onToggle,
}) => {
  // Different background colors for visual variety
  const bgGradients = [
    "linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.03) 100%)",
    "linear-gradient(135deg, rgba(var(--primary-color-rgb), 0.12) 0%, rgba(var(--primary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.12) 0%, rgba(var(--secondary-color-rgb), 0.06) 100%)",
    "linear-gradient(135deg, rgba(var(--accent-color-rgb), 0.12) 0%, rgba(var(--accent-color-rgb), 0.06) 100%)",
  ];

  const gradient = bgGradients[index % bgGradients.length];

  // Split long text into chunks for better readability when expanded
  const formatExpandedText = (text) => {
    if (!text) return ["No information available"];

    // If text is shorter than 200 chars, don't split it
    if (text.length < 200) return [text];

    // Split by sentences for better paragraph breaks
    const sentences = text.split(/(?<=[.!?])\s+/);
    const paragraphs = [];
    let currentParagraph = "";

    sentences.forEach((sentence) => {
      if (currentParagraph.length + sentence.length > 300) {
        paragraphs.push(currentParagraph);
        currentParagraph = sentence;
      } else {
        currentParagraph += (currentParagraph ? " " : "") + sentence;
      }
    });

    if (currentParagraph) {
      paragraphs.push(currentParagraph);
    }

    return paragraphs;
  };

  // Display placeholder text if value is empty or undefined
  const displayValue = item.value || "No information available";
  const paragraphs = formatExpandedText(displayValue);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
      onClick={onToggle}
      className={`rounded-xl overflow-hidden backdrop-blur-sm border border-white/10 cursor-pointer transition-all duration-300 ${isExpanded ? "col-span-full" : ""
        }`}
      style={{
        background: gradient,
      }}
      whileHover={{
        scale: isExpanded ? 1 : 1.02,
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <motion.div
        className="p-6"
        initial={false}
        animate={{
          padding: isExpanded ? "1.5rem" : "1.5rem",
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <motion.div
              className="w-10 h-10 rounded-full flex items-center justify-center mr-3 text-white"
              style={{ backgroundColor: "var(--primary-color)" }}
              whileHover={{ scale: 1.1 }}
            >
              {item.icon}
            </motion.div>
            <h3 className="text-xl font-bold text-white">{item.title}</h3>
          </div>
          <motion.div
            animate={{ rotate: isExpanded ? 90 : 0 }}
            transition={{ duration: 0.3 }}
            className="text-white/60"
          >
            <ArrowRight size={20} />
          </motion.div>
        </div>

        <AnimatePresence>
          {!isExpanded && (
            <motion.div
              className="text-white/70 line-clamp-3 text-sm"
              exit={{ opacity: 0, height: 0 }}
            >
              {displayValue}
            </motion.div>
          )}

          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="mt-4 space-y-4"
            >
              {paragraphs.map((paragraph, i) => (
                <motion.p
                  key={i}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: i * 0.1 }}
                  className="text-white/80 leading-relaxed"
                >
                  {paragraph}
                </motion.p>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

const BrandBackgroundSection = ({
  background,
  scrollProgress,
  headerStyle,
  textStyle,
  profileId,
  companyUrl,
  backgroundImage,
  palette,
}) => {
  const ref = useRef(null);
  const { scrollYProgress: sectionProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [narrativeVisible, setNarrativeVisible] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  const opacity = useTransform(sectionProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(sectionProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const bgY = useTransform(sectionProgress, [0, 1], ["0%", "30%"]);
  const bgScale = useTransform(sectionProgress, [0, 1], [1, 1.1]);
  const bgBlur = useTransform(sectionProgress, [0, 0.5, 1], [0, 3, 0]);

  // Split cards into groups/slides for mobile view
  const CARDS_PER_SLIDE = 3;

  // Extract RGB values from primary color for gradient usage
  useEffect(() => {
    const primaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--primary-color")
      .trim();
    const secondaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--secondary-color")
      .trim();
    const accentColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--accent-color")
      .trim();

    const hexToRgb = (hex) => {
      // Remove # if present
      hex = hex.replace("#", "");

      // Convert 3-digit hex to 6-digits
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      // Extract r, g, b values
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return `${r}, ${g}, ${b}`;
    };

    // Try to get colors and set RGB versions
    try {
      if (primaryColor) {
        document.documentElement.style.setProperty(
          "--primary-color-rgb",
          hexToRgb(primaryColor)
        );
      }
      if (secondaryColor) {
        document.documentElement.style.setProperty(
          "--secondary-color-rgb",
          hexToRgb(secondaryColor)
        );
      }
      if (accentColor) {
        document.documentElement.style.setProperty(
          "--accent-color-rgb",
          hexToRgb(accentColor)
        );
      }
    } catch (e) {
      console.error("Error setting RGB color values:", e);
    }
  }, []);

  // If narrative visibility is explicitly set to true in background settings, always show it
  useEffect(() => {
    if (background?.visibility?.narrative === true) {
      setNarrativeVisible(true);
    }
  }, [background?.visibility?.narrative]);

  // Prepare data for the narrative generation
  const backgroundData = useMemo(
    () => ({
      brandDescription: background?.brandDescription,
      brandPersona: background?.brandPersona,
      brandOrigin: background?.brandOrigin,
      recentShifts: background?.recentShifts,
      brandMission: background?.brandMission,
      brandVision: background?.brandVision,
      brandValues: background?.brandValues,
    }),
    [
      background?.brandDescription,
      background?.brandPersona,
      background?.brandOrigin,
      background?.recentShifts,
      background?.brandMission,
      background?.brandVision,
      background?.brandValues,
    ]
  );

  // Early return for null background - must be after all hooks
  if (!background) {
    return null;
  }

  // Create the items array with all possible items
  const allItems = [
    {
      title: "Brand Description",
      value: background.brandDescription,
      icon: <ClipboardList />,
      field: "brandDescription",
    },
    {
      title: "Brand Persona",
      value: background.brandPersona,
      icon: <Users />,
      field: "brandPersona",
    },
    {
      title: "Brand Origin",
      value: background.brandOrigin,
      icon: <Sunrise />,
      field: "brandOrigin",
    },
    {
      title: "Recent Shifts",
      value: background.recentShifts,
      icon: <Repeat />,
      field: "recentShifts",
    },
    {
      title: "Upcoming Shifts",
      value: background.upcomingShifts,
      icon: <TrendingUp />,
      field: "upcomingShifts",
    },
    {
      title: "Recent Excitement",
      value: background.recentExcitement,
      icon: <Sparkles />,
      field: "recentExcitement",
    },
    {
      title: "Upcoming Excitement",
      value: background.upcomingExcitement,
      icon: <Flag />,
      field: "upcomingExcitement",
    },
    {
      title: "Biggest Fans",
      value: background.biggestFans,
      icon: <Heart />,
      field: "biggestFans",
    },
    {
      title: "Target Market",
      value: background.isTargetMarket,
      icon: <Target />,
      field: "isTargetMarket",
    },
    {
      title: "Competitors",
      value: background.competitors,
      icon: <Trophy />,
      field: "competitors",
    },
    {
      title: "Challenges",
      value: background.challenges,
      icon: <AlertCircle />,
      field: "challenges",
    },
  ];

  // Filter items based on visibility settings
  const items = allItems.filter((item) => {
    // Check if there's a visibility setting for this field
    // If visibility is undefined or the specific field is undefined, default to showing the item
    const visibility = background.visibility;
    if (!visibility) return true;

    // Check if the field is specifically set to false
    return visibility[item.field] !== false;
  });

  // Debug log for items
  // console.log("Brand Background Section items:", { allItems: allItems.map((item) => item.field), visibleItems: items.map((item) => item.field), visibility: background.visibility });

  // Group items into slides for mobile navigation
  const itemSlides = [];
  for (let i = 0; i < items.length; i += CARDS_PER_SLIDE) {
    itemSlides.push(items.slice(i, i + CARDS_PER_SLIDE));
  }

  const toggleCard = (index) => {
    setExpandedCardIndex(index === expandedCardIndex ? null : index);
    // Only hide narrative when a card is expanded if the narrative is not explicitly set to be visible
    if (background.visibility?.narrative !== true) {
      setNarrativeVisible(index === expandedCardIndex);
    }
  };

  const nextSlide = () => {
    setActiveSlide((prev) => (prev < itemSlides.length - 1 ? prev + 1 : 0));
  };

  const prevSlide = () => {
    setActiveSlide((prev) => (prev > 0 ? prev - 1 : itemSlides.length - 1));
  };

  // Check if narrative visibility is specifically turned off
  const showNarrative = background.visibility?.narrative !== false;

  // Handle no items case
  if (items.length === 0) {
    return null;
  }

  // Reset active slide if the current slide index is now out of bounds
  // (This can happen if visibility toggles reduce the number of slides)
  if (itemSlides.length === 0) {
    setActiveSlide(0);
  } else if (activeSlide >= itemSlides.length) {
    setActiveSlide(itemSlides.length - 1);
  }

  return (
    <motion.section
      ref={ref}
      className="min-h-screen relative flex items-center justify-center py-20 overflow-hidden"
      style={{
        opacity,
        backgroundColor: "#111111",
        color: "rgba(255, 255, 255, 0.7)",
      }}
    >
      {/* Dot pattern background with dynamic blur */}
      <motion.div
        className="absolute inset-0 -z-10"
        style={{ filter: `blur(${bgBlur}px)` }}
      >
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px",
          }}
        ></div>
      </motion.div>

      {/* Background image with parallax effect */}
      {backgroundImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            y: bgY,
            scale: bgScale,
            opacity: 0.7,
            filter: "contrast(1.1) brightness(1.05)",
            mixBlendMode: "soft-light",
          }}
        />
      )}

      <motion.div
        className="relative z-10 max-w-7xl mx-auto px-6"
        style={{ scale }}
      >
        <motion.h2
          className="text-5xl md:text-7xl mb-10 font-bold text-white"
          style={{ textShadow: "0 2px 6px rgba(0, 0, 0, 0.5)" }}
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Brand Background
        </motion.h2>

        {/* Main Content */}
        <div className="space-y-10">
          {/* Desktop View: Interactive Cards Grid */}
          <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 auto-rows-min">
            {items.map((item, index) => (
              <InteractiveMetricCard
                key={index}
                item={item}
                index={index}
                headerStyle={headerStyle}
                isExpanded={expandedCardIndex === index}
                onToggle={() => toggleCard(index)}
              />
            ))}
          </div>

          {/* Mobile View: Interactive Cards Carousel */}
          <div className="md:hidden relative">
            <div className="flex justify-between items-center mb-4">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={prevSlide}
                className="bg-white/10 rounded-full p-2"
                disabled={itemSlides.length <= 1}
              >
                <ChevronLeft
                  className={`${itemSlides.length <= 1 ? "text-white/30" : "text-white"
                    }`}
                  size={20}
                />
              </motion.button>

              <div className="text-white/60 text-sm">
                {itemSlides.length > 0
                  ? `${activeSlide + 1} / ${itemSlides.length}`
                  : "0 / 0"}
              </div>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={nextSlide}
                className="bg-white/10 rounded-full p-2"
                disabled={itemSlides.length <= 1}
              >
                <ChevronRight
                  className={`${itemSlides.length <= 1 ? "text-white/30" : "text-white"
                    }`}
                  size={20}
                />
              </motion.button>
            </div>

            <AnimatePresence mode="wait">
              {itemSlides.length > 0 ? (
                <motion.div
                  key={activeSlide}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }}
                  className="space-y-4"
                >
                  {itemSlides[activeSlide].map((item, index) => (
                    <InteractiveMetricCard
                      key={index}
                      item={item}
                      index={index + activeSlide * CARDS_PER_SLIDE}
                      headerStyle={headerStyle}
                      isExpanded={
                        expandedCardIndex ===
                        index + activeSlide * CARDS_PER_SLIDE
                      }
                      onToggle={() =>
                        toggleCard(index + activeSlide * CARDS_PER_SLIDE)
                      }
                    />
                  ))}
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="p-8 text-center bg-white/5 rounded-lg border border-white/10"
                >
                  <p className="text-white/70">
                    No content is available to display.
                  </p>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Pagination dots - only show if there are multiple slides */}
            {itemSlides.length > 1 && (
              <div className="flex justify-center mt-6 space-x-2">
                {itemSlides.map((_, index) => (
                  <motion.button
                    key={index}
                    className="w-2 h-2 rounded-full"
                    style={{
                      backgroundColor:
                        activeSlide === index
                          ? "var(--primary-color)"
                          : "rgba(255, 255, 255, 0.3)",
                    }}
                    onClick={() => setActiveSlide(index)}
                    whileHover={{ scale: 1.3 }}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Narrative Section */}
          <AnimatePresence>
            {(narrativeVisible || background.visibility?.narrative === true) &&
              showNarrative && (
                <motion.div
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 40 }}
                  transition={{ duration: 0.5 }}
                  className="mt-16"
                >
                  <motion.div
                    className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 overflow-hidden"
                    whileHover={{
                      boxShadow:
                        "0 0 30px rgba(var(--primary-color-rgb), 0.15)",
                    }}
                  >
                    <NarrativeSection
                      profileId={profileId}
                      section="brandBackground"
                      sectionData={backgroundData}
                      companyUrl={companyUrl}
                      fallbackText="Analyzing brand background..."
                      textColor="rgba(255, 255, 255, 0.9)"
                      accentColor="var(--primary-color)"
                      style={{
                        minHeight: "300px",
                        fontSize: "1.2rem",
                        lineHeight: "1.8",
                        fontWeight: "500",
                      }}
                    />
                  </motion.div>
                </motion.div>
              )}
          </AnimatePresence>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default BrandBackgroundSection;
