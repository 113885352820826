/**
 * Service for handling API calls to backend services
 */

import { db, storage } from "../../../firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadString } from "firebase/storage";

// Cloud Function URL - Replace with your actual deployed function URL when available
const CLOUD_FUNCTION_URL = process.env.REACT_APP_CLOUD_FUNCTION_URL || 'https://REGION-PROJECT_ID.cloudfunctions.net/analyzeWebsite';

// Debug environment variables
// console.log('Environment Variables:');
// console.log('REACT_APP_CLOUD_FUNCTION_URL:', process.env.REACT_APP_CLOUD_FUNCTION_URL);
// console.log('REACT_APP_USE_LOCAL_SERVER:', process.env.REACT_APP_USE_LOCAL_SERVER);

/**
 * Analyze a website URL to extract brand information
 * @param {string} url - The website URL to analyze
 * @returns {Promise<object>} - The analysis results
 */
export const analyzeWebsite = async (url) => {
  try {
    // console.log('API Service: Analyzing website:', url);

    // Check if we should use the local server for development
    const useLocalServer = process.env.REACT_APP_USE_LOCAL_SERVER === 'true';
    const apiUrl = useLocalServer
      ? 'http://localhost:5000/api/analyze-website'
      : CLOUD_FUNCTION_URL;

    // console.log(`API Service: Using ${useLocalServer ? 'local server' : 'cloud function'} at ${apiUrl}`);

    // Call our API to analyze the website with the full URL
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ url }),
    });

    // console.log('API Service: Response status:', response.status);

    if (!response.ok) {
      // console.error('API Service: Response not OK:', response.status, response.statusText);
      let errorMessage = 'Failed to analyze website';
      try {
        const errorData = await response.json();
        errorMessage = errorData.error || errorMessage;
      } catch (e) {
        // console.error('API Service: Failed to parse error response:', e);
      }
      throw new Error(errorMessage);
    }

    try {
      const results = await response.json();
      // console.log('API Service: Received results:', results);
      return results;
    } catch (e) {
      // console.error('API Service: Failed to parse response:', e);
      throw new Error('Failed to parse response from server');
    }
  } catch (error) {
    // console.error("API error analyzing website:", error);

    // Fallback to mock data if the API call fails
    // This is useful during development or if the server is down
    // console.warn("Using fallback mock data for website analysis");

    // Add a clear indication that this is fallback data
    const fallbackData = {
      colors: {
        primary: "#01b280",
        secondary: "#6f57a5",
        background: "#111111",
        text: "#ffffff",
        _isFallback: true  // Add this flag to indicate fallback data
      },
      fonts: {
        primary: "Inter, sans-serif",
        secondary: "Poppins, sans-serif",
      },
      images: [
        {
          url: `${url}/logo.svg`,
          type: "logo",
          dimensions: { width: 200, height: 60 },
        },
      ],
      companyDetails: {
        name: url.split("//")[1]?.split(".")[0] || "Example Company",
        industry: "Technology",
      },
    };

    // Alert the user that we're using fallback data
    alert("We couldn't extract colors from this website automatically. This could be because the site uses JavaScript for styling or has minimal HTML content. You'll see default colors that you can customize manually.");

    return fallbackData;
  }
};

/**
 * Submit the brand profile data to Firestore
 * @param {object} formData - The complete form data to submit
 * @returns {Promise<object>} - The submission result with profile URL
 */
export const submitForm = async (formData) => {
  try {
    // Ensure the data structure matches what BrandProfile expects
    const dataToSubmit = {
      ...formData,
      // Map brandCharacteristics to characteristics for consistency
      characteristics: formData.brandCharacteristics || {},
      // Ensure all required sections exist
      brandBackground: formData.brandBackground || {},
      brandPersonality: formData.brandPersonality || {},
      brandStrategy: formData.brandStrategy || {},
      brandSound: formData.brandSound || {},
      demographics: {
        currentAgeRange: formData.demographics?.currentAgeRange || "",
        targetAgeRange: formData.demographics?.targetAgeRange || "",
        currentTerritory: formData.demographics?.currentTerritory || "",
        targetTerritory: formData.demographics?.targetTerritory || "",
        currentGender: formData.demographics?.currentGender || "",
        targetGender: formData.demographics?.targetGender || "",
        currentInterests: formData.demographics?.currentInterests || "",
        targetInterests: formData.demographics?.targetInterests || "",
        currentLifestyle: formData.demographics?.currentLifestyle || "",
        targetLifestyle: formData.demographics?.targetLifestyle || "",
        coreValues: formData.demographics?.coreValues || "",
        brandEssence: formData.demographics?.brandEssence || "",
      },
      projectGoals: formData.projectGoals || {},
      brandInfo: formData.brandInfo || {},
      brandStyle: formData.brandStyle || {},
      websiteUrl: formData.websiteUrl || "",
      createdAt: serverTimestamp(),
      status: "pending", // Initial status for the brand profile
    };

    // Add the document to the 'brandProfiles' collection
    const docRef = await addDoc(collection(db, "brandProfiles"), dataToSubmit);

    return {
      success: true,
      submissionId: docRef.id,
    };
  } catch (error) {
    // console.error("Error submitting form to Firestore:", error);
    throw new Error("Failed to submit form. Please try again.");
  }
};

/**
 * Generate visualizations for the brand profile
 * @param {object} formData - The form data to visualize
 * @returns {Promise<object>} - Object containing visualization data URLs
 */
const generateVisualizations = async (formData) => {
  // TODO: Implement actual visualization generation
  // For now, return mock data
  return {
    characteristicsRadar: "data:image/svg+xml,<svg>...</svg>",
    colorPalette: "data:image/svg+xml,<svg>...</svg>",
    // Add more visualizations as needed
  };
};

/**
 * Store visualizations in Firebase Storage
 * @param {string} profileId - The brand profile ID
 * @param {object} visualizations - Object containing visualization data URLs
 */
const storeVisualizations = async (profileId, visualizations) => {
  try {
    const promises = Object.entries(visualizations).map(([key, dataUrl]) => {
      const visualRef = ref(storage, `brand-profiles/${profileId}/${key}.svg`);
      return uploadString(visualRef, dataUrl, "data_url");
    });

    await Promise.all(promises);
  } catch (error) {
    // console.error("Error storing visualizations:", error);
    throw new Error("Failed to store visualizations");
  }
};

/**
 * Generate an email export of the brand profile
 * @param {object} formData - The form data to include in the email
 * @param {boolean} includeVisualizations - Whether to include visualizations
 * @returns {Promise<object>} - The email generation result
 */
export const generateEmailExport = async (
  formData,
  includeVisualizations = true
) => {
  try {
    const profileUrl = formData.profileUrl;
    const clientEmail = formData.clientEmail;

    if (!clientEmail) {
      throw new Error("Client email is required to generate email export");
    }

    // console.log(`Generating email export for: ${clientEmail}`);

    // Generate email content
    const emailContent = {
      to: clientEmail,
      subject: `Brand Profile: ${formData.brandInfo?.companyName || "New Brand"}`,
      html: `
        <h1>Brand Profile for ${formData.brandInfo?.companyName}</h1>
        <p>Your brand profile has been created successfully!</p>
        <p>View your complete profile here: <a href="${profileUrl}">${profileUrl}</a></p>
        
        <h2>Brand Profile Summary</h2>
        <div style="margin-top: 20px; padding: 20px; background-color: #f8f8f8; border-radius: 5px;">
          <h3>Company Information</h3>
          <p><strong>Company Name:</strong> ${formData.brandInfo?.companyName || "Not specified"}</p>
          <p><strong>Industry:</strong> ${formData.brandInfo?.industry || "Not specified"}</p>
          ${formData.brandInfo?.brandDescription ?
          `<p><strong>Brand Description:</strong> ${formData.brandInfo.brandDescription}</p>` : ""}
          
          ${formData.brandStyle?.colors ?
          `<h3>Brand Colors</h3>
             <div style="display: flex; gap: 10px; margin-bottom: 20px;">
               ${Object.entries(formData.brandStyle.colors).map(([name, color]) =>
            `<div style="text-align: center;">
                    <div style="width: 50px; height: 50px; background-color: ${color}; border-radius: 5px;"></div>
                    <p style="margin-top: 5px; font-size: 12px;">${name}</p>
                  </div>`
          ).join('')}
             </div>` : ""}
          
          ${formData.demographics?.brandEssence ?
          `<h3>Brand Essence</h3>
             <p>${formData.demographics.brandEssence}</p>` : ""}
        </div>
        
        <p style="margin-top: 30px;">Thank you for using our brand profiling service. We're excited to help bring your brand to life!</p>
      `,
      plainText: `
        Brand Profile for ${formData.brandInfo?.companyName}
        
        Your brand profile has been created successfully!
        View your complete profile here: ${profileUrl}
        
        BRAND PROFILE SUMMARY
        ---------------------
        
        Company Information:
        - Company Name: ${formData.brandInfo?.companyName || "Not specified"}
        - Industry: ${formData.brandInfo?.industry || "Not specified"}
        ${formData.brandInfo?.brandDescription ?
          `- Brand Description: ${formData.brandInfo.brandDescription}` : ""}
        
        ${formData.demographics?.brandEssence ?
          `Brand Essence:
        ${formData.demographics.brandEssence}` : ""}
        
        Thank you for using our brand profiling service. We're excited to help bring your brand to life!
      `,
    };

    // In a real implementation, we would send this email here
    // For now we're just returning the content that would be sent
    // console.log("Email content generated successfully");

    return {
      success: true,
      emailContent,
      profileUrl,
      recipientEmail: clientEmail
    };
  } catch (error) {
    // console.error("Error generating email:", error);
    throw new Error("Failed to generate email export");
  }
};

export const analyzeUrl = async (url) => {
  try {
    // Implement URL analysis logic here
    // This could involve calling an external API or processing the URL
    return {
      title: "Sample Analysis",
      description: "This is a placeholder for URL analysis results",
    };
  } catch (error) {
    // console.error("Error analyzing URL:", error);
    throw new Error("Failed to analyze URL. Please try again.");
  }
};
