/**
 * Questionnaire Data
 * Centralized location for all questionnaire steps, questions, and options
 * This makes it easier to:
 * 1. Maintain the questionnaire content
 * 2. Add/remove/reorder steps
 * 3. Support internationalization in the future
 * 4. Configure validation rules
 */

export const questionnaireSteps = [
  {
    id: "website-analysis",
    title: "Website Analysis",
    description: "Let's analyze your existing website to get started",
    component: "UrlAnalyzer",
    required: true,
  },
  {
    id: "brand-info",
    title: "Company Information",
    description: "Tell us about your company",
    component: "BrandInfoForm",
    required: true,
    fields: [
      {
        id: "companyName",
        label: "Company Name",
        type: "text",
        placeholder: "Enter your company name",
        required: true,
      },
      {
        id: "industry",
        label: "Industry",
        type: "text",
        placeholder: "What industry are you in?",
        required: true,
      },
      {
        id: "brandDescription",
        label: "Brand Description",
        type: "textarea",
        placeholder: "Describe your brand in a few sentences",
        required: false,
      },
    ],
  },
  {
    id: "brand-background",
    title: "Brand Background & Goals",
    description: "Tell us about the foundation and goals of your brand",
    component: "BrandBackgroundForm",
    required: true,
    fields: [
      {
        id: "brandDescription",
        label:
          "If you had to explain your brand to somebody that's never heard of it, how would you describe it?",
        type: "textarea",
        placeholder: "Describe your brand in a few sentences",
        required: true,
      },
      {
        id: "brandPersona",
        label: "If your brand was a person, how would you describe them?",
        type: "textarea",
        placeholder: "Describe your brand as a person",
        required: true,
      },
      {
        id: "brandOrigin",
        label: "Where does the story of the brand begin?",
        type: "textarea",
        placeholder: "Share your brand's origin story",
        required: false,
      },
      {
        id: "recentShifts",
        label:
          "Has the brand gone under any significant shifts in the past 12 months?",
        type: "textarea",
        placeholder: "Describe any recent changes",
        required: false,
      },
      {
        id: "upcomingShifts",
        label:
          "Are there any big shifts coming for the brand that we should be aware of?",
        type: "textarea",
        placeholder: "Share any upcoming changes",
        required: false,
      },
      {
        id: "recentExcitement",
        label:
          "What is the most exciting thing to happen at the brand in the last 12 months?",
        type: "textarea",
        placeholder: "Share recent wins or developments",
        required: false,
      },
      {
        id: "upcomingExcitement",
        label:
          "What one thing is the brand doing in the next 12 months that is exciting and we should know about?",
        type: "textarea",
        placeholder: "Tell us about upcoming plans",
        required: false,
      },
      {
        id: "biggestFans",
        label:
          "Who are your biggest fans, and what do they love most about you?",
        type: "textarea",
        placeholder:
          "Describe your core audience demographics and what they value",
        required: true,
      },
      {
        id: "isTargetMarket",
        label: "Are they your target market?",
        type: "radio",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
          { value: "other", label: "Other" },
        ],
        required: true,
      },
      {
        id: "targetMarketOther",
        label: "Please specify your target market if different",
        type: "textarea",
        placeholder: "Describe your target market",
        required: false,
        conditionalDisplay: {
          dependsOn: "isTargetMarket",
          showWhen: ["no", "other"],
        },
      },
    ],
  },
  {
    id: "brand-personality",
    title: "Brand Personality",
    description: "Help us understand your brand's unique character",
    component: "BrandPersonalityForm",
    required: true,
    fields: [
      {
        id: "culturalTrends",
        label:
          "Which cultural or societal trends do you think aligns with the brand?",
        type: "textarea",
        placeholder: "Describe relevant cultural or societal trends",
        required: true,
      },
      {
        id: "magicWand",
        label:
          "If you had a magic wand, what problem would you fix for your audience to make their lives better?",
        type: "textarea",
        placeholder: "Describe the key problem you'd solve",
        required: true,
      },
      {
        id: "biggestMisconception",
        label: "What's the biggest misconception about your brand?",
        type: "textarea",
        placeholder: "Share common misunderstandings about your brand",
        required: true,
      },
      {
        id: "biggestStrength",
        label:
          "What is the brand's biggest strength that makes your brand different from competitors?",
        type: "textarea",
        placeholder: "Describe your unique selling proposition",
        required: true,
      },
      {
        id: "surprisingFeedback",
        label:
          "What's the most surprising feedback you've ever received about the brand?",
        type: "textarea",
        placeholder: "Share unexpected feedback you've received",
        required: false,
      },
      {
        id: "celebrityChoice",
        label: "If you were hiring a celebrity, who would you choose?",
        type: "text",
        placeholder: "Name a celebrity who embodies your brand",
        required: false,
      },
      {
        id: "hiddenQuality",
        label:
          "What is your favourite thing about the brand that the audience never notices?",
        type: "textarea",
        placeholder: "Share an unnoticed but valued aspect of your brand",
        required: false,
      },
    ],
  },
  {
    id: "brand-strategy",
    title: "Brand Strategy",
    description: "Let's dig into your strategic positioning",
    component: "BrandStrategyForm",
    required: true,
    fields: [
      {
        id: "competitors",
        label: "Who are your 3 main competitors?",
        type: "text",
        placeholder: "List your main competitors",
        required: true,
      },
      {
        id: "perceptionChange",
        label:
          "If you could change one thing about the brand perception what would it be?",
        type: "textarea",
        placeholder:
          "Describe what you'd like to change about your brand perception",
        required: true,
      },
      {
        id: "globalRecognition",
        label:
          "Where in the world do you want the brand to be most recognised?",
        type: "textarea",
        placeholder: "Describe your target geographic markets",
        required: true,
      },
      {
        id: "mostOnBrand",
        label:
          "Which part of the customer journey do you think is most 'on-brand'?",
        type: "textarea",
        placeholder: "Describe the strongest part of your customer experience",
        required: true,
      },
      {
        id: "needsImprovement",
        label:
          "Which part of the customer journey do you think needs most work?",
        type: "textarea",
        placeholder: "Describe areas that need improvement",
        required: true,
      },
    ],
  },
  {
    id: "demographics",
    title: "Demographic Information",
    description: "Tell us about your current and target audience",
    component: "DemographicsForm",
    required: true,
    fields: [
      {
        id: "ageRange",
        label: "Age Range (Current & Target)",
        type: "textarea",
        placeholder: "Describe your current and target age ranges",
        required: true,
      },
      {
        id: "territory",
        label: "Territory (Current & Target)",
        type: "textarea",
        placeholder: "Describe your current and target territories",
        required: true,
      },
      {
        id: "gender",
        label: "Gender (Current & Target)",
        type: "textarea",
        placeholder: "Describe your current and target gender demographics",
        required: true,
      },
      {
        id: "interests",
        label: "Interests/Hobbies (Current & Target)",
        type: "textarea",
        placeholder: "Describe your audience's interests and hobbies",
        required: true,
      },
      {
        id: "lifestyle",
        label: "Lifestyle/Day in the life",
        type: "textarea",
        placeholder:
          "Are there any specific characteristics, habits or routines?",
        required: false,
      },
      {
        id: "coreValues",
        label: "Core Values",
        type: "textarea",
        placeholder: "Describe your brand's core values",
        required: true,
      },
      {
        id: "brandEssence",
        label: "Brand Essence",
        type: "textarea",
        placeholder: "Describe the essence of your brand in a few words",
        required: true,
      },
      {
        id: "currentChannels",
        label: "Where do people currently hear your brand?",
        type: "textarea",
        placeholder: "Eg. Social Media, TV, Ads, Apps etc.?",
        required: true,
      },
      {
        id: "currentEnvironments",
        label: "What environment do people usually hear the brand?",
        type: "textarea",
        placeholder: "Eg. Living room, on a bus, in a store, events etc.?",
        required: true,
      },
    ],
  },
  {
    id: "brand-sound",
    title: "The Brand Sound",
    description: "Let's explore your brand's sonic identity",
    component: "BrandSoundForm",
    required: true,
    fields: [
      {
        id: "currentSound",
        label: "How would you describe the current brand sound?",
        type: "textarea",
        placeholder: "Describe your current sonic branding",
        required: false,
      },
      {
        id: "soundChallenge",
        label: "What has been the main challenge for the brand's sound so far?",
        type: "textarea",
        placeholder: "Describe challenges with your brand sound",
        required: false,
      },
      {
        id: "soundCompetitor",
        label: "Is there a competitor that you think uses sound well?",
        type: "textarea",
        placeholder: "Name competitors with effective sonic branding",
        required: false,
      },
      {
        id: "aspirationalBrand",
        label:
          "Is there a brand you think of when you think about how you want the brand to be perceived in the future?",
        type: "textarea",
        placeholder: "Share aspirational brand examples",
        required: false,
      },
      {
        id: "musicBudget",
        label: "How much are you currently spending on music annually?",
        type: "radio",
        options: [
          { value: "0-10k", label: "£0 - £10k" },
          { value: "10k-25k", label: "£10k - £25k" },
          { value: "25k-50k", label: "£25k - £50k" },
          { value: "50k-75k", label: "£50k - £75k" },
          { value: "75k-100k", label: "£75k - £100k" },
          { value: "100k+", label: "£100k+" },
        ],
        required: true,
      },
    ],
  },
  {
    id: "brand-characteristics",
    title: "Brand Characteristics",
    description: "Quick-fire sliders to determine key traits of your brand",
    component: "BrandCharacteristicsForm",
    required: true,
    fields: [
      {
        id: "timeOfDay",
        label: "Is your brand a morning, midday or evening brand?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Morning",
        rightLabel: "Evening",
        required: true,
      },
      {
        id: "ageFeel",
        label: "Would your brand be youthful or mature?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Youthful",
        rightLabel: "Mature",
        required: true,
      },
      {
        id: "socialNature",
        label: "Would your brand be outspoken or introverted?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Introverted",
        rightLabel: "Outspoken",
        required: true,
      },
      {
        id: "tempo",
        label: "Does it feel like a run or a walk?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Walk",
        rightLabel: "Run",
        required: true,
      },
      {
        id: "volume",
        label: "Is it a whisper or a shout?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Whisper",
        rightLabel: "Shout",
        required: true,
      },
      {
        id: "community",
        label: "Is it an individual or a crowd?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Individual",
        rightLabel: "Crowd",
        required: true,
      },
      {
        id: "formality",
        label: "Does it feel casual or formal?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Casual",
        rightLabel: "Formal",
        required: true,
      },
      {
        id: "density",
        label: "Does it feel spacious or dense?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Spacious",
        rightLabel: "Dense",
        required: true,
      },
      {
        id: "weight",
        label: "Does it feel light and airy or thick and heavy?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Light & Airy",
        rightLabel: "Thick and Heavy",
        required: true,
      },
      {
        id: "digitalNature",
        label: "Does it feel natural or digital?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Natural",
        rightLabel: "Digital",
        required: true,
      },
      {
        id: "soundNature",
        label: "Does it feel organic or electronic?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Organic",
        rightLabel: "Electronic",
        required: true,
      },
      {
        id: "playfulness",
        label: "Does it feel serious or playful?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Serious",
        rightLabel: "Playful",
        required: true,
      },
      {
        id: "predictability",
        label: "Does it feel steady and predictable or constantly changing?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Steady",
        rightLabel: "Constantly Changing",
        required: true,
      },
      {
        id: "power",
        label: "Does it feel powerful or soft?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Soft",
        rightLabel: "Powerful",
        required: true,
      },
      {
        id: "intensity",
        label: "Does it feel intense or innocent?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Innocent",
        rightLabel: "Intense",
        required: true,
      },
      {
        id: "brightness",
        label: "Is it sunny or dark?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Dark",
        rightLabel: "Bright",
        required: true,
      },
      {
        id: "temperature",
        label: "Is it warm or cool?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Cool",
        rightLabel: "Warm",
        required: true,
      },
      {
        id: "tension",
        label: "Is it relaxed or on edge?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Relaxed",
        rightLabel: "On Edge",
        required: true,
      },
      {
        id: "timelessness",
        label: "Is it modern and trendy or classic and timeless?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Modern & Trendy",
        rightLabel: "Classic & Timeless",
        required: true,
      },
      {
        id: "structure",
        label: "Does it feel tight and focussed or loose and free-flowing?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Tight & Focussed",
        rightLabel: "Loose & Free-Flowing",
        required: true,
      },
      {
        id: "organization",
        label:
          "Does it feel organised and structured or wild and unpredictable?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Organised & Structured",
        rightLabel: "Wild & Unpredictable",
        required: true,
      },
      {
        id: "complexity",
        label: "Is it simple or complex?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Simple",
        rightLabel: "Complex",
        required: true,
      },
      {
        id: "layers",
        label: "Is it a single layer or multiple layers?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Single",
        rightLabel: "Layered",
        required: true,
      },
      {
        id: "groundedness",
        label: "Is it earthy or ethereal?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Earthy",
        rightLabel: "Ethereal",
        required: true,
      },
      {
        id: "gravity",
        label: "Is it grounded or floaty?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Grounded",
        rightLabel: "Floaty",
        required: true,
      },
      {
        id: "teamwork",
        label: "Is it a pack or is it a solo mission?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Pack",
        rightLabel: "Solo Mission",
        required: true,
      },
      {
        id: "energy",
        label: "Is it uplifting like a cheer or calm like a sigh?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Calm",
        rightLabel: "Uplifting",
        required: true,
      },
      {
        id: "focus",
        label:
          "Is it focussed on one clear idea or a blend of overlapping parts?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "One Clear Idea",
        rightLabel: "Overlapping Parts",
        required: true,
      },
      {
        id: "innovation",
        label: "Does it feel traditional or experimental?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Traditional",
        rightLabel: "Experimental",
        required: true,
      },
      {
        id: "novelty",
        label: "Does it feel familiar or new?",
        type: "slider",
        min: 1,
        max: 5,
        leftLabel: "Familiar",
        rightLabel: "New",
        required: true,
      },
    ],
  },
  {
    id: "project-goals",
    title: "Sonic Branding Project Goals",
    description: "Help us understand your expectations for this project",
    component: "ProjectGoalsForm",
    required: true,
    fields: [
      {
        id: "mainGoal",
        label:
          "What is the main goal you would be thrilled to achieve with utilising Sonic Branding?",
        type: "textarea",
        placeholder: "Describe your primary goal for sonic branding",
        required: true,
      },
      {
        id: "processExpectations",
        label: "Are there any specific expectations for the process?",
        type: "textarea",
        placeholder: "Share any expectations about how we work together",
        required: false,
      },
      {
        id: "deadlines",
        label: "Are there any deadlines we need to be aware about?",
        type: "textarea",
        placeholder: "Share any important dates or timeframes",
        required: false,
      },
      {
        id: "involvement",
        label:
          "How important is being physically involved in the creative process to you and your team?",
        type: "textarea",
        placeholder: "Describe your preferred level of involvement",
        required: true,
      },
    ],
  },
  {
    id: "final-review",
    title: "Review & Submit",
    description: "Review your responses and submit your brand profile",
    component: "ReviewForm",
    required: true,
  },
];

// Utility functions for working with questionnaire data
export const getStepById = (stepId) => {
  return questionnaireSteps.find((step) => step.id === stepId);
};

export const getNextStepId = (currentStepId) => {
  const currentIndex = questionnaireSteps.findIndex(
    (step) => step.id === currentStepId
  );
  if (currentIndex === -1 || currentIndex === questionnaireSteps.length - 1) {
    return null;
  }
  return questionnaireSteps[currentIndex + 1].id;
};

export const getPreviousStepId = (currentStepId) => {
  const currentIndex = questionnaireSteps.findIndex(
    (step) => step.id === currentStepId
  );
  if (currentIndex <= 0) {
    return null;
  }
  return questionnaireSteps[currentIndex - 1].id;
};
