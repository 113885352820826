import React from "react";
import { motion, useTransform } from "framer-motion";

const HeroSection = ({
  brandName,
  industry,
  scrollProgress,
  headerStyle,
  textStyle,
  heroImage,
  logoImage,
  palette,
  profileId,
  activeTab,
  setActiveTab,
}) => {
  const opacity = useTransform(scrollProgress, [0, 0.35], [1, 0]);
  const translateY = useTransform(scrollProgress, [0, 0.35], [0, -50]);
  const bgY = useTransform(scrollProgress, [0, 1], ["0%", "50%"]);
  const bgScale = useTransform(scrollProgress, [0, 0.5], [1, 1.1]);

  return (
    <motion.div
      className="h-screen relative flex items-center justify-center text-center overflow-hidden"
      style={{
        opacity,
        translateY,
        backgroundColor: "#111111", // Dark background for better contrast
      }}
    >
      {/* Dot pattern background */}
      <div className="absolute inset-0 -z-10">
        <div
          className="absolute inset-0 opacity-3"
          style={{
            background: `radial-gradient(circle at 1px 1px, var(--primary-color) 1px, transparent 0)`,
            backgroundSize: "50px 50px", // Increased spacing between dots
          }}
        ></div>
      </div>

      {/* Hero image background with parallax effect */}
      {heroImage && (
        <motion.div
          className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${heroImage})`,
            y: bgY,
            scale: bgScale,
            opacity: 0.25, // Increased from 0.1 for better visibility
            filter: "contrast(1.15) brightness(1.05)", // Added filter to enhance image
          }}
        />
      )}

      {/* Gradient overlay */}
      <motion.div
        className="absolute inset-0 w-full h-full"
        style={{
          y: bgY,
          backgroundImage: `radial-gradient(circle at 50% 50%, var(--primary-color-20) 0%, transparent 70%)`,
          opacity: 0.5, // Reduced from 0.6 to allow more image visibility
          mixBlendMode: "overlay", // Added blend mode for better integration
        }}
      />

      <div className="relative z-10 max-w-4xl mx-auto px-6">
        {logoImage && (
          <motion.div
            className="mb-12 flex justify-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src={logoImage}
              alt="Brand Logo"
              className="max-h-32 max-w-full object-contain"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = "none";
              }}
            />
          </motion.div>
        )}

        <motion.h1
          className="text-6xl md:text-8xl leading-none mb-8 font-bold"
          style={{
            fontFamily: headerStyle.fontFamily,
            color: "#ffffff", // White for better contrast
            textShadow: `0 2px 4px rgba(0,0,0,0.3)`,
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {brandName}
        </motion.h1>
        <motion.p
          className="text-2xl md:text-3xl mb-8"
          style={{
            fontFamily: textStyle.fontFamily,
            color: "rgba(255,255,255,0.7)", // Semi-transparent white
            opacity: 0.9,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {industry}
        </motion.p>

        {/* Tab Navigation - Improved and modernized */}
        <motion.div
          className="inline-flex items-center bg-black/40 backdrop-blur-md rounded-full p-1.5 border border-white/10 shadow-lg relative overflow-hidden mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          style={{
            boxShadow:
              "0 4px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(var(--primary-color-rgb), 0.2)",
          }}
        >
          {/* Animated background that moves with the selected tab */}
          <motion.div
            className="absolute h-full rounded-full z-0"
            animate={{
              x: activeTab === "brandDiscovery" ? 0 : "100%",
              width: activeTab === "brandDiscovery" ? "50%" : "50%",
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              mass: 1,
            }}
            style={{
              background: `linear-gradient(135deg, var(--primary-color-20) 0%, var(--primary-color) 100%)`,
              top: 0,
              left: 0,
            }}
          />

          <button
            className={`px-6 py-3 rounded-full text-lg font-medium transition-colors duration-200 relative z-10
              ${
                activeTab === "brandDiscovery"
                  ? "text-white"
                  : "text-white/60 hover:text-white"
              }`}
            onClick={() => setActiveTab("brandDiscovery")}
          >
            <motion.div
              initial={false}
              animate={{ scale: activeTab === "brandDiscovery" ? 1.05 : 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
              className="flex items-center gap-2"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 8V16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 12H16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Brand Discovery</span>
            </motion.div>
          </button>

          <button
            className={`px-6 py-3 rounded-full text-lg font-medium transition-colors duration-200 relative z-10
              ${
                activeTab === "soundboard"
                  ? "text-white"
                  : "text-white/60 hover:text-white"
              }`}
            onClick={() => setActiveTab("soundboard")}
          >
            <motion.div
              initial={false}
              animate={{ scale: activeTab === "soundboard" ? 1.05 : 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
              className="flex items-center gap-2"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 10C2 6.22876 2 4.34315 3.17157 3.17157C4.34315 2 6.22876 2 10 2H14C17.7712 2 19.6569 2 20.8284 3.17157C22 4.34315 22 6.22876 22 10V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V10Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M6 12H8"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M16 12H18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M11 7H13"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M11 17H13"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <span>Soundboard</span>
            </motion.div>
          </button>
        </motion.div>

        <motion.div
          className="animate-bounce"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <div
            className="w-8 h-14 border-2 rounded-full mx-auto flex justify-center"
            style={{ borderColor: "var(--accent-color)" }}
          >
            <div
              className="w-1.5 h-4 rounded-full mt-2"
              style={{ backgroundColor: "var(--accent-color)" }}
            ></div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default HeroSection;
