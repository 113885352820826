import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Search, Loader, Check, AlertCircle, Info } from "lucide-react";

/**
 * UrlAnalyzer component - First step of the onboarding process
 * Redesigned with a "floating in space" approach - no boxed inputs, bigger animated elements
 * Removed all background containers for a pure floating appearance
 */
const UrlAnalyzer = ({
  onAnalyze,
  isAnalyzing,
  analysisResults,
  analyzeError,
  inTimelineMode = false,
}) => {
  const [url, setUrl] = useState("");
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [usingFallbackColors, setUsingFallbackColors] = useState(false);
  const inputRef = useRef(null);
  const cursorRef = useRef(null);

  // Cursor animation
  const [cursorVisible, setCursorVisible] = useState(true);

  // Cursor blink effect when input is focused
  useEffect(() => {
    let blinkInterval;
    if (isFocused) {
      blinkInterval = setInterval(() => {
        setCursorVisible((prev) => !prev);
      }, 530);
    } else {
      setCursorVisible(false);
    }

    return () => clearInterval(blinkInterval);
  }, [isFocused]);

  // Reset analysis complete state when results change
  useEffect(() => {
    if (analysisResults) {
      setIsAnalysisComplete(true);
      // Check if we're using fallback colors
      setUsingFallbackColors(analysisResults.colors?._isFallback || false);
    }
  }, [analysisResults]);

  // Validate URL as user types
  const handleUrlChange = (e) => {
    const inputUrl = e.target.value;
    setUrl(inputUrl);

    // Basic URL validation
    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
    setIsUrlValid(urlPattern.test(inputUrl));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUrlValid && !isAnalyzing) {
      onAnalyze(url);
    }
  };

  // Handle input focus
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Handle input blur
  const handleBlur = () => {
    setIsFocused(false);
  };

  // Simplified content for timeline mode
  if (inTimelineMode) {
    return (
      <div className="w-full">
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="space-y-4">
            <div className="relative">
              <motion.div
                className="flex items-center space-x-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="text-white opacity-70"
                >
                  <Search
                    className={`w-6 h-6 ${url && !isUrlValid
                      ? "text-red-500"
                      : isUrlValid
                        ? "text-primary-green"
                        : "text-white"
                      }`}
                  />
                </motion.div>

                <div className="relative flex-1">
                  <input
                    ref={inputRef}
                    id="website-url"
                    type="url"
                    value={url}
                    onChange={handleUrlChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Enter your website URL"
                    className="w-full bg-transparent text-white text-xl md:text-2xl border-none outline-none focus:outline-none focus:ring-0 py-2"
                    disabled={isAnalyzing || isAnalysisComplete}
                  />

                  {/* Animated underline */}
                  <motion.div
                    className="absolute bottom-0 left-0 h-[1px] bg-white"
                    initial={{ width: "0%" }}
                    animate={{
                      width: isFocused ? "100%" : url ? "100%" : "0%",
                      backgroundColor:
                        url && !isUrlValid
                          ? "#ef4444"
                          : isUrlValid
                            ? "rgb(1, 178, 128)"
                            : "#ffffff",
                    }}
                    transition={{ duration: 0.3 }}
                  />

                  {/* Animated cursor */}
                  {isFocused && (
                    <motion.div
                      ref={cursorRef}
                      className="absolute h-6 w-[2px] bg-white top-1/2 -translate-y-1/2"
                      style={{
                        left: `${url.length * 0.6 + 0.5}em`,
                        opacity: cursorVisible ? 1 : 0,
                        backgroundColor: isUrlValid
                          ? "rgb(1, 178, 128)"
                          : "#ffffff",
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: cursorVisible ? 1 : 0 }}
                    />
                  )}
                </div>
              </motion.div>
            </div>

            <AnimatePresence>
              {url && !isUrlValid && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 flex items-center mt-1 text-sm ml-10"
                >
                  <AlertCircle className="w-3 h-3 mr-1" />
                  Please enter a valid website URL
                </motion.p>
              )}
            </AnimatePresence>
          </div>

          <div className="flex flex-col space-y-6">
            <AnimatePresence mode="wait">
              {!isAnalysisComplete ? (
                <motion.button
                  key="analyze-button"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  whileHover={{ scale: 1.05, y: -5 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  disabled={!isUrlValid || isAnalyzing}
                  className={`py-4 rounded-full font-medium flex items-center justify-center ${isUrlValid && !isAnalyzing
                    ? "bg-primary-green text-black hover:bg-primary-green/90"
                    : "bg-transparent text-gray-300 border border-gray-700 cursor-not-allowed"
                    } transition-all duration-300 w-64 mx-auto`}
                >
                  {isAnalyzing ? (
                    <>
                      <Loader className="w-5 h-5 mr-2 animate-spin" />
                      Analyzing...
                    </>
                  ) : (
                    "Analyze Website"
                  )}
                </motion.button>
              ) : (
                <motion.button
                  key="analyze-again-button"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  whileHover={{ scale: 1.05, y: -5 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  onClick={() => {
                    setUrl("");
                    setIsUrlValid(false);
                    setIsAnalysisComplete(false);
                  }}
                  className="py-4 rounded-full bg-transparent text-white border border-white/20 hover:border-white/40 transition-all duration-300 w-64 mx-auto"
                >
                  Analyze Another Website
                </motion.button>
              )}
            </AnimatePresence>
          </div>
        </form>

        <AnimatePresence>
          {analyzeError && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="mt-8 text-red-400 flex items-start"
            >
              <AlertCircle className="w-5 h-5 mr-3 flex-shrink-0 mt-0.5" />
              <div>
                <p className="font-medium">Analysis Error</p>
                <p className="text-sm mt-1">{analyzeError}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isAnalysisComplete && analysisResults && (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              className="mt-12"
            >
              <motion.h3
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                className="text-xl font-medium text-primary-green flex items-center"
              >
                <Check className="w-5 h-5 mr-2" />
                Website Analysis Complete
              </motion.h3>

              {usingFallbackColors && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="mt-4 flex items-center text-amber-500 bg-amber-50 p-3 rounded-lg"
                >
                  <Info className="mr-2 h-5 w-5 flex-shrink-0" />
                  <span className="text-sm">
                    We couldn't extract the actual colors from this website. This might be because it uses JavaScript for styling or has minimal HTML content. You're seeing default colors that you can customize manually.
                  </span>
                </motion.div>
              )}

              <div className="mt-8 grid grid-cols-1 gap-8">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <p className="text-sm font-medium text-gray-400 mb-4">
                    Extracted Colors
                  </p>
                  <div className="flex flex-wrap gap-4">
                    {Object.entries(analysisResults.colors).map(
                      ([name, color], index) => (
                        <motion.div
                          key={name}
                          className="flex flex-col items-center"
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ delay: 0.4 + index * 0.1 }}
                          whileHover={{ y: -5, scale: 1.1 }}
                        >
                          <div
                            className="w-12 h-12 rounded-full shadow-lg"
                            style={{ backgroundColor: color }}
                          />
                          <span className="text-sm text-white mt-2">
                            {color}
                          </span>
                        </motion.div>
                      )
                    )}
                  </div>
                </motion.div>

                {analysisResults.companyDetails?.name && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                  >
                    <p className="text-sm font-medium text-gray-400 mb-4">
                      Company Details
                    </p>
                    <div className="flex flex-col gap-3">
                      <p className="text-lg text-white">
                        <span className="text-gray-400 mr-2">Name:</span>
                        {analysisResults.companyDetails.name}
                      </p>
                      {analysisResults.companyDetails.industry && (
                        <p className="text-lg text-white">
                          <span className="text-gray-400 mr-2">Industry:</span>
                          {analysisResults.companyDetails.industry}
                        </p>
                      )}
                    </div>
                  </motion.div>
                )}
              </div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
                className="mt-10 text-center text-gray-400 text-sm"
              >
                <p>
                  Continue scrolling down to add more information about your
                  brand.
                </p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }

  // Full view for step-by-step mode, completely redesigned with floating elements directly on page background
  return (
    <div className="w-full max-w-4xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="py-8"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          Analyze Client Website
        </motion.h2>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="text-gray-400 mb-12 text-lg"
        >
          Enter the client's website URL to extract brand colors, fonts, and
          imagery for the onboarding form.
        </motion.p>

        <form onSubmit={handleSubmit} className="space-y-10">
          <div className="space-y-6">
            <motion.label
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
              htmlFor="website-url"
              className="block text-lg font-medium text-gray-300"
            >
              Website URL
            </motion.label>

            <div className="relative">
              <motion.div
                className="flex items-center space-x-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.6 }}
              >
                <motion.div
                  whileHover={{ scale: 1.1, rotate: 15 }}
                  className="text-white opacity-70"
                >
                  <Search
                    className={`w-8 h-8 ${url && !isUrlValid
                      ? "text-red-500"
                      : isUrlValid
                        ? "text-primary-green"
                        : "text-white"
                      }`}
                  />
                </motion.div>

                <div className="relative flex-1">
                  <input
                    ref={inputRef}
                    id="website-url"
                    type="url"
                    value={url}
                    onChange={handleUrlChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="https://client-website.com"
                    className="w-full bg-transparent text-white text-2xl md:text-3xl border-none outline-none focus:outline-none focus:ring-0 py-3"
                    disabled={isAnalyzing || isAnalysisComplete}
                  />

                  {/* Animated underline */}
                  <motion.div
                    className="absolute bottom-0 left-0 h-[2px] bg-white"
                    initial={{ width: "0%" }}
                    animate={{
                      width: isFocused ? "100%" : url ? "100%" : "0%",
                      backgroundColor:
                        url && !isUrlValid
                          ? "#ef4444"
                          : isUrlValid
                            ? "rgb(1, 178, 128)"
                            : "#ffffff",
                    }}
                    transition={{ duration: 0.3 }}
                  />

                  {/* Animated cursor */}
                  {isFocused && (
                    <motion.div
                      ref={cursorRef}
                      className="absolute h-8 w-[2px] bg-white top-1/2 -translate-y-1/2"
                      style={{
                        left: `${url.length * 0.6 + 0.5}em`,
                        opacity: cursorVisible ? 1 : 0,
                        backgroundColor: isUrlValid
                          ? "rgb(1, 178, 128)"
                          : "#ffffff",
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: cursorVisible ? 1 : 0 }}
                    />
                  )}
                </div>
              </motion.div>
            </div>

            <AnimatePresence>
              {url && !isUrlValid && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 flex items-center mt-1 text-lg ml-12"
                >
                  <AlertCircle className="w-4 h-4 mr-2" />
                  Please enter a valid website URL
                </motion.p>
              )}
            </AnimatePresence>
          </div>

          <div className="flex flex-col space-y-6 pt-6">
            <AnimatePresence mode="wait">
              {!isAnalysisComplete ? (
                <motion.button
                  key="analyze-button"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ delay: 0.6 }}
                  whileHover={{ scale: 1.05, y: -5 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  disabled={!isUrlValid || isAnalyzing}
                  className={`py-5 px-8 rounded-full font-medium flex items-center justify-center ${isUrlValid && !isAnalyzing
                    ? "bg-primary-green text-black hover:bg-primary-green/90"
                    : "bg-transparent text-gray-300 border border-gray-700 cursor-not-allowed"
                    } transition-all duration-300 text-lg w-72 mx-auto`}
                >
                  {isAnalyzing ? (
                    <>
                      <Loader className="w-5 h-5 mr-3 animate-spin" />
                      Analyzing Website...
                    </>
                  ) : (
                    "Analyze Website"
                  )}
                </motion.button>
              ) : (
                <motion.button
                  key="analyze-again-button"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  whileHover={{ scale: 1.05, y: -5 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  onClick={() => {
                    setUrl("");
                    setIsUrlValid(false);
                    setIsAnalysisComplete(false);
                  }}
                  className="py-5 px-8 rounded-full bg-transparent text-white border border-white/20 hover:border-white/40 transition-all duration-300 text-lg w-72 mx-auto"
                >
                  Analyze Another Website
                </motion.button>
              )}
            </AnimatePresence>
          </div>
        </form>

        <AnimatePresence>
          {analyzeError && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="mt-10 text-red-400 flex items-start"
            >
              <AlertCircle className="w-6 h-6 mr-3 flex-shrink-0 mt-0.5" />
              <div>
                <p className="font-medium text-lg">Analysis Error</p>
                <p className="text-base mt-2">{analyzeError}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isAnalysisComplete && analysisResults && (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              className="mt-16"
            >
              <motion.h3
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                className="text-2xl font-medium text-primary-green flex items-center"
              >
                <Check className="w-6 h-6 mr-3" />
                Website Analysis Complete
              </motion.h3>

              {usingFallbackColors && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="mt-4 flex items-center text-amber-500 bg-amber-50 p-3 rounded-lg"
                >
                  <Info className="mr-2 h-5 w-5 flex-shrink-0" />
                  <span className="text-sm">
                    We couldn't extract the actual colors from this website. This might be because it uses JavaScript for styling or has minimal HTML content. You're seeing default colors that you can customize manually.
                  </span>
                </motion.div>
              )}

              <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-10">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <p className="text-lg font-medium text-gray-400 mb-6">
                    Extracted Colors
                  </p>
                  <div className="flex flex-wrap gap-6">
                    {Object.entries(analysisResults.colors).map(
                      ([name, color], index) => (
                        <motion.div
                          key={name}
                          className="flex flex-col items-center"
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ delay: 0.4 + index * 0.1 }}
                          whileHover={{ y: -5, scale: 1.1 }}
                        >
                          <div
                            className="w-16 h-16 rounded-full shadow-lg"
                            style={{ backgroundColor: color }}
                          />
                          <span className="text-base text-white mt-3">
                            {color}
                          </span>
                        </motion.div>
                      )
                    )}
                  </div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <p className="text-lg font-medium text-gray-400 mb-6">
                    Detected Fonts
                  </p>
                  <div className="flex flex-col gap-3">
                    {Object.entries(analysisResults.fonts).map(
                      ([name, font], index) => (
                        <motion.div
                          key={name}
                          className="text-lg text-white"
                          initial={{ opacity: 0, x: -10 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: 0.5 + index * 0.1 }}
                        >
                          {font}
                        </motion.div>
                      )
                    )}
                  </div>
                </motion.div>

                {analysisResults.companyDetails?.name && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                    className="md:col-span-2"
                  >
                    <p className="text-lg font-medium text-gray-400 mb-6">
                      Company Details
                    </p>
                    <div className="flex flex-col gap-4">
                      <p className="text-xl text-white">
                        <span className="text-gray-400 mr-3">Name:</span>
                        {analysisResults.companyDetails.name}
                      </p>
                      {analysisResults.companyDetails.industry && (
                        <p className="text-xl text-white">
                          <span className="text-gray-400 mr-3">Industry:</span>
                          {analysisResults.companyDetails.industry}
                        </p>
                      )}
                    </div>
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default UrlAnalyzer;
