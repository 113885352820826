import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Edit, Eye, Archive, RefreshCw, Download } from "lucide-react";
import { collection, query, orderBy, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const BrandProfilesModule = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      const profilesRef = collection(db, "brandProfiles");
      const q = query(profilesRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const profilesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProfiles(profilesData);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching profiles:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchProfiles();
    } finally {
      setRefreshing(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "bg-yellow-500/20 text-yellow-500";
      case "active":
        return "bg-primary-green/20 text-primary-green";
      case "archived":
        return "bg-gray-500/20 text-gray-500";
      default:
        return "bg-white/20 text-white";
    }
  };

  const handleArchiveProfile = async (profileId) => {
    try {
      const profileRef = doc(db, "brandProfiles", profileId);
      await updateDoc(profileRef, {
        status: "archived"
      });

      // Refresh profiles after archiving
      await fetchProfiles();
    } catch (err) {
      console.error("Error archiving profile:", err);
      setError(err.message);
    }
  };

  // Function to export a profile document as JSON
  const exportProfileAsJson = async (profileId) => {
    try {
      // Get the full document data
      const profileRef = doc(db, "brandProfiles", profileId);
      const profileSnap = await getDoc(profileRef);

      if (!profileSnap.exists()) {
        toast.error("Profile not found");
        return;
      }

      // Get the data and add the document ID
      const profileData = {
        id: profileSnap.id,
        ...profileSnap.data()
      };

      // Convert timestamps to ISO strings for better readability
      const processedData = JSON.stringify(profileData, (key, value) => {
        // Convert any Firebase timestamps to ISO strings
        if (value && typeof value === 'object' && value.toDate && typeof value.toDate === 'function') {
          return value.toDate().toISOString();
        }
        return value;
      }, 2);

      // Create a blob and download link
      const blob = new Blob([processedData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `brand-profile-${profileId}.json`;

      // Trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Profile JSON exported successfully");
    } catch (err) {
      console.error("Error exporting profile:", err);
      toast.error("Failed to export profile");
    }
  };

  // Helper function to get the profile URL path
  const getProfileUrl = (profile) => {
    return profile.urlHandle ? `/brand-profile/${profile.urlHandle}` : `/brand-profile/${profile.id}`;
  };

  if (loading && !refreshing) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-white text-xl">Loading profiles...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Brand Profiles</h2>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRefresh}
          className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white/5 hover:bg-white/10 text-white"
          disabled={refreshing}
        >
          <RefreshCw className={`w-4 h-4 ${refreshing ? 'animate-spin' : ''}`} />
          <span>Refresh</span>
        </motion.button>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {profiles.map((profile) => (
          <motion.div
            key={profile.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/5 rounded-lg border border-white/10 p-6"
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-xl font-semibold text-white mb-2">
                  {profile.brandInfo?.companyName || "Unnamed Brand"}
                </h3>
                <div className="flex items-center gap-4 text-sm text-white/70 mb-2">
                  <span className="px-2 py-1 rounded-full bg-white/10">
                    {profile.brandInfo?.industry || "No Industry"}
                  </span>
                  <span>
                    Created:{" "}
                    {profile.createdAt
                      ? new Date(
                        profile.createdAt.toDate()
                      ).toLocaleDateString()
                      : "N/A"}
                  </span>
                  <span
                    className={`px-2 py-1 rounded-full ${getStatusColor(
                      profile.status
                    )}`}
                  >
                    {profile.status}
                  </span>
                </div>
                {profile.clientEmail ? (
                  <div className="flex items-center text-sm text-white/70">
                    <span className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      Client: <a href={`mailto:${profile.clientEmail}`} className="ml-1 text-primary-green hover:underline">{profile.clientEmail}</a>
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center text-sm text-white/40">
                    <span className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      Client: <span className="ml-1 italic">No email provided</span>
                    </span>
                  </div>
                )}
                {profile.urlHandle && (
                  <div className="flex items-center text-sm text-white/70 mt-1">
                    <span className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 010-5.656l4-4a4 4 0 015.656 5.656l-1.1 1.1" />
                      </svg>
                      URL: <span className="ml-1 font-mono bg-black/30 px-1 rounded">{profile.urlHandle}</span>
                    </span>
                  </div>
                )}
              </div>

              <div className="flex items-center gap-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate(getProfileUrl(profile))}
                  className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
                  title="View Profile"
                >
                  <Eye className="w-4 h-4" />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate(`/admin/brand-profiles/${profile.id}/edit`)}
                  className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
                  title="Edit Profile"
                >
                  <Edit className="w-4 h-4" />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => exportProfileAsJson(profile.id)}
                  className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
                  title="Export JSON"
                >
                  <Download className="w-4 h-4" />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleArchiveProfile(profile.id)}
                  className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
                  title="Archive Profile"
                >
                  <Archive className="w-4 h-4" />
                </motion.button>
              </div>
            </div>

            {/* Additional Profile Information */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-sm">
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="text-white/60 mb-2">Brand Sound</h4>
                <p className="text-white">
                  {profile.brandSound?.currentSound || "Not specified"}
                </p>
              </div>
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="text-white/60 mb-2">Target Audience</h4>
                <p className="text-white">
                  {profile.demographics?.targetAgeRange || "Not specified"}
                </p>
              </div>
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="text-white/60 mb-2">Brand Goals</h4>
                <p className="text-white">
                  {profile.projectGoals?.primaryGoal || "Not specified"}
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default BrandProfilesModule;
